<template>
  <div class="headerWrap">
    <div class="left">
      <div class="date">
        <div>
          <p>{{ date.date }}</p>
          <p>{{ date.day }}</p>
        </div>
        <div>
          <p>{{ date.time }}</p>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="title-container">
        <p class="subTitle" v-if="projectInfo.screenSubTitle">{{ projectInfo.screenSubTitle }}</p>
        <p class="title">{{ projectInfo.screenTitle }}</p>
      </div>
    </div>
    <div class="right">
      <div @click="goBack" class="user">
        <img src="~@/assets/images/shift.png" alt=""  />
        <p>{{$t('screen.goback')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import "vue-area-linkage/dist/index.css";
export default {
  data() {
    return {
      cityName: '',
      cityCode: '',
      selected: [],
      date: {
        time: null,
        date: null,
        month: null,
        day: null,
      },
      title: '',
      subTitle: '',
    };
  },
  props: {
    projectInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  mounted() {
    this.queryDate();
  },
  beforeDestroy() {
    if (this.$options.timmer) {
      clearInterval(this.$options.timmer)
      this.$options.timmer = null;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    toAdmin() {
      const { NODE_ENV } = process.env;
      const postData = localStorage.getItem("postData");
      if (NODE_ENV === "production") {
        window.location.href = `http://petroleummag.aossci.com/#/?postData=${encodeURI(
          postData
        )}`;
        return;
      }
      window.location.href = `http://petroleummag.dev.aossci.com/#/?postData=${encodeURI(
        postData
      )}`;
    },
    makeLevel(speed) {
      if (!speed) return "-";
      if (speed >= 1 && speed <= 5) {
        return "1";
      }
      if (speed > 5 && speed <= 11) {
        return "2";
      }
      if (speed > 11 && speed <= 19) {
        return "3";
      }
      if (speed > 19 && speed <= 28) {
        return "4";
      }
      if (speed > 28 && speed <= 38) {
        return "5";
      }
      if (speed > 38 && speed <= 49) {
        return "6";
      }
      if (speed > 49 && speed <= 61) {
        return "7";
      }
      if (speed > 61 && speed <= 74) {
        return "8";
      }
      if (speed > 74 && speed <= 88) {
        return "9";
      }
      if (speed > 88 && speed <= 102) {
        return "10";
      }
      if (speed > 117 && speed <= 134) {
        return "11";
      }
      if (speed > 134 && speed <= 149) {
        return "12";
      }
      if (speed > 149 && speed <= 166) {
        return "13";
      }
      if (speed > 166 && speed <= 183) {
        return "14";
      }
      if (speed > 183) {
        return "15";
      }
    },
    queryDate() {
      const days = [
        this.$t('Monday'),
        this.$t('Tuesday'),
        this.$t('Wednesday'),
        this.$t('Thursday'),
        this.$t('Friday'),
        this.$t('Saturday'),
        this.$t('Sunday')
      ]
      const date = new Date();
      this.date.date = dayjs(date).format("YYYY.MM.DD");
      this.date.time = dayjs(date).format("HH:mm:ss");
      this.date.day = days[date.getDay() - 1];
      this.timmer = setInterval(() => {
        const date = new Date();
        this.date.date = dayjs(date).format("YYYY.MM.DD");
        this.date.time = dayjs(date).format("HH:mm:ss");
        this.date.day = days[date.getDay() - 1];
      }, 1000);
    },
    logout() {
      localStorage.clear();
      this.$router.push({ path: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
.headerWrap {
  position: relative;
  color: #D9E6FF;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    .date {
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      color: #D9E6FF;
      &>div:last-child {
        margin-left: 20px;
        width: 137px;
        height: 42px;
        font-size: 32px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        color: #D9E6FF;
        line-height: 42px;
      }
    }
  }
  .center {
    height: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .title-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .subTitle {
        margin-top: 15px;
        height: 26px;
        font-size: 26px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        line-height: 26px;
        color: #85BEFF;
      }
      .title {
        height: 52px;
        font-size: 40px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        line-height: 52px;
        color: #FFF;
        // background-color: #ff0;
        background: linear-gradient(180deg, #AFC5DD 0%, #E9F1FF 53%, #7FB6FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .right {
    height: 100%;
    display: flex;
    padding-right: 12px;
    justify-content: flex-end;
    .user {
      padding-right: 8px;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      p {
        margin-top: 6px;
        user-select: none;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #D9E6FF;
        line-height: 12px;
      }
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
