var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "RealTime" }, [
    _c(
      "div",
      { staticClass: "RealTime-maporVideo" },
      [
        _c("left", {
          ref: "leftDom",
          attrs: { taskData: _vm.selectTaskData },
          on: { clearnSelectTask: _vm.clearnSelectTask },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.selectTaskData,
            expression: "!selectTaskData",
          },
        ],
        staticClass: "plan-mian",
      },
      [
        _c(
          "div",
          { staticClass: "plan-cont" },
          [
            _c("right", {
              ref: "rightDom",
              on: {
                changeSelectTask: _vm.changeSelectTask,
                clearnSelectTask: _vm.clearnSelectTask,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }