<template>
  <div class="login-container" ref="login" @keyup.enter.native="handleLogin">
    <top-color v-show="false"></top-color>
    <div v-if="type === 'login' || type === 'register'" :class="loginBoxClass">
      <div class="login-left">
        <img
          class="main-title--zh"
          src="/img/login/phugia-cloud-zh.png"
          alt=""
        />
        <!-- <img
          class="main-title--en"
          src="/img/login/phugia-cloud-en.png"
          alt=""
        /> -->
        <img class="main-pic" src="/img/logo.png" alt="" />
        <img class="main-slogan" src="/img/login/slogan.png" alt="" />
      </div>
      <div class="login-border">
        <div v-if="type === 'login'" class="login-main">
          <div class="login-way">
            <span
              @click="changeActive('person')"
              :class="['ways', activeWays === 'person' ? 'ways-active' : '']"
              >个人用户</span
            >
            <!-- <span @click="changeActive('company')" :class="['ways', activeWays==='company' ? 'ways-active' : '']">企业用户</span> -->
          </div>
          <userLogin v-if="activeName === 'user'" @goFlow="getFlow" @firstLogin="firstLogin"></userLogin>
          <codeLogin v-else-if="activeName === 'code'"></codeLogin>
          <!-- <thirdLogin v-else-if="activeName==='third'"></thirdLogin> -->
          <!-- <div class="login-menu">
            <div class="login-menu-title">第三方登录</div>
            <div class="login-menu-ways">
              <a
                class="wechat"
                href="#"
                @click.stop="activeName = 'wechat'"
              ></a>
              <a
                class="dingding"
                href="#"
                @click.stop="activeName = 'dingding'"
              ></a>
            </div>
          </div> -->
        </div>
        <div v-else-if="type === 'register'" class="login-main">
          <Register @goFlow="getFlow" />
        </div>
      </div>
    </div>
    <div v-if="type === 'forget'" :class="forgetBoxClass">
      <ForgetAccount @goFlow="getFlow" />
    </div>
    <div v-if="type === 'first_login'" :class="firstBoxClass">
      <FirstLogin @goFlow="getFlow" />
    </div>
    <div class="login-version">
      <span v-html="version.systemInfo"></span>
    </div>
  </div>
</template>
<script>
import userLogin from "./userlogin";
import codeLogin from "./codelogin";
import thirdLogin from "./thirdlogin";
import ForgetAccount from "./forgetAccount";
import FirstLogin from "./firstLogin";
import Register from "./register";
import { mapGetters } from "vuex";
import { dateFormat } from "@/util/date";
import { validatenull } from "@/util/validate";
import topLang from "@/page/index/top/top-lang";
import topColor from "@/page/index/top/top-color";
import { getQueryString, getTopUrl } from "@/util/util";
import { getSystemInfo } from "@/api/login";

export default {
  name: "login",
  components: {
    userLogin,
    codeLogin,
    thirdLogin,
    topLang,
    topColor,
    ForgetAccount,
    Register,
    FirstLogin
  },
  data() {
    return {
      time: "",
      activeName: "user",
      activeWays: "person",
      type: "login",
      loginBoxClass: "login-weaper animated bounceInDown",
      forgetBoxClass: "forget-main animated bounceInDown",
      firstBoxClass: "first-main animated bounceInDown",
      socialForm: {
        tenantId: "000000",
        source: "",
        code: "",
        state: "",
      },
      version: "",
      firstLogin: false
    };
  },
  watch: {
    $route() {
      this.handleLogin();
    },
  },
  created() {
    this.handleLogin();
    this.getTime();
    this.getVersion();
  },
  mounted() {},
  computed: {
    ...mapGetters(["website", "tagWel"]),
  },
  props: [],
  methods: {
    getVersion() {
      getSystemInfo()
        .then((res) => {
          if (res.data.code === 200) {
            // Cookies.set("version", res.data.data);
            this.version = res.data.data;
          }
        })
        .catch((err) => {});
    },
    changeActive(type) {
      this.activeWays = type;
    },
    getFlow(type) {
      if (
        (this.type === "login" && type === "register") ||
        (this.type === "register" && type === "login")
      ) {
        this.loginBoxClass = "login-weaper animated bounceInDown";
        this.forgetBoxClass = "forget-main animated bounceOutDown";
        this.type = type;
      } else if (type === "forget") {
        this.loginBoxClass = "login-weaper animated bounceOutDown";
        this.forgetBoxClass = "forget-main animated bounceInDown";
        setTimeout(() => {
          this.type = type;
        }, 600);
      } else if (type === "first_login") {
        this.loginBoxClass = "login-weaper animated bounceOutDown";
        this.firstBoxClass = "first-main animated bounceInDown";
        setTimeout(() => {
          this.type = type;
        }, 600);
      } else {
        this.loginBoxClass = "login-weaper animated bounceInDown";
        this.forgetBoxClass = "forget-main animated bounceOutDown";
        this.firstBoxClass = "first-main animated bounceOutDown";
        setTimeout(() => {
          this.type = type;
        }, 600);
      }
    },
    getTime() {
      setInterval(() => {
        this.time = dateFormat(new Date());
      }, 1000);
    },
    handleLogin() {
      const topUrl = getTopUrl();
      const redirectUrl = "/oauth/redirect/";
      this.socialForm.source = getQueryString("source");
      this.socialForm.code = getQueryString("code");
      this.socialForm.state = getQueryString("state");
      if (
        validatenull(this.socialForm.source) &&
        topUrl.includes(redirectUrl)
      ) {
        let source = topUrl.split("?")[0];
        source = source.split(redirectUrl)[1];
        this.socialForm.source = source;
      }
      if (
        !validatenull(this.socialForm.source) &&
        !validatenull(this.socialForm.code) &&
        !validatenull(this.socialForm.state)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "第三方系统登录中,请稍后。。。",
          spinner: "el-icon-loading",
        });
        this.$store
          .dispatch("LoginBySocial", this.socialForm)
          .then(() => {
            window.location.href = topUrl.split(redirectUrl)[0];
            this.$router.push({ path: this.tagWel.value });
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
      }
    },
    firstLogin() {
      this.firstLogin = true
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/login.scss";
</style>
