var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "loginForm",
      staticClass: "login-form",
      attrs: {
        "status-icon": "",
        rules: _vm.loginRules,
        model: _vm.loginForm,
        "label-width": "0",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "phone" } },
        [
          _c(
            "el-input",
            {
              attrs: {
                size: "small",
                "auto-complete": "off",
                placeholder: _vm.$t("login.phone"),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleLogin($event)
                },
              },
              model: {
                value: _vm.loginForm.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.loginForm, "phone", $$v)
                },
                expression: "loginForm.phone",
              },
            },
            [
              _c("i", {
                staticClass: "icon-shouji",
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "code" } },
        [
          _c(
            "el-input",
            {
              attrs: {
                size: "small",
                "auto-complete": "off",
                placeholder: _vm.$t("login.code"),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleLogin($event)
                },
              },
              model: {
                value: _vm.loginForm.code,
                callback: function ($$v) {
                  _vm.$set(_vm.loginForm, "code", $$v)
                },
                expression: "loginForm.code",
              },
            },
            [
              _c("i", {
                staticClass: "icon-yanzhengma",
                staticStyle: { "margin-top": "6px" },
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
              _c("template", { slot: "append" }, [
                _c(
                  "span",
                  {
                    staticClass: "msg-text",
                    class: [{ display: _vm.msgKey }],
                    on: { click: _vm.handleSend },
                  },
                  [_vm._v(_vm._s(_vm.msgText))]
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              staticClass: "login-submit",
              attrs: { size: "small", type: "primary" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleLogin($event)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("login.submit")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }