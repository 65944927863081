<template>
  <div class="on-duty-container">
    <div class="item">
      <span class="item-title">带班领导 : </span>
      <span class="item-content">{{ onDutyData && onDutyData.leader? onDutyData.leader : "-" }}</span>
    </div>
    <div class="item">
      <span class="item-title">值班主任 : </span>
      <span class="item-content">{{ onDutyData.director || "-" }}</span>
    </div>
    <div class="item">
      <span class="item-title">值班调度 : </span>
      <span class="item-content">{{ onDutyData.dispatcher || "-" }}</span>
    </div>
    <div class="item">
      <span class="item-title">值班科长 : </span>
      <span class="item-content">{{ onDutyData.sectionChief || "-" }}</span>
    </div>
    <div class="item">
      <span class="item-title">值班通讯 : </span>
      <span class="item-content">{{ onDutyData.correspondent || "-" }}</span>
    </div>
    <div class="item">
      <span class="item-title">值班司机 : </span>
      <span class="item-content">{{ onDutyData.driver || "-" }}</span>
    </div>
    <div class="item">
      <span class="item-title">值班人员 : </span>
      <span class="item-content">{{ onDutyData.people|| "-" }}</span>
    </div>
    <div class="item tel">
      <span class="item-title">通讯电话 : </span>
      <span
        class="item-content"
        :class="{'item-content-tel': onDutyData && onDutyData.communicationTelephone}"
        >{{ onDutyData.communicationTelephone || "-" }}</span
      >
    </div>
  </div>
</template>

<script>
import  { mapActions } from 'vuex'

export default {
  props: {
    detail: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    ...mapActions([
      'wsData'
    ]),
    onDutyData() {
      if (this.detail) {
        return this.detail.onDuty;
      }
      return {}
    },
  },
};
</script>

<style lang="scss" scoped>
.on-duty-container {
  margin-bottom: 13px;
  padding: 5px 25px 0px 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .item {
    width: 33%;
    margin-top: 14px;
    text-align: left !important;
    &.tel {
      width: 66%;
      text-align: left !important;
      // padding-left: 4%;
    }
    &-title {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }
    &-content {
      font-size: 12px;
      // padding-left: 6px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #84a6ff;
      letter-spacing: -1px;
      &-tel {
        background: #0052c0;
        border-radius: 9px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        padding-left: 13px;
        padding-right: 15px;
      }
    }
    // &:nth-child(3n + 1) {
    //   text-align: left;
    // }
    // &:nth-child(3n + 2) {
    //   text-align: center;
    // }
    // &:nth-child(3n) {
    //   text-align: left;
    //   padding-left: 30px;
    // }
  }
  .item-content-tel {
    letter-spacing: unset;
  }
}
</style>