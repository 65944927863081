<template>
  <div class="first">
    <div class="first-title">
      {{ $t("first.title") }}
    </div>
    <el-form
      class="first-form"
      :rules="firstRules"
      ref="firstForm"
      :model="firstForm"
      label-width="10px"
    >
      <el-form-item label=" " prop="phone">
        <el-input
          size="small"
          class="login-input"
          v-model="firstForm.phone"
          auto-complete="off"
          :placeholder="$t('forget.phone')"
        >
        </el-input>
      </el-form-item>
      <el-form-item label=" " prop="code">
        <el-row :span="24">
          <el-col :span="16">
            <el-input
              size="small"
              class="login-input"
              v-model="firstForm.code"
              auto-complete="off"
              :placeholder="$t('first.msgText')"
            >
            </el-input>
          </el-col>
          <el-col :span="8">
             <el-button
              class="send-code"
              plain
              @click="sendSms"
              :disabled="smsBtnDisabled"
              >{{ smsBtnTitle }}</el-button
            >
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <div class="btn-group">
          <el-button
            type="primary"
            class="btn btn-cancel"
            size="small"
            @click="cancel"
            >{{ $t("first.cancel") }}
          </el-button>
          <el-button
            type="primary"
            class="btn btn-submit"
            size="small"
            @click="submit"
            >{{ $t("first.submit") }}
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { sendSmsCode, bindPhone } from "@/api/login";

export default {
  name: "first",
  data() {
    // 电话号码校验
    var phoneReg = (rule, value, callback) => {
      var reg =
        /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
      if (!value) {
        callback(new Error("请填写电话号码！"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请检查电话号码格式！"));
        }
        callback();
      }
    };
    return {
      smsBtnTitle: "发送验证码",
      smsBtnDisabled: false,
      firstForm: {
        // 电话号码
        phone: "",
        //验证码的值
        code: "",
      },
      firstRules: {
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        phone: [{ required: true, validator: phoneReg, trigger: "blur" }],
      },
      passwordType: "password",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["tagWel"]),
  },
  props: [],
  methods: {
    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.smsBtnDisabled = false;
          this.smsBtnTitle = "发送验证码";
        } else {
          this.smsBtnTitle = time + "秒后重试";
          this.smsBtnDisabled = true;
          time--;
        }
      }, 1000);
    },
    sendSms() {
      this.$refs.firstForm.validateField("phone", (phone_check_err) => {
        if (!!phone_check_err) {
          return;
        }
        const params = {
          phone: this.firstForm.phone,
        };
        sendSmsCode(params)
          .then((res) => {
            if (res.data.code === 200) {
              this.validateBtn();
              this.$message.success("短信发送成功！");
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },
    cancel() {
      this.$emit("goFlow", "login");
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    submit() {
      this.$refs.firstForm.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "手机绑定中请稍后...",
            spinner: "el-icon-loading",
          });
          const data = {
            ...this.firstForm,
            userId: this.$store.getters.userInfo.user_id
          }
          bindPhone(data)
            .then((res) => {
              console.log(res);
              if (res.data.code === 200) {
                const firstData = JSON.parse(localStorage.getItem('firstLogin'))
                this.$store.dispatch("LoginSetToken", firstData).then(() => {
                  this.$message.success("手机绑定成功！");
                  setTimeout(() => {
                    console.log(this.tagWel.value)
                    this.$router.push({path: this.tagWel.value});
                  }, 1000);
                })
                loading.close();
              }
            })
            .catch((err) => {
              loading.close();
              this.$message.error(err);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.first {
  &-title {
    color: #0088ff;
    font-size: 24px;
    margin-top: 46px;
    padding-left: 32px;
  }
  &-form {
    margin-top: 22px;
    .el-form-item {
      margin-bottom: 12px;
      margin-left: 32px;
      position: relative;
      &__content {
        width: 480px;
      }
      &__label {
        position: absolute;
        left: 16px;
        top: 3px;
      }
    }

    .el-input {
      input {
        // padding-bottom: 10px;
        text-indent: 5px;
        background: transparent;
        border: none;
        border-radius: 0;
        color: #333;
        border-bottom: 1px solid rgb(235, 237, 242);
      }

      .el-input__prefix {
        i {
          padding: 0 5px;
          font-size: 16px !important;
        }
      }
    }
  }
  &-tips {
    color: #b8b8b8;
    font-size: 12px;
    margin-top: -10px;
    height: 30px;
  }
  .send-code {
    width: 132px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b8b8b8 !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    color: #9b9b9b !important;
    margin-left: 21px;
  }
  .btn-group {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    padding: 0 34px 0 5px;
    .btn {
      width: 180px;
      height: 64px;
      font-size: 24px;
      border-radius: 8px;
      &-cancel {
        border: 1px solid #0088ff !important;
        background: #ffffff !important;
        color: #0088ff !important;
      }
      &-submit {
        background: #0088ff !important;
        color: #ffffff !important;
      }
    }
  }
}
</style>
