var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "headerWrap" }, [
    _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "date" }, [
        _c("div", [
          _c("p", [_vm._v(_vm._s(_vm.date.date))]),
          _c("p", [_vm._v(_vm._s(_vm.date.day))]),
        ]),
        _c("div", [_c("p", [_vm._v(_vm._s(_vm.date.time))])]),
      ]),
    ]),
    _c("div", { staticClass: "center" }, [
      _c("div", { staticClass: "title-container" }, [
        _vm.projectInfo.screenSubTitle
          ? _c("p", { staticClass: "subTitle" }, [
              _vm._v(_vm._s(_vm.projectInfo.screenSubTitle)),
            ])
          : _vm._e(),
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.projectInfo.screenTitle)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "user user-info" }, [
        _c("i", { staticClass: "el-icon-user" }),
        _c("p", [_vm._v(_vm._s(_vm.userInfo.nick_name))]),
      ]),
      _c("div", { staticClass: "user", on: { click: _vm.goBack } }, [
        _c("img", {
          attrs: { src: require("@/assets/images/shift.png"), alt: "" },
        }),
        _c("p", [_vm._v(_vm._s(_vm.$t("screen.goback")))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }