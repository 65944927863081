<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-10-20 16:41:31
 * @watermark: 成都沃飞长空
 * @LastEditors: wq
-->
<template>
  <div class="params-label">
    <div class="params-title">{{ title }}</div>
    <div class="params-value">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
  },
};
</script>

<style lang="scss" scoped>
.params-label {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #3ecacf;
  border-radius: 0.20833vw;
}
.params-title {
  width: 50%;
  font-size: 12px;
  color: #fff;
  border-bottom: 1px solid #257b87;
}
.params-value {
  width: 50%;
  font-size: 16px;
  font-weight: bold;
  color: #3ecacf;
}
</style>
