var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "center-content" },
    [
      _c("Modal", {
        on: { close: _vm.closeMapModal },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(_vm.mapComponent, {
                  ref: "map-modal",
                  tag: "component",
                  staticClass: "map-full",
                  attrs: {
                    projectInfo: _vm.projectInfo,
                    mapData: _vm.mapData,
                    hasBtn: false,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.mapModalVisible,
          callback: function ($$v) {
            _vm.mapModalVisible = $$v
          },
          expression: "mapModalVisible",
        },
      }),
      _c(
        "div",
        { staticClass: "map-container" },
        [
          _c("Base", {
            staticClass: "label-title",
            attrs: { title: _vm.$t("screen.UAVMap") },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(_vm.mapComponent, {
                      ref: "map",
                      tag: "component",
                      staticClass: "map-div",
                      attrs: {
                        projectInfo: _vm.projectInfo,
                        mapData: _vm.mapData,
                      },
                      on: {
                        moving: _vm.planeMoving,
                        fullScreen: _vm.fullScreen,
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "video-container" },
        [
          _c("Base", {
            attrs: { title: "无人机视频画面" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "video" },
                      [
                        _vm.liveVideoVOList.length > 0
                          ? _c(_vm.useLive, {
                              ref: "videoLive",
                              tag: "component",
                              staticClass: "video-plane",
                              attrs: {
                                screen: false,
                                liveVideoVOList: _vm.liveVideoVOList,
                              },
                            })
                          : _c("div", {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: true,
                                  expression: "true",
                                },
                              ],
                              staticClass: "video-loading",
                              attrs: {
                                "element-loading-text": _vm.$t(
                                  "WaitingForVideoTransmission"
                                ),
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(0, 0, 0, 0.8)",
                              },
                            }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }