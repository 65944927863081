var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-page" }, [
    _c("div", {
      staticClass: "img",
      staticStyle: { "background-image": "url('/img/bg/403.svg')" },
    }),
    _c("div", { staticClass: "content" }, [
      _c("h1", [_vm._v("403")]),
      _c("div", { staticClass: "desc" }, [_vm._v("抱歉，你无权访问该页面")]),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c(
            "router-link",
            { attrs: { to: { path: "/" } } },
            [
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("返回首页"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }