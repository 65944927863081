var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "headerWrap" }, [
    _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "date" }, [
        _c("div", [
          _c("p", [_vm._v(_vm._s(_vm.date.date))]),
          _c("p", [_vm._v(_vm._s(_vm.date.day))]),
        ]),
        _c("div", [_c("p", [_vm._v(_vm._s(_vm.date.time))])]),
      ]),
    ]),
    _c("div", { staticClass: "center" }, [
      _c("div", { staticClass: "title-container" }, [
        _vm.projectInfo.screenSubTitle
          ? _c("p", { staticClass: "subTitle" }, [
              _vm._v(_vm._s(_vm.projectInfo.screenSubTitle)),
            ])
          : _vm._e(),
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.projectInfo.screenTitle)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "right" }, [
      !_vm.isPri
        ? _c(
            "div",
            { staticClass: "weather" },
            [
              _c("area-cascader", {
                ref: "areaCascader",
                staticClass: "areaCascader",
                attrs: { data: _vm.pcaa, level: 0, type: "all" },
                on: { change: _vm.areaChange },
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
              _vm.weather.today && Object.keys(_vm.weather.today).length
                ? _c("div", { staticClass: "cloud flex" }, [
                    _c(
                      "p",
                      {
                        staticClass: "b20",
                        staticStyle: { "line-height": "18px" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.weather.today.temperature + "℃") +
                            "\n        "
                        ),
                      ]
                    ),
                    _c("p", { staticClass: "mini" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.weather.today.directionName +
                              "风" +
                              _vm.weather.today.speed +
                              "m/s"
                          ) +
                          "\n        "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "today" }, [
                _c("p", { staticClass: "b16" }, [
                  _vm._v(_vm._s(_vm.weather.today.description || "")),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "user" }, [
        _c("div", { staticClass: "flex", on: { click: _vm.goBack } }, [
          _c("img", {
            attrs: { src: require("@/assets/images/shift.png"), alt: "" },
          }),
          _c("p", [_vm._v("管理后台")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }