var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device-page" }, [
    _c("div", { staticClass: "more", on: { click: _vm.openLedger } }, [
      _vm._v(_vm._s(_vm.$t("oilGasScreen.more"))),
    ]),
    _c("div", { staticClass: "chart-main" }, [
      _c("div", { staticClass: "chart-item" }, [
        _c("div", { staticClass: "chart" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/deviceOnline.png") },
          }),
          _c(
            "div",
            {
              staticClass: "total-num",
              attrs: { title: _vm.deviceData.onlineDeviceNum },
            },
            [_vm._v(_vm._s(_vm.deviceData.onlineDeviceNum))]
          ),
          _c("div", { staticClass: "total-name" }, [
            _vm._v(_vm._s(_vm.$t("oilGasScreen.inLineDevice"))),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "chart-item" }, [
        _c("div", { staticClass: "chart" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/deviceNum.png") },
          }),
          _c(
            "div",
            {
              staticClass: "total-num",
              attrs: { title: _vm.deviceData.totalDeviceNum },
            },
            [_vm._v(_vm._s(_vm.deviceData.totalDeviceNum))]
          ),
          _c("div", { staticClass: "total-name" }, [
            _vm._v(_vm._s(_vm.$t("oilGasScreen.devicesTotal"))),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "total-main" }, [
      _c("div", { staticClass: "total-item" }, [
        _c("div", { staticClass: "item-name" }, [
          _vm._v(_vm._s(_vm.$t("oilGasScreen.fixedWing"))),
        ]),
        _c("div", { staticClass: "item-num" }, [
          _c(
            "div",
            { staticClass: "num", attrs: { title: _vm.deviceData.vtolUavNum } },
            [_vm._v(_vm._s(_vm.deviceData.vtolUavNum))]
          ),
          _c("div", { staticClass: "unit" }, [
            _vm._v(_vm._s(_vm.$t("oilGasScreen.sets"))),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "total-item" }, [
        _c("div", { staticClass: "item-name" }, [
          _vm._v(_vm._s(_vm.$t("oilGasScreen.multiRotors"))),
        ]),
        _c("div", { staticClass: "item-num" }, [
          _c(
            "div",
            { staticClass: "num", attrs: { title: _vm.deviceData.multUavNum } },
            [_vm._v(_vm._s(_vm.deviceData.multUavNum))]
          ),
          _c("div", { staticClass: "unit" }, [
            _vm._v(_vm._s(_vm.$t("oilGasScreen.sets"))),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "total-item" }, [
        _c("div", { staticClass: "item-name" }, [
          _vm._v(_vm._s(_vm.$t("oilGasScreen.hangar"))),
        ]),
        _c("div", { staticClass: "item-num" }, [
          _c(
            "div",
            { staticClass: "num", attrs: { title: _vm.deviceData.hangarNum } },
            [_vm._v(_vm._s(_vm.deviceData.hangarNum))]
          ),
          _c("div", { staticClass: "unit" }, [
            _vm._v(_vm._s(_vm.$t("oilGasScreen.pcs"))),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }