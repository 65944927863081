<template>
    <!-- 设备信息概况 -->
    <div class="device-page">
        <div class="more" @click="openLedger">{{$t('oilGasScreen.more')}}</div>
        <div class="chart-main">
            <div class="chart-item">
                <div class="chart">
                    <img src="@/assets/images/deviceOnline.png" />
                    <div class="total-num" :title="deviceData.onlineDeviceNum">{{deviceData.onlineDeviceNum}}</div>
                    <div class="total-name">{{$t('oilGasScreen.inLineDevice')}}</div>
                </div>
            </div>
            <div class="chart-item">
                <div class="chart">
                    <img src="@/assets/images/deviceNum.png" />
                    <div class="total-num" :title="deviceData.totalDeviceNum">{{deviceData.totalDeviceNum}}</div>
                    <div class="total-name">{{$t('oilGasScreen.devicesTotal')}}</div>
                </div>
            </div>
        </div>
        <div class="total-main">
            <div class="total-item">
                <div class="item-name">{{$t('oilGasScreen.fixedWing')}}</div>
                <div class="item-num">
                    <div class="num" :title="deviceData.vtolUavNum">{{deviceData.vtolUavNum}}</div>
                    <div class="unit">{{$t('oilGasScreen.sets')}}</div>
                </div>
            </div>
            <div class="total-item">
                <div class="item-name">{{$t('oilGasScreen.multiRotors')}}</div>
                <div class="item-num">
                    <div class="num" :title="deviceData.multUavNum">{{deviceData.multUavNum}}</div>
                    <div class="unit">{{$t('oilGasScreen.sets')}}</div>
                </div>
            </div>
            <div class="total-item">
                <div class="item-name">{{$t('oilGasScreen.hangar')}}</div>
                <div class="item-num">
                    <div class="num" :title="deviceData.hangarNum">{{deviceData.hangarNum}}</div>
                    <div class="unit">{{$t('oilGasScreen.pcs')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'device-page',
    props: ['deviceData'],
    methods: {
        openLedger() {
            this.$parent.$parent.showLedger(true);
        }
    }
}
</script>

<style lang="scss" scoped>
.device-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    position: relative;
    .more {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 2;
        font-size: 13px;
        color: rgb(64, 158, 255);
        cursor: pointer;
    }
    .chart-main {
        width: 100%;
        height: 0;
        display: flex;
        flex: 1;
        .chart-item {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            justify-content: center;
            .chart {
                width: 170px;
                height: 140px;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                }
                .total-num {
                    width: 105px;
                    font-family: PangMen;
                    font-size: 30px;
                    color: #FFFFFF;
                    text-align: center;
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    transform: translateX(-50%);
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    cursor: pointer;
                }
                .total-name {
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: bold;
                    font-size: 14px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: -6px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .total-main {
        display: flex;
        padding: 5px 0;
        .total-item {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
            .item-name {
                font-family: MicrosoftYaHei;
                font-size: 13px;
                color: #FFFFFF;
            }
            .item-num {
                display: flex;
                margin-top: 3px;
                align-items: center;
                .num {
                    min-width: 60px;
                    max-width: 90px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    font-family: MicrosoftYaHei;
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                    background: rgba(28, 54, 107, 0.5);
                    border: 1px solid #64A1E8;
                    border-radius: 3px;
                    padding: 0 3px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    cursor: pointer;
                }
                .unit {
                    font-family: MicrosoftYaHei;
                    font-size: 15px;
                    color: #FFFFFF;
                    margin-left: 5px;
                }
            }
        }
    }
}
</style>