var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Box" }, [
    _c("div", { staticClass: "contentWrap" }, [
      _c(
        "div",
        { staticClass: "flex" },
        _vm._l(
          [
            { name: "在线设备", num: _vm.store.onlineNum || "-" },
            { name: "设备总数", num: _vm.store.totalNum || "-" },
          ],
          function (i, idx) {
            return _c("div", { key: idx, staticClass: "contentItemTop" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(i["num"] || "-") + "\n\t\t\t\t"),
              ]),
              _c("div", { staticClass: "name" }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(i["name"]) + "\n\t\t\t\t"),
              ]),
            ])
          }
        ),
        0
      ),
      _c(
        "div",
        { staticClass: "flex" },
        _vm._l(
          [
            { name: "无人机", num: _vm.store.uavNum },
            { name: "载荷", num: _vm.store.loadNum },
            { name: "数据链", num: _vm.store.dataLinkNum },
          ],
          function (i, idx) {
            return _c("div", { key: idx, staticClass: "contentItemBottom" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(i["num"] || "-") + "\n\t\t\t\t"),
              ]),
              _c("div", { staticClass: "name" }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(i["name"]) + "\n\t\t\t\t"),
              ]),
            ])
          }
        ),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }