var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen-bgc" }, [
    _c("div", { staticClass: "screen-home" }, [
      _c(
        "div",
        { staticClass: "header" },
        [_c("Header", { attrs: { projectInfo: _vm.projectInfo } })],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("Content", {
            ref: "content",
            attrs: { projectInfo: _vm.projectInfo },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }