<template>
    <!-- 巡检趋势 -->
    <div class="trendPage">
        <div class="swtch-list">
            <el-radio-group v-model="timeType" size="mini" @input="handlerData">
                <el-radio :label="'dailyRecordCount'">{{$t('oilGasScreen.daily')}}</el-radio>
                <el-radio :label="'weeklyRecordCount'">{{$t('oilGasScreen.weekly')}}</el-radio>
                <el-radio :label="'monthlyRecordCount'">{{$t('oilGasScreen.monthly')}}</el-radio>
            </el-radio-group>
        </div>
        <div class="chart-cont">
            <chartLine 
                :id="'trend-chart'"
                :xAxisLabel="{color: '#fff', rotate: 0, fontSize: 10}"
                :areaStyleList="[areaStyle]"
                :chartData="chartData"
            />
        </div>
    </div>
</template>

<script>
import chartLine from '@/components/echarts/chartLine.vue';

export default {
    name: 'trend-page',
    components: {chartLine},
    props: ['trendData'],
    data() {
        return {
            timeType: 'monthlyRecordCount',
            areaStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: 'rgba(67, 143, 255, 0.5)'},
                    {offset: 1, color: 'rgba(67, 143, 255, 0.1)'}
                ])
            },
            chartData: [
                {name: '', data: []}
            ]
        }
    },
    watch: {
        'trendData': {
            deep: true,
            handler: function() {
                this.handlerData();
            }
        }
    },
    methods: {
        handlerData() {
            let data = this.trendData.find(t => t.name === this.timeType).data;
            let chartData = [];
            Object.keys(data).map(t => {
                chartData.push({name: t, value: data[t] || 0});
            });
            this.chartData[0].data = chartData;
        }
    }
}
</script>

<style lang="scss" scoped>
.trendPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .swtch-list {
        position: absolute;
        top: 3px;
        right: 10px;
        z-index: 5;
        ::v-deep .el-radio-group {
            .el-radio {
                margin-right: 8px;
                .el-radio__inner {
                    width: 12px;
                    height: 12px;
                }
                .el-radio__label {
                    font-size: 12px;
                    color: #fff;
                    padding-left: 5px;
                }
                .el-radio__input.is-checked+.el-radio__label {
                    color: #409EFF;
                }
            }
        }
    }
    .chart-cont {
        width: 100%;
        height: 100%;
        position: relative;
    }
}
</style>