<template>
    <!-- 柱状图 -->
    <div class="chart-node" :id="`chart-${id}-${time}`"></div>
</template>

<script>
export default {
    name: 'chartBar',
    props: {
        // 当前组件id标志，必传，用于区分dom节点
        id: {
            type: String,
            required: true,
            default: `${new Date().getTime()}`
        },
        // 是否是堆叠柱状图
        isStacked: {
            type: Boolean,
            default: false
        },
        // 是否横向排列
        isTransverse: {
            type: Boolean,
            default: false
        },
        // chart颜色列表
        chartColors: {
            type: Array,
            default: () => {
                // 默认的颜色列表
                return [
                    '#438FFF',
                    '#F5D021',
                    '#40F3E6',
                    '#FF5F5F',
                    '#96F868',
                    '#FF804A',
                    '#A354C7',
                    '#3C64DA',
                    '#65BC3E',
                    '#FFC849',
                    '#D84343',
                    '#41B8E6',
                    '#22A569',
                    '#E6622A',
                    '#8D36B5',
                    '#EF59C4',
                    '#5680FF',
                    '#FFA847',
                    '#0095D2',
                    '#5370C5'
                ];
            }
        },
        // legend在顶部的位置，left，center，right
        legendPosition: {
            type: String,
            default: 'left'
        },
        // legend宽度自定义
        legendWidth: {
            type: Number | String,
            default: '220'
        },
        // grid自定义距离
        grid: {
            type: Object,
            default: () => {
                return {
                    left: '2%',
                    right: '2%',
                    top: '23%',
                    bottom: '3%',
                    containLabel: true
                };
            }
        },
        // tooltip自定义
        tooltip: {
            type: Object,
            default: () => {
                return {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                };
            }
        },
        // x轴线颜色
        xAxisColor: {
            type: String,
            default: '#0D2F74'
        },
        // x轴文字样式配置
        xAxisLabel: {
            type: Object,
            default: () => {
                return {
                    color: "#fff",
                    rotate: 0
                }
            }
        },
        // y轴文字样式配置
        yAxisLabel: {
            type: Object,
            default: () => {
                return {
                    color: "#fff"
                }
            }
        },
        // 是否需要y轴刻度线即平行x轴的线条
        isYAxisSplitLine: {
            type: Boolean,
            default: false
        },
        // y轴刻度线颜色
        yAxisSplitLineColor: {
            type: String,
            default: '#0B2252'
        },
        // 是否需要横向滚动条
        isZoom: {
            type: Boolean,
            default: false
        },
        // 横向滚动条配置
        zoomOption: {
            type: Object,
            default: () => {
                return {
                    width: 15,
                    height: 15,
                    backgroundColor: '#1E222A',
                    fillerColor: '#6AA4E6',
                    left: 'center',
                    right: 'center',
                    bottom: 0,
                    end: 20
                };
            }
        },
        // 数据量达到指定量时需要横向滚动条结合isZoom使用
        showZoomDataNum: {
            type: Number,
            default: 10
        },
        // bar柱的顶部圆角度
        barBorderRadius: {
            type: Array,
            default: () => {
                return [4, 4, 0, 0]
            }
        },
        // bar柱的最大宽度
        barMaxWidth: {
            type: String | Number,
            default: '50'
        },
        // 是否配置bar柱的最小高度，防止出现数据过小柱子显示不出的问题
        isBarMinHeight: {
            type: Boolean,
            default: false
        },
        // bar柱的最小高度
        barMinHeight: {
            type: Number,
            default: 10
        },
        // bar柱的渐变颜色
        barGradientList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        // dataOption自定义数据字段标识
        dataOption: {
            type: Object,
            required: true,
            default: () => {
                // legendKey表示图例以及series取值字段，dataKey表示当前栏目子级数据字段
                // xKey表示x轴取值字段，yKey表示y轴取值字段
                return {legendKey: 'name', dataKey: 'data', xKey: 'name', yKey: 'value'};
            }
        },
        // chartData数据
        chartData: {
            type: Object,
            default: () => {
                // [
                //     {name: '', data: [{name: '', value: 0}]}
                // ]
                return [];
            }
        }
    },
    data() {
        return {
            timer: null,
            time: new Date().getTime(),
            chartOption: '',
            defaultTooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            myChart: ''
        }
    },
    watch: {
        'chartData': {
            deep: true,
            handler: function(val) {
                this.resetChart(val);
            }
        }
    },
    mounted() {
        this.initChart();
        window.addEventListener('resize', this.handleChartResize);
    },
    methods: {
        initChart() {
            let chartDom = document.getElementById(`chart-${this.id}-${this.time}`);
            this.myChart = this.$echarts.init(chartDom);
            this.chartOption = {
                grid: this.grid,
                tooltip: this.tooltip.trigger ? this.tooltip : this.defaultTooltip,
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        lineStyle: {
                            color: this.xAxisColor || '#0D2F74',
                        }
                    },
                    axisTick: {
                        show: true
                    },
                    axisLabel: this.xAxisLabel,
                    data: []
                },
                yAxis: {
                    type: 'value',
                    axisLabel: this.yAxisLabel,
                    splitLine: {
                        show: this.isYAxisSplitLine || false,
                        lineStyle: {
                            color: this.yAxisSplitLineColor || '#0B2252'
                        }
                    }
                },
                series: []
            };
            // 设置颜色
            if (this.chartColors.length) {
                this.chartOption.color = this.chartColors;
            }
            // 添加图例
            if (this.isStacked || this.chartData.length > 1) {
                this.chartOption.legend = {
                    type: 'scroll',
                    top: '2%',
                    left: this.legendPosition,
                    width: this.legendWidth,
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        color: '#fff'
                    },
                    pageIconSize: 12,
                    pageIconColor: '#fff',
                    pageTextStyle: {
                        color: '#fff'
                    }
                }
            }
            // 设置grid
            this.chartOption.grid.containLabel = true;
            // 添加横向滚动条
            if (this.isZoom && this.chartData.length 
                && this.chartData[0][this.dataOption.dataKey].length >= this.showZoomDataNum) {
                let dataZoom = [
                    {
                        type: 'inside',
                        zoomOnMouseWheel: false,
                        moveOnMouseWheel: true,
                        moveOnMouseWheel: true
                    },
                    {
                        type: 'slider',
                        borderColor: 'transparent',
                        backgroundColor: this.zoomOption.backgroundColor || '#1E222A',
                        dataBackground: {
                            lineStyle: {
                                color: 'transparent'
                            },
                            areaStyle: {
                                color: 'transparent'
                            }
                        },
                        selectedDataBackground: {
                            lineStyle: {
                                color: 'transparent'
                            },
                            areaStyle: {
                                color: 'transparent'
                            }
                        },
                        textStyle: {
                            color: 'transparent'
                        },
                        fillerColor: this.zoomOption.fillerColor || '#6AA4E6'
                    }
                ];
                if (this.isTransverse) {
                    dataZoom[0].yAxisIndex = 0;
                    dataZoom[0].start = 100;
                    dataZoom[0].end = this.zoomOption.end || 50;
                    dataZoom[1].yAxisIndex = 0;
                    dataZoom[1].width = this.zoomOption.width || '10';
                    dataZoom[1].height = this.zoomOption.height || '60%';
                    dataZoom[1].right = this.zoomOption.right || '5%';
                    dataZoom[1].start = 100;
                    dataZoom[1].end = this.zoomOption.end || 50;
                } else {
                    dataZoom[0].start = 0;
                    dataZoom[0].end = this.zoomOption.end || 20;
                    dataZoom[1].height = this.zoomOption.height || '15';
                    dataZoom[1].left = this.zoomOption.left || 'center';
                    dataZoom[1].bottom = this.zoomOption.bottom || 0;
                    dataZoom[1].start = 0;
                    dataZoom[1].end = this.zoomOption.end || 20;
                }
                this.chartOption.dataZoom = dataZoom;
            }
            // 设置是否横向展示图形
            if (this.isTransverse) {
                let yAxis = JSON.parse(JSON.stringify(this.chartOption.yAxis));
                this.chartOption.yAxis = this.chartOption.xAxis;
                this.chartOption.xAxis = yAxis;
            }
            // 添加series
            if (this.isStacked) {
                this.chartOption.series = this.handleStacked(); // 堆叠图处理数据
            } else {
                this.chartOption.series = this.handleNormal(); // 普通图处理数据
            }
            this.chartOption && this.myChart.setOption(this.chartOption, {notMerge: true});
        },
        resetChart(data) {
            if (this.chartOption) {
                this.chartOption.tooltip = this.tooltip.trigger ? this.tooltip : this.defaultTooltip;
                if (!data.length) {
                    if (this.isStacked) {
                        this.chartOption.series = this.handleStacked(); // 堆叠图处理数据
                    } else {
                        this.chartOption.series = this.handleNormal(); // 普通图处理数据
                    }
                    delete this.chartOption.legend;
                    delete this.chartOption.dataZoom;
                } else {
                    this.chartOption.series = [];
                    // 判断图例
                    if (data.length > 1) {
                        if (!this.chartOption.legend) {
                            this.chartOption.legend = {
                                type: 'scroll',
                                top: '2%',
                                left: this.legendPosition,
                                width: this.legendWidth,
                                itemWidth: 10,
                                itemHeight: 10,
                                textStyle: {
                                    color: '#fff'
                                },
                                pageIconSize: 12,
                                pageIconColor: '#fff',
                                pageTextStyle: {
                                    color: '#fff'
                                }
                            }
                        }
                    } else {
                        delete this.chartOption.legend;
                    }
                    // 判断横向滚动条
                    if (this.isZoom) {
                        if (data.length && data[0][this.dataOption.dataKey].length >= this.showZoomDataNum) {
                            if (!this.chartOption.dataZoom) {
                                let dataZoom = [
                                    {
                                        type: 'inside',
                                        zoomOnMouseWheel: false,
                                        moveOnMouseWheel: true,
                                        moveOnMouseWheel: true
                                    },
                                    {
                                        type: 'slider',
                                        borderColor: 'transparent',
                                        backgroundColor: this.zoomOption.backgroundColor || '#1E222A',
                                        dataBackground: {
                                            lineStyle: {
                                                color: 'transparent'
                                            },
                                            areaStyle: {
                                                color: 'transparent'
                                            }
                                        },
                                        selectedDataBackground: {
                                            lineStyle: {
                                                color: 'transparent'
                                            },
                                            areaStyle: {
                                                color: 'transparent'
                                            }
                                        },
                                        textStyle: {
                                            color: 'transparent'
                                        },
                                        fillerColor: this.zoomOption.fillerColor || '#6AA4E6'
                                    }
                                ];
                                if (this.isTransverse) {
                                    dataZoom[0].yAxisIndex = 0;
                                    dataZoom[0].start = 100;
                                    dataZoom[0].end = this.zoomOption.end || 50;
                                    dataZoom[1].yAxisIndex = 0;
                                    dataZoom[1].width = this.zoomOption.width || '10';
                                    dataZoom[1].height = this.zoomOption.height || '60%';
                                    dataZoom[1].right = this.zoomOption.right || '5%';
                                    dataZoom[1].start = 100;
                                    dataZoom[1].end = this.zoomOption.end || 50;
                                } else {
                                    dataZoom[0].start = 0;
                                    dataZoom[0].end = this.zoomOption.end || 20;
                                    dataZoom[1].height = this.zoomOption.height || '15';
                                    dataZoom[1].left = this.zoomOption.left || 'center';
                                    dataZoom[1].bottom = this.zoomOption.bottom || 0;
                                    dataZoom[1].start = 0;
                                    dataZoom[1].end = this.zoomOption.end || 20;
                                }
                                this.chartOption.dataZoom = dataZoom;
                            }
                        } else {
                            delete this.chartOption.dataZoom;
                        }
                    } else {
                        delete this.chartOption.dataZoom;
                    }
                    if (this.isStacked) {
                        this.chartOption.series = this.handleStacked(); // 堆叠图处理数据
                    } else {
                        this.chartOption.series = this.handleNormal(); // 普通图处理数据
                    }
                }
                this.myChart && this.myChart.setOption(this.chartOption, {notMerge: true});
            }
        },
        handleNormal() {
            let _this = this;
            let series = [];
            if (!this.chartData.length) {
                let seriesData = {
                    name: '',
                    type: 'bar',
                    barMaxWidth: this.barMaxWidth || '50',
                    itemStyle: {
                        borderRadius: this.barBorderRadius
                    },
                    data: [0]
                };
                if (this.isBarMinHeight) {
                    seriesData.barMinHeight = 0;
                }
                series.push(seriesData);
                if (this.isTransverse) {
                    this.chartOption.yAxis.data = [];
                } else {
                    this.chartOption.xAxis.data = [];
                }
                if (this.chartOption.legend) {
                    this.chartOption.legend.data = [];
                }
            } else {
                let legendData = [];
                let xAxisData = [];
                this.chartData.map((t, index) => {
                    legendData.push(t[this.dataOption.legendKey]);
                    let yAxisData = [];
                    let seriesData = {
                        name: t[this.dataOption.legendKey],
                        type: 'bar',
                        barMaxWidth: this.barMaxWidth || '50',
                        itemStyle: {
                            borderRadius: this.barBorderRadius
                        },
                        data: []
                    };
                    if (this.isBarMinHeight) {
                        seriesData.barMinHeight = this.barMinHeight || 10;
                    }
                    if (this.barGradientList[index]) {
                        seriesData.itemStyle.color = this.barGradientList[index];
                    }
                    (t[this.dataOption.dataKey] || []).map(i => {
                        index === 0 && xAxisData.push(i[this.dataOption.xKey]);
                        let value = i[this.dataOption.yKey];
                        if (_this.isBarMinHeight) {
                            value = value || null;
                        }
                        yAxisData.push(value);
                    });
                    seriesData.data = yAxisData;
                    series.push(seriesData);
                });
                if (this.isTransverse) {
                    this.chartOption.yAxis.data = xAxisData;
                } else {
                    this.chartOption.xAxis.data = xAxisData;
                }
                if (this.chartOption.legend) {
                    this.chartOption.legend.data = legendData;
                }
            }
            return series;
        },
        handleStacked() {
            let _this = this;
            let legendData = [];
            let xAxisData = [];
            let rawData = []
            if (this.chartData.length) {
                this.chartData.map((t, index) => {
                    legendData.push(t[this.dataOption.legendKey]);
                    let rawItemData = [];
                    (t[this.dataOption.dataKey] || []).map(i => {
                        index === 0 && xAxisData.push(i[this.dataOption.xKey]);
                        let value = i[this.dataOption.yKey];
                        if (_this.isBarMinHeight) {
                            value = value || null;
                        }
                        rawItemData.push(value);
                    });
                    rawData.push(rawItemData);
                });
            }
            if (this.isTransverse) {
                this.chartOption.yAxis.data = xAxisData;
            } else {
                this.chartOption.xAxis.data = xAxisData;
            }
            let series = legendData.map((name, sid) => {
                let seriesItem = {
                    name,
                    type: 'bar',
                    stack: 'total',
                    barMaxWidth: this.barMaxWidth || '50',
                    data: rawData[sid]
                };
                if (_this.isBarMinHeight) {
                    seriesItem.barMinHeight = _this.barMinHeight || 50;
                }
                return seriesItem;
            });
            return series;
        },
        handleChartResize() {
            if (this.myChart) {
                this.myChart.dispose();
                this.myChart = '';
            }
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.initChart();
            }, 500);
        }
    },
    beforeDestroy() {
        if (this.myChart) {
            this.myChart.dispose();
            this.myChart = '';
        }
        window.removeEventListener('resize', this.handleChartResize);
    }
}
</script>

<style lang="scss" scoped>
.chart-node {
    width: 100%;
    height: 100%;
}
</style>