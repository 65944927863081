<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-09-24 10:48:36
 * @watermark: 成都沃飞长空
 * @LastEditors: wq
-->
<template>
  <div
    class="center-content">
    <Modal
      v-model="mapModalVisible"
      @close="closeMapModal">
      <template v-slot:content>
        <component
          :is="mapComponent"
          :projectInfo="projectInfo"
          ref="map-modal"
          :mapData="mapData"
          :hasBtn="false"
          class="map-full">
        </component>
      </template>
    </Modal>
    <div
      class="map-container">
      <BaseBox :bigBGIMG="true" style="padding: 2px" class="label-title" :title="$t('screen.UAVMap')">
        <template v-slot:content>
          <component
            ref="map"
            :is="mapComponent"
            :projectInfo="projectInfo"
            @moving="planeMoving"
            @fullScreen="fullScreen"
            :mapData="mapData">
          </component>
        </template>
      </BaseBox>
    </div>
  </div>
</template>

<script>
import BaseBox from "@/components/screen/BaseBox.vue";
import Map from "@/components/screen/map/map-center.vue";
import NewMap from "@/components/screen/map/new-map-center.vue";
import ParamsLabel from "./params-label.vue";
import Modal from "@/components/screen/modal.vue";
import { mapGetters } from "vuex";

export default {
  name: "Center",
  components: {
    Map,
    NewMap,
    ParamsLabel,
    BaseBox,
    Modal
  },
  props: {
    projectInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      visible: false,
      mapModalVisible: false,
      distance: 0,
      flyTime: "00:00:00",
      lastTime: "00:00:00",
    };
  },
  computed: {
    ...mapGetters([
      'newSocketData',
      'socketData',
      'wsData',
      'task'
    ]),
    mapData(){
      return this.newSocketData
    },
    mapComponent() {
      return 'NewMap'
    }
  },
  methods: {
    fullScreen() {
      this.mapModalVisible = true;
    },

    closeMapModal() {
      this.mapModalVisible = false;
    },

    planeMoving(params) {
      this.distance = params.distance;
      this.flyTime = this.formateSeconds(params.time);
      this.lastTime = this.formateSeconds(params.alltime - params.time + 1);
    },

    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
#myCanvas {
  display: none;
}

.video-loading {
  height: 100%;
  width: 100%;
  background-color: #011a38;
}

::v-deep.el-empty {
  width: 100%;
  height: 100%;

  .el-empty__description {
    display: none;
    margin-top: 10px;

    p {
      color: #237592 !important;
      font-size: 14px !important;
    }
  }

  .el-empty__image {
    img {
      width: 56px;
    }
  }
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;

  .video-modal {
    width: 100%;
    height: 100%;
  }

  .modal-btn {
    background: #abcaff;
    color: #051c3a;
    font-size: 14px;
    font-weight: bold;
    width: 100px;
    border: none;
    height: 25px;
    // line-height: 12px;
    position: absolute;
    left: 50%;
    bottom: -32px;
    transform: translate(-50%, 0%);
    padding: 0;
  }
}

.map-full {
  position: relative;
  width: 100%;
  height: 100%;
}

.center-content {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  height: 100%;
  position: relative;
  // background-image: url('~@/assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .map-container {
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
  }
}

.params-label-item {
  flex: 2;
  margin-bottom: 5%;
}

.label-title {
  flex: 1;
}
</style>
