<template>
    <!-- 吊舱概况 -->
    <div class="podVideo-page">
        <div class="swtch-uav">
            <el-select v-model="checkUav" size="mini" :placeholder="$t('oilGasScreen.pleaseSelectDrone')" 
                @change="changeUav">
                <el-option v-for="item in uavList" :key="item.taskName" :label="item.uavName" :value="item.uavName" />
            </el-select>
        </div>
        <div class="video-cont">
            <component v-if="liveVideoVOList.length" :is="useLive" ref="videoLive" class="video-plane" 
                :screen="false" :liveVideoVOList="liveVideoVOList" />
            <div v-else class="video-loading" v-loading="true" 
                :element-loading-text="$t('WaitingForVideoTransmission')" 
                element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            </div>
        </div>
    </div>
</template>

<script>
import VdieoLiveEasyplayer from "@/components/video-live-easyPlayer/index.vue";
import VdieoLiveHWLLS from "@/components/video-live-HWLLS/index.vue";
import { mapGetters, mapActions } from "vuex";
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';

export default {
    name: 'podVideo-page',
    components: {VdieoLiveHWLLS, VdieoLiveEasyplayer},
    props: ['podData'],
    data() {
        return {
            checkUav: '',
            checkUavItem: '',
            uavList: [],
            socketList: []
        }
    },
    computed: {
        ...mapGetters([
            'selectTask'
        ]),
        liveVideoVOList() {
            const liveVideoVOList = this.checkUavItem.liveVideos;
            if (liveVideoVOList && Array.isArray(liveVideoVOList)) {
                return liveVideoVOList;
            }
            return [];
        },
        useLive() {
            return this.checkUavItem.liveType === 1 ? 'VdieoLiveHWLLS': 'VdieoLiveEasyplayer';
        }
    },
    watch: {
        'podData': {
            deep: true,
            handler: function(data) {
                let itemUav = data.onlineUavList.find(t => t.uavName == this.checkUav);
                if (!itemUav) {
                    let checkUavItem = data.onlineUavList.length ? data.onlineUavList[0] : {};
                    this.setSelectTask(checkUavItem);
                } else {
                    if (!this.checkUavItem.liveType || !this.checkUavItem.liveVideos || !itemUav.liveVideos) {
                        this.checkUavItem = JSON.parse(JSON.stringify(itemUav));
                    }
                }
                this.setCurrentTaskList(data.onlineUavList);
                let {addData, removeData} = this.compareDifferences(this.uavList, data.onlineUavList || []);
                this.uavList = data.onlineUavList || [];
                this.closedSocket(removeData, addData);
                this.openSocket(addData);
            }
        },
        'selectTask': {
            deep: true,
            handler: function(val) {
                this.checkUav = val.uavName || '';
                this.checkUavItem = JSON.parse(JSON.stringify(val));
            }
        }
    },
    created() {
        this.$options.flyRouteHook = liveFlyRouteHook();
    },
    methods: {
        ...mapActions([
            // 设置当前执行的任务
            'addCurrentTaskList',
            // 清空当前执行的任务map
            'clearCurrentTaskList',
            // 当前执行的任务mapde
            'setCurrentTaskDataByKey',
            // 设置当前正在执行的任务
            'setSelectTask',
            // 设置正在执行的任务列表
            'setCurrentTaskList'
        ]),
        // 切换飞机
        changeUav() {
            this.checkUavItem = this.podData.onlineUavList.find(t => t.uavName === this.checkUav);
            this.setSelectTask(this.checkUavItem);
        },
        // 开启websocket链接
        openSocket(addData) {
            let _this = this;
            if (addData.length) {
                addData.map(t => {
                    let wsItem = this.socketList.find(i => i.sn === t.sn);
                    if (!wsItem) {
                        let guid = Cesium.createGuid().slice(0, 6).replace('-', 'A');
                        let wsUrl = `${window.global.VUE_APP_WS_ROUTE_NEW }/${t.sn}-${guid}`;
                        let wsCallBack = function({data}) {
                            // 新方案的数据存储
                            _this.setCurrentTaskDataByKey({
                                key: t.sn,
                                airName: t.uavName,
                                value: data
                            });
                        }
                        let ws = this.$options.flyRouteHook.initWebSocket({url: wsUrl, callback: wsCallBack});
                        this.addCurrentTaskList({
                            key: t.sn,
                            value: {
                                ws,
                                data: {},
                                icon: t.modelIcon,
                                selectIcon: t.modelSelectIcon
                            }
                        });
                        this.socketList.push({taskName: t.taskName, sn: t.sn, ws: ws});
                    };
                });
            }
        },
        // 关闭websocket链接并清除已绘制的飞机
        closedSocket(removeData, addData) {
            if (removeData.length) {
                let removeUavEntityData = [];
                removeData.map(t => {
                    let addItem = addData.find(i => i.sn === t.sn);
                    if (!addItem) {
                        let wsItem = this.socketList.find(i => i.sn === t.sn);
                        if (wsItem) {
                            this.$options.flyRouteHook.closeWsLink(wsItem);
                            let index = this.socketList.indexOf(wsItem);
                            index !== -1 && this.socketList.splice(index, 1);
                            removeUavEntityData.push(t);
                        }
                    }
                });
                this.$EventBus.$emit('clearn-uav-data', removeUavEntityData);
            }
        },
        // 比较两次飞机数据看是否存在差异
        compareDifferences(originalArray, currentArray) {
            const addData = currentArray.filter(
                newItem => originalArray.findIndex(oldItem => (oldItem.sn === newItem.sn 
                    && JSON.stringify(oldItem.liveVideos) === JSON.stringify(newItem.liveVideos))) === -1
            );
            const removeData = originalArray.filter(
                oldItem => currentArray.findIndex(newItem => (newItem.sn === oldItem.sn 
                    && JSON.stringify(newItem.liveVideos) === JSON.stringify(oldItem.liveVideos))) === -1
            );
            return {addData, removeData};
        }
    },
    beforeDestroy() {
        this.clearCurrentTaskList();
        this.$options.flyRouteHook.closeWsLink();
        this.$options.flyRouteHook = null;
    }
}
</script>

<style lang="scss" scoped>
.podVideo-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .swtch-uav {
        position: absolute;
        top: 2px;
        right: 80px;
        ::v-deep .el-select {
            width: 140px;
            .el-input__inner {
                background-color: transparent;
                border: 1px solid #1A417B;
                color: #fff;
                &::placeholder {
                    color: #fff;
                }
            }
            .el-input__icon {
                color: #fff;
            }
        }
    }
    .video-cont {
        width: 100%;
        height: 100%;
        position: relative;
        .video-loading {
            height: 100%;
            width: 100%;
            background-color: #011a38;
        }
    }
}
</style>