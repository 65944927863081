<template>
  <div class="oil-navigation-bar">
    <div class="navigation-inner">
      <div class="time">
        <span class="date">
          <span class="day">{{day}}</span>
          <span class="week">{{week}}</span>
        </span>
        <span class="clock">
          {{time}}
        </span>
      </div>
      <div class="bar">
        <div
          :class="currentPath==='overall-situation'? 'overall-situation-selected':''"
          class="overall-situation side-tabs">
          <span
            @click="change('overall-situation')"
            class="side-tabs-txt">
            {{$t('oilGasScreen.globalOverview')}}
          </span>
        </div>
        <div
          :class="currentPath==='real-time'? 'real-time-selected':''"
          class="real-time side-tabs-center">
          <span
            @click="change('real-time')"
            class="side-tabs-txt">
            {{$t('oilGasScreen.realTimeTask')}}
          </span>
        </div>
        <div class="center-title">
          <div class="title-text-shadow title-text">{{screenInfo.name || $t('oilGasScreen.screenTitle')}}</div>
          <div class="title-text">{{screenInfo.name || $t('oilGasScreen.screenTitle')}}</div>
        </div>
        <div
          :class="currentPath==='inspection-record'? 'inspection-record-selected':''"
          class="inspection-record side-tabs-center">
          <span
            @click="change('inspection-record')"
            class="side-tabs-txt">
            {{$t('oilGasScreen.inspectionRecord')}}
          </span>
        </div>
        <div
          :class="currentPath==='alarm-record'? 'alarm-record-selected':''"
          class="alarm-record side-tabs">
          <span
            @click="change('alarm-record')"
            class="side-tabs-txt">
            {{$t('oilGasScreen.alarmRecord')}}
          </span>
        </div>
      </div>
      <div @click="back" class="exit">
        {{$t('oilGasScreen.exit')}}
      </div>
    </div>
  </div>
</template>

<script>
import { getOilInspectionScreenDetail } from "@/api/oil-gas/index";
import { resetRouter } from "@/router/router";

export default {
  data(){
    return {
      screenInfo: {},
      day: '',
      week: '',
      time: '',
      timer: null
    }
  },
  computed: {
    currentPath() {
      const fullPath = this.$route.fullPath
      const matched = fullPath.split('/')
      if (matched.length === 3) {
        return matched[2]
      }
      return ''
    }
  },
  created() {
    this.getOilInspectionScreenDetail();
  },
  mounted() {
    this.timeKeeping()
  },
  methods: {
    getOilInspectionScreenDetail() {
      getOilInspectionScreenDetail({type: 5}).then((res) => {
        if (res.data.code === 200) {
          this.screenInfo = res.data.data.records[0] || {};
        }
      });
    },
    back() {
      if (sessionStorage.getItem('screenOil')) {
        this.$store.dispatch("LogOut").then(() => {
          resetRouter();
          this.$router.push({path: '/screenLogin'});
        });
      } else {
        this.$router.push({
          path: '/oil-gas/OG-screen',
          query: {},
        });
      }
    },
    change(path) {
      if(this.$route.fullPath !== `/screen-oil/${path}`){
        this.$router.push(`/screen-oil/${path}`)
      }
    },
    timeKeeping() {
      const date = new Date()
      const year = date.getFullYear()
      const mouth = date.getMonth() + 1
      const day = date.getDate()
      this.day = `${year}-${mouth}-${day}`
      const week = new Date().getDay();  
      if (week == 0) {  
        this.week = this.$t('Sunday');  
      } else if (week == 1) {  
        this.week = this.$t('Monday');  
      } else if (week == 2) {  
        this.week = this.$t('Tuesday');  
      } else if (week == 3) {  
        this.week = this.$t('Wednesday'); 
      } else if (week == 4) {  
        this.week = this.$t('Thursday'); 
      } else if (week == 5) {  
        this.week = this.$t('Friday'); 
      } else if (week == 6) {  
        this.week = this.$t('Saturday'); 
      }
      let hour = date.getHours()
      let min = date.getMinutes()
      min = min < 10? `0${min}`: min
      let second = date.getSeconds()
      second = second < 10? `0${second}`: second
      this.time = `${hour}:${min}:${second}`
      setTimeout(this.timeKeeping, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.oil-navigation-bar {
  padding: 13px 14px 0px 14px;
  .navigation-inner{
    height: 50px;
    background: #0B2B63;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .time {
      width: 180px;
      display: flex;
      font-size: 16px;
      color: #A5CFFF;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      margin-left: 19px;
      user-select: none;
      .date{
        width: 100px;
        display: flex;
        flex-direction: column;
        text-align: center;
        .day{
          height: 26px;
          line-height: 26px;
        }
        .week{
          height: 24px;
          line-height: 24px;
        }
      }
      .clock{
        margin-left: 5px;
        font-size: 24px;
        line-height: 50px;
      }
    }
    .bar{
      user-select: none;
      height: 100%;
      width: 1400px;
      position: relative;
      z-index: 500;
      .side-tabs{
        position: absolute;
        z-index: 3;
        height: 35px;
        top: 6px;
        width: 180px;
        line-height: 35px;
        text-align: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .side-tabs-center{
        z-index: 2;
        position: absolute;
        top: 6px;
        height: 45px;
        width: 212px;
        line-height: 45px;
        text-align: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .side-tabs-txt {
        cursor: pointer;
        position: absolute;
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 17px;
        color: #FFFFFF;
        font-style: normal;
        text-shadow: 1px 2px 1px #003868;
        user-select: none;
      }
      .overall-situation{
        left: 0px;
        background-image: url("~@/assets/images/oil-left-1.png");
        &:hover{
          background-image: url("~@/assets/images/oil-left-1-selected.png") !important;
        }
      }
      .overall-situation-selected {
        background-image: url("~@/assets/images/oil-left-1-selected.png") !important;
      }
      .real-time{
        left: 134px;
        background-image: url("~@/assets/images/oil-left-2.png");
        &:hover{
          background-image: url("~@/assets/images/oil-left-2-selected.png")  !important;
        }
      }
      .real-time-selected {
        background-image: url("~@/assets/images/oil-left-2-selected.png") !important;
      }
      .center-title{
        position: absolute;
        width: calc(100% - 530px);
        height: 70px;
        top: -1px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url("~@/assets/images/oil-center.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .title-text {
          position: absolute;
          height: 48px;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          background: linear-gradient(0deg, #D7F4FF 0%, #FFFFFF 50%, #CCE4FF 100%);;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: PangMen;
          text-align: center;
          letter-spacing: 6px;
          line-height: 48px;
          font-size: 48px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .title-text-shadow{
          color: transparent;
          text-shadow: 1px 2px 1px #003868;
        } 
      }
      .inspection-record{
        right: 134px;
        background-image: url("~@/assets/images/oil-right-2.png");
        &:hover{
          background-image: url("~@/assets/images/oil-right-2-selected.png")  !important;
        }
      }
      .inspection-record-selected{
        right: 134px;
        background-image: url("~@/assets/images/oil-right-2-selected.png");
      }
      .alarm-record{
        right: 0px;
        background-image: url("~@/assets/images/oil-right-1.png");
        &:hover{
          background-image: url("~@/assets/images/oil-right-1-selected.png")  !important;
        }
      }
      .alarm-record-selected{
        right: 0px;
        background-image: url("~@/assets/images/oil-right-1-selected.png")  !important;
      }
    }
    .exit{
      width: 94px;
      height: 29px;
      margin-right: 14px;
      margin-left: 90px;
      background: #114FAD;
      border-radius: 15px;
      border: 1px solid #2B77D6;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #A5CFFF;
      line-height: 29px;
      font-style: normal;
      text-align: center;
      cursor: pointer;
      user-select: none;
      &:hover{
        background: #4490E7;
      }
    }
  }
}
</style>