var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "screen_new_map_box", attrs: { id: "screen_new_map_box" } },
    [
      _c("div", { attrs: { id: "cesiumContainer_new_map_center" } }),
      _c("div", { staticClass: "right-top-tools" }, [
        _vm.hasBtn && _vm.showTopTools
          ? _c(
              "div",
              {
                staticClass: "full-screen",
                attrs: { title: "全屏" },
                on: { click: _vm.tiggerFullScreen },
              },
              [
                _c("img", {
                  class:
                    _vm.$i18n.locale === "zh" ? "full-image-zh" : "full-image",
                  attrs: {
                    src: require("@/assets" +
                      (_vm.$i18n.locale === "zh" ? "" : "/en") +
                      "/map/full.png"),
                  },
                }),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "track-screen", on: { click: _vm.trackTo } }, [
          _c("img", {
            class: _vm.$i18n.locale === "zh" ? "full-image-zh" : "full-image",
            attrs: {
              src: require("@/assets" +
                (_vm.$i18n.locale === "zh" ? "" : "/en") +
                "/map/track" +
                (_vm.beingTracked ? "_active" : "") +
                ".png"),
            },
          }),
        ]),
        _c(
          "div",
          {
            staticClass: "route-task-line",
            on: { click: _vm.taskRouteHandler },
          },
          [
            _c("img", {
              class: _vm.$i18n.locale === "zh" ? "full-image-zh" : "full-image",
              style: _vm.$i18n.locale === "zh" ? "height: 48px" : "",
              attrs: {
                src: require("@/assets" +
                  (_vm.$i18n.locale === "zh" ? "" : "/en") +
                  "/map/route" +
                  (_vm.showRouteLine ? "_active" : "") +
                  ".png"),
              },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "route-line",
            on: {
              click: function ($event) {
                return _vm.updateRoute(
                  _vm.$options.map3d,
                  _vm.selectTask.taskId
                )
              },
            },
          },
          [
            _c("img", {
              class: _vm.$i18n.locale === "zh" ? "full-image-zh" : "full-image",
              attrs: {
                src: require("@/assets" +
                  (_vm.$i18n.locale === "zh" ? "" : "/en") +
                  "/map/refresh.png"),
              },
            }),
          ]
        ),
      ]),
      _vm.showBottomTools
        ? _c(
            "div",
            {
              class: _vm.showRightPanel ? "open-oilInspection-panel" : "",
              attrs: { id: "baseController" },
            },
            [
              _c("div", { staticClass: "other" }, [
                _c(
                  "div",
                  {
                    staticClass: "zoomBtn",
                    staticStyle: { "border-radius": "4px" },
                    on: { click: _vm.locationTo },
                  },
                  [_c("img", { attrs: { src: "/img/map/location.png" } })]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.hoverTip.show
        ? _c(
            "div",
            {
              staticClass: "hover-tip",
              style:
                "\n      left: " +
                _vm.hoverTip.left +
                "px;\n      top: " +
                _vm.hoverTip.top +
                "px;\n      transform: translateY(calc(-100% - " +
                (_vm.hoverTip.type === _vm.NodeMap.Node.POINT ? 40 : 18) +
                "px)) translateX(-50%);\n    ",
            },
            [
              _c("div", { staticClass: "hover-tip-triangle" }),
              _vm._l(_vm.hoverTip.list, function (item) {
                return _c(
                  "div",
                  { key: item.label, staticClass: "hover-tip-item" },
                  [
                    _c("span", [_vm._v(_vm._s(item.label) + ": ")]),
                    _c("span", [_vm._v(_vm._s(item.value))]),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c("BaseStationPop", {
        ref: "BaseStationPop",
        attrs: { detailData: _vm.baseStationDetail },
      }),
      _vm.isEmergency
        ? _c("UavRealInfoPop", { ref: "UavRealInfoPop" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }