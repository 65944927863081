var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "ai-warning-detail",
      attrs: { id: "ai-warning-detail" },
    },
    [
      _c("div", { staticClass: "ai-warning-center" }, [
        _c("div", { staticClass: "ai-warning-center-top" }, [
          _vm._v(_vm._s(_vm.$t("oilGasScreen.alarmInfo"))),
        ]),
        _c("div", { staticClass: "ai-warning-center-msg" }, [
          _c("div", { staticClass: "ai-warning-center-msgd" }, [
            _c(
              "div",
              {
                ref: "alarmListTopDom",
                staticClass: "ai-warning-center-msgd-top",
              },
              [
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("warning.type") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("warning.type")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: { title: _vm.selectWarning.finalTypeName },
                      },
                      [_vm._v(_vm._s(_vm.selectWarning.finalTypeName || "--"))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("warning.place") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("warning.place")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: { title: _vm.selectWarning.alarmAddress },
                      },
                      [_vm._v(_vm._s(_vm.selectWarning.alarmAddress || "--"))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("warning.device") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("warning.device")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: { title: _vm.selectWarning.equipmentName },
                      },
                      [_vm._v(_vm._s(_vm.selectWarning.equipmentName || "--"))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("FilghtProjectName") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("FilghtProjectName")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: { title: _vm.selectWarning.projectName },
                      },
                      [_vm._v(_vm._s(_vm.selectWarning.projectName || "--"))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("FilghtTask") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("FilghtTask")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: { title: _vm.selectWarning.taskName },
                      },
                      [_vm._v(_vm._s(_vm.selectWarning.taskName || "--"))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("inspection.project") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("inspection.project")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: {
                          title: _vm.selectWarning.inpectionProjectName,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.selectWarning.inpectionProjectName || "--")
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("inspection.selfName") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("inspection.selfName")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: { title: _vm.selectWarning.inpectionObjectName },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.selectWarning.inpectionObjectName || "--")
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("warning.time") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("warning.time")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: { title: _vm.selectWarning.alarmTime },
                      },
                      [_vm._v(_vm._s(_vm.selectWarning.alarmTime || "--"))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("longitude") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("longitude")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: { title: _vm.selectWarning.lon },
                      },
                      [_vm._v(_vm._s(_vm.selectWarning.lon || "--"))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("latitude") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("latitude")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "msg",
                        attrs: { title: _vm.selectWarning.lat },
                      },
                      [_vm._v(_vm._s(_vm.selectWarning.lat || "--"))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item msg-item-column" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("warning.photo") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("warning.photo")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "msg-img" },
                      _vm._l(
                        _vm.alarmPics(_vm.selectWarning.picLinkUrlList),
                        function (item, index) {
                          return _c("el-image", {
                            key: index,
                            staticClass: "img",
                            attrs: {
                              src: item,
                              "preview-src-list": _vm.alarmPics(
                                _vm.selectWarning.picLinkUrlList
                              ),
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "msg-list" }, [
                  _c("div", { staticClass: "msg-item msg-item-column" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { title: _vm.$t("warning.video") },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("warning.video")) +
                            "："
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "msg-video" },
                      _vm._l(
                        _vm.alarmVideos(_vm.selectWarning.videoLinkUrlList),
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item + index,
                              staticClass: "video-list",
                              on: {
                                click: function ($event) {
                                  return _vm.openViewVideoDialog(item)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "video-list-img",
                                attrs: {
                                  src: require("@/assets/images/alarmvideo-bg.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
              ]
            ),
            _vm.selectWarning.alarmStatus === "pend"
              ? _c(
                  "div",
                  { staticClass: "ai-warning-center-msgd-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", loading: _vm.btnLoading.ignore },
                        on: { click: _vm.ignore },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("warning.ignore"))
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.corrections },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("warning.corrections"))
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.confirm },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("warning.confirm"))
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "correctDialog",
          attrs: {
            title: !_vm.isConfirm
              ? _vm.$t("warning.correctAlarm")
              : _vm.$t("warning.confirmAlarm"),
            visible: _vm.correctDialog,
            width: "450px",
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            top: "20vh",
            "before-close": _vm.handleCloseCorrect,
          },
          on: {
            "update:visible": function ($event) {
              _vm.correctDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "correctForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.correctForm,
                    rules: _vm.correctRules,
                    "label-width": "auto",
                  },
                },
                [
                  !_vm.isConfirm
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("warning.type") + "：",
                            prop: "alarmType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "small",
                                placeholder: _vm.$t("warning.type"),
                              },
                              model: {
                                value: _vm.correctForm.alarmType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.correctForm, "alarmType", $$v)
                                },
                                expression: "correctForm.alarmType",
                              },
                            },
                            _vm._l(_vm.alarmTypeList, function (item, index) {
                              return _c("el-option", {
                                key: index + item.dictKey,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("warning.alarmLevel") + "：",
                        prop: "alarmLevel",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "small",
                            placeholder: _vm.$t("warning.alarmLevel"),
                          },
                          model: {
                            value: _vm.correctForm.alarmLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.correctForm, "alarmLevel", $$v)
                            },
                            expression: "correctForm.alarmLevel",
                          },
                        },
                        _vm._l(_vm.alarmLevelList, function (item, index) {
                          return _c("el-option", {
                            key: index + item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleCloseCorrect },
                },
                [_vm._v(_vm._s(_vm.$t("btnCancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading:
                      _vm.btnLoading.corrections || _vm.btnLoading.confirm,
                  },
                  on: { click: _vm.submitCorrect },
                },
                [_vm._v(_vm._s(_vm.$t("btnOk")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.viewVideoDialog
        ? _c(
            "el-dialog",
            {
              staticClass: "viewVideoDialog",
              attrs: {
                title: _vm.$t("warning.video"),
                visible: _vm.viewVideoDialog,
                width: "680px",
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                top: "20vh",
                "before-close": _vm.handleCloseVideo,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.viewVideoDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _vm.viewVideoUrl
                    ? _c("xg-player", {
                        ref: "videoLive",
                        staticClass: "videoPlayer-xg-player",
                        attrs: {
                          videoUrl: _vm.viewVideoUrl,
                          videoType: _vm.videoType(_vm.viewVideoUrl),
                          controls: true,
                          screen: false,
                          showDownLoad: false,
                          show: true,
                          isShowMenu: false,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }