var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "screen-right-box",
      attrs: {
        "element-loading-text": _vm.$t("loading"),
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 1)",
      },
    },
    [
      _vm.taskList.length <= 1
        ? _c("div", { staticClass: "single-task" }, [
            _c(
              "div",
              { staticClass: "video-container" },
              [
                _c("BaseBox", {
                  attrs: { title: _vm.$t("screen.currentFootage") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "video-live-box" }, [
                              _vm.liveVideoVOList(_vm.firstTask).length
                                ? _c("div", { staticClass: "suspended" }, [
                                    _c(
                                      "div",
                                      { staticClass: "suspended-cont" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "suspended-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openVideoScreen(
                                                  _vm.firstTask
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t("navbar.screenfull")
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "video-live-box-cont active" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "video-live-box-top l-active",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/task-select-fkbg-ac.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.firstTask.taskName || "")
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm.liveVideoVOList(_vm.firstTask).length
                                    ? _c(_vm.useLive(_vm.firstTask), {
                                        ref: "videoLive",
                                        tag: "component",
                                        staticClass: "video-plane",
                                        attrs: {
                                          screen: false,
                                          liveVideoVOList: _vm.liveVideoVOList(
                                            _vm.firstTask
                                          ),
                                        },
                                      })
                                    : _c("div", {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: true,
                                            expression: "true",
                                          },
                                        ],
                                        staticClass: "video-loading",
                                        attrs: {
                                          "element-loading-text": _vm.$t(
                                            "WaitingForVideoTransmission"
                                          ),
                                          "element-loading-spinner":
                                            "el-icon-loading",
                                          "element-loading-background":
                                            "rgba(0, 0, 0, 0.8)",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    4031613368
                  ),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "plane" },
              [
                _c("BaseBox", {
                  attrs: { title: _vm.$t("screen.taskInfo") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [_c("Plant", { ref: "plane" })]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2074005745
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.taskList.length == 2
        ? _c("div", { staticClass: "dual-task" }, [
            _c(
              "div",
              { staticClass: "video-container" },
              [
                _c("BaseBox", {
                  attrs: { title: _vm.$t("screen.currentFootage") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "video-live-box" }, [
                              _vm.liveVideoVOList(_vm.firstTask).length
                                ? _c("div", { staticClass: "suspended" }, [
                                    _c(
                                      "div",
                                      { staticClass: "suspended-cont" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "suspended-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openVideoScreen(
                                                  _vm.firstTask
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t("navbar.screenfull")
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "video-live-box-cont active" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "video-live-box-top l-active",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/task-select-fkbg-ac.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.firstTask.taskName || "")
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm.liveVideoVOList(_vm.firstTask).length
                                    ? _c(_vm.useLive(_vm.firstTask), {
                                        ref: "videoLive",
                                        tag: "component",
                                        staticClass: "video-plane",
                                        attrs: {
                                          screen: false,
                                          liveVideoVOList: _vm.liveVideoVOList(
                                            _vm.firstTask
                                          ),
                                        },
                                      })
                                    : _c("div", {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: true,
                                            expression: "true",
                                          },
                                        ],
                                        staticClass: "video-loading",
                                        attrs: {
                                          "element-loading-text": _vm.$t(
                                            "WaitingForVideoTransmission"
                                          ),
                                          "element-loading-spinner":
                                            "el-icon-loading",
                                          "element-loading-background":
                                            "rgba(0, 0, 0, 0.8)",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]),
                            _vm._l(_vm.argtTaskList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.taskId,
                                  staticClass: "video-live-box",
                                },
                                [
                                  _vm.liveVideoVOList(item).length
                                    ? _c("div", { staticClass: "suspended" }, [
                                        _c(
                                          "div",
                                          { staticClass: "suspended-cont" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "suspended-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openVideoScreen(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "navbar.screenfull"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "video-live-box-cont",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleChange(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "video-live-box-top" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/task-select-fkbg.png"),
                                              alt: "",
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(item.taskName || "")),
                                          ]),
                                        ]
                                      ),
                                      _vm.liveVideoVOList(item).length
                                        ? _c(_vm.useLive(item), {
                                            ref: "videoLive" + index,
                                            refInFor: true,
                                            tag: "component",
                                            staticClass: "video-plane",
                                            attrs: {
                                              screen: false,
                                              liveVideoVOList:
                                                _vm.liveVideoVOList(item),
                                            },
                                          })
                                        : _c("div", {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: true,
                                                expression: "true",
                                              },
                                            ],
                                            staticClass: "video-loading",
                                            attrs: {
                                              "element-loading-text": _vm.$t(
                                                "WaitingForVideoTransmission"
                                              ),
                                              "element-loading-spinner":
                                                "el-icon-loading",
                                              "element-loading-background":
                                                "rgba(0, 0, 0, 0.8)",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    649241566
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.taskList.length == 3
        ? _c("div", { staticClass: "three-tasks" }, [
            _c(
              "div",
              { staticClass: "video-container" },
              [
                _c("BaseBox", {
                  attrs: { title: _vm.$t("screen.currentFootage") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "video-live-box" }, [
                              _vm.liveVideoVOList(_vm.firstTask).length
                                ? _c("div", { staticClass: "suspended" }, [
                                    _c(
                                      "div",
                                      { staticClass: "suspended-cont" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "suspended-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openVideoScreen(
                                                  _vm.firstTask
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t("navbar.screenfull")
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "video-live-box-cont active" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "video-live-box-top l-active",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/task-select-fkbg-ac.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.firstTask.taskName || "")
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm.liveVideoVOList(_vm.firstTask).length
                                    ? _c(_vm.useLive(_vm.firstTask), {
                                        ref: "videoLive",
                                        tag: "component",
                                        staticClass: "video-plane",
                                        attrs: {
                                          screen: false,
                                          liveVideoVOList: _vm.liveVideoVOList(
                                            _vm.firstTask
                                          ),
                                        },
                                      })
                                    : _c("div", {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: true,
                                            expression: "true",
                                          },
                                        ],
                                        staticClass: "video-loading",
                                        attrs: {
                                          "element-loading-text": _vm.$t(
                                            "WaitingForVideoTransmission"
                                          ),
                                          "element-loading-spinner":
                                            "el-icon-loading",
                                          "element-loading-background":
                                            "rgba(0, 0, 0, 0.8)",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "other-video" },
                              _vm._l(_vm.argtTaskList, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.taskId,
                                    staticClass: "video-live-box",
                                  },
                                  [
                                    _vm.liveVideoVOList(item).length
                                      ? _c(
                                          "div",
                                          { staticClass: "suspended" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "suspended-cont" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "suspended-item",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openVideoScreen(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "navbar.screenfull"
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "video-live-box-cont",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleChange(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "video-live-box-top video-live-box-top-d",
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/task-select-fkbg.png"),
                                                alt: "",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.taskName || "")
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm.liveVideoVOList(item).length
                                          ? _c(_vm.useLive(item), {
                                              ref: "videoLive" + index,
                                              refInFor: true,
                                              tag: "component",
                                              staticClass: "video-plane",
                                              attrs: {
                                                screen: false,
                                                liveVideoVOList:
                                                  _vm.liveVideoVOList(item),
                                              },
                                            })
                                          : _c("div", {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value: true,
                                                  expression: "true",
                                                },
                                              ],
                                              staticClass: "video-loading",
                                              attrs: {
                                                "element-loading-text": _vm.$t(
                                                  "WaitingForVideoTransmission"
                                                ),
                                                "element-loading-spinner":
                                                  "el-icon-loading",
                                                "element-loading-background":
                                                  "rgba(0, 0, 0, 0.8)",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1725917099
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.taskList.length > 3
        ? _c("div", { staticClass: "multi-tasking" }, [
            _c(
              "div",
              { staticClass: "video-container" },
              [
                _c("BaseBox", {
                  attrs: { title: _vm.$t("screen.UAVFootage") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return _vm._l(
                            _vm.argtTaskList,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.taskId,
                                  staticClass: "video-live-box",
                                },
                                [
                                  _vm.liveVideoVOList(item).length
                                    ? _c("div", { staticClass: "suspended" }, [
                                        _c(
                                          "div",
                                          { staticClass: "suspended-cont" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "suspended-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openVideoScreen(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "navbar.screenfull"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "video-live-box-cont",
                                      class: {
                                        active:
                                          _vm.selectTaskId === item.taskId,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleChange(item, true)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "video-live-box-top video-live-box-top-d",
                                          class: {
                                            "d-active":
                                              _vm.selectTaskId === item.taskId,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.selectTaskId ===
                                                  item.taskId,
                                                expression:
                                                  "selectTaskId === item.taskId",
                                              },
                                            ],
                                            attrs: {
                                              src: require("@/assets/images/task-select-fkbg-ac.png"),
                                              alt: "",
                                            },
                                          }),
                                          _c("img", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.selectTaskId !==
                                                  item.taskId,
                                                expression:
                                                  "selectTaskId !== item.taskId",
                                              },
                                            ],
                                            attrs: {
                                              src: require("@/assets/images/task-select-fkbg.png"),
                                              alt: "",
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(item.taskName || "")),
                                          ]),
                                        ]
                                      ),
                                      _vm.liveVideoVOList(item).length
                                        ? _c(_vm.useLive(item), {
                                            ref: "videoLive" + index,
                                            refInFor: true,
                                            tag: "component",
                                            staticClass: "video-plane",
                                            attrs: {
                                              screen: false,
                                              liveVideoVOList:
                                                _vm.liveVideoVOList(item),
                                            },
                                          })
                                        : _c("div", {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: true,
                                                expression: "true",
                                              },
                                            ],
                                            staticClass: "video-loading",
                                            attrs: {
                                              "element-loading-text": _vm.$t(
                                                "WaitingForVideoTransmission"
                                              ),
                                              "element-loading-spinner":
                                                "el-icon-loading",
                                              "element-loading-background":
                                                "rgba(0, 0, 0, 0.8)",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          )
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2939089996
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }