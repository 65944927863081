var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen-common-content" }, [
    _c(
      "div",
      { staticClass: "left" },
      [_c("Left", { attrs: { projectInfo: _vm.projectInfo } })],
      1
    ),
    _c("div", { staticClass: "right" }, [_c("Right")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }