var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "uav-realInfo-pop",
      attrs: { id: "uav-realInfo-pop" },
    },
    [
      _c("div", { staticClass: "uav-pop-top" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/closedicon.png"), alt: "" },
          on: {
            click: function ($event) {
              _vm.show = false
            },
          },
        }),
      ]),
      _c("div", { staticClass: "uav-pop-center" }, [
        _c("div", { staticClass: "uav-pop-center-top" }, [
          _vm._v(_vm._s(_vm.$t("screen.taskInfo"))),
        ]),
        _c("div", { staticClass: "uav-pop-center-msg" }, [
          _c("div", { staticClass: "uav-pop-center-msgd" }, [
            _c("div", { staticClass: "msg-list" }, [
              _c("div", { staticClass: "msg-item" }, [
                _c(
                  "label",
                  {
                    staticClass: "label",
                    attrs: { title: _vm.$t("screen.UAVNumber") },
                  },
                  [_vm._v(_vm._s(_vm.$t("screen.UAVNumber")) + "：")]
                ),
                _c("div", { staticClass: "msg", attrs: { title: _vm.sn } }, [
                  _vm._v(_vm._s(_vm.sn)),
                ]),
              ]),
              _c("div", { staticClass: "msg-item" }, [
                _c(
                  "label",
                  { staticClass: "label", attrs: { title: _vm.$t("height") } },
                  [_vm._v(_vm._s(_vm.$t("height")) + "：")]
                ),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(
                    _vm._s(
                      _vm.UAVData.fieldHeight
                        ? _vm.UAVData.fieldHeight.toFixed(2)
                        : "0"
                    ) + "m"
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "msg-list" }, [
              _c("div", { staticClass: "msg-item" }, [
                _c(
                  "label",
                  {
                    staticClass: "label",
                    attrs: { title: _vm.$t("mission.name") },
                  },
                  [_vm._v(_vm._s(_vm.$t("mission.name")) + "：")]
                ),
                _c(
                  "div",
                  { staticClass: "msg", attrs: { title: _vm.taskName } },
                  [_vm._v(_vm._s(_vm.taskName))]
                ),
              ]),
              _c("div", { staticClass: "msg-item" }, [
                _c(
                  "label",
                  {
                    staticClass: "label",
                    attrs: { title: _vm.$t("RealTimeMileage") },
                  },
                  [_vm._v(_vm._s(_vm.$t("RealTimeMileage")) + "：")]
                ),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(
                    _vm._s(
                      _vm.UAVData.distOnAir
                        ? (_vm.UAVData.distOnAir / 1000).toFixed(2)
                        : "0"
                    ) + "km"
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "msg-list" }, [
              _c("div", { staticClass: "msg-item" }, [
                _c(
                  "label",
                  {
                    staticClass: "label",
                    attrs: { title: _vm.$t("CurrentBatteryLevel") },
                  },
                  [_vm._v(_vm._s(_vm.$t("CurrentBatteryLevel")) + "：")]
                ),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(
                    _vm._s(
                      typeof _vm.UAVData.pwrLeft === "number"
                        ? _vm.UAVData.pwrLeft
                        : "0"
                    ) + "%"
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-item" }, [
                _c(
                  "label",
                  { staticClass: "label", attrs: { title: _vm.$t("speed") } },
                  [_vm._v(_vm._s(_vm.$t("speed")) + "：")]
                ),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(_vm._s(_vm.UAVData.gs ? _vm.UAVData.gs : "0") + "m/s"),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "msg-list" }, [
              _c("div", { staticClass: "msg-item" }, [
                _c(
                  "label",
                  {
                    staticClass: "label",
                    attrs: { title: _vm.$t("longitude") },
                  },
                  [_vm._v(_vm._s(_vm.$t("longitude")) + "：")]
                ),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(_vm._s(_vm.UAVData.lon ? _vm.UAVData.lon : "-")),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "msg-list" }, [
              _c("div", { staticClass: "msg-item" }, [
                _c(
                  "label",
                  {
                    staticClass: "label",
                    attrs: { title: _vm.$t("latitude") },
                  },
                  [_vm._v(_vm._s(_vm.$t("latitude")) + "：")]
                ),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(_vm._s(_vm.UAVData.lat ? _vm.UAVData.lat : "-")),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }