<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-09-24 10:48:36
 * @watermark: 成都沃飞长空
 * @LastEditors: wq
-->
<template>
  <div>
    <div class="home">
      <div class="header">
        <Header :projectInfo="projectInfo"/>
      </div>
      <div class="content">
        <Content 
          :detail="projectInfo"
          @handleGrandchildClick="handleGrandchildClick"
          ref="content" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Content from "./Content.vue";
import store from "@/store/index";
import { setToken, getToken } from '@/util/auth';
import { getDetail } from "@/api/phugiacloud/projectTask";
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';

export default {
  name: "screen",
  components: {
    Header,
    Content,
  },
  props: {
    projectInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      socket: null,
      detail: {},
    };
  },
  created() {
    this.$options.flyRouteHook = liveFlyRouteHook();
    const { token } = this.$route.query;
    //获取token参数
    if (token && getToken() !== token) {
      //单点登录带入token
      setToken(token);
    }
  },

  methods: {
    //add 2023-07-22 11:22:05
    handleGrandchildClick({ value, taskSelect }) {
      this.initRequest(value)
    },
    initRequest(value) {
      if (this._timeouter) {
        clearInterval(this._timeouter)
        this._timeouter = null
      }
      if (value !== undefined && value !== '') {
        // 创建新的ws之前清理一次现有的ws链接
        this.$options.flyRouteHook.closeWsLink();
        this.webSocketInt(value);
      }
      this.getScreenDataInt(value);
    },

    //add 2023-07-22 14:32:09
    async webSocketInt(value) {
      let uavID = "";
      let groundStationType = "";
      let tlfp = "";
      let ws = null;
      let useIot = '';
      let wsUrl = "";
      try {
        getDetail(value).then(res=>{
          this.detail = res.data.data;
          groundStationType = res.data.data.groundStationType;
          store.commit("SET_TASK", this.detail);
          useIot = this.detail.useIot;
          // 不在使用iot判断了
          // if (useIot === 1) {
          //   let guid = Cesium.createGuid().slice(0, 6).replace('-', 'A')
          //   wsUrl = `${window.global.VUE_APP_WS_ROUTE_NEW }/${this.detail.airPlaneSn}-${guid}`;
          // } else {
          //   if (groundStationType == "ASGCSS") {
          //     uavID = res.data.data.orderId.split('-')[0];
          //     wsUrl = `${window.global.VUE_APP_WS_ROUTE}/?type=REALTIME&uavId=${uavID.toLocaleUpperCase()}`;
          //   }else {
          //     tlfp = res.data.data.tlfp;
          //     wsUrl = `${window.global.VUE_APP_WS_ROUTE}?connectID=${tlfp}`;
          //   }
          // }
          let guid = Cesium.createGuid().slice(0, 6).replace('-', 'A')
          wsUrl = `${window.global.VUE_APP_WS_ROUTE_NEW }/${this.detail.airPlaneSn}-${guid}`;
          const wsCallBack = function({data}) {
            // if (useIot === 1) {
            //   // 新方案的数据存储
            //   store.commit("SET_NEW_GLOBAL_DATA", data);
            // } else {
            //   // 老方案的数据存储
            //   store.commit("SET_GLOBAL_DATA", { type: groundStationType, data });
            // }
            // 不在使用iot判断了
            store.commit("SET_NEW_GLOBAL_DATA", data);
          }
          wsUrl && this.$options.flyRouteHook.initWebSocket({url: wsUrl, callback: wsCallBack});
        });
      } catch (error) {
        console.error(error);
        return;
      }
    },

    getScreenDataInt(value) {
      const {flyId, projectId} = this.$route.query;
      if (flyId && value) {
        const params = {
          projectId: flyId,
          taskId: value ? value : 0,
          inspectionProjectId: ''
        };
        if (flyId != projectId) {
          params.inspectionProjectId = projectId;
        }
        this.$store.dispatch("GetScreenData", params);
      }
      this._timeouter = setTimeout(() => {
        this.getScreenDataInt(value);
      }, 2000);
    },

    initPopupLabels(taskSelect) {
      const content = this.$refs.content; // 获取子组件实例
      const left = content.$refs.left; // 获取子子组件实例
      const plane = left.$refs.plane; // 获取子子组件实例
      plane.initPopupLabels(taskSelect); // 调用子子组件的方法
    }
  },
  beforeDestroy() {
    //断开socket连接
    this.$store.commit("CLEAR_GLOBAL_DATA");
    this.$store.commit("CLEAR_NEW_GLOBAL_DATA");
    if (this._timeouter) {
      clearTimeout(this._timeouter)
      this._timeouter = null
    }
    this.$options.flyRouteHook.closeWsLink();
    this.$options.flyRouteHook = null;
  }
};
</script>

<style scoped lang="scss">
.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url("~@/assets/images/main-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .header {
    height: 110px;
    z-index: 10;
    background-image: url("~@/assets/images/main-bg-header.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .content {
    flex: 1;
    display: flex;
    padding: 20px 14px;
    height: calc(100% - 110px);
    overflow: hidden;
  }
}
</style>
