<template>
    <!-- 公共地图页 -->
    <div class="screen_map_box" id="screen_map_box">
        <div id="cesiumContainer_map_center"></div>
        <!-- 右上角操作栏目 -->
        <div class="right-top-tools" v-if="isShowTopTools">
            <div class="track-screen" @click="trackTo">
                <img :class="$i18n.locale ==='zh'? 'full-image-zh': 'full-image'" 
                    :src="require(`@/assets${$i18n.locale ==='zh' ? '' : '/en'}/map/track${beingTracked ? '_active' : ''}.png`)" 
                />
            </div>
        </div>
        <div v-if="hoverTip.show"
            :style="`
                left: ${hoverTip.left}px;
                top: ${hoverTip.top}px;
                transform: translateY(calc(-100% - ${hoverTip.type===NodeMap.Node.POINT? 40: 18}px)) translateX(-50%);
            `"
            class="hover-tip">
            <div class="hover-tip-triangle"></div>
            <div class="hover-tip-item" v-for="item in hoverTip.list" :key="item.label">
                <span>{{ item.label }}: </span>
                <span>{{ item.value }}</span>
            </div>
        </div>
        <!-- 基站悬浮窗 -->
        <BaseStationPop ref="BaseStationPop" :detailData="baseStationDetail" />
        <!-- 飞机实时信息浮窗 -->
        <UavRealInfoPop ref="UavRealInfoPop" v-if="mapType != 'alarmMap'" />
        <!-- 机库详情弹窗 -->
        <HangarDetails v-if="mapType == 'overallMap' && hangarShow" ref="hangarDetails" :show.sync="hangarShow" 
            :baseData="hangarBaseData" :detailsData="hangarDetailsData" :videoData="hangarVideoData" 
            @closeDetails="closeHangarPanel" />
        <!-- AI报警信息浮窗 -->
        <AIwarningDetail ref="AIwarningDetail" v-if="mapType == 'overallMap'" :selectWarning="selectAlarmData" 
            @close="closedAlarmInfoPop" />
        <!-- 报警信息浮窗 -->
        <AlarmReallinfoPop ref="AlarmReallinfoPop" v-if="mapType == 'overallMap' || mapType == 'alarmMap'" 
            :alarmData="selectAlarmData" />
    </div>
</template>

<script>
import Map3d from "@/components/cesium/js/Map3d";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import NodeMap from '@/components/cesium/js/node/index';
import oilAIWarning from "@/assets/img/map/oilAIWarning.png";
import oilAIWarningActive from "@/assets/img/map/oilAIWarning-active.png";
import { mapGetters, mapActions } from "vuex";
import { getMarkPoint, getMarkPolyline, getMarkPolygon } from "@/api/project/map";
import { getUserInspectionObject, getUserMarkerObject, 
    getUserHangarObject, getAIPendAlarmDetails } from '@/api/oilGasScreen/index';
import oilInspectionVue from '@/components/cesium/mixins/oilInspection.vue';
import noFlyZoneShowVue from "@/components/cesium/mixins/noFlyZoneShow.vue";
import airportShowVue from "@/components/cesium/mixins/airportShow.vue";
import basestationShowVue from "@/components/cesium/mixins/basestationShow.vue";
import hangarVue from "@/components/cesium/mixins/hangar.vue";
import UavRealInfoPop from "@/components/cesium/components/uav-realInfo-pop.vue";
import AIwarningDetail from "./components/ai-warning-detail.vue";
import AlarmReallinfoPop from "./components/alarm-reallinfo-pop.vue";
import PlaneIcon from "@/assets/img/map/XC_64x64_.png";
import PlaneActiveIcon from "@/assets/img/map/XC_seleted.png";
import HomeIcon from "@/assets/map/homePoint.png";
import HomeActiveIcon from "@/assets/map/homePoint-active.png";
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';
import userHangarHook from '@/components/cesium/js/hooks/hangarHook';

export default {
    name: 'oli-map',
    mixins: [ oilInspectionVue, noFlyZoneShowVue, airportShowVue, basestationShowVue, hangarVue ],
    components: { UavRealInfoPop, AIwarningDetail, AlarmReallinfoPop },
    props: {
        isShowTopTools: {
            type: Boolean,
            default: false
        },
        mapData: {
            type: Object | Array,
            default: () => {
                return [];
            }
        },
        // 是否需要跟随
        isTracked: {
            type: Boolean,
            default: true
        },
        // 地图使用类型
        mapType: {
            type: String,
            // 使用类型包括overallMap全局概况地图，realTimeMap实时任务地图，alarmMap报警记录地图
            default: 'realTimeMap'
        },
        // 报警列表数据
        alarmData: {
            type: Array,
            default: () => {
                return [];
            }
        },
        // AI报警以及普通报警数据
        AIAlarmData: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            NodeMap,
            viewModel: Cesium.SceneMode.SCENE3D,
            handlerMapMoveEnd: null,
            mapMoveEndTimer: null,
            mapMoveEndColseNum: 0, // 当定时器被强行中断10次以上就执行最后一次的定时器拿到范围坐标获取巡检对象
            mapMoveEndRequestList: [],
            mapMoveEndRequestTimer: null,
            emergencyMakerTimer: null,
            markData: [], // 标注数据
            // 巡检对象图层
            inspectionLayer: null,
            // 标注图层
            markLayer: null,
            // 报警记录图层
            alarmRecordLayer: null,
            // AI报警图层
            aiAlarmLayer: null,
            // 是否跟踪无人机
            beingTracked: true,
            // 是否第一次设置跟随，当前值改变一次后就无需改变了，仅限判断是否是第一次设置跟随
            isSetFlight: false,
            // 选择的报警项
            selectAlarmData: {},
            // 一些图标变量
            PlaneIcon
        }
    },
    computed: {
        ...mapGetters([
            'selectTask',
            'currentTaskMap',
            'currentTaskList',
            'itemForTaskMapChange'
        ]),
        // 当前正在执行任务列表
        taskSns() {
            let snList = this.currentTaskMap ? (Object.keys(this.currentTaskMap) || []) : [];
            return snList;
        }
    },
    watch: {
        itemForTaskMapChange: {
            handler(val) {
                val && this.updateFlyRoute(val);
            },
            deep: true, //true 深度监听
        },
        selectTask: {
            handler: function(newVal, oldVal) {
                this.changeFlyStyle(oldVal, 'init');
                if (newVal && newVal.sn) {
                    this.changetrackTo();
                    this.changeFlyStyle(newVal, 'change');
                } else {
                    this.$nextTick(() => {
                        let UavRealInfoPop = this.$refs.UavRealInfoPop;
                        if (UavRealInfoPop) {
                            UavRealInfoPop.show = false;
                        }
                    });
                }
            }
        },
        alarmData: {
            deep: true,
            handler: function(newVal, oldVal) {
                if (!this.alarmRecordLayer) return;
                if (oldVal) {
                    const removeData = oldVal.filter(
                        oldItem => newVal.findIndex(newItem => newItem.id === oldItem.id) === -1
                    );
                    removeData.map(t => {
                        this.alarmRecordLayer && this.alarmRecordLayer.removeNodeById(t.id);
                    });
                }
                if (this.selectAlarmData.id) {
                    let item = newVal.find(t => t.id === this.selectAlarmData.id);
                    !item && this.closedAlarmInfoPop();
                    !item && this.clearSelectEntity();
                }
                this.createAlarmRecord();
            }
        },
        AIAlarmData: {
            deep: true,
            handler: function(newVal, oldVal) {
                if (!this.aiAlarmLayer) return;
                if (oldVal) {
                    const removeData = oldVal.filter(
                        oldItem => newVal.findIndex(newItem => newItem.id === oldItem.id) === -1
                    );
                    removeData.map(t => {
                        this.aiAlarmLayer && this.aiAlarmLayer.removeNodeById(t.id);
                    });
                }
                if (this.selectAlarmData.id) {
                    let item = newVal.find(t => t.id === this.selectAlarmData.id);
                    !item && this.closedAlarmInfoPop();
                    !item && this.clearSelectEntity();
                }
                this.aiAlarmHandle();
            }
        }
    },
    created() {
        this.$options.popslist = [];
        this.$options.popIds = [];
    },
    mounted() {
        // 清除飞机实体监听
        this.$EventBus.$off('clearn-uav-data');
        this.$EventBus.$on('clearn-uav-data', data => {
            this.clearnUavEntity(data);
        });
        // AI报警事件监听
        this.$EventBus.$off('AI-Notification-Location');
        this.$EventBus.$on('AI-Notification-Location', data => {
            this.localAiAlarm(data);
        });
        this.$options.flyRouteHook = liveFlyRouteHook();
        this.$options.hangarHook = userHangarHook();
        this.init();
    },
    methods: {
        ...mapActions([
            // 设置当前正在执行的任务
            'setSelectTask',
        ]),
        init() {
            let _this = this;
            const viewer = new Map3d("cesiumContainer_map_center", {
                sceneMode: this.viewModel,
                i18n: this.$i18n
            });
            this.$options.map3d = viewer;
            this.initLayer();
            this.getNoFlyDataTwo(); // 加载禁飞区
            this.getAirportData(); // 加载机场
            this.getBasestationData(); // 加载基站
            if (this.mapType != 'alarmMap') {
                this.getUserMarkerData(); // 加载标注对象
                this.createUserMarkerDataTimer(); // 初始化标注对象获取定时器
                this.getHangarData(); // 加载机库
            }
            if (this.mapType != 'alarmMap') {
                setTimeout(() => {
                    this.getCornerPositions('first');
                    this.removeMapMoveEvent();
                    this.addMapMoveEvent(() => {
                        _this.getCornerPositions('move');
                    });
                }, 1000);
                // 循环定时检测mapMoveEndRequestList中是否有待执行的请求任务
                this.mapMoveEndRequestTimer = setInterval(() => {
                    let length = _this.mapMoveEndRequestList.length;
                    if (length) {
                        let lastPositions = _this.mapMoveEndRequestList[length - 1];
                        _this.getUserInspectionData(lastPositions.positions); // 根据范围加载巡检对象
                    }
                }, 3000);
            }
            this.setEditModel();
        },
        // 初始化图层
        initLayer() {
            this.$options.flyRouteHook.setMap({map3d: this.$options.map3d, flylineLayerID: "flylineLayer", 
                defaultZoomTo: false, showVisualPolygon: true, showHomePoint: true, flightFollowing: this.beingTracked});
            this.initNoflyShowLayer();
            this.initAirportShowLayer();
            this.initBasestationShowLayer();
            this.$options.hangarHook.setMap({map3d: this.$options.map3d, layerID: 'hangarLayer'});
            this.inspectionLayer = new BaseLayer({ name: "inspectionLayer" });
            this.$options.map3d.layerManager.add(this.inspectionLayer);
            this.markLayer = new BaseLayer({ name: "markLayer" });
            this.$options.map3d.layerManager.add(this.markLayer);
            this.alarmRecordLayer = new BaseLayer({ name: "alarmRecordLayer" });
            this.$options.map3d.layerManager.add(this.alarmRecordLayer);
            this.aiAlarmLayer = new BaseLayer({ name: "aiAlarmLayer" });
            this.$options.map3d.layerManager.add(this.aiAlarmLayer);
        },
        // 设置交互
        setEditModel(en = true) {
            const self = this;
            if (en === true) {
                this.$options.map3d._container.style.cursor = "default";
            }
            let afterFinish = async (model, origin, selectFeature) => {
                if (!model) {
                    this.closedAlarmInfoPop();
                    return;
                }
                if (model._drawType === 'FlyPoint') {
                    if (model && 
                        (this.mapType == 'overallMap' || (this.mapType == 'realTimeMap' && this.taskSns.length > 1))) {
                        if (!model.id) return;
                        let UavRealInfoPop = this.$refs.UavRealInfoPop;
                        if (model.id === this.selectTask.sn) {
                            if (UavRealInfoPop) {
                                if (this.mapType == 'overallMap' 
                                    || (this.mapType == 'realTimeMap' && !this.$parent.taskData)) {
                                    UavRealInfoPop.show = true;
                                }
                            }
                        } else {
                            let taskItem = this.currentTaskList.find(t => t.sn === model.id);
                            if (this.mapType == 'overallMap') {
                                this.setSelectTask(taskItem);
                                if (UavRealInfoPop) {
                                    UavRealInfoPop.show = true;
                                }
                            } else if (this.mapType == 'realTimeMap') {
                                let noSetFirstTask = this.taskSns.length > 3 ? true : false;
                                if (taskItem && !this.$parent.taskData) {
                                    taskItem && this.$emit('changeUav', taskItem, noSetFirstTask);
                                }
                            }
                        }
                    }
                }
                if (model._drawType === 'hangarPoint') {
                    if (this.mapType == 'overallMap') {
                        this.selectedHangarNode(model);
                    }
                }
                if (model._drawType === 'oilScreenWarningPoint') {
                    if (model.id) {
                        let alarmItemData = '';
                        if (this.mapType == 'overallMap') {
                            alarmItemData = this.AIAlarmData.find(t => t.id == model.id);
                            if (alarmItemData && alarmItemData.alarmStatus == 'pend') {
                                let data = await this.getAIPendAlarmDetailsData(alarmItemData.id);
                                if (data.code == 200 && data.data) {
                                    alarmItemData.alarmStatus = data.data.alarmStatus;
                                } else {
                                    alarmItemData.alarmStatus = '';
                                }
                            }
                        } else if (this.mapType == 'alarmMap') {
                            alarmItemData = this.alarmData.find(t => t.id == model.id);
                        }
                        if (alarmItemData && this.mapType != 'realTimeMap') {
                            this.closedAlarmInfoPop();
                            this.selectAlarmData = alarmItemData;
                            this.createAlarmInfoPop();
                        }
                    }
                }
            }
            // 注册完交互事件的对用回调
            this.$options.map3d.triggerEditModel(en, {
                map: this.$options.map3d,
                vueHandler: self,
                afterFinish
            });
        },
        // 监听地图移动
        addMapMoveEvent(callback) {
            let _this = this;
            this.handlerMapMoveEnd = () => {
                if (_this.mapMoveEndTimer && _this.mapMoveEndColseNum < 10) {
                    clearTimeout(this.mapMoveEndTimer);
                    _this.mapMoveEndColseNum += 1;
                    _this.mapMoveEndTimer = null;
                }
                if (!_this.mapMoveEndTimer) {
                    _this.mapMoveEndTimer = setTimeout(() => {
                        // console.log('移动结束');
                        callback && callback();
                        clearTimeout(_this.mapMoveEndTimer);
                        _this.mapMoveEndTimer = null;
                        _this.mapMoveEndColseNum = 0;
                    }, 2000);
                }
            }
            this.$options.map3d.scene.camera.moveEnd.addEventListener(this.handlerMapMoveEnd);
        },
        // 移除监听
        removeMapMoveEvent() {
            if (this.handlerMapMoveEnd) {
                this.$options.map3d.scene.camera.moveEnd.removeEventListener(this.handlerMapMoveEnd);
                this.handlerMapMoveEnd = null;
            }
        },
        // 获取当前地图可视区域四角范围坐标
        getCornerPositions(type) {
            let _this = this;
            let canvas = _this.$options.map3d.scene.canvas;
            let width = canvas.clientWidth;
            let height = canvas.clientHeight;
            let corners = [
                new Cesium.Cartesian2(0, height), // 左上角
                new Cesium.Cartesian2(width, height), // 右上角
                new Cesium.Cartesian2(width, 0), // 右下角
                new Cesium.Cartesian2(0, 0) // 左下角
            ];
            let cornerPositions = corners.map(function(corner) {
                let ray = _this.$options.map3d.scene.camera.getPickRay(corner);
                let intersection = _this.$options.map3d.scene.globe.pick(ray, _this.$options.map3d.scene);
                return intersection ? Cesium.Ellipsoid.WGS84.cartesianToCartographic(intersection) : null;
            });
            let positions = [];
            cornerPositions.map(t => {
                positions.push({
                    x: t ? Cesium.Math.toDegrees(t.longitude) : '',
                    y: t ? Cesium.Math.toDegrees(t.latitude) : ''
                });
            });
            positions.push(positions[0]);
            let noDataItem = positions.find(t => !t.x || !t.y);
            if (!noDataItem) {
                if (type == 'first') {
                    this.getUserInspectionData(positions); // 根据范围加载巡检对象
                } else if (type == 'move') {
                    // 检测到是地图移动产生的先将请求放置在集合中缓存，以防多次请求
                    this.mapMoveEndRequestList.push({positions});
                }
            }
        },
        // 绘制更新飞机
        updateFlyRoute(value) {
            const id = value.key;
            let taskData = this.currentTaskMap[id];
            this.$options.flyRouteHook.findEntityById({id, showEntityLabel: true, entityLabelText: value.airName, 
                entityIcon: taskData.icon || this.PlaneIcon, iconSize: 60, homeIcon: HomeIcon, homeIconSize: 30, 
                lineColor: "#70DFFF", lineWidth: 3, polygonColor: "#FFE600", polygonAlpha: 0.45, data: value.data});
            let UavRealInfoPop = this.$refs.UavRealInfoPop;
            if (this.selectTask.sn == id) {
                // 设置飞行浮窗
                if (UavRealInfoPop) {
                    if (this.mapType == 'overallMap' || (this.mapType == 'realTimeMap' && this.taskSns.length > 1)) {
                        const tempLon = Number(value.data.lon) * 1;
                        const tempLat = Number(value.data.lat) * 1;
                        this.$options.flyRouteHook.addUavRealInfoPop({
                            id: 'uav-realInfo-pop',
                            parentId: 'screen_new_map_box',
                            positions: [tempLon, tempLat, 0],
                            x: 0,
                            y: 32
                        });
                    }
                }
            }
            // 当前选择的任务id和更新的实体id一致则表示当前实体需要设置跟随以及切换实体样式
            if (this.selectTask.sn == id && !this.isSetFlight) {
                setTimeout(() => {
                    if (this.isTracked) {
                        this.beingTracked = this.$options.flyRouteHook.setFlightFollowingById(this.selectTask.sn);
                    }
                    let currentTask = this.currentTaskMap[this.selectTask.sn];
                    this.$options.flyRouteHook.changeEntityStyle(
                        this.selectTask.sn,
                        currentTask ? (currentTask.selectIcon || PlaneActiveIcon) : PlaneActiveIcon,
                        '#FFFFFF',
                        HomeActiveIcon
                    );
                    if (UavRealInfoPop && this.mapType == 'realTimeMap' && this.taskSns.length > 1) {
                        UavRealInfoPop.show = true;
                    }
                }, 2500);
                this.isSetFlight = true;
            }
        },
        // 切换跟随
        trackTo() {
            this.beingTracked = this.$options.flyRouteHook.changeFlightFollowing(this.selectTask.sn);
        },
        changetrackTo() {
            if (this.isTracked) {
                this.beingTracked = this.$options.flyRouteHook.setFlightFollowingById(this.selectTask.sn, true);
            }
        },
        // 改变飞机以及路线样式
        changeFlyStyle(data, type) {
            if (!data || !data.sn) return;
            let currentTask = this.currentTaskMap[data.sn];
            let PlaneIcon = this.PlaneIcon;
            if (currentTask) {
                PlaneIcon = type === 'init' ? (currentTask.icon || PlaneIcon) 
                : (currentTask.selectIcon || PlaneActiveIcon);
            }
            let color = type === 'init' ? '#70DFFF' : '#FFFFFF';
            let homeImg = type === 'init' ? HomeIcon : HomeActiveIcon;
            this.$options.flyRouteHook.changeEntityStyle(data.sn, PlaneIcon, color, homeImg);
        },
        // 清除失效飞机实体
        clearnUavEntity(data = []) {
            let snList = [];
            let UavRealInfoPop = this.$refs.UavRealInfoPop;
            data.map(t => {
                snList.push(t.sn);
                if (t.sn === this.selectTask.sn) {
                    if (UavRealInfoPop) {
                        UavRealInfoPop.show = false;
                    }
                }
            });
            this.$options.flyRouteHook.clearUavEntity(snList);
        },
        // 获取巡检对象并绘制
        getUserInspectionData(positions) {
            // console.log('请求巡检对象' + new Date().getTime());
            this.mapMoveEndRequestList = [];
            clearTimeout(this.mapMoveEndTimer);
            this.mapMoveEndColseNum = 0;
            this.mapMoveEndTimer = null;
            let params = {coordinateList: positions};
            getUserInspectionObject(params).then(res => {
                if (res.data.code == 200) {
                    const data = res.data.data;
                    if (data) {
                        // 给编辑图层集合添加图层
                        this.addInspectLabels(data);
                    }
                }
            });
        },
        addInspectLabels(data) {
            let _this = this;
            this.$options.map3d.layerManager.remove(this.inspectionLayer);
            this.$nextTick(() => {
                _this.inspectionLayer = new BaseLayer({ name: "inspectionLayer" });
                _this.$options.map3d.layerManager.add(_this.inspectionLayer);
                _this.createInspectionsIntoLayer(data, _this.inspectionLayer);
            });
        },
        // 获取标注对象并绘制
        getUserMarkerData() {
            getUserMarkerObject().then(res => {
                if (res.data.code == 200) {
                    const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];
                    const removeData = this.markData.filter(
                        oldItem => data.findIndex(newItem => newItem.id === oldItem.id) === -1
                    );
                    this.markData = data;
                    removeData.map(t => {
                        this.markLayer.removeNodeById(t.id);
                    });
                    this.addEmergencyMakerLabels(data);
                }
            });
        },
        addEmergencyMakerLabels(data) {
            for (let j = 0; j < data.length; j++) {
                const mark = data[j];
                let markNode = this.markLayer.getNodeById(mark.id);
                if (markNode) {
                    this.createMarkNode(mark, markNode);
                } else {
                    const node = this.createMarkNode(mark);
                    this.markLayer.addNode(node);
                }
            }
        },
        createMarkNode(mark, markNode) {
            const geojson = mark.content;
            const type = geojson.geometry.type;
            const coordinates = geojson.geometry.coordinates;
            let node;
            if (type === "Point") {
                if (markNode) {
                    markNode.name = mark.name;
                    markNode.color = geojson.properties.color ? geojson.properties.color : markNode.color;
                    markNode.position = Cesium.Cartesian3.fromDegrees(...coordinates);
                } else {
                    node = new NodeMap.MarkPoint({
                        id: mark.id,
                        name: mark.name,
                        createBy: mark.createBy,
                        show: !!mark.visibility,
                        dirId: mark.dirId,
                        color: geojson.properties.color,
                        isFire: !!geojson.properties.icon,
                        position: Cesium.Cartesian3.fromDegrees(...coordinates),
                        canEdit: false
                    });
                }
            }
            if (type === "LineString") {
                var positions = [];
                for (let k = 0; k < coordinates.length; k++) {
                    const coordinate = coordinates[k];
                    const position = Cesium.Cartesian3.fromDegrees(...coordinate);
                    positions.push(position);
                }
                if (markNode) {
                    markNode.entity.label.text._value = mark.name;
                    markNode.color = geojson.properties.color ? geojson.properties.color : markNode.color;
                    markNode.positions = positions;
                } else {
                    node = new NodeMap.MarkLine({
                        id: mark.id,
                        positions,
                        dirId: mark.dirId,
                        createBy: mark.createBy,
                        color: geojson.properties.color,
                        show: !!mark.visibility,
                        name: mark.name,
                        canEdit: false
                    });
                }
            }
            if (type === "Polygon") {
                var positions = [];
                for (let k = 0; k < coordinates[0].length - 1; k++) {
                    const coordinate = coordinates[0][k];
                    const position = Cesium.Cartesian3.fromDegrees(...coordinate);
                    positions.push(position);
                }
                if (markNode) {
                    markNode.entity.label.text._value = mark.name;
                    markNode.color = geojson.properties.color ? geojson.properties.color : markNode.color;
                    markNode.positions = positions;
                } else {
                    node = new NodeMap.MarkPolygon({
                        id: mark.id,
                        positions,
                        dirId: mark.dirId,
                        createBy: mark.createBy,
                        color: geojson.properties.color,
                        show: !!mark.visibility,
                        name: mark.name,
                        canEdit: false
                    });
                }
            }
            return node;
        },
        openScreenMakerHovertip(node) {
            if (!node || !node.id) return;
            let entity = this.markLayer.getById(node.id);
            if (!entity) return;
            let distance = '0m',
                dis,
                suffix,
                horiDistance = 0;
            switch (node.type) {
                case NodeMap.Node.POINT:
                    getMarkPoint({id: node.id}).then(res => {
                        if (res.data.code === 200) {
                            const data = res.data.data;
                            this.hoverTip.show = true;
                            this.hoverTip.list = [{label: this.$t('name'), value: data.name}];
                        }
                    });
                    break;
                case NodeMap.Node.LINE:
                    getMarkPolyline({id: node.id}).then(res => {
                        if (res.data.code === 200) {
                            const data = res.data.data;
                            // 水平距离/周长计算
                            horiDistance = entity.horiDistance;
                            dis = horiDistance.toFixed(2);
                            suffix = 'm';
                            if (dis > 1000) {
                                dis = (dis / 1000).toFixed(2);
                                suffix = 'km';
                            }
                            distance = dis + suffix;
                            this.hoverTip.show = true;
                            this.hoverTip.list = [
                                {label: this.$t('name'), value: data.name},
                                {label: this.$t('mark.horizontalDistance'), value: distance}
                            ];
                        }
                    });
                    break;
                case NodeMap.Node.POLYGON:
                    getMarkPolygon({id: node.id}).then(res => {
                        if (res.data.code === 200) {
                            const data = res.data.data;
                            // 水平距离/周长计算
                            horiDistance = entity.horiDistance;
                            dis = horiDistance.toFixed(2);
                            suffix = 'm';
                            if (dis > 1000) {
                                dis = (dis / 1000).toFixed(2);
                                suffix = 'km';
                            }
                            distance = dis + suffix;
                            // 水平面积计算
                            let area = '0m²';
                            let square = data.area;
                            if (square && square > 1000 * 1000) {
                                area = (square / 1000 / 1000).toFixed(2) + 'km²';
                            } else if (square && square <= 1000 * 1000) {
                                area = square.toFixed(2)  + 'm²';
                            }
                            this.hoverTip.show = true;
                            this.hoverTip.list = [
                                {label: this.$t('name'), value: data.name},
                                {label: this.$t('mark.horizontalPerimeter'), value: distance},
                                {label: this.$t('mark.horizontalArea'), value: area}
                            ];
                        }
                    });
                    break;
            }
        },
        // 获取标注对象定时器
        createUserMarkerDataTimer() {
            if (this.emergencyMakerTimer) clearInterval(this.emergencyMakerTimer);
            this.emergencyMakerTimer = setInterval(() => {
                this.getUserMarkerData();
            }, 5000);
        },
        // 绘制报警记录
        createAlarmRecord() {
            if (this.mapType === 'alarmMap') {
                this.alarmData.map(t => {
                    let alarmPoint = this.alarmRecordLayer.getNodeById(t.id);
                    if (alarmPoint) {
                        alarmPoint.position = Cesium.Cartesian3.fromDegrees(t.lon * 1, t.lat * 1);
                    } else {
                        let node = new NodeMap.OilScreenWarningPoint({
                            id: t.id,
                            position: Cesium.Cartesian3.fromDegrees(t.lon * 1, t.lat * 1),
                        });
                        this.alarmRecordLayer.addNode(node);
                    }
                });
            }
        },
        // 获取机库
        getHangarData() {
            this.$options.hangarHook.clearRenderLayer(); // 绘制之前清空一次
            getUserHangarObject().then(res => {
                if (res.data.code == 200) {
                    let type = 'show';
                    if (this.mapType == 'overallMap') {
                        type = 'select';
                    }
                    this.$options.hangarHook.handlingHangarData({data: res.data.data || [], type});
                }
            });
        },
        // 添加并打开报警信息浮窗
        createAlarmInfoPop() {
            const tempLon = Number(this.selectAlarmData.lon) * 1;
            const tempLat = Number(this.selectAlarmData.lat) * 1;
            let popConfig = {id: 'alarm-reallinfo-pop', x: -175, y: -295};
            let AlarminfoPop = this.$refs.AlarmReallinfoPop;
            if (this.mapType == 'overallMap' && this.selectAlarmData.alarmStatus == 'pend') {
                popConfig = {id: 'ai-warning-detail', x: -185, y: -315};
                AlarminfoPop = this.$refs.AIwarningDetail;
            }
            // this.$options.flyRouteHook.addUavRealInfoPop
            this.addAlarmInfoPop({
                id: popConfig.id,
                parentId: 'screen_map_box',
                positions: [tempLon, tempLat, 0],
                x: popConfig.x,
                y: popConfig.y
            });
            if (AlarminfoPop) {
                AlarminfoPop.show = true;
            }
        },
        // 移出并关闭报警信息浮窗
        closedAlarmInfoPop() {
            // this.$options.flyRouteHook.removeAllUavpops();
            this.removeAllAlarmpops();
            let AlarminfoPop = this.$refs.AlarmReallinfoPop;
            if (this.mapType == 'overallMap' && this.selectAlarmData.alarmStatus == 'pend') {
                AlarminfoPop = this.$refs.AIwarningDetail;
            }
            if (AlarminfoPop) {
                AlarminfoPop.show = false;
                this.selectAlarmData = {};
            }
        },
        // AI报警数据处理绘制
        aiAlarmHandle() {
            if (this.mapType == 'overallMap') {
                this.AIAlarmData.map(t => {
                    let alarmPoint = this.aiAlarmLayer.getNodeById(t.id);
                    if (alarmPoint) {
                        alarmPoint.position = Cesium.Cartesian3.fromDegrees(t.lon * 1, t.lat * 1);
                    } else {
                        let config = {id: t.id, position: Cesium.Cartesian3.fromDegrees(t.lon * 1, t.lat * 1)};
                        if (t.identifyAlarmType == 'ai') {
                            config.icon = oilAIWarning;
                            config.selectIcon = oilAIWarningActive;
                        }
                        let node = new NodeMap.OilScreenWarningPoint(config);
                        node && this.aiAlarmLayer.addNode(node);
                    }
                });
            }
        },
        // 定位ai报警
        localAiAlarm(data) {
            let _this = this;
            if (this.mapType == 'overallMap') {
                this.$options.map3d.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(
                        Number(data.lon),
                        Number(data.lat),
                        1000 * 4
                    ),
                    duration: 1.0
                });
                // 选中实体并打开弹窗
                setTimeout(async () => {
                    _this.selectAlarmEntity(data.id);
                    let alarmItemData = _this.AIAlarmData.find(t => t.id == data.id);
                    if (alarmItemData && alarmItemData.alarmStatus == 'pend') {
                        let data = await this.getAIPendAlarmDetailsData(alarmItemData.id);
                        if (data.code == 200 && data.data) {
                            alarmItemData = data.data;
                        } else {
                            alarmItemData = '';
                        }
                    }
                    if (alarmItemData) {
                        _this.closedAlarmInfoPop();
                        _this.selectAlarmData = alarmItemData;
                        _this.createAlarmInfoPop();
                    }
                }, 1000);
            }
        },
        // 选中报警实体
        selectAlarmEntity(id) {
            if (this.$options.map3d.interactive._current.selectFeatureByEntity) {
                let entity = this.aiAlarmLayer.getById(id);
                if (entity) {
                    this.$options.map3d.interactive._current.selectFeatureByEntity(entity);
                }
            }
        },
        // 清除选中实体
        clearSelectEntity() {
            this.$options.map3d.interactive._current.cancelSelectFeature();
        },
        // 请求未处理AI报警详情
        getAIPendAlarmDetailsData(id) {
            return new Promise(resolve => {
                getAIPendAlarmDetails({id}).then(res => {
                    if (res.data.code == 200) {
                        resolve({code: res.data.code, data: res.data.data || ''});
                    } else {
                        resolve({code: ''});
                    }
                }).catch(() => {
                    resolve({code: ''});
                });
            });
        },
        // 添加报警信息弹窗
        addAlarmInfoPop(options) {
            let _this = this;
            if (!options) return;
            this.removeAllAlarmpops();
            const positions = options.positions;
            const height = options.height || 2;
            const id = options.id;
            const parentId = options.parentId;
            const X = options.x || 0;
            const Y = options.y || 0;
            this.$options.popIds.push(options.id);
            this.$options.popslist.push({
                id: id,
                positions: positions,
                x: X,
                y: Y
            });
            if (this.$options.map3d.scene.preRender._listeners.length) {
                this.$options.map3d.scene.preRender._listeners.forEach(item => {
                    if (item && item.name == 'alarmPop') {
                        this.$options.map3d.scene.preRender.removeEventListener(item);
                    }
                });
            }
            let alarmPop = () => {
                _this.$options.popslist.forEach(item => {
                    let mapContainer = document.getElementById(parentId);
                    let container = document.getElementById(item.id);
                    const x = item.x;
                    const y = item.y;
                    if (container) {
                        let windowPosition = new Cesium.Cartesian2();
                        let canvasWidth, canvasHeight;
                        if (mapContainer) {
                            canvasWidth = mapContainer.clientWidth;
                            canvasHeight = mapContainer.clientHeight;
                        } else {
                            canvasWidth = _this.$options.map3d.scene.canvas.width;
                            canvasHeight = _this.$options.map3d.scene.canvas.height;
                        }
                        Cesium.SceneTransforms.wgs84ToWindowCoordinates(
                            _this.$options.map3d.scene,
                            Cesium.Cartesian3.fromDegrees(
                                item.positions[0],
                                item.positions[1],
                                item.positions[2] + height
                            ),
                            windowPosition
                        );
                        container.style.right = canvasWidth - windowPosition.x - x - container.offsetWidth * 0.5 + "px";
                        container.style.bottom = canvasHeight - windowPosition.y + y + "px";
                    }
                });
            };
            this.$options.map3d.scene.preRender.addEventListener(alarmPop);
        },
        // 移除所有报警信息弹窗
        removeAllAlarmpops() {
            if (this.$options.popslist.length) {
                this.$options.popslist = [];
            }
            if (this.$options.popIds.length) {
                if (this.$options.map3d.scene.preRender._listeners.length) {
                    this.$options.map3d.scene.preRender._listeners.forEach(item => {
                        if (item && item.name == 'alarmPop') {
                            this.$options.map3d.scene.preRender.removeEventListener(item);
                        }
                    });
                }
                this.$options.popIds = [];
            }
        }
    },
    beforeDestroy() {
        this.$EventBus.$off('AI-Notification-Location');
        this.$options.flyRouteHook && this.$options.flyRouteHook.clearFlyRoute();
        this.$options.flyRouteHook = null;
        this.$options.hangarHook && this.$options.hangarHook.clearHangar();
        this.$options.hangarHook = null;
        this.closedAlarmInfoPop();
        if (this.mapMoveEndTimer) {
            clearTimeout(this.mapMoveEndTimer);
            this.mapMoveEndTimer = null;
        }
        this.removeMapMoveEvent();
        if (this.emergencyMakerTimer) {
            clearInterval(this.emergencyMakerTimer);
            this.emergencyMakerTimer = null;
        }
        if (this.mapMoveEndRequestTimer) {
            clearInterval(this.mapMoveEndRequestTimer);
            this.mapMoveEndRequestTimer = null;
        }
        this.$options.map3d && this.$options.map3d.destroy();
    }
}
</script>

<style lang="scss" scoped>
.screen_map_box {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    #cesiumContainer_map_center {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
    }
    .right-top-tools {
        position: absolute;
        top: 100px;
        right: 10px;
        z-index: 2;
        &>div {
            cursor: pointer;
        }
        .full-image-zh {
            width: 36px;
            height: 40px;
        }
        .full-image {
            width: 48px;
            height: 46px;
        }
    }
    .hover-tip {
        position: absolute;
        width: 200px;
        background-color: rgba(0, 0, 0, 0.8);
        color: #FFFFFF;
        padding: 8px;
        border-radius: 4px;
        &-triangle {
            position: absolute;
            box-sizing: border-box;
            border: 10px solid;
            border-color: transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8) transparent;
            left: 50%;
            top: 100%;
            transform: translateX(-50%)  translateY(-51%) rotateZ(45deg);
        }
        &-item {
            word-wrap: break-word
        }
    }
}
</style>