<template>
    <!-- 巡检时长统计 -->
    <div class="durationPage" ref="durationPage">
        <div class="swtch-list">
            <el-radio-group v-model="categoryType" size="mini" @input="handlerData">
                <el-radio :label="'dailyDurationStatistics'">{{$t('oilGasScreen.today')}}</el-radio>
                <el-radio :label="'weeklyDurationStatistics'">{{$t('oilGasScreen.theWeek')}}</el-radio>
                <el-radio :label="'monthlyDurationStatistics'">{{$t('oilGasScreen.theMonth')}}</el-radio>
            </el-radio-group>
        </div>
        <div class="chart-cont">
            <div class="chart-bg" :style="{'width': `${bgPosNum}px`, 'height': `${bgPosNum}px`}"></div>
            <chartPie 
                v-if="showChart"
                :id="'duration-chart'"
                :isRoseChart="true"
                :chartRadius="['23%', '43%']"
                :chartCenter="['50%', '50%']"
                :tooltip="tooltip"
                :graphicOption="{x: fontPosNum.x, y: fontPosNum.y, text: chartTotal}"
                :labelFormatter="'{c} \n {b}'"
                :labelLine="{length: 30, length2: 50}"
                :labelPadding="[0, -45]"
                :labelLayout="{verticalAlign: 'middle', dy: 2}"
                :chartData="chartData"
            />
        </div>
    </div>
</template>

<script>
import chartPie from '@/components/echarts/chartPie.vue';

export default {
    name: 'duration-page',
    components: {chartPie},
    props: ['durationData'],
    data() {
        return {
            showChart: false,
            categoryType: 'monthlyDurationStatistics',
            fontPosNum: {x: 0, y: 0},
            bgPosNum: 120,
            tooltip: {
                trigger: 'item',
                valueFormatter: (value) => {
                    return `${value}h`;
                }
            },
            chartTotal: '0',
            chartData: []
        }
    },
    watch: {
        'durationData': {
            deep: true,
            handler: function() {
                this.handlerData();
            }
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.showChart = false;
            this.$nextTick(() => {
                const domWidth = this.$refs.durationPage.offsetWidth;
                const domHeight = this.$refs.durationPage.offsetHeight;
                this.fontPosNum = {
                    x: (domWidth * 0.5),
                    y: (domHeight * 0.5) - 11
                };
                this.bgPosNum = domHeight * 0.5;
                this.showChart = true;
            });
        },
        handlerData() {
            let itemData = this.durationData.find(t => t.name === this.categoryType);
            let chartData = [];
            itemData.data && Object.keys(itemData.data).map(t => {
                chartData.push({name: t, value: itemData.data[t] || 0});
            });
            this.chartTotal = itemData.total ? `${itemData.total}` : '0';
            this.chartData = chartData;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style lang="scss" scoped>
.durationPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .swtch-list {
        position: absolute;
        top: 3px;
        right: 10px;
        z-index: 5;
        ::v-deep .el-radio-group {
            .el-radio {
                margin-right: 8px;
                .el-radio__inner {
                    width: 12px;
                    height: 12px;
                }
                .el-radio__label {
                    font-size: 12px;
                    color: #fff;
                    padding-left: 5px;
                }
                .el-radio__input.is-checked+.el-radio__label {
                    color: #409EFF;
                }
            }
        }
    }
    .chart-cont {
        width: 100%;
        height: 100%;
        position: relative;
        .chart-bg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("~@/assets/images/RosePie-bg.png");
        }
    }
}
</style>