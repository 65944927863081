var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-box", class: _vm.bigBGIMG ? "base-box-big" : "" },
    [
      _c(
        "div",
        {
          staticClass: "title",
          on: {
            click: function ($event) {
              return _vm.$emit("clickTitle")
            },
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.title))])]
      ),
      _c("div", { staticClass: "content" }, [_vm._t("content")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }