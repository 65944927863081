<template>
  <!-- 报警记录 -->
  <div class="AlarmRecord">
    <div class="Alarm-map">
      <oliMap :isShowTopTools="false" :isTracked="false" :mapType="'alarmMap'" :alarmData="alarmData" />
    </div>
    <div class="top-panle">
      <div class="select-item">
        <el-select v-model="chenckForm.project" size="mini" @change="changeSelectData" 
          :placeholder="$t('oilGasScreen.pleaseSelectInspectionItem')">
          <el-option v-for="item in projectList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="select-item">
        <el-select v-model="chenckForm.alarmType" size="mini" @change="changeSelectData" 
          :placeholder="$t('oilGasScreen.pleaseSelectAlarmType')">
          <el-option v-for="item in alarmTypeList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="time-item">
        <el-date-picker v-model="chenckForm.time" size="mini" type="datetimerange" :range-separator="$t('to')" 
          popper-class="time-item-poper" :start-placeholder="$t('startTime')" :end-placeholder="$t('endTime')" 
          :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" 
          :clearable="false" @change="changeSelectData" />
      </div>
    </div>
    <div class="left-panle panle-main">
      <div class="panle-content">
        <div class="panle-item">
          <BaseBox :title="$t('oilGasScreen.factoryAlarm')">
            <template v-slot:content>
              <factoryAlarm :factoryAlarmData="factoryAlarmData" />
            </template>
          </BaseBox>
        </div>
        <div class="panle-item">
          <BaseBox :title="$t('oilGasScreen.objectAlarm')">
            <template v-slot:content>
              <inspectionAlarm :inspectionAlarmData="inspectionAlarmData" />
            </template>
          </BaseBox>
        </div>
        <div class="panle-item">
          <BaseBox :title="$t('oilGasScreen.alarmType')">
            <template v-slot:content>
              <alarmType :alarmTypeData="alarmTypeData" />
            </template>
          </BaseBox>
        </div>
      </div>
    </div>
    <div class="right-panle panle-main">
      <div class="panle-content">
        <div class="panle-item">
          <BaseBox :title="$t('oilGasScreen.alarmTrend')">
            <template v-slot:content>
              <alarmTrend :alarmTrendData="alarmTrendData" />
            </template>
          </BaseBox>
        </div>
        <div class="panle-item">
          <BaseBox :title="$t('oilGasScreen.realTimeAlarm')">
            <template v-slot:content>
              <realTimeAlarm :realTimeAlarmData="realTimeAlarmData" />
            </template>
          </BaseBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import oliMap from '../map/index.vue';
import BaseBox from '../BaseBox/index.vue';
import factoryAlarm from './components/factoryAlarm.vue';
import inspectionAlarm from './components/inspectionAlarm.vue';
import alarmType from './components/alarmType.vue';
import alarmTrend from './components/alarmTrend.vue';
import realTimeAlarm from './components/realTimeAlarm.vue';
import { getProjectList } from '@/api/oil-gas';
import { getDictionary } from "@/api/system/dictbiz";
import { getAlarmRecordList, getAlarmRecordStatistics } from '@/api/oilGasScreen/index';

export default {
  name: 'AlarmRecord',
  components: {
    oliMap,
    BaseBox,
    factoryAlarm,
    inspectionAlarm,
    alarmType,
    alarmTrend,
    realTimeAlarm
  },
  data() {
    return {
      alarmTimer: null,
      projectList: [],
      alarmTypeList: [],
      chenckForm: {
        project: '',
        alarmType: '',
        time: ''
      },
      alarmData: [],
      // 厂处报警统计
      factoryAlarmData: [
        {name: '', data: {}, total: 0}
      ],
      // 巡检对象报警统计
      inspectionAlarmData: {},
      // 报警类型统计
      alarmTypeData: {},
      // 报警趋势图
      alarmTrendData: [
        {name: '', data: {}},
        {name: '', data: {}},
        {name: '', data: {}}
      ],
      // 实时报警
      realTimeAlarmData: []
    }
  },
  created() {
    this.getAlarmTime();
    this.getProjectList();
  },
  mounted() {
    setTimeout(() => {
      this.$emit('loadEnd');
    }, 1000);
    if (this.alarmTimer) {
      clearInterval(this.alarmTimer);
      this.alarmTimer = null;
    }
    this.alarmTimer = setInterval(() => {
      this.getProjectList();
    }, 10000);
  },
  methods: {
    changeSelectData() {
      this.getAlarmListData();
    },
    getAlarmTime() {
      // 当前日期
      let currentDate  = new Date();
      let year = currentDate.getFullYear(); // 获取年份
      let month = currentDate.getMonth() + 1; // 获取月份，+1因为getMonth()返回的月份从0开始
      let day = currentDate.getDate(); // 获取日
      let endDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      // 30天前的日期
      let currentTimer = new Date().getTime(endDate);
      let threeDaysAgo = new Date(currentTimer - 1000 * 60 * 60 * 24 * 30);
      let lastY = threeDaysAgo.getFullYear();
      let lastM = threeDaysAgo.getMonth() + 1;
      let lastD = threeDaysAgo.getDate();
      let startData = `${lastY}-${lastM.toString().padStart(2, '0')}-${lastD.toString().padStart(2, '0')}`;
      startData = `${startData} 00:00:00`;
      endDate = `${endDate} 23:59:59`;
      this.chenckForm.time = [startData, endDate];
    },
    getProjectList() {
      getProjectList({current: 1, size: 9999}).then(res => {
        if (res.data.code === 200) {
          let projectListItem = {value: '', label: this.$t('allProjects')};
          this.projectList = res.data.data.records.map(item => {
            return {value: item.id, label: item.projectName};
          });
          this.projectList = [projectListItem, ...this.projectList];
          if (this.projectList.length && !this.chenckForm.project) {
            this.chenckForm.project = this.projectList[0].value;
          }
        }
      }).finally(() => {
        this.getAlarmTypeList();
      });
    },
    getAlarmTypeList() {
      getDictionary({code: 'OilPatrolInspection'}).then(res=>{
        if (res.data.code === 200) {
          let alarmTypeItem = {value: '', label: this.$t('allType')};
          this.alarmTypeList = res.data.data.map(item => {
            return {value: item.dictKey, label: item.dictValue};
          });
          this.alarmTypeList = [alarmTypeItem, ...this.alarmTypeList];
          if (this.alarmTypeList.length && !this.chenckForm.alarmType) {
            this.chenckForm.alarmType = this.alarmTypeList[0].value;
          }
        }
      }).finally(() => {
        this.getAlarmListData();
      });
    },
    getAlarmListData() {
      // if (!this.chenckForm.project) {
      //   return this.$message.error(this.$t('oilGasScreen.noProject'));
      // }
      // if (!this.chenckForm.alarmType) {
      //   return this.$message.error(this.$t('oilGasScreen.noAlarmType'));
      // }
      if (!this.chenckForm.time || !this.chenckForm.time.length) {
        return this.$message.error(this.$t('oilGasScreen.noTime'));
      }
      let params = {
        isInspectProject: 0,
        inpectionProjectId: this.chenckForm.project,
        alarmType: this.chenckForm.alarmType,
        startTime: this.chenckForm.time[0],
        endTime: this.chenckForm.time[1]
      };
      let ids = '';
      getAlarmRecordList(params).then(res => {
        if (res.data.code === 200) {
          const data = res.data.data ? (res.data.data.records || []) : [];
          this.alarmData = data;
          const dataLength = data.length;
          data.map((t, index) => {
            ids = index + 1 === dataLength ? `${ids}${t.id}` : `${ids}${t.id},`;
          });
          this.getAlarmStatistics(ids);
        } else {
          this.$message.error(this.$t('requestErrorText'));
        }
      }).catch(err => {
        this.$message.error(err);
      });
    },
    getAlarmStatistics(ids) {
      getAlarmRecordStatistics({ids: ids}).then(res => {
        if (res.data.code == 200) {
          this.handleData(res.data.data || {});
        } else {
          this.$message.error(this.$t('requestErrorText'));
        }
      }).catch(err => {
        this.$message.error(err);
      });
    },
    handleData(data) {
      // 厂处报警统计
      this.factoryAlarmData = [
        {name: 'projectAlarmStatistics', data: data.projectAlarmStatistics || {}, total: data.projectAlarmTotalCount || 0}
      ];
      // 巡检对象报警统计
      this.inspectionAlarmData = data.objectAlarmStatistics || {};
      // 报警类型统计
      this.alarmTypeData = data.alarmTypeStatistics || {};
      // 报警趋势图
      this.alarmTrendData = [
        {name: 'dailyAlarmTrend', data: data.dailyAlarmTrend || {}},
        {name: 'weeklyAlarmTrend', data: data.weeklyAlarmTrend || {}},
        {name: 'monthlyAlarmTrend', data: data.monthlyAlarmTrend || {}}
      ];
      // 实时报警
      this.realTimeAlarmData = data.alarmList || [];
    }
  },
  beforeDestroy() {
    if (this.alarmTimer) {
      clearInterval(this.alarmTimer);
      this.alarmTimer = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.AlarmRecord {
  width: 100%;
  height: 100%;
  position: relative;
  .Alarm-map {
    width: 100%;
    height: 100%;
    background: #fff;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      box-shadow: 
        inset 400px 0 400px #000E2E, /* 左上角 */
        inset -400px 0 400px #000E2E, /* 右上角 */
        inset 0 0 100px #000E2E, /* 左下角 */
        inset 0 0 100px #000E2E;  /* 右下角 */
    }
  }
  .top-panle {
    display: flex;
    width: calc(100% - 950px);
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 0;
    border-radius: 4px;
    background: rgba(0, 14, 46, 0.5);
    .select-item {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      ::v-deep .el-select {
        width: 100%;
        .el-input__inner {
          background-color: transparent;
          border: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-input__icon {
          color: #fff;
        }
      }
    }
    .time-item {
      display: flex;
      flex: 2;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      ::v-deep .el-input__inner {
        width: 100%;
        background-color: transparent;
        border: none;
        color: #fff;
        .el-input__icon, .el-range-separator {
          color: #fff;
        }
        .el-range-input {
          color: #fff;
          background-color: transparent;
          &::placeholder {
            color: #fff;
          }
        }
      }
    }
  }
  .panle-main {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 450px;
    display: flex;
    flex-direction: column;
  }
  .left-panle {
    left: 0;
    padding-left: 10px;
    .panle-content {
      align-items: flex-start;
    }
  }
  .right-panle {
    right: 0;
    padding-right: 10px;
    .panle-content {
      align-items: flex-end;
    }
  }
  .panle-content {
    height: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .panle-item {
      width: 100%;
      min-height: 220px;
      flex: 1;
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>

<style lang="scss">
.time-item-poper {
  .el-picker-panel__footer .el-button--text {
    display: none;
  }
}
</style>