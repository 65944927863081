var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c("div", { staticClass: "shadow" }, [
          _c("div", { staticClass: "modal" }, [
            _vm.title
              ? _c("span", { staticClass: "modal-title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ])
              : _vm._e(),
            _c("div", { staticClass: "close" }, [
              _c("img", {
                attrs: { src: require("@/assets/map/close.png") },
                on: { click: _vm.close },
              }),
            ]),
            _c("div", { staticClass: "content" }, [_vm._t("content")], 2),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }