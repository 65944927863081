var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cesium-div" },
    [
      _c("div", { attrs: { id: "cesiumContainer_new_map_center" } }),
      _vm.hasBtn
        ? _c(
            "div",
            {
              staticClass: "full-screen",
              attrs: { title: "全屏" },
              on: { click: _vm.tiggerFullScreen },
            },
            [
              _c("img", {
                staticClass: "full-image",
                attrs: { src: require("../../../assets/map/full.png") },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "track-screen",
          attrs: { title: _vm.tracked ? "解除跟随" : "跟随" },
          on: { click: _vm.track },
        },
        [
          _c("img", {
            staticClass: "full-image",
            attrs: { src: _vm.tracked ? _vm.track_activeIcon : _vm.trackIcon },
          }),
        ]
      ),
      !_vm.moveAble
        ? _c("div", { staticClass: "info" }, [
            _vm._v("数据接收中，地图可缩放但不允许拖动"),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "route-line",
          on: {
            click: function ($event) {
              return _vm.updateRoute(_vm.$options.map3d, _vm.task.id)
            },
          },
        },
        [
          _c("i", { staticClass: "el-icon-refresh-left" }),
          _c("span", { staticStyle: { "margin-left": "2px" } }, [
            _vm._v("刷新"),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "route-task-line" },
        [
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.showRouteLine,
                callback: function ($$v) {
                  _vm.showRouteLine = $$v
                },
                expression: "showRouteLine",
              },
            },
            [_c("span", { staticClass: "Violet-line" }), _vm._v("任务航线")]
          ),
        ],
        1
      ),
      _vm.hoverTip.show
        ? _c(
            "div",
            {
              staticClass: "hover-tip",
              style:
                "\n      left: " +
                _vm.hoverTip.left +
                "px;\n      top: " +
                _vm.hoverTip.top +
                "px;\n      transform: translateY(calc(-100% - " +
                (_vm.hoverTip.type === _vm.NodeMap.Node.POINT ? 40 : 18) +
                "px)) translateX(-50%);\n    ",
            },
            [
              _c("div", { staticClass: "hover-tip-triangle" }),
              _vm._l(_vm.hoverTip.list, function (item) {
                return _c(
                  "div",
                  { key: item.label, staticClass: "hover-tip-item" },
                  [
                    _c("span", [_vm._v(_vm._s(item.label) + ": ")]),
                    _c("span", [_vm._v(_vm._s(item.value))]),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c("BaseStationPop", {
        ref: "BaseStationPop",
        attrs: { detailData: _vm.baseStationDetail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }