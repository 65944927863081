<template>
  <div id="bar-chart"></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      option: {},
      initStyle: {
        width: 0,
        height: 0,
      },
      // barData: this.$store.state,
    };
  },
  computed: {
    ...mapGetters([
      'wsData',
      'socketData',
      'newSocketData',
      'task'
    ]),
  },
  watch: {
    wsData: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        if (!val) {
          return;
        }
        this.update();
      },
      deep: true, //true 深度监听
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      let barData = this.wsData.todayStaticsAlarmDataVOList
      let xAxis = [];
      let series = [];
      barData.map((item) => {
        xAxis.push(item.alarmTypeName);
        series.push(item.count);
      });
      if (!this.$options.chartDom) {
        this.initChart({
          xAxis,
          series,
        });
      } else {
        this.setOption({
          xAxis,
          series,
        })
        this.$options.chartDom.setOption(this.option, true);
      }
    },
    initChart(val) {
      let chartDom = document.getElementById("bar-chart");
      this.$options.chartDom = this.$echarts.init(chartDom);
      this.setOption(val)
      this.$options.chartDom.setOption(this.option);
    },
    setOption(val) {
      this.option = {
        color: ["#FF344C"],
        xAxis: {
          type: "category",
          data: val.xAxis || [],
          axisLabel: {
            interval: 0,
            textStyle: {
              color: "#ffffff", //这里用参数代替了
            },
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "15%",
          top: "20%",
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          show: false,
        },
        series: [
          {
            data: val.series || [],
            type: "bar",
            barMinHeight: 2,
            barWidth: 30,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: { color: "#ffffff", fontSize: 18 },
                },
              },
            },
          },
        ],
      };
    }
  }
};
</script>

<style lang="scss" scoped>
#bar-chart {
  width: 100%;
  height: 90%;
  bottom: 0;
  position: absolute;
}
</style>
