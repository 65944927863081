var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "screen-box-oil" },
    [
      _vm.loading ? _c("loading-view") : _vm._e(),
      _c(
        "div",
        { staticClass: "screen-main-oil" },
        [
          _c("navigation-bar"),
          _c(
            "div",
            { staticClass: "screen-oil-content" },
            [_c("router-view", { on: { loadEnd: _vm.loadEnd } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }