<template>
  <!-- 实时任务 -->
  <div class="RealTime">
    <div class="RealTime-maporVideo">
      <left ref="leftDom" :taskData="selectTaskData" @clearnSelectTask="clearnSelectTask" />
    </div>
    <div class="plan-mian" v-show="!selectTaskData">
      <div class="plan-cont">
          <right ref="rightDom" @changeSelectTask="changeSelectTask" @clearnSelectTask="clearnSelectTask" />
      </div>
    </div>
  </div>
</template>

<script>
import left from './components/left/index.vue';
import right from './components/right/index.vue'
import { mapGetters, mapActions } from "vuex";
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';
import { getRealTimeTask } from '@/api/oilGasScreen/index';

export default {
  name: 'RealTime',
  components: {left, right},
  data() {
    return {
      realTimeTaskTimer: null,
      // 在线设备数据
      onlineUavData: {
        onlineUavList: []
      },
      selectTaskData: '',
      uavList: [],
      socketList: []
    }
  },
  computed: {
    ...mapGetters([
      'selectTask'
    ]),
  },
  watch: {
    'onlineUavData': {
      deep: true,
      handler: function(data) {
        let itemUav = data.onlineUavList.find(t => t.sn == this.selectTask.sn);
        if (!itemUav) {
          let checkUavItem = data.onlineUavList.length ? data.onlineUavList[0] : {};
          this.setSelectTask(checkUavItem);
          this.clearnSelectTask();
          this.$nextTick(() => {
            this.$refs.leftDom.$refs.map.isSetFlight = false;
          });
        }
        this.setCurrentTaskList(data.onlineUavList);
        let {addData, removeData} = this.compareDifferences(this.uavList, data.onlineUavList || []);
        this.uavList = data.onlineUavList || [];
        this.closedSocket(removeData, addData);
        this.openSocket(addData);
      }
    }
  },
  created() {
    this.$options.flyRouteHook = liveFlyRouteHook();
  },
  mounted() {
    setTimeout(() => {
      this.$emit('loadEnd');
    }, 1000);
    this.getRealTimeTaskData();
    if (this.realTimeTaskTimer) {
      clearInterval(this.realTimeTaskTimer);
      this.realTimeTaskTimer = null;
    }
    this.realTimeTaskTimer = setInterval(() => {
      this.getRealTimeTaskData();
    }, 10000);
  },
  methods: {
    ...mapActions([
      // 设置当前执行的任务
      'addCurrentTaskList',
      // 清空当前执行的任务map
      'clearCurrentTaskList',
      // 当前执行的任务mapde
      'setCurrentTaskDataByKey',
      // 设置当前正在执行的任务
      'setSelectTask',
      // 设置正在执行的任务列表
      'setCurrentTaskList'
    ]),
    getRealTimeTaskData() {
      getRealTimeTask().then(res => {
        if (res.data.code == 200) {
          this.handleData(res.data.data || {});
        } else {
          this.$message.error(this.$t('requestErrorText'));
        }
      }).catch(err => {
        this.$message.error(err);
      });
    },
    handleData(data) {
      // 在线设备数据
      let onlineUavList = data.onlineUavList || [];
      if (onlineUavList && onlineUavList.length) {
        onlineUavList.map(t => {
          t.liveVideos = (!t.liveVideos || !t.liveVideos.length) ? null : t.liveVideos
        });
      }
      this.onlineUavData = {
        onlineUavList: onlineUavList || []
      };
    },
    // 开启websocket链接
    openSocket(addData) {
      let _this = this;
      if (addData.length) {
        addData.map(t => {
          let wsItem = this.socketList.find(i => i.sn === t.sn);
          if (!wsItem) {
            let guid = Cesium.createGuid().slice(0, 6).replace('-', 'A');
            let wsUrl = `${window.global.VUE_APP_WS_ROUTE_NEW }/${t.sn}-${guid}`;
            let wsCallBack = function({data}) {
              // 新方案的数据存储
              _this.setCurrentTaskDataByKey({
                key: t.sn,
                airName: t.uavName,
                value: data
              });
            }
            let ws = this.$options.flyRouteHook.initWebSocket({url: wsUrl, callback: wsCallBack});
            this.addCurrentTaskList({
              key: t.sn,
              value: {
                ws,
                data: {},
                icon: t.modelIcon,
                selectIcon: t.modelSelectIcon
              }
            });
            this.socketList.push({taskName: t.taskName, sn: t.sn, ws: ws});
          }
        });
      }
    },
    // 关闭websocket链接并清除已绘制的飞机
    closedSocket(removeData, addData) {
      if (removeData.length) {
        let removeUavEntityData = [];
        removeData.map(t => {
          let addItem = addData.find(i => i.sn === t.sn);
          if (!addItem) {
            let wsItem = this.socketList.find(i => i.sn === t.sn);
            if (wsItem) {
              this.$options.flyRouteHook.closeWsLink(wsItem);
              let index = this.socketList.indexOf(wsItem);
              index !== -1 && this.socketList.splice(index, 1);
              removeUavEntityData.push(t);
            }
          }
        });
        this.$EventBus.$emit('clearn-uav-data', removeUavEntityData);
      }
    },
    // 比较两次飞机数据看是否存在差异
    compareDifferences(originalArray, currentArray) {
      const addData = currentArray.filter(
        newItem => originalArray.findIndex(oldItem => (oldItem.sn === newItem.sn 
          && JSON.stringify(oldItem.liveVideos) === JSON.stringify(newItem.liveVideos))) === -1
      );
      const removeData = originalArray.filter(
        oldItem => currentArray.findIndex(newItem => (newItem.sn === oldItem.sn 
          && JSON.stringify(newItem.liveVideos) === JSON.stringify(oldItem.liveVideos))) === -1
      );
      return {addData, removeData};
    },
    changeSelectTask(data) {
      this.selectTaskData = data;
    },
    clearnSelectTask() {
      this.selectTaskData = '';
    }
  },
  beforeDestroy() {
    if (this.realTimeTaskTimer) {
      clearInterval(this.realTimeTaskTimer);
      this.realTimeTaskTimer = null;
    }
    this.clearCurrentTaskList();
    this.$options.flyRouteHook.closeWsLink();
    this.$options.flyRouteHook = null;
  }
}
</script>

<style lang="scss" scoped>
.RealTime {
  width: 100%;
  height: 100%;
  display: flex;
  .RealTime-maporVideo {
    width: 0;
    flex: 1;
    position: relative;
  }
  .plan-mian {
    width: 550px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    .plan-cont {
      height: 0;
      flex: 1;
      margin-top: 50px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }
  }
}
</style>