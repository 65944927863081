<template>
    <!-- 无人机台账 -->
    <div class="droneLedger-page">
        <!-- <i class="el-icon-refresh"></i> -->
        <i class="el-icon-close" @click="closeLedger"></i>
        <div class="panle-table">
            <el-table :data="uavTableData" style="width: 100%" height="100%">
                <el-table-column prop="manufacturerName" align="center" 
                    show-overflow-tooltip :label="$t('oilGasScreen.brand')" />
                <el-table-column prop="equipmentName" align="center" 
                    show-overflow-tooltip :label="$t('oilGasScreen.droneName')" />
                <el-table-column prop="equipmentType" align="center" 
                    show-overflow-tooltip :label="$t('oilGasScreen.droneType')" />
                <el-table-column prop="equipmentNumber" align="center" 
                    show-overflow-tooltip :label="$t('oilGasScreen.droneModel')" />
                <el-table-column prop="airportName" align="center" 
                    show-overflow-tooltip :label="$t('oilGasScreen.belongingAirport')" />
                <el-table-column prop="equipmentStatus" align="center" 
                    show-overflow-tooltip :label="$t('oilGasScreen.state')" />
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'droneLedger-page',
    props: ['ledgerData'],
    data() {
        return {
            uavTableData: []
        }
    },
    watch: {
        'ledgerData': {
            deep: true,
            handler: function() {
                this.uavTableData = JSON.parse(JSON.stringify(this.ledgerData));
            }
        }
    },
    methods: {
        closeLedger() {
            this.$parent.$parent.showLedger(false);
        }
    }
}
</script>

<style lang="scss" scoped>
.droneLedger-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .el-icon-refresh {
        position: absolute;
        top: 2px;
        right: 28px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-size: 18px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .el-icon-close {
        position: absolute;
        top: 2px;
        right: 5px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-size: 18px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .panle-table {
        width: 100%;
        height: 100%;
        overflow: auto;
        ::v-deep .el-table {
            &::before {
                background-color: transparent;
            }
            background-color: transparent;
            .el-table__header-wrapper {
                tr, th {
                    background-color: #011A38;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 13px;
                    color: #FFFFFF;
                    padding: 0;
                    height: 38px;
                    border: none;
                }
                th.is-leaf {
                    border: none;
                }
            }
            .el-table__body-wrapper {
                tr, td {
                    background-color: transparent;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 13px;
                    color: #FFFFFF;
                    padding: 0;
                    height: 38px;
                    border: none;
                    .el-icon-delete {
                        cursor: pointer;
                    }
                    .table-select {
                        .el-input__inner {
                            background-color: transparent;
                            border: 1px solid rgba(161, 168, 187, 0.3);
                            font-weight: 700;
                            font-style: normal;
                            font-size: 13px;
                            color: #FFFFFF;
                            &::placeholder {
                                font-weight: 700;
                                font-style: normal;
                                font-size: 13px;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
                td.is-leaf {
                    border: none;
                }
            }
        }
    }
}
</style>