var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen-content" }, [
    _c(
      "div",
      { staticClass: "left", class: { "screen-left": _vm.selectTaskData } },
      [
        _c("Left", {
          attrs: { projectInfo: _vm.projectInfo, taskData: _vm.selectTaskData },
          on: { clearnSelectTask: _vm.clearnSelectTask },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("Right", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.selectTaskData,
              expression: "!selectTaskData",
            },
          ],
          ref: "rightDom",
          on: { changeSelectTask: _vm.changeSelectTask },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }