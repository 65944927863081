var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "loginForm",
      staticClass: "login-form",
      attrs: {
        rules: _vm.loginRules,
        model: _vm.loginForm,
        "label-width": "0",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "username" } },
        [
          _c(
            "el-input",
            {
              staticClass: "login-input",
              attrs: {
                size: "small",
                "auto-complete": "off",
                placeholder: _vm.$t("login.username"),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleLogin($event)
                },
              },
              model: {
                value: _vm.loginForm.username,
                callback: function ($$v) {
                  _vm.$set(_vm.loginForm, "username", $$v)
                },
                expression: "loginForm.username",
              },
            },
            [
              _c("span", {
                staticClass: "icon-user",
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "password", attrs: { prop: "password" } },
        [
          _c(
            "el-input",
            {
              staticClass: "login-input",
              attrs: {
                size: "small",
                type: _vm.passwordType,
                "auto-complete": "off",
                placeholder: _vm.$t("login.password"),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleLogin($event)
                },
              },
              model: {
                value: _vm.loginForm.password,
                callback: function ($$v) {
                  _vm.$set(_vm.loginForm, "password", $$v)
                },
                expression: "loginForm.password",
              },
            },
            [
              _c("span", {
                staticClass: "icon-password",
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
              _c("i", {
                staticClass: "el-icon-view el-input__icon",
                attrs: { slot: "suffix" },
                on: { click: _vm.showPassword },
                slot: "suffix",
              }),
            ]
          ),
        ],
        1
      ),
      this.website.captchaMode
        ? _c(
            "el-form-item",
            { staticClass: "code", attrs: { prop: "code" } },
            [
              _c(
                "el-row",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "login-input",
                          attrs: {
                            size: "small",
                            "auto-complete": "off",
                            placeholder: _vm.$t("login.code"),
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleLogin($event)
                            },
                          },
                          model: {
                            value: _vm.loginForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "code", $$v)
                            },
                            expression: "loginForm.code",
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "icon-code",
                            attrs: { slot: "prefix" },
                            slot: "prefix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 2 } }),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "login-code" }, [
                      _c("img", {
                        staticClass: "login-code-img",
                        attrs: { src: _vm.loginForm.image },
                        on: { click: _vm.refreshCode },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "account" }, [
        _c("span", { staticClass: "account-register" }),
        _c(
          "span",
          {
            staticClass: "account-forget",
            on: {
              click: function ($event) {
                return _vm.goFlow("forget")
              },
            },
          },
          [_vm._v("忘记密码?")]
        ),
      ]),
      _c(
        "el-form-item",
        { staticClass: "login-btn" },
        [
          _c(
            "el-button",
            {
              staticClass: "login-submit",
              attrs: { type: "primary", size: "small" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleLogin($event)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("login.submit")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }