<template>
	<div class="Box">
		<div class="contentWrap">
			<div class="flex">
				<div class="contentItemTop" v-for="(i, idx) in [
					{ name: '在线设备', num: store.onlineNum || '-' },
					{ name: '设备总数', num: store.totalNum || '-' },
				]" :key="idx">
					<div class="num">
						{{ i['num'] || '-' }}
					</div>
					<div class="name">
						{{ i['name'] }}
					</div>
				</div>
			</div>
			<div class="flex">
				<div class="contentItemBottom" v-for="(i, idx) in [
					{ name: '无人机', num: store.uavNum },
					{ name: '载荷', num: store.loadNum },
					{ name: '数据链', num: store.dataLinkNum },
				]" :key="idx">
					<div class="num">
						{{ i['num'] || '-' }}
					</div>
					<div class="name">
						{{ i['name'] }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	components: {},
	computed: {
		store() {
			return this.$store.state.wsData.equipmentStatistics || {}
		},
	},
}
</script>
<style scoped lang="scss">
.Box {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	.contentWrap {
		width: 100%;
		height: calc(100% - 20px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 14px;

		.flex {
			display: flex;

			&:nth-of-type(1) {
				.contentItemTop:nth-of-type(1) {
					margin-right: 40px;
				}
			}

			&:nth-of-type(2) {
				margin-top: 14px;

				.contentItemBottom:nth-of-type(2) {
					margin: 0 60px;
				}
			}
		}

		.contentItemTop {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 16px;

			&>div {
				flex: 1;
				align-items: center;
				justify-content: flex-end;
				display: flex;
				flex-direction: column;
				margin-bottom: 6px;
			}

			height: 119px;
			width: 119px;
			background-image: url('~@/assets/images/equipment.png');
			background-repeat: no-repeat;
			background-size: contain;

			.num {
				font-size: 36px;
				line-height: 6px;
				color: #87c4ff;
				font-weight: bold;
			}

			.name {
				font-size: 12px;
				color: #fff;
				line-height: 50px;
			}
		}

		.contentItemBottom {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			height: 80px;
			width: 100px;

			.num {
				width: 95px;
				height: 46px;
				background: #084684;
				border-radius: 100px 100px 0px 0px;
				font-size: 26px;
				color: #87c4ff;
				font-weight: bold;
				line-height: 50px;
				text-align: center;
			}

			.name {
				font-size: 12px;
				color: #fff;
				margin-top: 6px;
			}
		}
	}
}
</style>
