<template>
    <div class="right-cont">
        <div class="screen-right-box" v-loading="loading" :element-loading-text="$t('loading')" 
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 1)">
            <!-- 单任务 -->
            <div class="single-task" v-if="taskList.length <= 1">
                <div class="task-video">
                    <BaseBox :title="$t('screen.currentFootage')">
                        <template v-slot:content>
                            <div class="video-cont active">
                                <div class="video-title">
                                    <img src="@/assets/images/task-select-fkbg-ac.png" alt="">
                                    <span>{{firstTask.taskName || ''}}</span>
                                </div>
                                <div class="suspended" v-if="liveVideoVOList(firstTask).length">
                                    <div class="suspended-cont">
                                        <div class="suspended-item" @click="openVideoScreen(firstTask)">
                                            {{$t('navbar.screenfull')}}</div>
                                    </div>
                                </div>
                                <div class="video-zone">
                                    <component v-if="liveVideoVOList(firstTask).length" :is="useLive(firstTask)" 
                                        ref="videoLive" class="video-plane" :screen="false" 
                                        :liveVideoVOList="liveVideoVOList(firstTask)" />
                                    <div v-else class="video-loading" v-loading="true" 
                                        :element-loading-text="$t('WaitingForVideoTransmission')" 
                                        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </BaseBox>
                </div>
                <div class="task-msg">
                    <BaseBox :title="$t('screen.taskInfo')">
                        <template v-slot:content>
                            <div class="plane-cont">
                                <Plant ref="plane" />
                            </div>
                        </template>
                    </BaseBox>
                </div>
            </div>
            <!-- 双任务 -->
            <div class="dual-task" v-if="taskList.length == 2">
                <BaseBox :title="$t('screen.currentFootage')">
                    <template v-slot:content>
                        <div class="video-list">
                            <div class="video-list-item">
                                <div class="video-cont active">
                                    <div class="video-title">
                                        <img src="@/assets/images/task-select-fkbg-ac.png" alt="">
                                        <span>{{firstTask.taskName || ''}}</span>
                                    </div>
                                    <div class="suspended" v-if="liveVideoVOList(firstTask).length">
                                        <div class="suspended-cont">
                                            <div class="suspended-item" @click="openVideoScreen(firstTask)">
                                                {{$t('navbar.screenfull')}}</div>
                                        </div>
                                    </div>
                                    <div class="video-zone">
                                        <component v-if="liveVideoVOList(firstTask).length" :is="useLive(firstTask)" 
                                            ref="videoLive" class="video-plane" :screen="false" 
                                            :liveVideoVOList="liveVideoVOList(firstTask)" />
                                        <div v-else class="video-loading" v-loading="true" 
                                            :element-loading-text="$t('WaitingForVideoTransmission')" 
                                            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="video-list-item" v-for="(item, index) in argtTaskList" :key="item.sn">
                                <div class="video-cont" @click="handleChange(item)">
                                    <div class="video-title">
                                        <img src="@/assets/images/task-select-fkbg.png" alt="">
                                        <span>{{item.taskName || ''}}</span>
                                    </div>
                                    <div class="suspended" v-if="liveVideoVOList(item).length">
                                        <div class="suspended-cont">
                                            <div class="suspended-item" @click="openVideoScreen(item)">
                                                {{$t('navbar.screenfull')}}</div>
                                        </div>
                                    </div>
                                    <div class="video-zone">
                                        <component v-if="liveVideoVOList(item).length" :is="useLive(item)" 
                                            :ref="'videoLive' + index" class="video-plane" :screen="false" 
                                            :liveVideoVOList="liveVideoVOList(item)" />
                                        <div v-else class="video-loading" v-loading="true" 
                                            :element-loading-text="$t('WaitingForVideoTransmission')" 
                                            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </BaseBox>
            </div>
            <!-- 三任务 -->
            <div class="three-tasks" v-if="taskList.length == 3">
                <BaseBox :title="$t('screen.currentFootage')">
                    <template v-slot:content>
                        <div class="video-list">
                            <div class="check-video">
                                <div class="video-cont active">
                                    <div class="video-title">
                                        <img src="@/assets/images/task-select-fkbg-ac.png" alt="">
                                        <span>{{firstTask.taskName || ''}}</span>
                                    </div>
                                    <div class="suspended" v-if="liveVideoVOList(firstTask).length">
                                        <div class="suspended-cont">
                                            <div class="suspended-item" @click="openVideoScreen(firstTask)">
                                                {{$t('navbar.screenfull')}}</div>
                                        </div>
                                    </div>
                                    <div class="video-zone">
                                        <component v-if="liveVideoVOList(firstTask).length" :is="useLive(firstTask)" 
                                            ref="videoLive" class="video-plane" :screen="false" 
                                            :liveVideoVOList="liveVideoVOList(firstTask)" />
                                        <div v-else class="video-loading" v-loading="true" 
                                            :element-loading-text="$t('WaitingForVideoTransmission')" 
                                            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="other-video">
                                <div class="other-video-item" v-for="(item, index) in argtTaskList" :key="item.sn">
                                    <div class="video-cont">
                                        <div class="video-title">
                                            <img src="@/assets/images/task-select-fkbg.png" alt="">
                                            <span>{{item.taskName || ''}}</span>
                                        </div>
                                        <div class="suspended" v-if="liveVideoVOList(item).length">
                                            <div class="suspended-cont">
                                                <div class="suspended-item" @click="openVideoScreen(item)">
                                                    {{$t('navbar.screenfull')}}</div>
                                            </div>
                                        </div>
                                        <div class="video-zone">
                                            <component v-if="liveVideoVOList(item).length" :is="useLive(item)" 
                                                :ref="'videoLive' + index" class="video-plane" :screen="false" 
                                                :liveVideoVOList="liveVideoVOList(item)" />
                                            <div v-else class="video-loading" v-loading="true" 
                                                :element-loading-text="$t('WaitingForVideoTransmission')" 
                                                element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </BaseBox>
            </div>
            <!-- 多任务 -->
            <div class="multi-tasking" v-if="taskList.length > 3">
                <BaseBox :title="$t('screen.currentFootage')">
                    <template v-slot:content>
                        <div class="tasking-cont">
                            <div class="tasking-item" v-for="(item, index) in argtTaskList" :key="item.sn">
                                <div class="video-cont"  :class="{'active': selectSn === item.sn}">
                                    <div class="video-title">
                                        <img src="@/assets/images/task-select-fkbg-ac.png" alt="">
                                        <span>{{item.taskName || ''}}</span>
                                    </div>
                                    <div class="suspended" v-if="liveVideoVOList(item).length">
                                        <div class="suspended-cont">
                                            <div class="suspended-item" @click="openVideoScreen(item)">
                                                {{$t('navbar.screenfull')}}</div>
                                        </div>
                                    </div>
                                    <div class="video-zone">
                                        <component v-if="liveVideoVOList(item).length" :is="useLive(item)" 
                                            :ref="'videoLive' + index" class="video-plane" :screen="false" 
                                            :liveVideoVOList="liveVideoVOList(item)" />
                                        <div v-else class="video-loading" v-loading="true" 
                                            :element-loading-text="$t('WaitingForVideoTransmission')" 
                                            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </BaseBox>
            </div>
        </div>
    </div>
</template>

<script>
import BaseBox from '../../../BaseBox/index.vue';
import VdieoLiveEasyplayer from "@/components/video-live-easyPlayer/index.vue";
import VdieoLiveHWLLS from "@/components/video-live-HWLLS/index.vue";
import Plant from "./plane.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'olscr-right',
    components: {BaseBox, VdieoLiveEasyplayer, VdieoLiveHWLLS, Plant},
    data() {
        return {
            loading: true,
            firstTask: {},
            argtTaskList: [],
            selectSn: '',
            uavList: []
        }
    },
    computed: {
        ...mapGetters([
            'selectTask',
            'currentTaskList'
        ]),
        liveVideoVOList() {
            return function(taskData) {
                const liveVideoVOList = taskData.liveVideos;
                if (liveVideoVOList && Array.isArray(liveVideoVOList)) {
                    return liveVideoVOList;
                }
                return [];
            }
        },
        useLive() {
            return function(taskData) {
                return taskData.liveType === 1 ? 'VdieoLiveHWLLS': 'VdieoLiveEasyplayer';
            }
        },
        // 当前正在执行任务列表
        taskList() {
            let taskList = this.currentTaskList || [];
            return taskList;
        }
    },
    watch: {
        'currentTaskList': {
            deep: true,
            handler: function(val) {
                if (val && val.length) {
                    let itemUav = val.find(t => t.sn == this.selectTask.sn);
                    let length = val.length;
                    let argtTaskList = [];
                    if (!itemUav) {
                        this.firstTask = JSON.parse(JSON.stringify(val[0]));
                        this.selectSn = val[0].sn;
                        this.$emit('clearnSelectTask');
                        this.$nextTick(() => {
                            this.$parent.$refs.leftDom.$refs.map.isSetFlight = false;
                        });
                    } else {
                        if (JSON.stringify(this.firstTask) === '{}') {
                            this.firstTask = JSON.parse(JSON.stringify(itemUav));
                            this.selectSn = itemUav.sn;
                        }
                        if (!this.firstTask.liveType || !this.firstTask.liveVideos || !itemUav.liveVideos) {
                            this.firstTask = JSON.parse(JSON.stringify(itemUav));
                        }
                        if (this.$parent.selectTaskData && 
                            JSON.stringify(this.$parent.selectTaskData) !== JSON.stringify(itemUav)) {
                            this.$emit('changeSelectTask', JSON.parse(JSON.stringify(itemUav)));
                        }
                    }
                    let {addData, removeData} = this.compareDifferences(this.uavList, val || []);
                    this.uavList = val || [];
                    if (addData.length || removeData.length) {
                        val.map((t) => {
                            if (length > 3) {
                                argtTaskList.push(t);
                            } else {
                                if (t.sn !== this.firstTask.sn) {
                                    argtTaskList.push(t);
                                }
                            }
                        });
                        if (argtTaskList.length) {
                            this.argtTaskList = [...argtTaskList];
                        }
                    }
                } else {
                    this.firstTask = {};
                    this.argtTaskList = [];
                    this.selectSn = '';
                    this.uavList = [];
                    this.setSelectTask('');
                }
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }
        }
    },
    methods: {
        ...mapActions([
            'setSelectTask'
        ]),
        // 切换视频选中
        handleChange(taskData, noSetFirstTask) {
            if (taskData) {
                let currentTaskList = [...this.currentTaskList];
                let task = currentTaskList.find(t => t.sn === taskData.sn);
                if (task) {
                    if (!noSetFirstTask) {
                        let argtTaskItem = this.argtTaskList.find(t => t.sn === task.sn);
                        let index = this.argtTaskList.indexOf(argtTaskItem);
                        if (index != -1) {
                            this.$set(this.argtTaskList, index, JSON.parse(JSON.stringify(this.firstTask)));
                        }
                        this.firstTask = JSON.parse(JSON.stringify(task));
                    }
                    this.selectSn = task.sn;
                    this.setSelectTask(task);
                }
            }
        },
        // 打开视频全屏
        openVideoScreen(taskData) {
            if (taskData) {
                if (this.selectSn !== taskData.sn) {
                    // 当前点击全屏的sn与选中的sn不一致时
                    let noSetFirstTask = this.taskList.length > 3 ? true : false;
                    this.handleChange(taskData, noSetFirstTask);
                }
                this.$emit('changeSelectTask', JSON.parse(JSON.stringify(taskData)));
            }
        },
        // 比较两次飞机数据看是否存在差异
        compareDifferences(originalArray, currentArray) {
            const addData = currentArray.filter(
                newItem => originalArray.findIndex(oldItem => (oldItem.sn === newItem.sn 
                    && JSON.stringify(oldItem.liveVideos) === JSON.stringify(newItem.liveVideos))) === -1
            );
            const removeData = originalArray.filter(
                oldItem => currentArray.findIndex(newItem => (newItem.sn === oldItem.sn 
                    && JSON.stringify(newItem.liveVideos) === JSON.stringify(oldItem.liveVideos))) === -1
            );
            return {addData, removeData};
            // if (removeData.length) {
            //     let argtTaskList = JSON.parse(JSON.stringify(this.argtTaskList));
            //     removeData.map(t => {
            //         let item = argtTaskList.find(i => i.sn === t.sn);
            //         let itemIndex = argtTaskList.indexOf(item);
            //         if (itemIndex !== -1) {
            //             argtTaskList.splice(itemIndex, 1);
            //         }
            //     });
            //     this.argtTaskList = [...argtTaskList];
            // }
            // if (addData.length) {
            //     this.argtTaskList = [...this.argtTaskList, ...addData];
            // }
        }
    }
}
</script>

<style lang="scss" scoped>
.right-cont {
    width: 100%;
    height: 100%;
    position: relative;
    .screen-right-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: rgba(12, 27, 58, 0.8);
        cursor: pointer;
        .single-task {
            height: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            .task-video {
                height: 48%;
                .video-cont {
                    margin-top: 10px;
                }
            }
            .task-msg {
                height: 50%;
                .plane-cont {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    margin: 0 12px;
                }
            }
        }
        .dual-task {
            height: 0;
            flex: 1;
            .video-list {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                margin-top: 20px;
                .video-list-item {
                    height: 49%;
                }
            }
        }
        .three-tasks {
            height: 0;
            flex: 1;
            .video-list {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                margin-top: 20px;
                .check-video {
                    height: 60%;
                }
                .other-video {
                    height: 38%;
                    display: flex;
                    justify-content: space-between;
                    .other-video-item {
                        width: 49%;
                    }
                }
            }
        }
        .multi-tasking {
            height: 0;
            flex: 1;
            .tasking-cont {
                height: 100%;
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                justify-content: space-between;
                overflow: auto;
                .tasking-item {
                    width: 48%;
                    height: 200px;
                    margin-top: 8px;
                    &:nth-child(1) {
                        margin-top: 0;
                    }
                    &:nth-child(2) {
                        margin-top: 0;
                    }
                }
            }
        }
        .video-cont {
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            &.active {
                background: #0B2554;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url("~@/assets/images/plane-active-bg.png");
            }
            .video-title {
                height: 40px;
                display: flex;
                align-items: center;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 15px;
                color: #FFFFFF;
                img {
                    width: 24px;
                    height: 24px;
                    margin: 0 8px;
                }
            }
            .suspended {
                display: none;
                position: absolute;
                z-index: 9999;
                bottom: 13%;
                left: 50%;
                transform: translateX(-50%);
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #FFFFFF;
                background: rgba(0, 0, 0, 0.6);
                border-radius: 16px;
                cursor: pointer;
                .suspended-cont {
                    display: flex;
                    .suspended-item {
                        padding: 5px 18px;
                    }
                }
            }
            &:hover .suspended {
                display: block;
            }
            .video-zone {
                height: 0;
                flex: 1;
                padding: 0 5px 5px 5px;
                .video-loading {
                    height: 100%;
                    width: 100%;
                    background-color: #011a38;
                }
            }
        }
    }
}
</style>