var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "on-duty-container" }, [
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "item-title" }, [_vm._v("带班领导 : ")]),
      _c("span", { staticClass: "item-content" }, [
        _vm._v(
          _vm._s(
            _vm.onDutyData && _vm.onDutyData.leader
              ? _vm.onDutyData.leader
              : "-"
          )
        ),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "item-title" }, [_vm._v("值班主任 : ")]),
      _c("span", { staticClass: "item-content" }, [
        _vm._v(_vm._s(_vm.onDutyData.director || "-")),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "item-title" }, [_vm._v("值班调度 : ")]),
      _c("span", { staticClass: "item-content" }, [
        _vm._v(_vm._s(_vm.onDutyData.dispatcher || "-")),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "item-title" }, [_vm._v("值班科长 : ")]),
      _c("span", { staticClass: "item-content" }, [
        _vm._v(_vm._s(_vm.onDutyData.sectionChief || "-")),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "item-title" }, [_vm._v("值班通讯 : ")]),
      _c("span", { staticClass: "item-content" }, [
        _vm._v(_vm._s(_vm.onDutyData.correspondent || "-")),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "item-title" }, [_vm._v("值班司机 : ")]),
      _c("span", { staticClass: "item-content" }, [
        _vm._v(_vm._s(_vm.onDutyData.driver || "-")),
      ]),
    ]),
    _c("div", { staticClass: "item" }, [
      _c("span", { staticClass: "item-title" }, [_vm._v("值班人员 : ")]),
      _c("span", { staticClass: "item-content" }, [
        _vm._v(_vm._s(_vm.onDutyData.people || "-")),
      ]),
    ]),
    _c("div", { staticClass: "item tel" }, [
      _c("span", { staticClass: "item-title" }, [_vm._v("通讯电话 : ")]),
      _c(
        "span",
        {
          staticClass: "item-content",
          class: {
            "item-content-tel":
              _vm.onDutyData && _vm.onDutyData.communicationTelephone,
          },
        },
        [_vm._v(_vm._s(_vm.onDutyData.communicationTelephone || "-"))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }