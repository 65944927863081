<template>
  <div class="leftBox">
    <div class="plane">
      <Base :title="$t('screen.UAVInfo')">
      <template v-slot:content>
        <Plant  @handleChildClick="handleChildClick" ref="plane"></Plant>
      </template>
      </Base>
    </div>
    <div class="device">
      <Base title="设备统计情况">
      <template v-slot:content>
        <Device></Device>
      </template>
      </Base>
    </div>
    <div class="history">
      <Base title="历史记录">
      <template v-slot:content>
        <History></History>
      </template>
      </Base>
    </div>
  </div>
</template>
<script>
import Base from "@/components/screen/Base.vue";
import Device from "./device.vue";
import History from "./history.vue";
import Plant from "./plane.vue";
export default {
  components: { Device, History, Plant, Base },
  methods: {
  handleChildClick(param) {
      // 触发自定义事件
      this.$emit('handleContentClick', param);
    }
  }
};
</script>
<style scoped lang="scss">
.leftBox {
  height: 100%;
  width: 100%;
}

.plane {
  height: 310px;
  border-radius: 8px;
  margin-bottom: 21px;
  .base {
    background-image: url("~@/assets/images/plane.png");
  }
}

.device {
  height: 232px;
  border-radius: 8px;
  margin-bottom: 21px;

  .base {
    background-image: url("~@/assets/images/history.png");
  }
}

.history {
  height: calc(100% - 584px);
  .base {
    background-image: url("~@/assets/images/history.png");
  }
}
</style>
