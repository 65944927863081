var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "leftBox" }, [
    _c(
      "div",
      { staticClass: "plane" },
      [
        _c("Base", {
          attrs: { title: _vm.$t("screen.UAVInfo") },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("Plant", {
                    ref: "plane",
                    on: { handleChildClick: _vm.handleChildClick },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "device" },
      [
        _c("Base", {
          attrs: { title: "设备统计情况" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [_c("Device")]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "history" },
      [
        _c("Base", {
          attrs: { title: "历史记录" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [_c("History")]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }