var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "OverallSituation" }, [
    _c(
      "div",
      { staticClass: "Overall-map" },
      [
        _c("oliMap", {
          attrs: {
            isTracked: false,
            mapType: "overallMap",
            AIAlarmData: _vm.AIAlarmData,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "left-panle panle-main" }, [
      _c("div", { staticClass: "panle-content" }, [
        _c(
          "div",
          { staticClass: "panle-item panle-item-sort panle-item-sbxx" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.deviceOverview") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("devicePage", {
                        attrs: { deviceData: _vm.deviceData },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "panle-item panle-item-sort" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.uavFlightStatistics") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("uavPage", { attrs: { uavFlyData: _vm.uavFlyData } }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "panle-item panle-item-lsjc" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.historySortie") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("sortiePage", {
                        attrs: { historySortieData: _vm.historySortieData },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "right-panle panle-main" }, [
      _c("div", { staticClass: "panle-content" }, [
        _c(
          "div",
          { staticClass: "panle-item panle-item-sort panle-item-dcqk" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.podOverview") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("podVideoPage", { attrs: { podData: _vm.podData } }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "panle-item panle-item-sort panle-item-gxxj" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.pipeInspection") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("pipelinePage", { attrs: { pipeData: _vm.pipeData } }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "panle-item panle-item-yjxj" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.oilWellInspection") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("oilWellPage", {
                        attrs: { oilWellData: _vm.oilWellData },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "top-panle" },
      [
        _c("realTimeAlarm", {
          attrs: { alarmData: _vm.alarmData },
          on: { changeSearchAlarm: _vm.changeSearchAlarm },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowLedger,
            expression: "isShowLedger",
          },
        ],
        staticClass: "bottom-panle",
      },
      [
        _c("BaseBox", {
          attrs: { title: _vm.$t("oilGasScreen.uavLedger") },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("droneLedger", { attrs: { ledgerData: _vm.ledgerData } }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }