<template>
    <!-- 饼图 -->
    <div class="chart-node" :id="`chart-${id}-${time}`"></div>
</template>

<script>
export default {
    name: 'chartPie',
    props: {
        // 当前组件id标志，必传，用于区分dom节点
        id: {
            type: String,
            required: true,
            default: `${new Date().getTime()}`
        },
        // 是否为双饼图
        isDoubleChart: {
            type: Boolean,
            default: false
        },
        // 是否为玫瑰图
        isRoseChart: {
            type: Boolean,
            default: false
        },
        // 是否为半圆饼图
        isSemicircle: {
            type: Boolean,
            default: false
        },
        // chart颜色列表
        chartColors: {
            type: Array,
            default: () => {
                // 默认的颜色列表
                return [
                    '#438FFF',
                    '#F5D021',
                    '#40F3E6',
                    '#FF5F5F',
                    '#96F868',
                    '#FF804A',
                    '#A354C7',
                    '#3C64DA',
                    '#65BC3E',
                    '#FFC849',
                    '#D84343',
                    '#41B8E6',
                    '#22A569',
                    '#E6622A',
                    '#8D36B5',
                    '#EF59C4',
                    '#5680FF',
                    '#FFA847',
                    '#0095D2',
                    '#5370C5'
                ];
            }
        },
        // grid自定义距离
        grid: {
            type: Object,
            default: () => {
                return {
                    left: '5%', // 左
                    right: '5%', // 右
                    bottom: '3%', // 下
                    top: '4%' // 上
                };
            }
        },
        // graphic自定义
        graphicOption: {
            type: Object,
            default: () => {
                return {
                    x: 200,
                    y: 90,
                    z: 5,
                    text: '',
                    fontSize: '23px'
                }
            }
        },
        // 若为双饼图第二个饼图的graphic自定义
        graphicOptionTwo: {
            type: Object,
            default: () => {
                return {
                    x: 306,
                    y: 130,
                    z: 5,
                    text: '',
                    fontSize: '23px'
                }
            }
        },
        // tooltip自定义
        tooltip: {
            type: Object,
            default: () => {
                return {
                    trigger: 'item',
                    // formatter: '{b} : {c}'
                };
            }
        },
        // legend在顶部的位置，left，center，right
        legendPosition: {
            type: String,
            default: 'left'
        },
        // legend宽度自定义
        legendWidth: {
            type: Number | String,
            default: '220'
        },
        // 饼图内外radius半径大小
        chartRadius: {
            type: Array,
            default: () => {
                return ['40%', '55%'];
            }
        },
        // 若为双饼图第二个饼图的内外radius半径大小
        chartRadiusTwo: {
            type: Array,
            default: () => {
                return ['20%', '40%'];
            }
        },
        // 饼图center中心点位置
        chartCenter: {
            type: Array,
            default: () => {
                return ['50%', '50%'];
            }
        },
        // 若为双饼图第二个饼图的center中心点位置
        chartCenterTwo: {
            type: Array,
            default: () => {
                return ['75%', '60%'];
            }
        },
        // label的padding距离自定义
        labelPadding: {
            type: Array,
            default: () => {
                return [0, -12];
            }
        },
        // 若为双饼图第二个饼图的labelPadding自定义
        labelPaddingTwo: {
            type: Array,
            default: () => {
                return [0, -12];
            }
        },
        // label的自定义文字显示内容
        labelFormatter: {
            type: String,
            default: '{c}'
        },
        // 若为双饼图第二个饼图的文字显示内容
        labelFormatterTwo: {
            type: String,
            default: '{c}'
        },
        // labelLine引导线自定义
        labelLine: {
            type: Object,
            default: () => {
                return {
                    length: 5,
                    length2: 30
                };
            }
        },
        // 若为双饼图第二个饼图的引导线自定义
        labelLineTwo: {
            type: Object,
            default: () => {
                return {
                    length: 5,
                    length2: 30
                };
            }
        },
        // labelLayout引导线文字位置自定义
        labelLayout: {
            type: Object,
            default: () => {
                return {
                    verticalAlign: "bottom",
                    dy: -3
                };
            }
        },
        // 若为双饼图第二个饼图的引导线文字位置自定义
        labelLayoutTwo: {
            type: Object,
            default: () => {
                return {
                    verticalAlign: "bottom",
                    dy: -3
                };
            }
        },
        // dataOption，series的data自定义使用字段
        dataOption: {
            type: Object,
            default: () => {
                return {};
            }
        },
        // chartData数据
        chartData: {
            type: Array,
            default: () => {
                return [{value: 0, name: '' }];
            }
        },
        // 若为双饼图第二个饼图的chartData数据
        chartDataTwo: {
            type: Array,
            default: () => {
                return [{value: 0, name: '' }];
            }
        }
    },
    data() {
        return {
            timer: null,
            time: new Date().getTime(),
            chartOption: '',
            defaultTooltip: {trigger: 'item'},
            myChart: ''
        }
    },
    watch: {
        'chartData': {
            deep: true,
            handler: function(val) {
                let data = val || [];
                if (this.dataOption.label && this.dataOption.value) {
                    data = val.map(t => {
                        return {
                            value: t[this.dataOption.value],
                            name: t[this.dataOption.label]
                        }
                    });
                }
                this.resetChart(0, data, this.graphicOption.text);
            }
        },
        'chartDataTwo': {
            deep: true,
            handler: function(val) {
                let data = val;
                if (this.dataOption.label && this.dataOption.value) {
                    data = val.map(t => {
                        return {
                            value: t[this.dataOption.value],
                            name: t[this.dataOption.label]
                        }
                    });
                }
                this.resetChart(1, data, this.graphicOptionTwo.text);
            }
        }
    },
    mounted() {
        this.initChart();
        window.addEventListener('resize', this.handleChartResize);
    },
    methods: {
        initChart() {
            let chartDom = document.getElementById(`chart-${this.id}-${this.time}`);
            this.myChart = this.$echarts.init(chartDom);
            this.chartOption = {
                grid: this.grid, // 调整距离
                tooltip: this.tooltip.trigger ? this.tooltip : this.defaultTooltip,
                graphic: [
                    {
                        type: 'text',
                        x: this.graphicOption.x || 200,
                        y: this.graphicOption.y || 90,
                        z: this.graphicOption.z || 5,
                        style: {
                            text: `${this.graphicOption.text || ''}`,
                            font: `${this.graphicOption.fontSize || '23px'} PangMen`,
                            textAlign: 'center',
                            fill: '#fff'
                        }
                    }
                ],
                legend: {
                    type: 'scroll',
                    top: '2%',
                    left: this.legendPosition,
                    width: this.legendWidth,
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        color: '#fff'
                    },
                    pageIconSize: 12,
                    pageIconColor: '#fff',
                    pageTextStyle: {
                        color: '#fff'
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: this.chartRadius,
                        center: this.chartCenter,
                        label: {
                            show: true,
                            padding: this.labelPadding,
                            color: 'inherit',
                            fontSize: 14,
                            lineHeight: 15,
                            formatter: this.labelFormatter
                        },
                        labelLine: this.labelLine,
                        labelLayout: this.labelLayout,
                        data: this.chartData
                    }
                ]
            };
            // 设置颜色
            if (this.chartColors.length) {
                this.chartOption.color = this.chartColors;
            }
            // 是否为双饼图
            if (this.isDoubleChart) {
                // 添加series
                this.chartOption.series.push({
                    type: 'pie',
                    radius: this.chartRadiusTwo,
                    center: this.chartCenterTwo,
                    label: {
                        show: true,
                        padding: this.labelPaddingTwo,
                        color: 'inherit',
                        fontSize: 14,
                        lineHeight: 15,
                        formatter: this.labelFormatterTwo
                    },
                    labelLine: this.labelLineTwo,
                    labelLayout: this.labelLayoutTwo,
                    data: this.chartDataTwo
                });
                // 添加graphic
                this.chartOption.graphic.push({
                    type: 'text',
                    x: this.graphicOptionTwo.x || 306,
                    y: this.graphicOptionTwo.y || 130,
                    z: this.graphicOptionTwo.z || 5,
                    style: {
                        text: `${this.graphicOptionTwo.text || ''}`,
                        font: `${this.graphicOptionTwo.fontSize || '23px'} PangMen`,
                        textAlign: 'center',
                        fill: '#fff'
                    }
                });
            }
            // 是否为玫瑰饼图
            if (this.isRoseChart) {
                this.chartOption.series[0].roseType = 'area';
                this.chartOption.series[0].itemStyle = {borderRadius: 5};
                if (this.chartOption.series[1]) {
                    this.chartOption.series[1].roseType = 'area';
                    this.chartOption.series[1].itemStyle = {borderRadius: 5};
                }
            }
            // 是否为半圆饼图
            if (this.isSemicircle) {
                this.chartOption.series[0].startAngle = 180;
                this.chartOption.series[0].endAngle = 360;
                if (this.chartOption.series[1]) {
                    this.chartOption.series[1].startAngle = 180;
                    this.chartOption.series[1].endAngle = 360;
                }
            }
            this.chartOption && this.myChart.setOption(this.chartOption, {notMerge: true});
        },
        resetChart(index, data, total) {
            if (this.chartOption) {
                this.chartOption.tooltip = this.tooltip.trigger ? this.tooltip : this.defaultTooltip;
                this.chartOption.graphic[index].style.text = `${total || ''}`;
                this.chartOption.series[index].data = data;
                this.myChart && this.myChart.setOption(this.chartOption, {notMerge: true});
            }
        },
        handleChartResize() {
            if (this.myChart) {
                this.myChart.dispose();
                this.myChart = '';
            }
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.initChart();
            }, 500);
        }
    },
    beforeDestroy() {
        if (this.myChart) {
            this.myChart.dispose();
            this.myChart = '';
        }
        window.removeEventListener('resize', this.handleChartResize);
    }
}
</script>

<style lang="scss" scoped>
.chart-node {
    width: 100%;
    height: 100%;
}
</style>