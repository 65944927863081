<template>
  <div class="Box">
    <div class="topinfo">
      <!-- v-show="false" -->
      <div class="top1" v-if="taskList.length">
        <avue-select v-model="taskSelect" :dic="taskList" :clearable="false" @change="handleChange"></avue-select>
      </div>
      <div class="top2">
        <div class="left">
          <img class="imgUavInfo" :src="iconMap['drone']" alt="" />
          无人机序列号
        </div>
        <div class="right">{{ equipmentInfo.serialNumber || "-" }}</div>
      </div>
    </div>
    <div class="plane-info">
      <el-row :gutter="20" class="bodyBox">
        <el-col :span="9">
          <el-row>
            <el-col :span="13" class="elColLeft"> 当前模式：</el-col>
            <el-col :span="11" class="elColRight">{{
              store.flyMode ? store.flyMode : "-"
            }}</el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="15" class="elColLeft">当前电量：</el-col>
            <el-col :span="9" class="elColRight">{{
              typeof store.batteryRemaining === "number"
              ? `${store.batteryRemaining + "%"}`
              : "-"
            }}</el-col>
          </el-row>
        </el-col>
        <el-col :span="9">
          <el-row>
            <el-col :span="8" class="elColLeft">纬度：</el-col>
            <el-col :span="16" class="elColRight">{{
              store.latitude ? store.latitude : "-"
            }}</el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="bodyBox">
        <el-col :span="9">
          <el-row>
            <el-col :span="13" class="elColLeft">飞手姓名：</el-col>
            <el-col :span="11" class="elColRight">{{
              flyOperatorName ? flyOperatorName : "-"
            }}</el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="15" class="elColLeft">解锁信号：</el-col>
            <el-col :span="9" class="elColRight">{{
              typeof store.lockedState === "boolean"
              ? store.lockedState
                ? "已解锁"
                : "已加锁"
              : "-"
            }}</el-col>
          </el-row>
        </el-col>
        <el-col :span="9">
          <el-row>
            <el-col :span="8" class="elColLeft">经度：</el-col>
            <el-col :span="16" class="elColRight">{{
              store.longitude ? store.longitude : "-"
            }}</el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <div class="bottom plane-card">
      <el-row type="flex" justify="space-between">
        <el-col :span="12" class="col">
          <el-row class="flex">
            <el-col :span="12" class="left">
              <el-row>
                <el-col :span="8">
                  <img class="imgUav" :src="iconMap['speed']" alt="" />
                </el-col>
                <el-col :span="16">速度</el-col>
              </el-row>
            </el-col>
            <el-col :span="12" class="right">{{ store.gs }}<span>m/s</span></el-col>
          </el-row>
        </el-col>
        <el-col :span="12" class="col">
          <el-row class="flex">
            <el-col :span="12" class="left">
              <el-row>
                <el-col :span="8">
                  <img class="imgUav" :src="iconMap['altitude']" alt="" />
                </el-col>
                <el-col :span="16">场高</el-col>
              </el-row>
            </el-col>
            <el-col :span="12" class="right">{{ store.height? store.height.toFixed(2): 0 }}<span>m</span></el-col>
          </el-row></el-col>
      </el-row>
      <el-row type="flex" justify="space-between" style="margin-top: 20px">
        <el-col :span="12" class="col">
          <el-row class="flex">
            <el-col :span="12" class="left">
              <el-row>
                <el-col :span="8">
                  <img class="imgUav" :src="iconMap['mileage']" alt="" />
                </el-col>
                <el-col :span="16">实时里程</el-col>
              </el-row>
            </el-col>
            <el-col :span="12" class="right">{{ this.flyDistanceFormat }}
              <span>km</span>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" class="col">
          <el-row class="flex">
            <el-col :span="12" class="left">
              <el-row>
                <el-col :span="8">
                  <img class="imgUav" :src="iconMap['flight_time']" alt="" />
                </el-col>
                <el-col :span="16">飞行时间</el-col>
              </el-row>
            </el-col>
            <el-col :span="12" class="right">{{ this.minuteFormate }}</el-col>
          </el-row></el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import drone from "@/assets/images/drone.png";
import speed from "@/assets/images/speed.png";
import altitude from "@/assets/images/altitude.png";
import mileage from "@/assets/images/mileage.png";
import flight_time from "@/assets/images/flight_time.png";
import * as dayjs from "dayjs";
import { getDict } from "@/api/map/index";

import { getFlyingTask } from "@/api/phugiacloud/projectManagement";

import { getStore } from "@/util/store.js";
var objectSupport = require("dayjs/plugin/objectSupport");
dayjs.extend(objectSupport);
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      taskSelect: "",
      taskList: [],
      taskId: "",
      socket: null,
      electricity: 100,
      dimensionality: '30′32′27.168"N',
      speed: 72,
      altitude: 666,
      longitude: '103′37′29.917"E',
      iconMap: {
        drone,
        speed,
        altitude,
        mileage,
        flight_time,
      },
      popupLabels: [],
      playerData: {
        flyMode: "",
        batteryRemaining: "",
        latitude: "",
        longitude: "",
        gs: "",
        as: "",
        height: "",
        flyDistance: "",
        flyTime: "",
      },

      selected: '',
      flyDistanceUnit: 0,
      options111: [
        { label: '任务1111', value: '1' },
        { label: '任务11122355555555', value: '2' },
        { label: '任务433', value: '3' }
      ]

    };
  },
  async created() {
    //获取真正执行的任务
    // 获取远程任务列表
    const flyId = this.$route.query.flyId;
    if (!flyId) return;
    getFlyingTask({ projectId: flyId }).then((res) => {
      for (let i = 0; i < res.data.data.length; i += 1) {
        const task = res.data.data[i];
        const taskLabel = {
          label: task.taskName,
          value: task.taskId
        };
        this.taskList.push(taskLabel);
      }

      if (this.taskList.length > 0) {
        // 从本地存储中获取上一次保存的选择值
        this.taskSelect = this.taskList[0].value; // 默认选择第一项
        // this.handleChange(this.taskSelect);
      }
    });
  },
  computed: {
    ...mapGetters([
      'wsData',
      'socketData',
      'newSocketData',
      'task'
    ]),
    store() {
      // if (this.task && this.task.useIot === 1) {
      //   let gs = typeof this.newSocketData.gs === 'number'? this.newSocketData.gs.toFixed(1): '';
      //   this.playerData.longitude = this.newSocketData.lon? this.newSocketData.lon.toFixed(7): '';
      //   this.playerData.latitude = this.newSocketData.lat? this.newSocketData.lat.toFixed(7): '';
      //   // this.playerData.flyMode = this.newSocketData.flgtmd;
      //   this.playerData.flyMode = '-';
      //   this.playerData.batteryRemaining = this.newSocketData.pwrLeft;
      //   this.playerData.lockedState = this.newSocketData.lcksta? true: false;
      //   this.playerData.gs = gs;
      //   this.playerData.height = this.newSocketData.fieldHeight;
      //   this.playerData.flyDistanceFormat = (this.newSocketData.distOnAir/ 1000).toFixed(2) ;
      //   this.playerData.flyTime = this.newSocketData.tmOnAir;
      // } else {
      //   if (this.socketData.type == "ASGCSS") {
      //     var data = JSON.parse(JSON.stringify(this.socketData.data))
      //     data.flyDistanceFormat = data.flyDistance / 1000
      //     return data || this.playerData;
      //   } else {
      //     const { cardVoList } = this.socketData.data;
      //     if (cardVoList) {
      //       let result = [];
      //       for (let i = 0; i < cardVoList.length; i += 1) {
      //         const temp = cardVoList[i];
      //         const path = temp.packageAlines.split("/");
      //         const has = this.popupLabels.find((n) => n.key === path[1]);
      //         let s;
      //         if (has) {
      //           s = {
      //             ...has,
      //             value: temp.value,
      //           };
      //           result.push(s);
      //         }
      //         for (let i = 0; i < result.length; i += 1) {
      //           const temp = result[i];
      //           if (temp.key === "sensors_v_172_vlng") {
      //             if (
      //               typeof temp.value === "string" ||
      //               typeof temp.value === "number"
      //             ) {
      //               let num = Number(temp.value); //将字符串转换为Number类型 temp.value
      //               let result = num.toFixed(2); //将Number类型转换为保留两位数的字符串数据
      //               this.playerData.longitude = result;
      //             }
      //           }
      //           // 纬度
      //           if (temp.key === "sensors_v_172_vlat") {
      //             if (
      //               typeof temp.value === "string" ||
      //               typeof temp.value === "number"
      //             ) {
      //               let num = Number(temp.value); //将字符串转换为Number类型 temp.value
      //               let result = num.toFixed(2); //将Number类型转换为保留两位数的字符串数据
      //               this.playerData.latitude = result;
      //             }
      //           }
      //           // 表空速度
      //           if (temp.key === "sensors_v_172_vvc") {
      //             if (
      //               typeof temp.value === "string" ||
      //               typeof temp.value === "number"
      //             ) {
      //               let num = Number(temp.value); //将字符串转换为Number类型 temp.value
      //               let result = num.toFixed(1); //将Number类型转换为保留两位数的字符串数据
      //               this.playerData.gs = result;
      //             }
      //           }
      //           // 海拔高度
      //           //if (temp.key === "sensors_v_172_valt") {
      //           if (temp.key === "sensors_v_172_vrpz") {
      //             if (
      //               typeof temp.value === "string" ||
      //               typeof temp.value === "number"
      //             ) {
      //               let num = Number(temp.value); //将字符串转换为Number类型 temp.value
      //               let result = num.toFixed(1); //将Number类型转换为保留两位数的字符串数据
      //               this.playerData.height = result;
      //             }
      //           }
      //           // 实时里程
      //           if (temp.key === "player_energy_flying_distance") {
      //             if ( typeof temp.value === "string" || typeof temp.value === "number" ) {
      //               let num = Number(temp.value); //将字符串转换为Number类型 temp.value
      //               // let result = num.toFixed(2); //将Number类型转换为保留两位数的字符串数据
      //               this.playerData.flyDistanceFormat = num / 1000;
      //             }
      //           }
      //           // 飞行时间
      //           if (temp.key === "player_energy_flying_time") {
      //             if (
      //               typeof temp.value === "string" ||
      //               typeof temp.value === "number"
      //             ) {
      //               let num = Number(temp.value); //将字符串转换为Number类型 temp.value
      //               let result = num.toFixed(2); //将Number类型转换为保留两位数的字符串数据
      //               this.playerData.flyTime = result;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      // 不使用iot判断了
      let gs = typeof this.newSocketData.gs === 'number'? this.newSocketData.gs.toFixed(1): '';
      this.playerData.longitude = this.newSocketData.lon? this.newSocketData.lon.toFixed(7): '';
      this.playerData.latitude = this.newSocketData.lat? this.newSocketData.lat.toFixed(7): '';
      // this.playerData.flyMode = this.newSocketData.flgtmd;
      this.playerData.flyMode = '-';
      this.playerData.batteryRemaining = this.newSocketData.pwrLeft;
      this.playerData.lockedState = this.newSocketData.lcksta? true: false;
      this.playerData.gs = gs;
      this.playerData.height = this.newSocketData.fieldHeight;
      this.playerData.flyDistanceFormat = (this.newSocketData.distOnAir/ 1000).toFixed(2) ;
      this.playerData.flyTime = this.newSocketData.tmOnAir;
      return this.playerData;
    },

    equipmentInfo() {
      return this.wsData.equipmentInfo || {};
    },

    flyOperatorName() {
      if (this.task && this.task.useIot === 1) {
        return this.newSocketData.flyOperatorName;
      }
      return this.wsData.flyOperatorName || '';
    },

    minuteFormate() {
      if (!this.store.flyTime) return "00:00";
      var hours = Math.floor(this.store.flyTime / 3600);
      var minutes = Math.floor((this.store.flyTime - (hours * 3600)) / 60);
      var remainingSeconds = this.store.flyTime - (hours * 3600) - (minutes * 60);

      if (hours < 10) {
        hours = "0" + hours;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (remainingSeconds < 10) {
        remainingSeconds = "0" + remainingSeconds;
      }

      return hours + ":" + minutes + ":" + remainingSeconds;
    },

    flyDistanceFormat() {
      return this.store.flyDistanceFormat
    //   if (!this.store.flyDistance) return "0";
    //   if (this.store.flyDistance >= 1000 * 100){
    //     //  大于等于 1000 * 100 米，直接显示米数
    //     this.flyDistanceUnit = 1;
    //     return parseInt(this.store.flyDistance / 1000);
    //   } else if (this.store.flyDistance >= 1000) {
    //     //大于等于 1000 米，将距离转换为千米
    //     this.flyDistanceUnit = 1;
    //     return (this.store.flyDistance / 1000).toFixed(1);
    //  } else {
    //     //小于 1000 米，直接显示米数 
    //     return this.store.flyDistance;
    //   }
    },
  },

  methods: {

    /**
     * 初始化随动框字段
     */
    initPopupLabels(taskSelect) {
      //获取字典
      getDict({
        taskId: taskSelect,
      }).then((res) => {
        const datas = JSON.parse(res.data.data.cardJson);
        const card = datas.cards.cardContent.find((x) => {
          return x.vslType === 3;
        });
        const items = card.items[0].items;
        for (let i = 0; i < items.length; i += 1) {
          const path = items[i].def.uri.split("/");
          this.popupLabels.push({
            packAlias: items[i].def.uri,
            name: items[i].def.nameI18n,
            pack: path[0],
            key: path[1],
            unit: items[i].def.unit,
            value: 0,
          });
        }
      });
    },

    handleChange(value) {
      const prevTaskSelect = getStore({ name: "prevTaskSelect" }) || "";
      this.taskSelect = value;
      // 触发自定义事件，并传递参数
      const data = {
        value: value,
        taskSelect: prevTaskSelect
      };
      localStorage.setItem('prevTaskSelect', value); // 将选择值保存到本地存储
      this.$emit('handleChildClick', data);
    }

  }
};
</script>
<style scoped lang="scss">
.Box {
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .plane-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .plane-code {
    flex: 1;

    &>.el-row {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .plane-card {
    flex: 2;
  }

  .topinfo {
    display: flex;
    justify-content: space-between;
  }

  .top1 {
    margin-top: 12px;
    background-color: rgba(0, 0, 0, 0.29);
    border-radius: 3px;
    width: 30%;
    margin-right: 6px;

    ::v-deep .el-input__inner {
      color: #ffffff;
      background-color: #041C37;
      border-color: #245DC7;
    }
  }

  .top2 {
    margin-top: 12px;
    background-color: rgba(0, 0, 0, 0.29);
    border-radius: 3px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .left {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 30px;
        margin-right: 5px;
      }
      line-height: 100%;
      font-weight: bold;
      color: #ffffff;
      font-size: 12px;
      padding-left: 12px;
    }
    .right {
      text-align: end;
      font-weight: bold;
      color: #84c0ff;
      font-size: 20px;
      padding-right: 12px;
    }
  }

  .bottom {
    margin-top: 12px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    .el-row {
      flex: 1;
    }

    .col {
      width: 49%;
      background-color: rgba(0, 0, 0, 0.29);
      border-radius: 3px;
      display: flex;
      align-items: center;

      .left {
        text-align: start;
        font-weight: bold;
        color: #ffffff;
        font-size: 12px;
        padding-left: 12px;
      }

      .right {
        text-align: end;
        font-weight: bold;
        color: #84c0ff;
        font-size: 26px;
        padding-right: 12px;

        span {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }

  .bodyBox {
    font-size: 12px;
    margin-top: 12px;
    font-weight: bold;

    .elColLeft {
      text-align: end;
      overflow: hidden;
      white-space: nowrap;
      color: #ffffff;
    }

    .elColRight {
      text-align: start;
      color: #84c0ff;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.imgUavInfo {
  width: 30px;
}

.flex {
  display: flex;
  align-items: center;

  &>.left>.el-row {
    display: flex;
    align-items: center;
  }
}
</style>
