<!--
 * @Description:
 * @Autor: wq
 * @Date: 2021-06-25 16:26:36
 * @watermark: 成都沃飞长空
 * @LastEditors: wq
-->

<template>
  <div class="cesium-div" id="screen_map_box">
    <div id="cesiumContainer_map_r3"></div>
    <div v-if="hasBtn && showTopTools" class="full-screen"
      @click="tiggerFullScreen" title="全屏">
      <img class="full-image" src="../../../assets/map/full.png" />
    </div>
    <div class="info" v-if="!moveAble">数据接收中，地图可缩放但不允许拖动</div>
    <div @click="updateRoute($options.map3d, task.id)" class="route-line" v-if="showTopTools">
      <i class="el-icon-refresh-left"></i>
      <span style="margin-left:2px">刷新</span>
    </div>
    <div class="route-task-line" v-if="showTopTools">
       <el-checkbox
        @change="showHiddenRoute"
        v-model="isShowRoute"><span class="Violet-line"></span>任务航线</el-checkbox>
    </div>
    <div v-if="hoverTip.show"
      :style="`
        left: ${hoverTip.left}px;
        top: ${hoverTip.top}px;
        transform: translateY(calc(-100% - ${hoverTip.type===NodeMap.Node.POINT? 40: 18}px)) translateX(-50%);
      `"
      class="hover-tip">
      <div class="hover-tip-triangle"></div>
      <div class="hover-tip-item" v-for="item in hoverTip.list" :key="item.label">
        <span>{{ item.label }}: </span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <!-- 基站悬浮窗 -->
    <BaseStationPop ref="BaseStationPop" :detailData="baseStationDetail" />
    <!-- 飞机实时信息浮窗 -->
    <UavRealInfoPop ref="UavRealInfoPop" v-if="isEmergency" />
  </div>
</template>

<script>
import Map3d from "@/components/cesium/js/Map3d";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import { wgsArr2cartesianArr } from "@/util/mapUtils";
import { mapGetters } from "vuex";
import PlaneIcon from "@/assets/img/map/XC_64x64_.png";
import HomeIcon from "../../../assets/map/homePoint.png";
import transformTranslate from "@turf/transform-translate";
import baseLine from "./line.json";
import { getInspectionObjects, getMarkObjects } from "@/api/project/label";
import { getMarkPoint, getMarkPolyline, getMarkPolygon } from "@/api/project/map";
import { findEquipmentModel } from "@/api/map/index"
import {getHangarList} from '@/api/hangar/index';
import NodeMap from '@/components/cesium/js/node/index';
import BaseLayer2 from "@/components/cesium/js/BaseLayer";
import greenLandProvider from '@/components/cesium/mixins/greenLandProvider.vue';
import routeLine from '@/components/cesium/mixins/routeLine.vue';
import oilInspectionVue from '@/components/cesium/mixins/oilInspection.vue';
import noFlyZoneShowVue from "@/components/cesium/mixins/noFlyZoneShow.vue";
import airportShowVue from "@/components/cesium/mixins/airportShow.vue";
import basestationShowVue from "@/components/cesium/mixins/basestationShow.vue";
import UavRealInfoPop from "@/components/cesium/components/uav-realInfo-pop.vue";
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';
import userHangarHook from '@/components/cesium/js/hooks/hangarHook';

export default {
  name: "Map",
  mixins: [ greenLandProvider, routeLine, oilInspectionVue, noFlyZoneShowVue, airportShowVue, basestationShowVue ],
  components: { UavRealInfoPop },
  data() {
    return {
      NodeMap,
      intervalHandler: null,
      map: null,
      planes: [],
      alarms: [],
      moveAble: true,
      satellite: null,
      road: null,
      isSatellite: true,
      isNormalMap: true,
      viewModel: Cesium.SceneMode.SCENE3D,
      flylineLayer: null,
      follow: false,
      inspectionLayer: null,
      chooseEquipmentId: '',
      // 标注图层
      markLayer: null,
      PlaneIcon,
      equipmentData: ''
    };
  },
  props: {
    projectInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    mapData: {
      type: Object
    },
    hasBtn: {
      type: Boolean,
      default: true
    },
    // 是否为应急
    isEmergency: {
      type: Boolean,
      default: false
    },
    // 是否展示右侧顶部操作栏
    showTopTools: {
      type: Boolean,
      default: true
    },
    // 是否展示右侧底部操作栏
    showBottomTools: {
      type: Boolean,
      default: true
    },
    // 切换飞机是否需要更改实体样式
    isChangeStyle: {
      type: Boolean,
      default: false
    },
    // 是否需要展示飞机跟随tips
    showMapTips: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'task'
    ]),
  },
  watch: {
    mapData: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        if (!val) {
          return;
        }
        this.updatePlane(val);
      },
      deep: true, //true 深度监听
    },
    task() {
      this.task && this.getEquipmentModel();
    }
  },
  mounted() {
    this.$options.flyRouteHook = liveFlyRouteHook();
    this.$options.hangarHook = userHangarHook();
    this.init();
  },
  methods: {
    init() {
      const viewer = new Map3d("cesiumContainer_map_r3", {
        sceneMode: this.viewModel,
      });
      this.$options.map3d = viewer;
      this.initLayer();
      this.getNoFlyDataTwo(); // 加载禁飞区
      this.getAirportData(); // 加载机场
      this.getBasestationData(); // 加载基站
      this.getHangarData(); // 获取加载机库列表
      this.requestInspectLabel(this.$route.query.flyId)
      this.isEmergency && this.requestEmergencyMakerLabel(this.$route.query.projectId)
      this.setEditModel()
      this.initGreenLand(this.$options.map3d)
      setTimeout(() => {
        if (this.task && this.task.id) {
          this.initRouteLine(this.$options.map3d, this.task.id)
        }
      }, 1000);
    },

    // 重新获取飞机信息
    getEquipmentModel() {
      let _this = this;
      findEquipmentModel({sn: this.task.airPlaneSn}).then(res => {
        if (res.data.code === 200) {
          const data = res.data.data;
          if (data) {
            this.equipmentData = data;
            this.PlaneIcon = data.modelIcon || this.PlaneIcon;
            setTimeout(() => {
              const id = _this.task.equipmentItem.airs.equipmentId;
              _this.$options.flyRouteHook.changeEntityStyle(id, this.equipmentData.modelIcon || this.PlaneIcon, "#FFE600");
            }, 2500);
          }
        }
      });
    },

    // 初始化图层
    initLayer() {
      this.lineLayer = new BaseLayer({ name: "lineLayer" });
      this.$options.flyRouteHook.setMap({map3d: this.$options.map3d, flylineLayerID: "flylineLayer",
        showVisualPolygon: true, showHomePoint: true});
      this.initNoflyShowLayer();
      this.initAirportShowLayer();
      this.initBasestationShowLayer();
      this.$options.hangarHook.setMap({map3d: this.$options.map3d, layerID: 'hangarLayer'});
      this.$options.map3d.layerManager.add(this.lineLayer);
      this.inspectionLayer = new BaseLayer2({ name: "inspectionLayer" });
      this.$options.map3d.layerManager.add(this.inspectionLayer);
      this.markLayer = new BaseLayer({ name: "markLayer" });
      this.$options.map3d.layerManager.add(this.markLayer);
    },

    loadBaseLine() {
      const temp = JSON.parse(JSON.stringify(baseLine));
      const lineRight = transformTranslate(temp.geometry, 50 / 1000, 0);
      const lineLeft = transformTranslate(temp.geometry, 50 / 1000, 180);

      const car3 = wgsArr2cartesianArr(temp.geometry.coordinates);
      const lineCenter = new Cesium.Entity({
        id: "lineCenter",
        polyline: {
          positions: new Cesium.CallbackProperty(function () {
            return car3;
          }, true),
          material: new Cesium.Color.fromCssColorString("#FF0000"),
          width: 3,
        },
      });

      const car3right = wgsArr2cartesianArr(lineRight.coordinates);
      const lineRightEntity = new Cesium.Entity({
        id: "lineRight",
        polyline: {
          positions: new Cesium.CallbackProperty(function () {
            return car3right;
          }, true),
          material: new Cesium.Color.fromCssColorString("#0000FF"),
          width: 3,
        },
      });

      const car3Left = wgsArr2cartesianArr(lineLeft.coordinates);
      const lineLeftEntity = new Cesium.Entity({
        id: "lineLeft",
        polyline: {
          positions: new Cesium.CallbackProperty(function () {
            return car3Left;
          }, true),
          material: new Cesium.Color.fromCssColorString("#0000FF"),
          width: 3,
        },
      });

      this.lineLayer.add(lineCenter);
      this.lineLayer.add(lineRightEntity);
      this.lineLayer.add(lineLeftEntity);
      this.$options.map3d.zoomTo(lineCenter);
    },

    tiggerFullScreen() {
      this.$emit("fullScreen");
    },

    updateFlyRoutes(data, equipmentInfo, uavDataDTO) {
      const id = (equipmentInfo && equipmentInfo.equipmentItem) ? equipmentInfo.equipmentItem.airs.equipmentId
        : this.task.equipmentItem.airs.equipmentId;
      const { longitude, latitude, uavYaw } = data || {};
      const { homeLongitude, homeLatitude } = uavDataDTO;
      this.$options.flyRouteHook.findEntityById({id, showEntityLabel: true, entityLabelText: equipmentInfo.equipmentName,
        entityIcon: this.PlaneIcon, iconSize: 60, homeIcon: HomeIcon, homeIconSize: 30,
        lineColor: "#FFE600", lineWidth: 3, polygonColor: "#FFE600", polygonAlpha: 0.45, lon: longitude,
        lat: latitude, yaw: uavYaw, homelon: homeLongitude, homelat: homeLatitude, ...data});
      let UavRealInfoPop = this.$refs.UavRealInfoPop;
      if (this.chooseEquipmentId == id && this.isEmergency) {
        // 设置飞行浮窗
        if (UavRealInfoPop && longitude && latitude) {
          const tempLon = Number(longitude) * 1;
          const tempLat = Number(latitude) * 1;
          this.$options.flyRouteHook.addUavRealInfoPop({
            id: 'uav-realInfo-pop',
            parentId: 'screen_map_box',
            positions: [tempLon, tempLat, 0],
            x: 0,
            y: 32
          });
        }
      }
      // 当前飞机信息和上次飞机信息不一致时
      if (this.chooseEquipmentId != id) {
        this.chooseEquipmentId = id;
        if (this.isEmergency) {
          setTimeout(() => {
            if (UavRealInfoPop) {
              UavRealInfoPop.show = true;
            }
          }, 2500);
        }
      }
    },

    updateFlyRoute(data, equipmentInfo, uavDataDTO) {
      const { longitude, latitude, uavYaw } = data;
      const { homeLongitude, homeLatitude } = uavDataDTO;
      const { equipmentName } = equipmentInfo;
      if (!longitude || !latitude) {
        return;
      }
      const uavId = equipmentName;
      const position = Cesium.Cartesian3.fromDegrees(longitude, latitude);
      let HomePosition;
      if (homeLatitude && homeLongitude) {
        HomePosition = Cesium.Cartesian3.fromDegrees(
          Number(homeLongitude),
          Number(homeLatitude)
        );
      }

      let uav = this.flylineLayer.getById(uavId);

      if (!uav) {
        uav = new Cesium.Entity({
          id: uavId,
          position: position,
          billboard: {
            image: this.PlaneIcon,
            width: 64,
            height: 32,
          },
          label: {
            text: uavId,
            font: "12px",
            showBackground: true,
            backgroundColor: new Cesium.Color.fromCssColorString(
              "rgba(0,0,0,0.8)"
            ),
            backgroundPadding: new Cesium.Cartesian2(10, 8),
            pixelOffset: new Cesium.Cartesian2(0, 20),
            verticalOrigin: Cesium.VerticalOrigin.TOP,
            // horizontalOrigin: Cesium.HorizontalOrigin.LEFT
          },
          polyline: {
            positions: [position],
            material: Cesium.Color.fromCssColorString("#FFE600"),
            width: 3.0,
            depthFailMaterial: Cesium.Color.fromCssColorString("#FFE600"),
          },
        });
        this.flylineLayer.add(uav);

        if (HomePosition) {
          const homeMarker = new Cesium.Entity({
            id: `${uavId}-home`,
            position: HomePosition,
            billboard: {
              image: HomeIcon,
            },
          });
          this.flylineLayer.add(homeMarker);
        }

        this.$options.map3d.zoomTo(uav);
        return;
      } else {
        let home = this.flylineLayer.getById(`${uavId}-home`);
        uav.position = position;
        if (home && HomePosition) {
          home.position = HomePosition;
        }
        let old;
        if (!uav.position.getValue) {
          old = uav.polyline.positions;
        } else {
          old = uav.polyline.positions.getValue().slice();
        }
        const all = [];
        for (let i = 0; i < old.length; i++) {
          const temp = old[i];
          if (
            all[all.length - 1] &&
            all[all.length - 1].x == temp.x &&
            all[all.length - 1].y == temp.y &&
            all[all.length - 1].z == temp.z
          ) {
            continue;
          }
          const hp = JSON.parse(JSON.stringify(temp));
          all.push(hp);
        }
        all.push(position);

        uav.billboard.rotation = -Cesium.Math.toRadians(uavYaw || 0);
        uav.polyline.positions = new Cesium.CallbackProperty(function () {
          return all;
        }, false);
        if (this.follow) {
          this.$options.map3d.setCenter([longitude, latitude]);
        }
      }
    },

    updatePlane(data) {
      const { type, data: newData } = data;
      if (type === "PhugiaOS") {
        if (!newData || !newData) {
          return;
        }
        if (newData && Object.keys(newData).length>0) {
          // this.moveAble = false;
          this.updateFlyRoutes(newData, this.$store.state.wsData.equipmentInfo, this.$store.state.wsData.uavDataDTO);
        } else {
          // this.moveAble = true;
        }
      } else {
        this.updateFlyRoutes(newData, this.$store.state.wsData.equipmentInfo, this.$store.state.wsData.uavDataDTO);
      }

      // this.$options.map3d.scene.screenSpaceCameraController.enableTranslate = this.moveAble;
    },

    requestInspectLabel(id) {
      getInspectionObjects({
        projectId: id,
        businessType: 1
      }).then(res=>{
        const code = res.data.code
        if (code === 200) {
          const data = res.data.data
          if (data) {
            this.inspectionPathList = data.map(item=>{
              return {
                label: item.dirName,
                value: item.id
              }
            })
            // 给编辑图层集合添加图层
            this.addInspectLabels(data)
          }
        }
      })
    },

    addInspectLabels (data) {
      this.createInspectionsIntoLayer(data, this.inspectionLayer)
    },

    requestEmergencyMakerLabel(id) {
      if (!id) return;
      getMarkObjects({projectId: id}).then(res => {
        const code = res.data.code;
        if (code === 200) {
          const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];
          this.addEmergencyMakerLabels(data);
        }
      });
    },
    addEmergencyMakerLabels(data) {
      for (let j = 0; j < data.length; j++) {
        const mark = data[j];
        const node = this.createMarkNode(mark);
        this.markLayer.addNode(node);
      }
    },
    createMarkNode(mark) {
      const geojson = mark.content;
      const type = geojson.geometry.type;
      const coordinates = geojson.geometry.coordinates;
      let node;
      if (type === "Point") {
        node = new NodeMap.MarkPoint({
          id: mark.id,
          name: mark.name,
          createBy: mark.createBy,
          show: !!mark.visibility,
          dirId: mark.dirId,
          color: geojson.properties.color,
          isFire: !!geojson.properties.icon,
          position: Cesium.Cartesian3.fromDegrees(...coordinates),
          canEdit: false
        });
      }
      if (type === "LineString") {
        var positions = [];
        for (let k = 0; k < coordinates.length; k++) {
          const coordinate = coordinates[k];
          const position = Cesium.Cartesian3.fromDegrees(...coordinate);
          positions.push(position);
        }
        node = new NodeMap.MarkLine({
          id: mark.id,
          positions,
          dirId: mark.dirId,
          createBy: mark.createBy,
          color: geojson.properties.color,
          show: !!mark.visibility,
          name: mark.name,
          canEdit: false
        });
      }
      if (type === "Polygon") {
        var positions = [];
        for (let k = 0; k < coordinates[0].length - 1; k++) {
          const coordinate = coordinates[0][k];
          const position = Cesium.Cartesian3.fromDegrees(...coordinate);
          positions.push(position);
        }
        node = new NodeMap.MarkPolygon({
          id: mark.id,
          positions,
          dirId: mark.dirId,
          createBy: mark.createBy,
          color: geojson.properties.color,
          show: !!mark.visibility,
          name: mark.name,
          canEdit: false
        });
      }
      return node;
    },
    openScreenMakerHovertip(node) {
      if (!node || !node.id) return;
      let entity = this.markLayer.getById(node.id);
      if (!entity) return;
      let distance = '0m',
        dis,
        suffix,
        horiDistance = 0;
      switch (node.type) {
        case NodeMap.Node.POINT:
          getMarkPoint({id: node.id}).then(res => {
            if (res.data.code === 200) {
              const data = res.data.data;
              this.hoverTip.show = true;
              this.hoverTip.list = [{label: this.$t('name'), value: data.name}];
            }
          });
          break;
        case NodeMap.Node.LINE:
          getMarkPolyline({id: node.id}).then(res => {
            if (res.data.code === 200) {
              const data = res.data.data;
              // 水平距离/周长计算
              horiDistance = entity.horiDistance;
              dis = horiDistance.toFixed(2);
              suffix = 'm';
              if (dis > 1000) {
                dis = (dis / 1000).toFixed(2);
                suffix = 'km';
              }
              distance = dis + suffix;
              this.hoverTip.show = true;
              this.hoverTip.list = [
                {label: this.$t('name'), value: data.name},
                {label: this.$t('mark.horizontalDistance'), value: distance}
              ];
            }
          });
          break;
        case NodeMap.Node.POLYGON:
          getMarkPolygon({id: node.id}).then(res => {
            if (res.data.code === 200) {
              const data = res.data.data;
              // 水平距离/周长计算
              horiDistance = entity.horiDistance;
              dis = horiDistance.toFixed(2);
              suffix = 'm';
              if (dis > 1000) {
                dis = (dis / 1000).toFixed(2);
                suffix = 'km';
              }
              distance = dis + suffix;
              // 水平面积计算
              let area = '0m²';
              let square = data.area;
              if (square && square > 1000 * 1000) {
                area = (square / 1000 / 1000).toFixed(2) + 'km²';
              } else if (square && square <= 1000 * 1000) {
                area = square.toFixed(2)  + 'm²';
              }
              this.hoverTip.show = true;
              this.hoverTip.list = [
                {label: this.$t('name'), value: data.name},
                {label: this.$t('mark.horizontalPerimeter'), value: distance},
                {label: this.$t('mark.horizontalArea'), value: area}
              ];
            }
          });
          break;
      }
    },

    setEditModel(en = true) {
      console.log('当前地图注册的交互事件：', en );
      const self = this;
      this.activeDraw = ''
      if (en === true) {
        this.drawFinish = true;
        this.$options.map3d._container.style.cursor = "default";
      }
      // 绘制完成后需要自动进入编辑模式 同时对部分元素
      let afterFinish =  (model, origin, selectFeature) => {
        if (model._drawType === 'FlyPoint' && this.isEmergency) {
          if (model) {
            if (!model.id) return;
            if (model.id === this.chooseEquipmentId) {
              let UavRealInfoPop = this.$refs.UavRealInfoPop;
              if (UavRealInfoPop && !this.$parent.$parent.taskData) {
                UavRealInfoPop.show = true;
              }
            } else {
              if (this.task && !this.$parent.$parent.taskData) {
                taskItem && this.$emit('changeUav', this.task, true);
              }
            }
          }
        }
        let drawType = selectFeature && selectFeature.drawType
        this.selectedOilNode(selectFeature)
        this.$options.map3d.scene.screenSpaceCameraController.enableTilt = false;
        if (selectFeature && selectFeature.id.indexOf("uav-") > -1) {
          self.clickUav(selectFeature.id);
          return;
        }
        if (!model) {
          return;
        }
        const primitive = model.parent ? model.parent : model;
        const orgId = primitive.orgId;
        drawType = primitive.drawType;
        // 若存在原始id 则代表需要调用 更新接口 而不是新增接口
        if (orgId && drawType !== "flyline") {
          const type = primitive.polygon
            ? "Polygon"
            : primitive.polyline
            ? "LineString"
            : "Point";
          const geojson = labelEntity2geojson(this.$options.map3d, primitive, type);
          const feature = {
            dirId: primitive.editLayer.id,
            geojson: JSON.stringify(geojson),
            tagName: geojson.properties.text,
            tagType: type,
            id: primitive.id,
          };
          // 后台接口弃用----lmw
          // const request = updateMapLabel(feature);
          // request.then((res) => {});
        }
      }
      // 注册完交互事件的对用回调
      this.$options.map3d.triggerEditModel(en, {
        map: this.$options.map3d,
        vueHandler: self,
        afterFinish
      });
    },

    // 获取机库列表并绘制
    getHangarData() {
      const { projectType } = this.$route.query;
      let uavList = [];
      if (projectType === 'emergency') {
        uavList = this.projectInfo ? (this.projectInfo.equipmentIds || []) : [];
      } else {
        uavList = this.projectInfo ? (this.projectInfo.uavList || []) : [];
      }
      this.$options.hangarHook.clearRenderLayer(); // 绘制之前清空一次
      if (uavList.length) {
        getHangarList(uavList).then(res => {
          if (res.data.code == 200) {
            this.$options.hangarHook.handlingHangarData({data: res.data.data || [], type: 'show'});
          }
        });
      }
    }
  },
  beforeDestroy() {
    if (this.intervalHandler) {
      clearInterval(this.intervalHandler);
      this.intervalHandler = null;
    }
    this.$options.flyRouteHook && this.$options.flyRouteHook.clearFlyRoute();
    this.$options.flyRouteHook = null;
    this.$options.hangarHook && this.$options.hangarHook.clearHangar();
    this.$options.hangarHook = null;
    this.$options.map3d && this.$options.map3d.destroy();
  },
};
</script>

<style lang="scss" scoped>
.cesium-div{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .route-line{
    position: absolute;
    top: 15px;
    left: 11px;
    height: 39px;
    width: 56px;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    color: #FFFFFF;
    line-height: 39px;
    padding-left: 5px;
    cursor: pointer;
  }
  .route-task-line{
    position: absolute;
    top: 15px;
    left: 85px;
    height: 39px;
    color: #FFFFFF;
    width: 100px;
    line-height: 39px;
    /deep/ .el-checkbox__label{
      color: #FFFFFF;
    }
    .Violet-line {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 3px;
      background-color: #EC3FF7;
      margin-right: 4px;
    }
  }
  .hover-tip {
    position: absolute;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFFFFF;
    padding: 8px;
    border-radius: 4px;
    &-triangle{
      position: absolute;
      box-sizing: border-box;
      border: 10px solid;
      border-color: transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8) transparent;
      left: 50%;
      top: 100%;
      transform: translateX(-50%)  translateY(-51%) rotateZ(45deg);
    }
    &-item{
      word-wrap: break-word
    }
  }
}
#cesiumContainer_map_r3 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
#linecanvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 10;
}

.nodes {
  position: absolute;
  right: 20px;
  width: 328px;
  height: 98%;
  top: 1%;
}
.cesium-widget-credits {
  display: none !important;
  visibility: hide !important;
}

#navigationHelpButtonContainer {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 10;
  width: 96px;
  height: 96px;
}
.player-controller {
  position: absolute;
  bottom: 2%;
  left: 2%;
  display: flex;
  justify-content: center;
}
.play-btn {
  width: 56px;
  height: 56px;
}

.full-screen {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image {
  width: 100%;
  height: 100%;
}

.info {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  padding: 5px 10px;
}

.flypopup {
  z-index: 2;
}
</style>
