<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-09-24 10:48:36
 * @watermark: 成都沃飞长空
 * @LastEditors: wq
-->
<template>
  <div class="center-content">
    <Modal v-model="mapModalVisible" @close="closeMapModal">
      <template v-slot:content>
        <component
          :is="mapComponent"
          :projectInfo="projectInfo"
          ref="map-modal"
          :mapData="mapData"
          :hasBtn="false"
          class="map-full"
        />
      </template>
    </Modal>
    <div class="map-container">
      <Base class="label-title" :title="$t('screen.UAVMap')">
        <template v-slot:content>
          <component
            ref="map"
            :is="mapComponent"
            :projectInfo="projectInfo"
            @moving="planeMoving"
            @fullScreen="fullScreen"
            :mapData="mapData"
            class="map-div"
          ></component>
        </template>
      </Base>
    </div>
    <div class="video-container">
      <Base title="无人机视频画面">
        <template v-slot:content>
          <div class="video">
            <component
              v-if="liveVideoVOList.length > 0"
              :is="useLive"
              ref="videoLive"
              class="video-plane"
              :screen="false"
              :liveVideoVOList="liveVideoVOList"
            ></component>
            <div
              v-else
              class="video-loading"
              v-loading="true"
              :element-loading-text="$t('WaitingForVideoTransmission')"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
            ></div>
          </div>
        </template>
      </Base>
    </div>
  </div>
</template>

<script>
import Base from "@/components/screen/Base.vue";
import Map from "@/components/screen/map/map-center.vue";
import NewMap from "@/components/screen/map/new-map-pri.vue";
import ParamsLabel from "./params-label.vue";
import Modal from "@/components/screen/modal.vue";
import emptyIcon from "@/assets/images/empty.png";
import { mapGetters } from "vuex";
import VdieoLiveEasyplayer from "@/components/video-live-easyPlayer/index.vue";
import VdieoLiveHWLLS from "@/components/video-live-HWLLS/index.vue";

export default {
  name: "Center",
  components: {
    Map,
    NewMap,
    ParamsLabel,
    Base,
    Modal,
    VdieoLiveEasyplayer,
    VdieoLiveHWLLS
  },
  props: {
    projectInfo: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data() {
    return {
      emptyIcon,
      visible: false,
      mapModalVisible: false,
      distance: 0,
      flyTime: "00:00:00",
      lastTime: "00:00:00",
    };
  },
  computed: {
    ...mapGetters([
      'newSocketData',
      'socketData',
      'task'
    ]),
    mapData(){
      // return (this.task && this.task.useIot === 1)? this.newSocketData: this.socketData
      return this.newSocketData
    },
    store() {
      return this.$store.state;
    },
    wsData() {
      return this.$store.state.wsData;
    },
    liveVideoVOList() {
      return this.task && this.task.liveVideoVOList ? this.task.liveVideoVOList: []
    },
    videoParams() {
      return [
        {
          title: "当前模式",
          value: this.$store.state.wsData.videoUrlGroup.flyMode || "-",
        },
        {
          title: "画面模式",
          value: this.$store.state.wsData.videoUrlGroup.videoFormatName || "-",
        },
        {
          title: "吊舱方位角",
          value: this.$store.state.wsData.videoUrlGroup.sensorYaw
            ? this.$store.state.wsData.videoUrlGroup.sensorYaw + "°"
            : "-",
        },
        {
          title: "吊舱俯仰角",
          value: this.$store.state.wsData.videoUrlGroup.sensorPitch
            ? this.$store.state.wsData.videoUrlGroup.sensorPitch + "°"
            : "-",
        },
      ];
    },
    useLive() {
      return this.task.liveType === 1 ? 'VdieoLiveHWLLS': 'VdieoLiveEasyplayer'
    },
    mapComponent() {
      // return (this.task && this.task.useIot === 1)? 'NewMap': 'Map'
      return 'NewMap'
    }
  },
  methods: {
    toggleFullScreen() {
      this.$refs.video.pause();
      this.visible = true;
    },
    screenshot() {
      var img = document.getElementById("tulip");
      var c = document.getElementById("myCanvas");
      const w = img.clientWidth;
      const h = img.clientHeight;
      c.width = w;
      c.height = h;
      var ctx = c.getContext("2d");
      ctx.drawImage(img, 0, 0, w, h);
      var canvas = document.getElementById("myCanvas");
    },
    close() {
      this.visible = false;
      this.$refs.video.play();
    },
    fullScreen() {
      this.mapModalVisible = true;
    },
    closeMapModal() {
      this.mapModalVisible = false;
    },
    planeMoving(params) {
      this.distance = params.distance;
      this.flyTime = this.formateSeconds(params.time);
      this.lastTime = this.formateSeconds(params.alltime - params.time + 1);
    },
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },
  }
};
</script>

<style lang="scss" scoped>
#myCanvas {
  display: none;
}

.video-loading {
  height: 100%;
  width: 100%;
  background-color: #011a38;
}

::v-deep.el-empty {
  width: 100%;
  height: 100%;

  .el-empty__description {
    display: none;
    margin-top: 10px;

    p {
      color: #237592 !important;
      font-size: 14px !important;
    }
  }

  .el-empty__image {
    img {
      width: 56px;
    }
  }
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;

  .video-modal {
    width: 100%;
    height: 100%;
  }

  .modal-btn {
    background: #abcaff;
    color: #051c3a;
    font-size: 14px;
    font-weight: bold;
    width: 100px;
    border: none;
    height: 25px;
    // line-height: 12px;
    position: absolute;
    left: 50%;
    bottom: -32px;
    transform: translate(-50%, 0%);
    padding: 0;
  }
}

.map-full {
  position: relative;
  width: 100%;
  height: 100%;
}

.center-content {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  height: 100%;
  position: relative;
  // background-image: url('~@/assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .map-container {
    width: 100%;
    height: 50%;
    //padding-top: 10px;
    // position: absolute;
    // z-index: 10;
    // top: 3px;
    // left: 13px;
    color: #fff;
    display: flex;
    margin-bottom: 27px;

    // padding: 6px;
    .base {
      background-image: url("~@/assets/images/map.png");
    }
  }
}

.params-label-item {
  flex: 2;
  margin-bottom: 5%;
}

.video-container {
  border-radius: 8px;
  width: 100%;
  height: 50%;
  display: flex;
  // padding: 6px;
  ::v-deep.content {
    display: flex;
  }

  .base {
    background-image: url("~@/assets/images/map.png");
  }

  .video {
    flex: 4;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 6px;
    z-index: 0;
    box-sizing: border-box;
    overflow: hidden;
  }

  .video-dom {
    position: absolute;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    left: 4px;
    right: 4px;
    bottom: 4px;
    top: 4px;
  }

  .video-label {
    // background-color: #ffffff;
    height: 100%;
    width: 120px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-around;
    padding: 20px 6px 20px 6px;

    .btns {
      display: flex;
      flex-direction: column;

      // height: 66px;
      .btn-shot {
        width: 100%;
        margin: 0;
        background: #4382f0;
        color: #fff;
        border: none;
      }

      .btn-full {
        margin: 0;
        background: #abcaff;
        color: #051c3a;
        border: none;
        margin: 0 0 6px 0;
      }
    }

    .params {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      // padding: 20px 0;
      li {
        display: flex;
        width: 99px;
        height: 74px;
        background: #011a38;
        border-radius: 3px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p:nth-of-type(1) {
          font-size: 16px;
          font-weight: bold;
          color: #84c0ff;
        }

        i {
          margin: 6px 0 6px 0;
          width: 75px;
          height: 1px;
          opacity: 0.26;
          border: 1px solid;
          border-image: linear-gradient(
              129deg,
              rgba(206, 225, 255, 1),
              rgba(13, 42, 89, 1)
            )
            1 1;
        }

        p:nth-of-type(2) {
          font-size: 12px;
          color: #ffffff;
        }
      }
    }
  }
}

.label-title {
  flex: 1;
}

.video-plane{
  height: 100%;
}

.map-div {
  // padding: 20px;
  z-index: 0;
  border-radius: 10px;
  background: #fff;
  position: absolute;
  // width: 100%;
  // height: 100%;
  overflow: hidden;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  // padding: 10px;
}
</style>
