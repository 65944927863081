var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "center-content" },
    [
      _c("Modal", {
        on: { close: _vm.closeMapModal },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(_vm.mapComponent, {
                  ref: "map-modal",
                  tag: "component",
                  staticClass: "map-full",
                  attrs: {
                    projectInfo: _vm.projectInfo,
                    mapData: _vm.mapData,
                    hasBtn: false,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.mapModalVisible,
          callback: function ($$v) {
            _vm.mapModalVisible = $$v
          },
          expression: "mapModalVisible",
        },
      }),
      _c(
        "div",
        { staticClass: "map-container" },
        [
          _c("BaseBox", {
            staticClass: "label-title",
            staticStyle: { padding: "2px" },
            attrs: { bigBGIMG: true, title: _vm.$t("screen.UAVMap") },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(_vm.mapComponent, {
                      ref: "map",
                      tag: "component",
                      attrs: {
                        projectInfo: _vm.projectInfo,
                        mapData: _vm.mapData,
                      },
                      on: {
                        moving: _vm.planeMoving,
                        fullScreen: _vm.fullScreen,
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }