var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "droneLedger-page" }, [
    _c("i", { staticClass: "el-icon-close", on: { click: _vm.closeLedger } }),
    _c(
      "div",
      { staticClass: "panle-table" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.uavTableData, height: "100%" },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "manufacturerName",
                align: "center",
                "show-overflow-tooltip": "",
                label: _vm.$t("oilGasScreen.brand"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "equipmentName",
                align: "center",
                "show-overflow-tooltip": "",
                label: _vm.$t("oilGasScreen.droneName"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "equipmentType",
                align: "center",
                "show-overflow-tooltip": "",
                label: _vm.$t("oilGasScreen.droneType"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "equipmentNumber",
                align: "center",
                "show-overflow-tooltip": "",
                label: _vm.$t("oilGasScreen.droneModel"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "airportName",
                align: "center",
                "show-overflow-tooltip": "",
                label: _vm.$t("oilGasScreen.belongingAirport"),
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "equipmentStatus",
                align: "center",
                "show-overflow-tooltip": "",
                label: _vm.$t("oilGasScreen.state"),
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }