var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "login",
      staticClass: "login-container",
      nativeOn: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.handleLogin($event)
        },
      },
    },
    [
      _c("top-color", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      }),
      _vm.type === "login" || _vm.type === "register"
        ? _c("div", { class: _vm.loginBoxClass }, [
            _vm._m(0),
            _c("div", { staticClass: "login-border" }, [
              _vm.type === "login"
                ? _c(
                    "div",
                    { staticClass: "login-main" },
                    [
                      _c("div", { staticClass: "login-way" }, [
                        _c(
                          "span",
                          {
                            class: [
                              "ways",
                              _vm.activeWays === "person" ? "ways-active" : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.changeActive("person")
                              },
                            },
                          },
                          [_vm._v("个人用户")]
                        ),
                      ]),
                      _vm.activeName === "user"
                        ? _c("userLogin", {
                            on: {
                              goFlow: _vm.getFlow,
                              firstLogin: _vm.firstLogin,
                            },
                          })
                        : _vm.activeName === "code"
                        ? _c("codeLogin")
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.type === "register"
                ? _c(
                    "div",
                    { staticClass: "login-main" },
                    [_c("Register", { on: { goFlow: _vm.getFlow } })],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.type === "forget"
        ? _c(
            "div",
            { class: _vm.forgetBoxClass },
            [_c("ForgetAccount", { on: { goFlow: _vm.getFlow } })],
            1
          )
        : _vm._e(),
      _vm.type === "first_login"
        ? _c(
            "div",
            { class: _vm.firstBoxClass },
            [_c("FirstLogin", { on: { goFlow: _vm.getFlow } })],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "login-version" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.version.systemInfo) } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-left" }, [
      _c("img", {
        staticClass: "main-title--zh",
        attrs: { src: "/img/login/phugia-cloud-zh.png", alt: "" },
      }),
      _c("img", {
        staticClass: "main-pic",
        attrs: { src: "/img/logo.png", alt: "" },
      }),
      _c("img", {
        staticClass: "main-slogan",
        attrs: { src: "/img/login/slogan.png", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }