<!--
 * @Description:
 * @Autor: wq
 * @Date: 2021-06-25 16:26:36
 * @watermark: 成都沃飞长空
 * @LastEditors: wq
-->

<template>
  <div class="cesium-div">
    <div id="cesiumContainer_new_map_center"></div>
    <div
      v-if="hasBtn"
      class="full-screen"
      @click="tiggerFullScreen"
      title="全屏"
    >
      <img class="full-image" src="../../../assets/map/full.png" />
    </div>
    <div
      class="track-screen"
      @click="track"
      :title="tracked? `解除跟随`: `跟随`"
    >
      <img class="full-image" :src="tracked? track_activeIcon: trackIcon" />
    </div>
    <div class="info" v-if="!moveAble">数据接收中，地图可缩放但不允许拖动</div>
    <div @click="updateRoute($options.map3d, task.id)" class="route-line">
      <i class="el-icon-refresh-left"></i>
      <span style="margin-left:2px">刷新</span>
    </div>
    <div class="route-task-line">
      <el-checkbox v-model="showRouteLine">
        <span class="Violet-line"></span>任务航线</el-checkbox>
    </div>
    <div v-if="hoverTip.show"
      :style="`
        left: ${hoverTip.left}px;
        top: ${hoverTip.top}px;
        transform: translateY(calc(-100% - ${hoverTip.type===NodeMap.Node.POINT? 40: 18}px)) translateX(-50%);
      `"
      class="hover-tip">
      <div class="hover-tip-triangle"></div>
      <div class="hover-tip-item" v-for="item in hoverTip.list" :key="item.label">
        <span>{{ item.label }}: </span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <!-- 基站悬浮窗 -->
    <BaseStationPop ref="BaseStationPop" :detailData="baseStationDetail" />
  </div>
</template>

<script>
import Map3d from "@/components/cesium/js/Map3d";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import { wgsArr2cartesianArr } from "@/util/mapUtils";
import PlaneIcon from "@/assets/img/map/XC_64x64_.png";
import HomeIcon from "../../../assets/map/homePoint.png";
import transformTranslate from "@turf/transform-translate";
import { mapGetters } from "vuex";
import baseLine from "./line.json";
import { getInspectionObjects } from "@/api/project/label";
import NodeMap from '@/components/cesium/js/node/index';
import BaseLayer2 from "@/components/cesium/js/BaseLayer";
import greenLandProvider from '@/components/cesium/mixins/greenLandProvider.vue';
import routeLine from '@/components/cesium/mixins/routeLine.vue';
import trackIcon from '../../../assets/map/track.png'
import track_activeIcon from '../../../assets/map/track_active.png'
import oilInspectionVue from '@/components/cesium/mixins/oilInspection.vue';
import noFlyZoneShowVue from "@/components/cesium/mixins/noFlyZoneShow.vue";
import airportShowVue from "@/components/cesium/mixins/airportShow.vue";
import basestationShowVue from "@/components/cesium/mixins/basestationShow.vue";
// 无人机图标
import { findEquipmentModel } from "@/api/map/index"
import {getHangarList} from '@/api/hangar/index';
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';
import userHangarHook from '@/components/cesium/js/hooks/hangarHook';

export default {
  name: "Map",
  mixins: [ greenLandProvider, routeLine, oilInspectionVue, noFlyZoneShowVue, airportShowVue, basestationShowVue ],
  data() {
    return {
      NodeMap,
      intervalHandler: null,
      map: null,
      planes: [],
      alarms: [],
      moveAble: true,
      satellite: null,
      road: null,
      isSatellite: true,
      isNormalMap: true,
      viewModel: Cesium.SceneMode.SCENE3D,
      follow: false,
      inspectionLayer: null,
      tracked: true,
      sn: null,
      chooseEquipmentId: '',
      trackIcon,
      track_activeIcon,
      PlaneIcon,
      equipmentData: ''
    };
  },
  props: {
    projectInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    mapData: {
      type: Object,
    },
    hasBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters([
      'task'
    ]),
  },
  watch: {
    mapData: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        if (!val) {
          return;
        }
        this.updatePlane(val);
      },
      deep: true, //true 深度监听
    },
    task() {
      this.task && this.getEquipmentModel();
    }
  },
  mounted() {
    this.$options.flyRouteHook = liveFlyRouteHook();
    this.$options.hangarHook = userHangarHook();
    this.init();
  },
  methods: {
    init() {
      let createMap = () => {
        const viewer = new Map3d("cesiumContainer_new_map_center", {
          sceneMode: this.viewModel,
        });
        this.$options.map3d = viewer;
        this.initLayer();
        this.getNoFlyDataTwo(); // 加载禁飞区
        this.getAirportData(); // 加载机场
        this.getBasestationData(); // 加载基站
        this.getHangarData(); // 获取加载机库列表
        this.requestInspectLabel(this.$route.query.flyId)
        this.setEditModel()
        this.initGreenLand()
        setTimeout(() => {
          if (this.task && this.task.id) {
            this.initRouteLine(this.$options.map3d, this.task.id)
          }
        }, 1000);
      }
      if (!this.task.airPlaneSn) {
        createMap();
        return;
      }
      findEquipmentModel({
        sn: this.task.airPlaneSn
      }).then(res=>{
        if (res.data.code === 200) {
          const data = res.data.data
          if (data) {
            this.equipmentData = data;
            this.PlaneIcon = data.modelIcon || this.PlaneIcon;
          }
        }
      }).finally(()=>{
        createMap();
      })
    },
    // 重新获取飞机信息
    getEquipmentModel() {
      let _this = this;
      findEquipmentModel({sn: this.task.airPlaneSn}).then(res => {
        if (res.data.code === 200) {
          const data = res.data.data;
          if (data) {
            this.equipmentData = data;
            this.PlaneIcon = data.modelIcon || this.PlaneIcon;
            setTimeout(() => {
              const id = _this.task.equipmentItem.airs.equipmentId;
              _this.$options.flyRouteHook.changeEntityStyle(id, this.equipmentData.modelIcon || this.PlaneIcon, "#FFE600");
            }, 2500);
          }
        }
      });
    },

    // 初始化图层
    initLayer() {
      this.lineLayer = new BaseLayer({ name: "lineLayer" });
      this.$options.flyRouteHook.setMap({map3d: this.$options.map3d, flylineLayerID: "flylineLayer",
        showVisualPolygon: true, showHomePoint: true, flightFollowing: true});
      this.initNoflyShowLayer();
      this.initAirportShowLayer();
      this.initBasestationShowLayer();
      this.$options.hangarHook.setMap({map3d: this.$options.map3d, layerID: 'hangarLayer'});
      this.$options.map3d.layerManager.add(this.lineLayer);
      this.inspectionLayer = new BaseLayer2({ name: "inspectionLayer" });
      this.$options.map3d.layerManager.add(this.inspectionLayer);
    },

    loadBaseLine() {
      const temp = JSON.parse(JSON.stringify(baseLine));
      const lineRight = transformTranslate(temp.geometry, 50 / 1000, 0);
      const lineLeft = transformTranslate(temp.geometry, 50 / 1000, 180);
      const car3 = wgsArr2cartesianArr(temp.geometry.coordinates);
      const lineCenter = new Cesium.Entity({
        id: "lineCenter",
        polyline: {
          positions: new Cesium.CallbackProperty(function () {
            return car3;
          }, true),
          material: new Cesium.Color.fromCssColorString("#FF0000"),
          width: 3,
        },
      });
      const car3right = wgsArr2cartesianArr(lineRight.coordinates);
      const lineRightEntity = new Cesium.Entity({
        id: "lineRight",
        polyline: {
          positions: new Cesium.CallbackProperty(function () {
            return car3right;
          }, true),
          material: new Cesium.Color.fromCssColorString("#0000FF"),
          width: 3,
        },
      });
      const car3Left = wgsArr2cartesianArr(lineLeft.coordinates);
      const lineLeftEntity = new Cesium.Entity({
        id: "lineLeft",
        polyline: {
          positions: new Cesium.CallbackProperty(function () {
            return car3Left;
          }, true),
          material: new Cesium.Color.fromCssColorString("#0000FF"),
          width: 3,
        },
      });
      this.lineLayer.add(lineCenter);
      this.lineLayer.add(lineRightEntity);
      this.lineLayer.add(lineLeftEntity);
      this.$options.map3d.zoomTo(lineCenter);
    },

    tiggerFullScreen() {
      this.$emit("fullScreen");
    },

    updateFlyRoute(data) {
      const id = this.task.equipmentItem.airs.equipmentId;
      if (this.chooseEquipmentId != id) {
        // 飞机不一致时先清空当前飞行图层
        this.$options.flyRouteHook.clearFlylineLayer();
      }
      this.$options.flyRouteHook.findEntityById({id, showEntityLabel: true, entityLabelText: this.task.equipmentName,
        entityIcon: this.PlaneIcon, iconSize: 60, homeIcon: HomeIcon, homeIconSize: 30,
        lineColor: "#FFE600", lineWidth: 3, polygonColor: "#FFE600", polygonAlpha: 0.45, data});
      // 当前飞机信息和上次飞机信息不一致时
      if (this.chooseEquipmentId != id) {
        this.chooseEquipmentId = id;
        setTimeout(() => {
          this.tracked = this.$options.flyRouteHook.setFlightFollowingById(this.chooseEquipmentId, true);
        }, 2500);
      }
    },

    updatePlane(data) {
      this.updateFlyRoute(data);
    },

    requestInspectLabel(id) {
      if (!id) return;
      getInspectionObjects({
        projectId: id,
        businessType: 1
      }).then(res=>{
        const code = res.data.code
        if (code === 200) {
          const data = res.data.data
          if (data) {
            this.inspectionPathList = data.map(item=>{
              return {
                label: item.dirName,
                value: item.id
              }
            })
            // 给编辑图层集合添加图层
            this.addInspectLabels(data)
          }
        }
      })
    },

    addInspectLabels (data) {
      this.createInspectionsIntoLayer(data, this.inspectionLayer)
    },

    track() {
      this.tracked = this.$options.flyRouteHook.changeFlightFollowing(this.task.equipmentItem.airs.equipmentId);
    },

    setEditModel(en = true) {
      console.log('当前地图注册的交互事件：', en );
      const self = this;
      this.activeDraw = ''
      if (en === true) {
        this.drawFinish = true;
        this.$options.map3d._container.style.cursor = "default";
      }
      // 绘制完成后需要自动进入编辑模式 同时对部分元素
      let afterFinish =  (model, origin, selectFeature) => {
        let drawType = selectFeature && selectFeature.drawType
        this.selectedOilNode(selectFeature)
        this.$options.map3d.scene.screenSpaceCameraController.enableTilt = false;
        if (selectFeature && selectFeature.id.indexOf("uav-") > -1) {
          self.clickUav(selectFeature.id);
          return;
        }
        if (!model) {
          return;
        }
        const primitive = model.parent ? model.parent : model;
        const orgId = primitive.orgId;
        drawType = primitive.drawType;
        // 若存在原始id 则代表需要调用 更新接口 而不是新增接口
        if (orgId && drawType !== "flyline") {
          const type = primitive.polygon
            ? "Polygon"
            : primitive.polyline
            ? "LineString"
            : "Point";
          const geojson = labelEntity2geojson(this.$options.map3d, primitive, type);
          const feature = {
            dirId: primitive.editLayer.id,
            geojson: JSON.stringify(geojson),
            tagName: geojson.properties.text,
            tagType: type,
            id: primitive.id,
          };
          // 后台接口弃用----lmw
          // const request = updateMapLabel(feature);
          // request.then((res) => {});
        }
      }
      // 注册完交互事件的对用回调
      this.$options.map3d.triggerEditModel(en, {
        map: this.$options.map3d,
        vueHandler: self,
        afterFinish
      });
    },
    // 获取机库列表并绘制
    getHangarData() {
      const { projectType } = this.$route.query;
      let uavList = [];
      if (projectType === 'emergency') {
        uavList = this.projectInfo ? (this.projectInfo.equipmentIds || []) : [];
      } else {
        uavList = this.projectInfo ? (this.projectInfo.uavList || []) : [];
      }
      this.$options.hangarHook.clearRenderLayer(); // 绘制之前清空一次
      if (uavList.length) {
        getHangarList(uavList).then(res => {
          if (res.data.code == 200) {
            this.$options.hangarHook.handlingHangarData({data: res.data.data || [], type: 'show'});
          }
        });
      }
    }
  },
  beforeDestroy() {
    if (this.intervalHandler) {
      clearInterval(this.intervalHandler);
      this.intervalHandler = null;
    }
    this.$options.flyRouteHook && this.$options.flyRouteHook.clearFlyRoute();
    this.$options.flyRouteHook = null;
    this.$options.hangarHook && this.$options.hangarHook.clearHangar();
    this.$options.hangarHook = null;
    this.$options.map3d && this.$options.map3d.destroy();
  }
};
</script>

<style lang="scss" scoped>
.cesium-div{
  position: relative;
  .route-line{
    position: absolute;
    top: 15px;
    left: 11px;
    height: 39px;
    width: 56px;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    color: #FFFFFF;
    line-height: 39px;
    padding-left: 5px;
    cursor: pointer;
  }
  .route-task-line{
    position: absolute;
    top: 15px;
    left: 85px;
    height: 39px;
    color: #FFFFFF;
    width: 100px;
    line-height: 39px;
    /deep/ .el-checkbox__label{
      color: #FFFFFF;
    }
    .Violet-line {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 3px;
      background-color: #EC3FF7;
      margin-right: 4px;
    }
  }
  .hover-tip {
    position: absolute;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFFFFF;
    padding: 8px;
    border-radius: 4px;
    &-triangle{
      position: absolute;
      box-sizing: border-box;
      border: 10px solid;
      border-color: transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8) transparent;
      left: 50%;
      top: 100%;
      transform: translateX(-50%)  translateY(-51%) rotateZ(45deg);
    }
    &-item{
      word-wrap: break-word
    }
  }
}
#cesiumContainer_new_map_center {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
#linecanvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 10;
}

.nodes {
  position: absolute;
  right: 20px;
  width: 328px;
  height: 98%;
  top: 1%;
}
.cesium-widget-credits {
  display: none !important;
  visibility: hide !important;
}

#navigationHelpButtonContainer {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 10;
  width: 96px;
  height: 96px;
}
.player-controller {
  position: absolute;
  bottom: 2%;
  left: 2%;
  display: flex;
  justify-content: center;
}
.play-btn {
  width: 56px;
  height: 56px;
}

.full-screen {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.track-screen {
  cursor: pointer;
  position: absolute;
  top: 56px;
  right: 10px;
  width: 36px;
  height: 36px;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
}

.full-image {
  width: 100%;
  height: 100%;
}

.info {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  padding: 5px 10px;
}

.flypopup {
  z-index: 2;
}
</style>
