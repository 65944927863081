<template>
  <div class="rightWrap">
    <div v-if="!isOther" class="duty">
      <Base title="值班值守">
        <template v-slot:content>
          <Duty :detail="detail"></Duty>
        </template>
      </Base>
    </div>
    <div class="today" :class="isOther? 'hide-Duty':''">
      <Base title="当日报警统计">
        <template v-slot:content>
          <Today style="margin-bottom: 10px"></Today>
        </template>
      </Base>
    </div>
    <div class="trend">
      <Base title="报警趋势">
        <template v-slot:content>
          <Trend></Trend>
        </template>
      </Base>
    </div>
  </div>
</template>

<script>
import Duty from "./duty.vue";
import Trend from "./trend.vue";
import Base from "@/components/screen/Base.vue";
import Today from "./today.vue";
export default {
  components: { Base, Duty, Trend, Today },
  created(){
    if (this.detail.screenVO && this.detail.screenVO.route == 'forest') {
      this.isOther = false
    }
  },
  props: {
    detail: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  mounted() {},
  beforeDestroy() {
    this.timmer = null;
  },
  data() {
    return {
      Player: null,
      urls: [],
      url: "",
      isOther: true
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.rightWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .duty {
    height: 15%;
    margin-bottom: 27px;
    .base {
      background-image: url("~@/assets/images/duty.png");
    }
  }
  .today {
    height: 20%;
    margin-bottom: 27px;
    .base {
      background-image: url("~@/assets/images/today.png");
    }
  }
  .hide-Duty{
    height: calc(30% + 27px);
  }
  .trend {
    height: 65%;
    .base {
      background-image: url("~@/assets/images/trend.png");
    }
  }
}
</style>
