<template>
    <!-- AI报警地图弹窗 -->
    <div id="ai-warning-detail" class="ai-warning-detail" v-show="show">
        <div class="ai-warning-center">
            <div class="ai-warning-center-top">{{$t('oilGasScreen.alarmInfo')}}</div>
            <div class="ai-warning-center-msg">
                <div class="ai-warning-center-msgd">
                    <div class="ai-warning-center-msgd-top" ref="alarmListTopDom">
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('warning.type')">
                                    {{$t('warning.type')}}：</label>
                                <div class="msg" :title="selectWarning.finalTypeName">{{selectWarning.finalTypeName || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('warning.place')">
                                    {{$t('warning.place')}}：</label>
                                <div class="msg" :title="selectWarning.alarmAddress">{{selectWarning.alarmAddress || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('warning.device')">
                                    {{$t('warning.device')}}：</label>
                                <div class="msg" :title="selectWarning.equipmentName">{{selectWarning.equipmentName || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('FilghtProjectName')">
                                    {{$t('FilghtProjectName')}}：</label>
                                <div class="msg" :title="selectWarning.projectName">{{selectWarning.projectName || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('FilghtTask')">
                                    {{$t('FilghtTask')}}：</label>
                                <div class="msg" :title="selectWarning.taskName">{{selectWarning.taskName || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('inspection.project')">
                                    {{$t('inspection.project')}}：</label>
                                <div class="msg" :title="selectWarning.inpectionProjectName">{{selectWarning.inpectionProjectName || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('inspection.selfName')">
                                    {{$t('inspection.selfName')}}：</label>
                                <div class="msg" :title="selectWarning.inpectionObjectName">{{selectWarning.inpectionObjectName || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('warning.time')">
                                    {{$t('warning.time')}}：</label>
                                <div class="msg" :title="selectWarning.alarmTime">{{selectWarning.alarmTime || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('longitude')">
                                    {{$t('longitude')}}：</label>
                                <div class="msg" :title="selectWarning.lon">{{selectWarning.lon || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item">
                                <label class="label" :title="$t('latitude')">
                                    {{$t('latitude')}}：</label>
                                <div class="msg" :title="selectWarning.lat">{{selectWarning.lat || '--'}}</div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item msg-item-column">
                                <label class="label" :title="$t('warning.photo')">
                                    {{$t('warning.photo')}}：</label>
                                <div class="msg-img">
                                    <el-image class="img" v-for="(item, index) in alarmPics(selectWarning.picLinkUrlList)" 
                                        :key="index" :src="item" :preview-src-list="alarmPics(selectWarning.picLinkUrlList)" />
                                </div>
                            </div>
                        </div>
                        <div class="msg-list">
                            <div class="msg-item msg-item-column">
                                <label class="label" :title="$t('warning.video')">
                                    {{$t('warning.video')}}：</label>
                                <div class="msg-video">
                                    <div class="video-list" v-for="(item, index) in alarmVideos(selectWarning.videoLinkUrlList)" 
                                        :key="item + index" @click="openViewVideoDialog(item)">
                                        <img class="video-list-img" src="@/assets/images/alarmvideo-bg.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ai-warning-center-msgd-btn" v-if="selectWarning.alarmStatus === 'pend'">
                        <el-button size="mini" :loading="btnLoading.ignore" @click="ignore">
                            {{$t('warning.ignore')}}</el-button>
                        <el-button type="primary" size="mini" @click="corrections">
                            {{$t('warning.corrections')}}</el-button>
                        <el-button type="primary" size="mini" @click="confirm">
                            {{$t('warning.confirm')}}</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 更改AI报警信息弹窗 -->
        <el-dialog class="correctDialog" :title="!isConfirm ? $t('warning.correctAlarm') : $t('warning.confirmAlarm')" 
            :visible.sync="correctDialog" width="450px" :modal-append-to-body="false" :close-on-click-modal="false" 
            top="20vh" :before-close="handleCloseCorrect">
            <div class="content">
                <el-form :model="correctForm" :rules="correctRules" ref="correctForm" label-width="auto" class="demo-ruleForm">
                    <el-form-item :label="$t('warning.type') + '：'" prop="alarmType" v-if="!isConfirm">
                        <el-select v-model="correctForm.alarmType" size="small" 
                            :placeholder="$t('warning.type')" style="width: 100%;">
                            <el-option v-for="(item, index) in alarmTypeList" :label="item.dictValue"
                                :value="item.dictKey" :key="index + item.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('warning.alarmLevel') + '：'" prop="alarmLevel">
                        <el-select v-model="correctForm.alarmLevel" size="small" 
                            :placeholder="$t('warning.alarmLevel')" style="width: 100%;">
                            <el-option v-for="(item, index) in alarmLevelList" :label="item.label"
                                :value="item.value" :key="index + item.value" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCloseCorrect">{{$t('btnCancel')}}</el-button>
                <el-button size="small" type="primary" :loading="btnLoading.corrections || btnLoading.confirm" 
                    @click="submitCorrect">{{$t('btnOk')}}</el-button>
            </span>
        </el-dialog>
        <!-- 查看报警视频弹窗 -->
        <el-dialog class="viewVideoDialog" :title="$t('warning.video')" :visible.sync="viewVideoDialog" width="680px" 
            v-if="viewVideoDialog" :modal-append-to-body="false" :close-on-click-modal="false" top="20vh" 
            :before-close="handleCloseVideo">
            <div class="content">
                <xg-player class="videoPlayer-xg-player" v-if="viewVideoUrl" ref="videoLive" :videoUrl="viewVideoUrl" 
                    :videoType="videoType(viewVideoUrl)" :controls="true" :screen="false" :showDownLoad="false" 
                    :show="true" :isShowMenu="false" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import XgPlayer from '@/components/video-live-xgplayer';
import { getDictionary } from "@/api/system/dictbiz";
import { editAIAlarmData } from "@/api/project/index";

export default {
    name: 'AIwarning-detail',
    components: { XgPlayer },
    props: {
        selectWarning: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            show: false,
            btnLoading: {
                ignore: false,
                corrections: false,
                confirm: false
            },
            correctDialog: false,
            isConfirm: false,
            alarmTypeList: [],
            alarmLevelList: [
                {label: this.$t('warning.levelOne'), value: 1},
                {label: this.$t('warning.levelTwo'), value: 2},
                {label: this.$t('warning.levelThree'), value: 3},
                {label: this.$t('warning.levelFour'), value: 4}
            ],
            correctForm: {
                alarmType: '',
                alarmLevel: ''
            },
            correctRules: {
                alarmType: [{required: true, message: this.$t('warning.type'), trigger: 'change'}],
                alarmLevel: [{required: true, message: this.$t('warning.alarmLevel'), trigger: 'change'}]
            },
            viewVideoDialog: false,
            viewVideoUrl: ''
        }
    },
    computed: {
        alarmPics() {
            return function(data) {
                if (!data) return [];
                return data;
            }
        },
        alarmVideos() {
            return function(data) {
                if (!data) return [];
                return data;
            }
        },
        videoType() {
            return function(data) {
                return data.substr(-3);
            }
        }
    },
    watch: {
        show(val) {
            if (val) {
                setTimeout(() => {
                    this.$refs.alarmListTopDom.scrollTop = 0;
                }, 500);
            }
        }
    },
    methods: {
        getDictList() {
            getDictionary({code: 'OilPatrolInspection'}).then(res => {
                if (res.data.code == 200) {
                    this.alarmTypeList = res.data.data || [];
                }
            });
        },
        ignore() {
            let params = {
                id: this.selectWarning.id,
                alarmStatus: 'ignore'
            };
            this.btnLoading = {
                ignore: true,
                corrections: false,
                confirm: false
            };
            editAIAlarmData(params).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(this.$t('requestSuccessText'));
                    this.$emit('close');
                }
            }).finally(() => {
                this.btnLoading = {
                    ignore: false,
                    corrections: false,
                    confirm: false
                };
            });
        },
        corrections() {
            this.getDictList();
            this.correctForm = {
                alarmType: this.selectWarning.finalTypeCode,
                alarmLevel: this.selectWarning.level || 3
            };
            this.isConfirm = false;
            this.correctDialog = true;
        },
        confirm() {
            this.correctForm = {
                alarmLevel: this.selectWarning.level || 3
            };
            this.isConfirm = true;
            this.correctDialog = true;
        },
        handleCloseCorrect() {
            this.correctDialog = false;
            this.correctForm = {
                alarmType: '',
                alarmLevel: ''
            };
            this.btnLoading = {
                ignore: false,
                corrections: false,
                confirm: false
            };
            this.isConfirm = false;
        },
        submitCorrect() {
            let params;
            if (!this.isConfirm) {
                params = {
                    id: this.selectWarning.id,
                    alarmStatus: 'amend',
                    finalTypeCode: this.correctForm.alarmType,
                    level: this.correctForm.alarmLevel
                };
                let dictItem = this.alarmTypeList.find(t => t.dictKey === this.correctForm.alarmType);
                if (dictItem) {
                    params.finalTypeName = dictItem.dictValue;
                }
                this.btnLoading = {
                    ignore: false,
                    corrections: true,
                    confirm: false
                };
            } else {
                params = {
                    id: this.selectWarning.id,
                    alarmStatus: 'confirm',
                    level: this.correctForm.alarmLevel
                };
                this.btnLoading = {
                    ignore: false,
                    corrections: false,
                    confirm: true
                };
            }
            editAIAlarmData(params).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(this.$t('requestSuccessText'));
                    this.handleCloseCorrect();
                    this.$emit('close');
                }
            }).finally(() => {
                this.btnLoading = {
                    ignore: false,
                    corrections: false,
                    confirm: false
                };
            });
        },
        openViewVideoDialog(url) {
            this.viewVideoUrl = url;
            this.viewVideoDialog = true;
        },
        handleCloseVideo() {
            this.viewVideoDialog = false;
            this.viewVideoUrl = '';
        }
    }
}
</script>

<style lang="scss" scoped>
.ai-warning-detail {
    width: 320px;
    position: absolute;
    cursor: pointer;
    .ai-warning-center {
        width: 320px;
        height: 426px;
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("~@/assets/images/mapTipsbigbg.png");
        .ai-warning-center-top {
            height: 28px;
            line-height: 30px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            text-align: center;
        }
        .ai-warning-center-msg {
            height: 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 25px 30px 25px 22px;
            .ai-warning-center-msgd {
                height: 0;
                display: flex;
                flex-direction: column;
                flex: 1;
                .ai-warning-center-msgd-top {
                    height: 0;
                    flex: 1;
                    font-family: MicrosoftYaHeiUI;
                    font-size: 14px;
                    color: #FFFFFF;
                    overflow: auto;
                    position: relative;
                    .msg-list {
                        flex: 1;
                        margin-top: 8px;
                        &:first-child {
                            margin-top: 0;
                        }
                        .msg-item {
                            display: flex;
                            flex: 1;
                            .label {
                                width: 85px;
                                color: rgba(255, 255, 255, 0.7);
                                cursor: pointer;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                text-overflow: ellipsis;
                                word-break: break-all;
                                &.label-msg {
                                    flex: 1;
                                }
                            }
                            .msg {
                                flex: 1;
                                margin-left: 5px;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                text-overflow: ellipsis;
                                word-break: break-all;
                            }
                            .msg-img {
                                display: flex;
                                overflow: auto;
                                margin-top: 5px;
                                .img {
                                    min-width: 75px;
                                    width: 75px;
                                    height: 48px;
                                    margin-left: 5px;
                                    &:first-child {
                                        margin-left: 0;
                                    }
                                }
                            }
                            .msg-video {
                                display: flex;
                                overflow-x: auto;
                                overflow-y: hidden;
                                margin-top: 5px;
                                .video-list {
                                    width: 75px;
                                    min-width: 75px;
                                    height: 48px;
                                    margin-left: 5px;
                                    cursor: pointer;
                                    &:first-child {
                                        margin-left: 0;
                                    }
                                    .video-list-img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                        .msg-item-column {
                            flex-direction: column;
                        }
                    }
                }
                .ai-warning-center-msgd-btn {
                    padding-top: 10px;
                    text-align: center;
                }
            }
        }
    }
    .correctDialog {
        ::v-deep .el-dialog {
            background-color: rgba(30, 34, 42, 1);
            .el-dialog__header {
                .el-dialog__title {
                    font-size: 16px;
                    color: #FFFFFF;
                }
                .el-dialog__headerbtn .el-dialog__close {
                    color: #ffffff;
                }
            }
            .el-dialog__body {
                padding: 15px 20px;
                .content {
                    .el-form-item__label {
                        color: #ffffff;
                    }
                    .el-input__inner {
                        font-size: 14px;
                        color: #ffffff;
                        background-color: transparent;
                        &::placeholder {
                            color:  rgba(153, 153, 153, 0.79);
                        }
                    }
                }
            }
        }
    }
    .viewVideoDialog {
        ::v-deep .el-dialog {
            background-color: rgba(0, 0, 0, 1);
            .el-dialog__header {
                .el-dialog__title {
                    font-size: 16px;
                    color: #FFFFFF;
                }
                .el-dialog__headerbtn .el-dialog__close {
                    color: #ffffff;
                }
            }
            .el-dialog__body {
                padding: 0;
                .content {
                    width: 100%;
                    height: 325px;
                    .videoPlayer-xg-player {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>