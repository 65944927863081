<template>
    <div class="left-cont">
        <div class="box-main">
            <BaseBox :title="!taskData ? $t('screen.realMap') : $t('screen.realVideo')" />
        </div>
        <div class="suspended" v-show="taskData">
            <div class="suspended-cont">
                <div class="suspended-item" @click="$emit('clearnSelectTask', '')">{{$t('navbar.screenfullF')}}</div>
            </div>
        </div>
        <div class="map-cont" :class="{'narrow-map': taskData}" :style="locationObj">
            <div class="narrow-map-top" v-if="taskData">{{$t('screen.realMap')}}</div>
            <div :class="{'screen-map-center': !taskData, 'narrow-map-center': taskData}">
                <oliMap ref="map" :isShowTopTools="true" :mapType="'realTimeMap'" @changeUav="changeUav" />
            </div>
        </div>
        <div class="video-cont" v-show="taskData">
            <component v-if="liveVideoVOList(taskData).length" :is="useLive(taskData)" 
                ref="videoLive" class="video-plane" :screen="false" 
                :liveVideoVOList="liveVideoVOList(taskData)" />
            <div v-else class="video-loading" v-loading="true" 
                :element-loading-text="$t('WaitingForVideoTransmission')" 
                element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            </div>
        </div>
    </div>
</template>

<script>
import oliMap from '../../../map/index.vue';
import BaseBox from '../../../BaseBox/index.vue';
import VdieoLiveEasyplayer from "@/components/video-live-easyPlayer/index.vue";
import VdieoLiveHWLLS from "@/components/video-live-HWLLS/index.vue";
import { mapGetters } from "vuex";

export default {
    name: 'olscr-left',
    components: {oliMap, VdieoLiveEasyplayer, VdieoLiveHWLLS, BaseBox},
    props: {
        taskData: {
            default: ''
        }
    },
    data() {
        return {
            locationObj: {
                top: '0px',
                bottom: '0px',
                left: '0px',
                right: '0px'
            }
        }
    },
    computed: {
        ...mapGetters([
            'currentTaskList'
        ]),
        liveVideoVOList() {
            return function(taskData) {
                const liveVideoVOList = taskData.liveVideos;
                if (liveVideoVOList && Array.isArray(liveVideoVOList)) {
                    return liveVideoVOList;
                }
                return [];
            }
        },
        useLive() {
            return function(taskData) {
                return taskData.liveType === 1 ? 'VdieoLiveHWLLS': 'VdieoLiveEasyplayer';
            }
        },
        taskList() {
            let taskList = this.currentTaskList || [];
            return taskList;
        }
    },
    watch: {
        'taskData': {
            deep: true,
            handler: function(val) {
                let UavRealInfoPop = this.$refs.map.$refs.UavRealInfoPop;
                if (val) {
                    this.locationObj = {
                        width: '400px',
                        height: '350px',
                        top: '95px',
                        right: '30px'
                    }
                    if (UavRealInfoPop) {
                        UavRealInfoPop.show = false;
                    }
                } else {
                    this.locationObj = {
                        top: '0px',
                        bottom: '0px',
                        left: '0px',
                        right: '0px'
                    }
                    if (UavRealInfoPop && this.taskList.length > 1) {
                        UavRealInfoPop.show = true;
                    }
                }
                if (this.$refs.map.$options.map3d && this.$refs.map.$options.map3d.resize) {
                    this.$refs.map.$options.map3d.resize();
                }
            }
        }
    },
    methods: {
        changeUav(data, noSetFirstTask) {
            this.$parent.$refs.rightDom.handleChange(data, noSetFirstTask);
        }
    }
}
</script>

<style lang="scss" scoped>
.left-cont {
    width: 100%;
    height: 100%;
    background: #000000;
    position: relative;
    .box-main {
        padding: 0 10px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50px;
        z-index: 9999;
        ::v-deep .base-box {
            border: none;
        }
    }
    .suspended {
        display: none;
        position: absolute;
        z-index: 9999;
        bottom: 55px;
        left: 50%;
        transform: translateX(-50%);
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #FFFFFF;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 16px;
        cursor: pointer;
        .suspended-cont {
            display: flex;
            .suspended-item {
                padding: 5px 18px;
            }
        }
    }
    &:hover .suspended {
        display: block;
    }
    .map-cont {
        background: #fff;
        position: absolute;
        z-index: 2;
        .screen-map-center {
            height: 100%;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                pointer-events: none;
                box-shadow: 
                    inset 200px 0 200px #000E2E, /* 左上角 */
                    inset -200px 0 200px #000E2E, /* 右上角 */
                    inset 0 0 100px #000E2E, /* 左下角 */
                    inset 0 0 100px #000E2E;  /* 右下角 */
            }
        }
    }
    .narrow-map {
        display: flex;
        flex-direction: column;
        padding: 0;
        background: transparent;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("~@/assets/images/narrow-map-bg.png");
        .narrow-map-top {
            height: 20px;
            line-height: 20px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            text-align: center;
        }
        .narrow-map-center {
            height: 0;
            flex: 1;
            padding: 8px 7px;
            ::v-deep #cesiumContainer_map_center {
                border-radius: 16px;
            }
        }
    }
    .video-cont {
        width: 100%;
        height: calc(100% - 25px);
        position: relative;
        top: 25px;
        .video-loading {
            height: 100%;
            width: 100%;
            background-color: #011a38;
            ::v-deep .el-loading-mask {
                z-index: 1 !important;
            }
        }
    }
}
</style>