var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "screen_map_box", attrs: { id: "screen_map_box" } },
    [
      _c("div", { attrs: { id: "cesiumContainer_map_center" } }),
      _vm.isShowTopTools
        ? _c("div", { staticClass: "right-top-tools" }, [
            _c(
              "div",
              { staticClass: "track-screen", on: { click: _vm.trackTo } },
              [
                _c("img", {
                  class:
                    _vm.$i18n.locale === "zh" ? "full-image-zh" : "full-image",
                  attrs: {
                    src: require("@/assets" +
                      (_vm.$i18n.locale === "zh" ? "" : "/en") +
                      "/map/track" +
                      (_vm.beingTracked ? "_active" : "") +
                      ".png"),
                  },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm.hoverTip.show
        ? _c(
            "div",
            {
              staticClass: "hover-tip",
              style:
                "\n            left: " +
                _vm.hoverTip.left +
                "px;\n            top: " +
                _vm.hoverTip.top +
                "px;\n            transform: translateY(calc(-100% - " +
                (_vm.hoverTip.type === _vm.NodeMap.Node.POINT ? 40 : 18) +
                "px)) translateX(-50%);\n        ",
            },
            [
              _c("div", { staticClass: "hover-tip-triangle" }),
              _vm._l(_vm.hoverTip.list, function (item) {
                return _c(
                  "div",
                  { key: item.label, staticClass: "hover-tip-item" },
                  [
                    _c("span", [_vm._v(_vm._s(item.label) + ": ")]),
                    _c("span", [_vm._v(_vm._s(item.value))]),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c("BaseStationPop", {
        ref: "BaseStationPop",
        attrs: { detailData: _vm.baseStationDetail },
      }),
      _vm.mapType != "alarmMap"
        ? _c("UavRealInfoPop", { ref: "UavRealInfoPop" })
        : _vm._e(),
      _vm.mapType == "overallMap" && _vm.hangarShow
        ? _c("HangarDetails", {
            ref: "hangarDetails",
            attrs: {
              show: _vm.hangarShow,
              baseData: _vm.hangarBaseData,
              detailsData: _vm.hangarDetailsData,
              videoData: _vm.hangarVideoData,
            },
            on: {
              "update:show": function ($event) {
                _vm.hangarShow = $event
              },
              closeDetails: _vm.closeHangarPanel,
            },
          })
        : _vm._e(),
      _vm.mapType == "overallMap"
        ? _c("AIwarningDetail", {
            ref: "AIwarningDetail",
            attrs: { selectWarning: _vm.selectAlarmData },
            on: { close: _vm.closedAlarmInfoPop },
          })
        : _vm._e(),
      _vm.mapType == "overallMap" || _vm.mapType == "alarmMap"
        ? _c("AlarmReallinfoPop", {
            ref: "AlarmReallinfoPop",
            attrs: { alarmData: _vm.selectAlarmData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }