var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "durationPage", staticClass: "durationPage" }, [
    _c(
      "div",
      { staticClass: "swtch-list" },
      [
        _c(
          "el-radio-group",
          {
            attrs: { size: "mini" },
            on: { input: _vm.handlerData },
            model: {
              value: _vm.categoryType,
              callback: function ($$v) {
                _vm.categoryType = $$v
              },
              expression: "categoryType",
            },
          },
          [
            _c("el-radio", { attrs: { label: "dailyDurationStatistics" } }, [
              _vm._v(_vm._s(_vm.$t("oilGasScreen.today"))),
            ]),
            _c("el-radio", { attrs: { label: "weeklyDurationStatistics" } }, [
              _vm._v(_vm._s(_vm.$t("oilGasScreen.theWeek"))),
            ]),
            _c("el-radio", { attrs: { label: "monthlyDurationStatistics" } }, [
              _vm._v(_vm._s(_vm.$t("oilGasScreen.theMonth"))),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "chart-cont" },
      [
        _c("div", {
          staticClass: "chart-bg",
          style: { width: _vm.bgPosNum + "px", height: _vm.bgPosNum + "px" },
        }),
        _vm.showChart
          ? _c("chartPie", {
              attrs: {
                id: "duration-chart",
                isRoseChart: true,
                chartRadius: ["23%", "43%"],
                chartCenter: ["50%", "50%"],
                tooltip: _vm.tooltip,
                graphicOption: {
                  x: _vm.fontPosNum.x,
                  y: _vm.fontPosNum.y,
                  text: _vm.chartTotal,
                },
                labelFormatter: "{c} \n {b}",
                labelLine: { length: 30, length2: 50 },
                labelPadding: [0, -45],
                labelLayout: { verticalAlign: "middle", dy: 2 },
                chartData: _vm.chartData,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }