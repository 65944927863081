<template>
  <!-- 巡检记录 -->
  <div class="InspectionRecord">
    <div class="downLoad-report" @click="exportRecords">{{$t('oilGasScreen.downloadReport')}}</div>
    <div class="Record-cont">
      <div class="Record-cont-main">
        <div class="Record-item">
          <div class="chart-item">
            <BaseBox :title="$t('oilGasScreen.typeStatistics')">
              <template v-slot:content>
                <objectType :objectTypeData="objectTypeData" />
              </template>
            </BaseBox>
          </div>
          <div class="chart-item">
            <BaseBox :title="$t('oilGasScreen.abnormalRanking')">
              <template v-slot:content>
                <objectException :objectExceptionData="objectExceptionData" />
              </template>
            </BaseBox>
          </div>
        </div>
        <div class="Record-item-2">
          <div class="Record-item-top">
            <div class="chart-item">
              <BaseBox :title="$t('oilGasScreen.inspectionRecords')">
                <template v-slot:content>
                  <record :recordData="recordData" />
                </template>
              </BaseBox>
            </div>
            <div class="chart-item">
              <BaseBox :title="$t('oilGasScreen.inspectionDuration')">
                <template v-slot:content>
                  <duration :durationData="durationData" />
                </template>
              </BaseBox>
            </div>
          </div>
          <div class="Record-item-bottom">
            <div class="chart-item">
              <BaseBox :title="$t('oilGasScreen.inspectionTrend')">
                <template v-slot:content>
                  <trend :trendData="trendData" />
                </template>
              </BaseBox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下载记录弹窗 -->
    <el-dialog class="exportDialog" :title="$t('oilGasScreen.downloadReport')" :visible.sync="exportDialog" width="450px"
      :modal-append-to-body="false" :close-on-click-modal="false" top="20vh" :before-close="handleCloseExport">
      <div class="content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
          <el-form-item :label="$t('oilGasScreen.date') + '：'" prop="time">
            <el-date-picker type="date" size="small" :placeholder="$t('oilGasScreen.date')" value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" v-model="ruleForm.time" clearable style="width: 100%;" />
          </el-form-item>
          <el-form-item :label="$t('oilGasScreen.project') + '：'" prop="inspection">
            <el-select v-model="ruleForm.inspection" size="small" clearable 
              :placeholder="$t('oilGasScreen.project')" style="width: 100%;">
              <el-option v-for="(item, index) in projectList" :label="item.label"
                :value="item.value" :key="index + item.value" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCloseExport">{{$t('cancelText')}}</el-button>
        <el-button size="small" type="primary" @click="submitExport">{{$t('submitText')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BaseBox from '../BaseBox/index.vue';
import objectType from './components/objectType.vue';
import objectException from './components/objectException.vue';
import record from './components/record.vue';
import duration from './components/duration.vue';
import trend from './components/trend.vue';
import { getProjectList } from '@/api/oil-gas';
import { exportInspectionrecords } from '@/api/oil-gas/inspectionRecords/index';
import { getInspectionRecord } from '@/api/oilGasScreen/index';

export default {
  name: 'InspectionRecord',
  components: {
    BaseBox,
    objectType,
    objectException,
    record,
    duration,
    trend
  },
  data() {
    return {
      inspectionTimer: null,
      exportDialog: false,
      projectList: [],
      ruleForm: {
        time: '',
        inspection: ''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      rules: {
        time: [
          {required: true, message: '请选择日期', trigger: 'change'}
        ],
        inspection: [
          {required: true, message: '请选择巡检项目', trigger: 'change'}
        ]
      },
      // 对象类型统计数据
      objectTypeData: {},
      // 巡检对象异常排名数据
      objectExceptionData: {},
      // 巡检记录统计数据
      recordData: [
        {name: 'dailyRecordStatistics', data: {}, total: 0},
        {name: 'weeklyRecordStatistics', data: {}, total: 0},
        {name: 'monthlyRecordStatistics', data: {}, total: 0}
      ],
      // 巡检时长统计数据
      durationData: [
        {name: 'dailyDurationStatistics', data: {}, total: 0},
        {name: 'weeklyDurationStatistics', data: {}, total: 0},
        {name: 'monthlyDurationStatistics', data: {}, total: 0}
      ],
      // 巡检趋势统计数据
      trendData: [
        {name: 'dailyRecordCount', data: {}},
        {name: 'weeklyRecordCount', data: {}},
        {name: 'monthlyRecordCount', data: {}}
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      this.$emit('loadEnd');
    }, 1000);
    this.getProjectList();
    this.getInspectionRecordData();
    if (this.inspectionTimer) {
      clearInterval(this.inspectionTimer);
      this.inspectionTimer = null;
    }
    this.inspectionTimer = setInterval(() => {
      this.getInspectionRecordData();
    }, 10000);
  },
  methods: {
    getInspectionRecordData() {
      getInspectionRecord().then(res => {
        if (res.data.code == 200) {
          this.handleData(res.data.data || {});
        } else {
          this.$message.error(this.$t('requestErrorText'));
        }
      }).catch(err => {
        this.$message.error(err);
      });
    },
    handleData(data) {
      // 对象类型统计数据
      this.objectTypeData = data.objectCount || {};
      // 巡检对象异常排名数据
      this.objectExceptionData = data.objectExceptionStatistics || {};
      // 巡检记录统计数据
      this.recordData = [
        {name: 'dailyRecordStatistics', data: data.dailyRecordStatistics || {}, total: data.dailyRecordTotalCount || 0},
        {name: 'weeklyRecordStatistics', data: data.weeklyRecordStatistics || {}, total: data.weeklyRecordTotalCount || 0},
        {name: 'monthlyRecordStatistics', data: data.monthlyRecordStatistics || {}, total: data.monthlyRecordTotalCount || 0}
      ];
      // 巡检时长统计数据
      this.durationData = [
        {name: 'dailyDurationStatistics', data: data.dailyDurationStatistics || {}, total: data.dailyDurationTotalCount || 0},
        {name: 'weeklyDurationStatistics', data: data.weeklyDurationStatistics || {}, total: data.weeklyDurationTotalCount || 0},
        {name: 'monthlyDurationStatistics', data: data.monthlyDurationStatistics || {}, total: data.monthlyDurationTotalCount || 0}
      ];
      // 巡检趋势统计数据
      this.trendData = [
        {name: 'dailyRecordCount', data: data.dailyRecordCount || {}},
        {name: 'weeklyRecordCount', data: data.weeklyRecordCount || {}},
        {name: 'monthlyRecordCount', data: data.monthlyRecordCount || {}}
      ];
    },
    // 获取项目列表
    getProjectList() {
      getProjectList({current: 1,size: 9999}).then(res => {
        if (res.data.code === 200) {
          this.projectList = res.data.data.records.map(item=>{
            return {value: item.id, label: item.projectName};
          });
        }
      });
    },
    // 打开下载报告弹窗
    exportRecords() {
      this.ruleForm = {
        time: '',
        inspection: ''
      };
      this.exportDialog = true;
    },
    // 关闭下载报告弹窗
    handleCloseExport() {
      this.exportDialog = false;
      this.$refs['ruleForm'].resetFields();
      this.ruleForm = {
        time: '',
        inspection: ''
      };
    },
    // 提交下载报告
    submitExport() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.downLoadRecordsFile();
        }
      });
    },
    downLoadRecordsFile() {
      const loading = this.$loading({
        lock: true,
        text: this.$t('oilGasScreen.downloadLoadingText'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      exportInspectionrecords({flyDate: this.ruleForm.time, projectId: this.ruleForm.inspection}).then(res => {
        let index = res.headers["content-disposition"].indexOf("=") + 1;
        let fileName = decodeURIComponent(
          res.headers["content-disposition"].substr(index)
        );
        const blob = res.data;
        let link = document.createElement("a");
        link.href = URL.createObjectURL(
          new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          })
        );
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        this.handleCloseExport();
      }).finally(() => {
        loading && loading.close();
      });
    }
  },
  beforeDestroy() {
    if (this.inspectionTimer) {
      clearInterval(this.inspectionTimer);
      this.inspectionTimer = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.InspectionRecord {
  width: 100%;
  height: 100%;
  position: relative;
  .downLoad-report {
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 2;
    font-size: 15px;
    color: rgb(64, 158, 255);
    cursor: pointer;
  }
  .Record-cont {
    width: 100%;
    height: calc(100% - 56px);
    position: relative;
    top: 42px;
    display: flex;
    .Record-cont-main {
      width: 0;
      flex: 1;
      padding: 0 10px;
      display: flex;
      overflow: auto;
      .Record-item {
        display: flex;
        flex-direction: column;
        flex: 1;
        .chart-item {
          height: 0;
          flex: 1;
          margin-top: 15px;
          min-height: 220px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .Record-item-2 {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        flex: 2;
        .Record-item-top {
          display: flex;
          height: 0;
          min-height: 220px;
          flex: 1;
          .chart-item {
            flex: 1;
            min-height: 220px;
            margin-left: 15px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
        .Record-item-bottom {
          display: flex;
          height: 0;
          min-height: 220px;
          flex: 1;
          margin-top: 15px;
          .chart-item {
            flex: 1;
            min-height: 220px;
          }
        }
      }
    }
  }
  .exportDialog {
    ::v-deep .el-dialog {
      background-color: rgba(30, 34, 42, 1);
      .el-dialog__header {
        .el-dialog__title {
          font-size: 16px;
          color: #FFFFFF;
        }
        .el-dialog__headerbtn .el-dialog__close {
          color: #ffffff;
        }
      }
      .el-dialog__body {
        padding: 15px 20px;
        .content {
          .el-form-item__label {
            color: #ffffff;
          }
          .el-input__inner {
            font-size: 14px;
            color: #ffffff;
            background-color: transparent;
            &::placeholder {
              color:  rgba(153, 153, 153, 0.79);
            }
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>