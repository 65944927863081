<template>
  <el-form
    class="login-form"
    :rules="loginRules"
    ref="loginForm"
    :model="loginForm"
    label-width="0"
  >
    <el-form-item prop="username">
      <el-input
        size="small"
        class="login-input"
        @keyup.enter.native="handleLogin"
        v-model="loginForm.username"
        auto-complete="off"
        :placeholder="$t('login.username')"
      >
        <span slot="prefix" class="icon-user"></span>
        <!-- <i slot="suffix" style="{color: #B8B8B8; font-size: 21px }" class="icon-yonghu"/> -->
      </el-input>
    </el-form-item>
    <el-form-item class="password" prop="password">
      <el-input
        size="small"
        class="login-input"
        @keyup.enter.native="handleLogin"
        :type="passwordType"
        v-model="loginForm.password"
        auto-complete="off"
        :placeholder="$t('login.password')"
      >
        <span slot="prefix" class="icon-password"></span>
        <i
          class="el-icon-view el-input__icon"
          slot="suffix"
          @click="showPassword"
        />
        <!-- <i slot="suffix" style="{color: #B8B8B8; font-size: 21px }" class="icon-mima"/> -->
      </el-input>
    </el-form-item>
    <el-form-item class="code" v-if="this.website.captchaMode" prop="code">
      <el-row :span="24">
        <el-col :span="16">
          <el-input
            size="small"
            class="login-input"
            @keyup.enter.native="handleLogin"
            v-model="loginForm.code"
            auto-complete="off"
            :placeholder="$t('login.code')"
          >
            <span slot="prefix" class="icon-code"></span>
            <!-- <i slot="suffix" style="{color: #B8B8B8; font-size: 21px }" class="icon-yanzhengma"/> -->
          </el-input>
        </el-col>
        <el-col :span="2"> </el-col>
        <el-col :span="6">
          <div class="login-code">
            <img
              :src="loginForm.image"
              class="login-code-img"
              @click="refreshCode"
            />
          </div>
        </el-col>
      </el-row>
    </el-form-item>
    <div class="account">
      <!-- <span class="account-register" @click="goFlow('register')">账号注册</span> -->
      <span class="account-register"></span>
      <span class="account-forget" @click="goFlow('forget')">忘记密码?</span>
    </div>
    <el-form-item class="login-btn">
      <el-button
        type="primary"
        size="small"
        @click.native.prevent="handleLogin"
        class="login-submit"
        >{{ $t("login.submit") }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters } from "vuex";
import { info } from "@/api/system/tenant";
import { getCaptcha, getDetail } from "@/api/user";
import { getTopUrl } from "@/util/util";
import { setStore} from '@/util/store'

export default {
  name: "userlogin",
  data() {
    return {
      tenantMode: this.website.tenantMode,
      loginForm: {
        //租户ID
        // tenantId: "000000",
        //用户名
        username: "",
        //密码
        password: "",
        //账号类型
        type: "account",
        //验证码的值
        code: "",
        //验证码的索引
        key: "",
        //预加载白色背景
        image:
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
      },
      loginRules: {
        tenantId: [
          { required: false, message: "请输入租户ID", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 1, message: "密码长度最少为6位", trigger: "blur" },
        ],
      },
      passwordType: "password",
    };
  },
  created() {
    this.getTenant();
    this.refreshCode();
  },
  mounted() {
    setTimeout(() => {
      sessionStorage.removeItem('screenOil');
    }, 1000);
  },
  computed: {
    ...mapGetters([
      "tagWel",
      'menuAll'
    ]),
  },
  props: [],
  methods: {
    goFlow(type) {
      this.$emit("goFlow", type);
    },
    refreshCode() {
      getCaptcha().then((res) => {
        const data = res.data;
        this.loginForm.key = data.key;
        this.loginForm.image = data.image;
      });
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    handleLogin() {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: '登录中,请稍后。。。',
              spinner: "el-icon-loading"
            });
            const routePath = this.$route.path;
            this.$store.dispatch("LoginByUsername", this.loginForm).then(() => {
              this.$router.$avueRouter.formatRoutes(this.menuAll, true);
              this.handleAI()
              this.$nextTick(()=>{
                if (routePath === '/screenLogin') {
                  sessionStorage.setItem('screenOil', true);
                  this.$router.push({path: '/screen-oil'});
                } else {
                  this.$router.push({path: this.tagWel.value});
                }
                loading.close();
              })
            }).catch(() => {
              loading.close();
              this.refreshCode();
            });
          }
        });
      },

    getTenant() {
      let domain = getTopUrl();
      // 临时指定域名，方便测试
      //domain = "https://bladex.vip";
      info(domain).then((res) => {
        const data = res.data;
        if (data.success && data.data.tenantId) {
          this.tenantMode = false;
          this.loginForm.tenantId = data.data.tenantId;
          this.$parent.$refs.login.style.backgroundImage = `url(${data.data.backgroundUrl})`;
        }
      });
    },
    handleAI() {
      setStore({
        name: 'do-not-show-again',
        content: false,
        type:'message' 
      })
      setTimeout(()=>{
        this.$EventBus.$emit("login-notice-to-AI");
      }, 2000)
    }
  },
};
</script>

<style lang="scss">
.login-form {
  i {
    color: #b8b8b8 !important;
  }
  .el-form-item {
    margin-top: 16px;
  }
  .login-input {
    input {
      font-size: 16px !important;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #b8b8b8 !important;
      border-bottom: 1px solid #b8b8b8 !important;
    }
  }
}
</style>
