<template>
  <!-- 全局概况 -->
  <div class="OverallSituation">
    <div class="Overall-map">
      <oliMap :isTracked="false" :mapType="'overallMap'" :AIAlarmData="AIAlarmData" />
    </div>
    <div class="left-panle panle-main">
      <div class="panle-content">
        <div class="panle-item panle-item-sort panle-item-sbxx">
          <BaseBox :title="$t('oilGasScreen.deviceOverview')">
            <template v-slot:content>
              <devicePage :deviceData="deviceData" />
            </template>
          </BaseBox>
        </div>
        <div class="panle-item panle-item-sort">
          <BaseBox :title="$t('oilGasScreen.uavFlightStatistics')">
            <template v-slot:content>
              <uavPage :uavFlyData="uavFlyData" />
            </template>
          </BaseBox>
        </div>
        <div class="panle-item panle-item-lsjc">
          <BaseBox :title="$t('oilGasScreen.historySortie')">
            <template v-slot:content>
              <sortiePage :historySortieData="historySortieData" />
            </template>
          </BaseBox>
        </div>
      </div>
    </div>
    <div class="right-panle panle-main">
      <div class="panle-content">
        <div class="panle-item panle-item-sort panle-item-dcqk">
          <BaseBox :title="$t('oilGasScreen.podOverview')">
            <template v-slot:content>
              <podVideoPage :podData="podData" />
            </template>
          </BaseBox>
        </div>
        <div class="panle-item panle-item-sort panle-item-gxxj">
          <BaseBox :title="$t('oilGasScreen.pipeInspection')">
            <template v-slot:content>
              <pipelinePage :pipeData="pipeData" />
            </template>
          </BaseBox>
        </div>
        <div class="panle-item panle-item-yjxj">
          <BaseBox :title="$t('oilGasScreen.oilWellInspection')">
            <template v-slot:content>
              <oilWellPage :oilWellData="oilWellData" />
            </template>
          </BaseBox>
        </div>
      </div>
    </div>
    <div class="top-panle">
      <realTimeAlarm :alarmData="alarmData" @changeSearchAlarm="changeSearchAlarm" />
    </div>
    <div class="bottom-panle" v-show="isShowLedger">
      <BaseBox :title="$t('oilGasScreen.uavLedger')">
        <template v-slot:content>
          <droneLedger :ledgerData="ledgerData" />
        </template>
      </BaseBox>
    </div>
  </div>
</template>

<script>
import oliMap from '../map/index.vue';
import BaseBox from '../BaseBox/index.vue';
import devicePage from './components/device.vue';
import uavPage from './components/uav.vue';
import sortiePage from './components/sortie.vue';
import podVideoPage from './components/podVideo.vue';
import pipelinePage from './components/pipeline.vue';
import oilWellPage from './components/oilWell.vue';
import realTimeAlarm from './components/realTimeAlarm.vue';
import droneLedger from './components/droneLedger.vue';
import { getOverallSituation, getUserAlarmData } from '@/api/oilGasScreen/index';

export default {
  name: 'OverallSituation',
  components: {
    oliMap,
    BaseBox,
    devicePage,
    uavPage,
    sortiePage,
    podVideoPage,
    pipelinePage,
    oilWellPage,
    realTimeAlarm,
    droneLedger
  },
  data() {
    return {
      isShowLedger: false,
      overallTimer: null,
      userAlarmTimer: null,
      // 设备信息概况数据
      deviceData: {
        onlineDeviceNum: 0,
        totalDeviceNum: 0,
        vtolUavNum: 0,
        multUavNum: 0,
        hangarNum: 0
      },
      // 无人机飞行统计数据
      uavFlyData: [
        {name: 'flightSorties', data: {}},
        {name: 'flightDuration', data: {}},
        {name: 'flightMileage', data: {}}
      ],
      // 历史架次概况数据
      historySortieData: [
        {name: 'dailyFlightSorties', data: {}},
        {name: 'weeklyFlightSorties', data: {}},
        {name: 'monthlyFlightSorties', data: {}}
      ],
      // 吊舱概况数据
      podData: {
        onlineUavList: []
      },
      // 管线巡检数据
      pipeData: [
        {name: 'dailyOilLineInspectMileage', data: {}, total: 0},
        {name: 'monthlyOilLineInspectMileage', data: {}, total: 0}
      ],
      // 油井巡检数据
      oilWellData: [
        {name: 'dailyOilWellInspectC', data: {}, total: 0},
        {name: 'monthlyOilWellInspectCount', data: {}, total: 0}
      ],
      // 报警数据
      alarmData: [],
      // 无人机台账数据
      ledgerData: [],
      // AI报警数据高级搜索参数
      searchAlarmForm: {
        time: []
      },
      // AI报警以及普通报警数据
      AIAlarmData: []
    }
  },
  created() {
    let time = [];
    // 当前日期
    let currentDate  = new Date();
    let year = currentDate.getFullYear(); // 获取年份
    let month = currentDate.getMonth() + 1; // 获取月份，+1因为getMonth()返回的月份从0开始
    let day = currentDate.getDate(); // 获取日
    let endDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    time = [`${endDate} 00:00:00`, `${endDate} 23:59:59`];
    this.searchAlarmForm.time = time;
  },
  mounted() {
    setTimeout(() => {
      this.$emit('loadEnd');
    }, 1000);
    // 获取统计数据
    this.getOverallSituationData();
    if (this.overallTimer) {
      clearInterval(this.overallTimer);
      this.overallTimer = null;
    }
    this.overallTimer = setInterval(() => {
      this.getOverallSituationData();
    }, 10000);
    // 获取报警数据
    this.getUserAlarmList();
    if (this.userAlarmTimer) {
      clearInterval(this.userAlarmTimer);
      this.userAlarmTimer = null;
    }
    this.userAlarmTimer = setInterval(() => {
      this.getUserAlarmList();
    }, 5000);
  },
  methods: {
    showLedger(state) {
      this.isShowLedger = state;
    },
    getOverallSituationData() {
      getOverallSituation().then(res => {
        if (res.data.code == 200) {
          this.handleData(res.data.data || {});
        } else {
          this.$message.error(this.$t('requestErrorText'));
        }
      }).catch(err => {
        this.$message.error(err);
      });
    },
    handleData(data) {
      // 设备信息概况数据
      this.deviceData = {
        onlineDeviceNum: data.onlineDeviceNum || 0,
        totalDeviceNum: data.totalDeviceNum || 0,
        vtolUavNum: data.vtolUavNum || 0,
        multUavNum: data.multUavNum || 0,
        hangarNum: data.hangarNum || 0
      };
      // 无人机飞行统计数据
      this.uavFlyData = [
        {name: 'flightSorties', data: data.flightSorties || {}},
        {name: 'flightDuration', data: data.flightDuration || {}},
        {name: 'flightMileage', data: data.flightMileage || {}}
      ];
      // 历史架次概况数据
      this.historySortieData = [
        {name: 'dailyFlightSorties', data: data.dailyFlightSorties || {}},
        {name: 'weeklyFlightSorties', data: data.weeklyFlightSorties || {}},
        {name: 'monthlyFlightSorties', data: data.monthlyFlightSorties || {}}
      ];
      // 吊舱概况数据
      let onlineUavList = data.onlineUavList || [];
      if (onlineUavList && onlineUavList.length) {
        onlineUavList.map(t => {
          t.liveVideos = (!t.liveVideos || !t.liveVideos.length) ? null : t.liveVideos
        });
      }
      this.podData = {
        onlineUavList: onlineUavList || []
      };
      // 管线巡检数据
      this.pipeData = [
        {name: 'dailyOilLineInspectMileage', data: data.dailyOilLineInspectMileage || {}, total: data.dailyOilLineTotalMileage || 0},
        {name: 'monthlyOilLineInspectMileage', data: data.monthlyOilLineInspectMileage || {}, total: data.monthlyOilLineTotalMileage || 0}
      ];
      // 油井巡检数据
      this.oilWellData = [
        {name: 'dailyOilWellInspectC', data: data.dailyOilWellInspectCount || {}, total: data.dailyOilWellTotalCount || 0},
        {name: 'monthlyOilWellInspectCount', data: data.monthlyOilWellInspectCount || {}, total: data.monthlyOilWellTotalCount || 0}
      ];
      // 报警数据
      this.alarmData = data.alarmList || [];
      // 无人机台账数据
      this.ledgerData = data.uavList || [];
    },
    changeSearchAlarm(data) {
      this.searchAlarmForm = data;
      this.getUserAlarmList();
    },
    getUserAlarmList() {
      let time = this.searchAlarmForm.time;
      if (time && time.length) {
        let params = {startTime: time[0], endTime: time[1]};
        getUserAlarmData(params).then(res => {
          if (res.data.code == 200) {
            let AIAlarmData = [];
            (res.data.data || []).map(t => {
              if (t.identifyAlarmType == 'manual') {
                t.alarmTypeName = t.finalTypeName;
                t.address = t.alarmAddress;
                t.uavName = t.equipmentName ? [t.equipmentName] : '';
                t.picUrl = t.picLinkUrlList || [];
                t.videoUrl = t.videoLinkUrlList || [];
                AIAlarmData.push(t);
              } else if (t.identifyAlarmType == 'ai') {
                AIAlarmData.push(t);
              }
            });
            this.AIAlarmData = AIAlarmData;
          }
        });
      }
    }
  },
  beforeDestroy() {
    if (this.overallTimer) {
      clearInterval(this.overallTimer);
      this.overallTimer = null;
    }
    if (this.userAlarmTimer) {
      clearInterval(this.userAlarmTimer);
      this.userAlarmTimer = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.OverallSituation {
  width: 100%;
  height: 100%;
  position: relative;
  .Overall-map {
    width: 100%;
    height: 100%;
    background: #fff;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      box-shadow: 
        inset 400px 0 400px #000E2E, /* 左上角 */
        inset -400px 0 400px #000E2E, /* 右上角 */
        inset 0 0 100px #000E2E, /* 左下角 */
        inset 0 0 100px #000E2E;  /* 右下角 */
    }
  }
  .panle-main {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 450px;
    display: flex;
    flex-direction: column;
  }
  .left-panle {
    left: 0;
    // background: linear-gradient(to right, rgba(0, 14, 46, 1) 0%, rgba(0, 14, 46, 0.9) 50%, rgba(0, 14, 46, 0) 100%);
    padding-left: 10px;
    .panle-content {
      align-items: flex-start;
    }
  }
  .right-panle {
    right: 0;
    // background: linear-gradient(to left, rgba(0, 14, 46, 1) 0%, rgba(0, 14, 46, 0.9) 50%, rgba(0, 14, 46, 0) 100%);
    padding-right: 10px;
    .panle-content {
      align-items: flex-end;
    }
  }
  .panle-content {
    height: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .panle-item {
      width: 100%;
      min-height: 220px;
      flex: 1;
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
      &.panle-item-sbxx {
        min-height: 240px;
        max-height: 300px;
      }
      &.panle-item-sort {
        width: 400px;
      }
      &.panle-item-lsjc {
        min-height: 200px;
        max-height: 28%;
      }
      &.panle-item-dcqk {
        min-height: 200px;
      }
      &.panle-item-gxxj {
        min-height: 220px;
      }
      &.panle-item-yjxj {
        min-height: 220px;
        max-height: 28%;
      }
    }
  }
  .top-panle {
    position: absolute;
    top: 50px;
    right: 420px;
  }
  .bottom-panle {
    display: flex;
    flex-direction: column;
    width: calc(100% - 930px);
    height: 226px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 14, 46, 0.5);
    border-radius: 4px;
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>