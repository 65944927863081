<template>
  <div class="screen-common-content">
    <div class="left">
      <Left :projectInfo="projectInfo" />
    </div>
      <div class="right">
      <Right />
    </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import Left from "./left/index.vue";
import Right from "./right/index.vue";
export default {
  components: {
    Left,
    Right,
  },
  props: {
    projectInfo: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  mounted() {
    this.queryDate();
  },
  beforeDestroy() {
    if (this.timmer) {
      clearInterval(this.timmer)
    }
    this.timmer = null;
  },
  data() {
    return {
      Player: null,
      urls: [],
      url: "",
      weeks: [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      timmer: null,
      date: {
        time: null,
        date: null,
        month: null,
      },
    };
  },
  methods: {
    // 创建时间
    makeDate() {
      const date = new Date();
      this.date.date = dayjs(date).format("YYYY.MM.DD");
      this.date.time = dayjs(date).format("HH : mm : ss");
      this.date.month = dayjs(date).format("M月");
      this.date.week = this.weeks[date.getDay()];
    },

    // 定时更新时间
    queryDate() {
      this.makeDate();
      this.timmer = setInterval(() => {
        this.makeDate();
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.screen-common-content {
  padding: 3.5% 1% 3% 1%;
  width: 100%;
  .left {
    width: 68%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
  }
  .right {
    width: 32%;
    display: inline-block;
    height: 100%;
    vertical-align: top;
    box-sizing: border-box;
  }
}
</style>
