var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "objectType" }, [
    _c(
      "div",
      { staticClass: "chart-cont" },
      [
        _c("chartBar", {
          attrs: {
            id: "objectType-chart",
            isStacked: true,
            grid: { left: "2%", right: "2%", top: "23%", bottom: "8%" },
            legendWidth: "auto",
            barMaxWidth: "35",
            isBarMinHeight: true,
            barMinHeight: 10,
            tooltip: _vm.tooltip,
            xAxisLabel: { color: "#fff", rotate: 0, fontSize: 10 },
            isZoom: true,
            showZoomDataNum: 6,
            zoomOption: {
              width: "100%",
              height: 15,
              backgroundColor: "#1E222A",
              fillerColor: "#6AA4E6",
              bottom: 0,
              end: 50,
            },
            chartData: _vm.chartData,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }