<template>
    <!-- 无人机飞行概况 -->
    <div class="uav-page" ref="uavPage">
        <div class="swtch-list">
            <el-radio-group v-removeAria v-model="categoryType" size="mini" @input="handlerData">
                <el-radio :label="'flightSorties'">{{$t('oilGasScreen.sortie')}}</el-radio>
                <el-radio :label="'flightDuration'">{{$t('oilGasScreen.duration')}}</el-radio>
                <el-radio :label="'flightMileage'">{{$t('oilGasScreen.mileage')}}</el-radio>
            </el-radio-group>
        </div>
        <!-- <div class="today">今天</div>
        <div class="tomonth">本月</div> -->
        <div class="chart-cont">
            <!-- <div class="chart-bg chart-bg-1"></div>
            <div class="chart-bg chart-bg-2"></div>
            <chartPie 
                v-if="showChart"
                :id="'uav-chart'"
                :isDoubleChart="true"
                :isRoseChart="true"
                :chartRadius="[28, 48]"
                :chartCenter="[103, centerNum]"
                :chartRadiusTwo="[28, 48]"
                :chartCenterTwo="[307, centerNum]"
                :graphicOption="{x: 103, y: fontPosNum, text: '2'}"
                :graphicOptionTwo="{x: 307, y: fontPosNum, text: '2'}"
                :chartData="chartData"
                :chartDataTwo="chartDataTwo"
            /> -->
            <chartBar 
                :id="'uav-chart'"
                :isTransverse="true"
                :chartColors="['#588CF2']"
                :grid="{left: '3%', right: '10%', top: '15%', bottom: '3%'}"
                :legendWidth="'auto'"
                :xAxisLabel="{color: '#fff', rotate: 0, fontSize: 10}"
                :barMaxWidth="10"
                :barBorderRadius="[0, 4, 4, 0]"
                :tooltip="tooltip"
                :barGradientList="gradientList"
                :isZoom="true"
                :zoomOption="{width: 10, height: '80%', backgroundColor: '#1E222A', fillerColor: '#6AA4E6', 
                    right: '3%', bottom: 0, end: 50}"
                :chartData="chartData"
            />
        </div>
    </div>
</template>

<script>
import chartBar from '@/components/echarts/chartBar.vue';

export default {
    name: 'uav-page',
    components: {chartBar},
    props: ['uavFlyData'],
    data() {
        return {
            // showChart: false,
            categoryType: 'flightSorties',
            // centerNum: 0,
            // fontPosNum: 0,
            gradientList: [
                new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {offset: 0, color: 'rgba(89, 140, 242, 1)'},
                    {offset: 0.5, color: 'rgba(89, 140, 242, 0.5)'},
                    {offset: 1, color: 'rgba(89, 140, 242, 0.1)'}
                ])
            ],
            tooltip: {},
            chartData: [
                {name: '', data: []}
            ]
        }
    },
    watch: {
        'uavFlyData': {
            deep: true,
            handler: function() {
                this.handlerData();
            }
        }
    },
    mounted() {
        // this.handleResize();
        // window.addEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.showChart = false;
            this.$nextTick(() => {
                const domHeight = this.$refs.uavPage.offsetHeight;
                this.centerNum = domHeight * 0.32 + 110 / 2;
                this.fontPosNum = (domHeight * 0.32 + 110 / 2) - 11;
                this.showChart = true;
            });
        },
        handlerData() {
            let data = this.uavFlyData.find(t => t.name === this.categoryType).data;
            let chartData = [];
            Object.keys(data).map(t => {
                chartData.push({name: t, value: data[t] || 0});
            });
            let tooltip = {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                valueFormatter: (value) => {
                    return value;
                }
            };
            if (this.categoryType === 'flightDuration') {
                tooltip.valueFormatter = (value) => {
                    return `${value}h`;
                }
            } else if (this.categoryType === 'flightMileage') {
                tooltip.valueFormatter = (value) => {
                    return `${value}km`;
                }
            }
            this.tooltip = tooltip;
            this.chartData[0].data = chartData;
        }
    },
    beforeDestroy() {
        // window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style lang="scss" scoped>
.uav-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .swtch-list {
        position: absolute;
        top: 3px;
        right: 10px;
        z-index: 5;
        ::v-deep .el-radio-group {
            .el-radio {
                margin-right: 8px;
                .el-radio__inner {
                    width: 12px;
                    height: 12px;
                }
                .el-radio__label {
                    font-size: 12px;
                    color: #fff;
                    padding-left: 5px;
                }
                .el-radio__input.is-checked+.el-radio__label {
                    color: #409EFF;
                }
            }
        }
    }
    .today {
        position: absolute;
        bottom: 5px;
        left: 90px;
        font-family: MicrosoftYaHeiUI;
        font-size: 13px;
        color: #FFFFFF;
    }
    .tomonth {
        position: absolute;
        bottom: 5px;
        right: 78px;
        font-family: MicrosoftYaHeiUI;
        font-size: 13px;
        color: #FFFFFF;
    }
    .chart-cont {
        width: 100%;
        height: 100%;
        position: relative;
        .chart-bg-1 {
            width: 110px;
            height: 110px;
            position: absolute;
            left: 12%;
            top: 32%;
        }
        .chart-bg-2 {
            width: 110px;
            height: 110px;
            position: absolute;
            left: 63%;
            top: 32%;
        }
        .chart-bg {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("~@/assets/images/RosePie-bg.png");
            
        }
    }
}
</style>