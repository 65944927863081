var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register" },
    [
      _c("div", { staticClass: "register-title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("register.title")) + "\n  "),
      ]),
      _c(
        "el-form",
        {
          ref: "registForm",
          staticClass: "register-form",
          attrs: {
            rules: _vm.registRules,
            model: _vm.registForm,
            "label-width": "10px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: " ", prop: "userName" } },
            [
              _c("el-input", {
                staticClass: "login-input",
                attrs: {
                  size: "small",
                  "auto-complete": "off",
                  placeholder: _vm.$t("register.username"),
                },
                model: {
                  value: _vm.registForm.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.registForm, "userName", $$v)
                  },
                  expression: "registForm.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " ", prop: "password" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "login-input",
                  attrs: {
                    size: "small",
                    type: _vm.passwordType,
                    "auto-complete": "new-password",
                    placeholder: _vm.$t("register.password"),
                  },
                  model: {
                    value: _vm.registForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.registForm, "password", $$v)
                    },
                    expression: "registForm.password",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-view el-input__icon",
                    attrs: { slot: "suffix" },
                    on: { click: _vm.showPassword },
                    slot: "suffix",
                  }),
                ]
              ),
              _c("div", { staticClass: "register-tips" }, [
                _vm._v(_vm._s(_vm.$t("register.passwrodTips"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " ", prop: "ensurePwd" } },
            [
              _c("el-input", {
                staticClass: "login-input",
                attrs: {
                  size: "small",
                  type: _vm.passwordType,
                  "auto-complete": "off",
                  placeholder: _vm.$t("register.ensurePwd"),
                },
                model: {
                  value: _vm.registForm.ensurePwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.registForm, "ensurePwd", $$v)
                  },
                  expression: "registForm.ensurePwd",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " ", prop: "email" } },
            [
              _c("el-input", {
                staticClass: "login-input",
                attrs: {
                  size: "small",
                  "auto-complete": "off",
                  placeholder: _vm.$t("register.email"),
                },
                model: {
                  value: _vm.registForm.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.registForm, "email", $$v)
                  },
                  expression: "registForm.email",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " ", prop: "phone" } },
            [
              _c("el-input", {
                staticClass: "login-input",
                attrs: {
                  size: "small",
                  "auto-complete": "off",
                  placeholder: _vm.$t("register.phone"),
                },
                model: {
                  value: _vm.registForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.registForm, "phone", $$v)
                  },
                  expression: "registForm.phone",
                },
              }),
            ],
            1
          ),
          this.website.captchaMode
            ? _c(
                "el-form-item",
                { attrs: { label: " ", prop: "code" } },
                [
                  _c(
                    "el-row",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input", {
                            staticClass: "login-input",
                            attrs: {
                              size: "small",
                              "auto-complete": "off",
                              placeholder: _vm.$t("register.msgText"),
                            },
                            model: {
                              value: _vm.registForm.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.registForm, "code", $$v)
                              },
                              expression: "registForm.code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "send-code",
                              attrs: {
                                plain: "",
                                disabled: _vm.smsBtnDisabled,
                              },
                              on: { click: _vm.sendSms },
                            },
                            [_vm._v(_vm._s(_vm.smsBtnTitle))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "btn-group" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn btn-cancel",
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v(_vm._s(_vm.$t("register.cancel")) + "\n        ")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn btn-submit",
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.submit },
                  },
                  [_vm._v(_vm._s(_vm.$t("register.submit")) + "\n        ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }