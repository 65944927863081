<template>
  <div class="screen-common-right-box">
    <div class="video-container">
      <BaseBox :title="$t('screen.UAVFootage')">
      <template v-slot:content>
        <div class="video-live-box">
          <component
            v-if="liveVideoVOList.length > 0"
            :is="useLive"
            ref="videoLive"
            class="video-plane"
            :screen="false"
            :liveVideoVOList="liveVideoVOList"
          ></component>
          <div
            v-else
            class="video-loading"
            v-loading="true"
            :element-loading-text="$t('WaitingForVideoTransmission')"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
          </div>
        </div>
      </template>
      </BaseBox>
    </div>
    <div class="plane">
      <BaseBox :title="$t('screen.UAVInfo')">
      <template v-slot:content>
        <Plant ref="plane"></Plant>
      </template>
      </BaseBox>
    </div>
    
  </div>
</template>
<script>
import BaseBox from "@/components/screen/BaseBox.vue";
import Plant from "./plane.vue";
import VdieoLiveEasyplayer from "@/components/video-live-easyPlayer/index.vue";
import VdieoLiveHWLLS from "@/components/video-live-HWLLS/index.vue";
import { mapGetters } from "vuex";

export default {
  components: { VdieoLiveHWLLS, VdieoLiveEasyplayer, Plant, BaseBox },
  computed: {
    ...mapGetters([
      'selectTask'
    ]),
    liveVideoVOList() {
      const liveVideoVOList = this.selectTask.liveVideoVOList
      if (liveVideoVOList && Array.isArray(liveVideoVOList)) {
        return liveVideoVOList
      }
      return []
    },
    useLive() {
      return this.selectTask.liveType === 1 ? 'VdieoLiveHWLLS': 'VdieoLiveEasyplayer'
    },
  },
  methods: {
    
  }
};
</script>
<style scoped lang="scss">
.screen-common-right-box {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  color: #FFFFFF;
  flex-direction: column;
  justify-content: space-between;
  .plane {
    height: calc(56% - 22px);
    box-sizing: border-box;
    .base {
      background-image: url("~@/assets/images/plane.png");
    }
  }
  .video-container {
    width: 100%;
    height: 44%; 
    box-sizing: border-box;
    .video-live-box {
      height: 100%;
      .video-plane{
        height: 100%;
      }
      .video-loading {
        height: 100%;
        width: 100%;
        background-color: #011a38;
      }
    }
  }
}
</style>
