var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen-common-right-box" }, [
    _c(
      "div",
      { staticClass: "video-container" },
      [
        _c("BaseBox", {
          attrs: { title: _vm.$t("screen.UAVFootage") },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "video-live-box" },
                    [
                      _vm.liveVideoVOList.length > 0
                        ? _c(_vm.useLive, {
                            ref: "videoLive",
                            tag: "component",
                            staticClass: "video-plane",
                            attrs: {
                              screen: false,
                              liveVideoVOList: _vm.liveVideoVOList,
                            },
                          })
                        : _c("div", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: true,
                                expression: "true",
                              },
                            ],
                            staticClass: "video-loading",
                            attrs: {
                              "element-loading-text": _vm.$t(
                                "WaitingForVideoTransmission"
                              ),
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background":
                                "rgba(0, 0, 0, 0.8)",
                            },
                          }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "plane" },
      [
        _c("BaseBox", {
          attrs: { title: _vm.$t("screen.UAVInfo") },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [_c("Plant", { ref: "plane" })]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }