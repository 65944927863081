<template>
  <transition name="fade">
    <div class="shadow" v-if="visible">
      <div class="modal">
        <span class="modal-title" v-if="title">{{ title }}</span>
        <div class="close">
          <img @click="close" src="~@/assets/map/close.png">
        </div>
        <div class="content">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    title: String,
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.shadow {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #00000050;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    width: 92%;
    height: 96%;
    background: #000;
    position: relative;
    padding: 40px;
    .close {
      position: absolute;
      right: 0px;
      top: 24px;
      i {
        font-size: 28px;
        cursor: pointer;
        color: #fff;
      }
    }
    .content {
      //   background: #fff;
      height: 100%;
      width: 100%;
    }
    .modal-title {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      border: none;
      position: absolute;
      left: 50%;
      height: 0px;
      top: 10px;
      transform: translate(-50%, 0%);
      padding: 0;
    }
  }
}
</style>
