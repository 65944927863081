<template>
    <div class="screen-content">
        <div class="left" :class="{'screen-left': selectTaskData}">
            <Left :projectInfo="projectInfo" :taskData="selectTaskData" @clearnSelectTask="clearnSelectTask" />
        </div>
        <div class="right">
            <Right ref="rightDom" v-show="!selectTaskData" @changeSelectTask="changeSelectTask" />
        </div>
    </div>
</template>

<script>
import Left from "./left/index.vue";
import Right from "./right/index.vue";

export default {
    name: 'emergencyScreen-Content',
    components: {Left, Right},
    props: {
        projectInfo: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    data() {
        return {
            selectTaskData: ''
        }
    },
    methods: {
        changeSelectTask(data) {
            this.selectTaskData = data;
        },
        clearnSelectTask() {
            this.selectTaskData = '';
        }
    }
}
</script>

<style lang="scss" scoped>
.screen-content {
    padding: 3.5% 1% 3% 1%;
    width: 100%;
    .left {
        width: 68%;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        &.screen-left {
            width: 100%;
        }
    }
    .right {
        width: 32%;
        display: inline-block;
        height: 100%;
        vertical-align: top;
        box-sizing: border-box;
    }
}
</style>