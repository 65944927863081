<template>
    <div class="right-plane-Box">
        <div class="topinfo">
            <span class="topinfo-left">
                <img class="imgUavInfo" :src="drone" alt="" />
                <span>{{$t('screen.UAVModel')}}：<span class="wrj">{{selectTaskName || '--'}}</span></span>
            </span>
        </div>
        <div class="plane-info">
            <el-row :gutter="24" class="bodyBox">
                <el-col :span="8">
                    <el-row>
                        <el-col :span="$i18n.locale ==='zh'? 12 : 15" class="elColLeft">
                            {{$t('currentMode')}}：
                        </el-col>
                        <el-col :span="$i18n.locale ==='zh'? 12 : 9" class="elColRight">
                            {{UAVData.flgtmd ? UAVData.flgtmd : "-"}}
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="$i18n.locale ==='zh'? 12 : 19" class="elColLeft">
                            {{$t('CurrentBatteryLevel')}}：
                        </el-col>
                        <el-col :span="$i18n.locale ==='zh'? 10 : 5" class="elColRight">
                            {{typeof UAVData.pwrLeft === "number" ? `${UAVData.pwrLeft + "%"}` : "-"}}
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="$i18n.locale ==='zh'? 12 : 15" class="elColLeft">
                            {{$t('unlockingSignal')}}：
                        </el-col>
                        <el-col :span="$i18n.locale ==='zh'? 12 : 9" class="elColRight">
                            {{UAVData.lcksta == 1 ? $t('unlocked') : $t('locked')}}
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row :gutter="24" class="bodyBox">
                <el-col :span="$i18n.locale ==='zh'? 8 : 9">
                    <el-row>
                        <el-col :span="$i18n.locale ==='zh'? 8 : 11" class="elColLeft">
                            {{$t('longitude')}}：
                        </el-col>
                        <el-col :span="$i18n.locale ==='zh'? 16 : 13" class="elColRight">
                            {{UAVData.lon ? UAVData.lon : "-"}}
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="$i18n.locale ==='zh'? 8 : 12">
                    <el-row>
                        <el-col :span="$i18n.locale ==='zh'? 8 : 10" class="elColLeft">
                            {{$t('latitude')}}：
                        </el-col>
                        <el-col :span="$i18n.locale ==='zh'? 16 : 14" class="elColRight">
                            {{UAVData.lat ? UAVData.lat : "-"}}
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div class="plane-card">
            <div class="plane-card-item">
                <span>
                    <img class="imgUav" :src="speed" alt="" />
                    <span class="card-item-title">{{$t('speed')}}</span>
                </span>
                <span>
                    <span class="card-item-value">{{UAVData.gs}}</span>
                    <span class="card-item-unit">m/s</span>
                </span>
            </div>
            <div class="plane-card-item">
                <span>
                    <img class="imgUav" :src="altitude" alt="" />
                    <span class="card-item-title">{{$t('height')}}</span>
                </span>
                <span>
                    <span class="card-item-value">
                        {{UAVData.fieldHeight ? UAVData.fieldHeight.toFixed(2) : 0}}
                    </span>
                    <span class="card-item-unit">m</span>
                </span>
            </div>
            <div class="plane-card-item">
                <span>
                    <img class="imgUav" :src="mileage" alt=""/>
                    <span class="card-item-title">{{$t('RealTimeMileage')}}</span>
                </span>
                <span>
                    <span class="card-item-value">
                        {{this.UAVData.distOnAir ? (this.UAVData.distOnAir / 1000).toFixed(2) : '0'}}
                    </span>
                    <span class="card-item-unit">km</span>
                </span>
            </div>
            <div class="plane-card-item">
                <span>
                    <img class="imgUav" :src="flight_time" alt="" />
                    <span class="card-item-title">{{$t('FlightTime')}}</span>
                </span>
                <span>
                    <span class="card-item-value">{{this.minuteFormate}}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import drone from "@/assets/images/drone.png";
import speed from "@/assets/images/speed.png";
import altitude from "@/assets/images/altitude.png";
import mileage from "@/assets/images/mileage.png";
import flight_time from "@/assets/images/flight_time.png";
import { mapGetters } from "vuex";

export default {
    name: 'olscr-plane',
    data() {
        return {
            selectTaskName: '',
            speed,
            drone,
            altitude,
            mileage,
            flight_time
        };
    },
    computed: {
        ...mapGetters([
            'selectTask',
            'currentTaskMap'
        ]),
        // 无人机数据
        UAVData() {
            let UAVData = {};
            if (this.selectTask && this.selectTask.sn) {
                if (this.currentTaskMap[this.selectTask.sn]) {
                    UAVData = this.currentTaskMap[this.selectTask.sn].data || {};
                }
            }
            return UAVData;
        },
        // 飞行时间
        minuteFormate() {
            if (!this.UAVData && !this.UAVData.tmOnAir) {
                return "00:00";
            }
            let hours = Math.floor(this.UAVData.tmOnAir / 3600);
            let minutes = Math.floor((this.UAVData.tmOnAir - (hours * 3600)) / 60);
            let remainingSeconds = this.UAVData.tmOnAir - (hours * 3600) - (minutes * 60);
            if (isNaN(hours) || isNaN(minutes) || isNaN(remainingSeconds)) {
                return "00:00";
            }
            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (remainingSeconds < 10) {
                remainingSeconds = "0" + remainingSeconds;
            }
            return hours + ":" + minutes + ":" + remainingSeconds;
        }
    },
    watch: {
        selectTask: {
            handler() {
                this.selectTaskName = this.selectTask && this.selectTask.uavName ? this.selectTask.uavName : '';
            },
            deep: true
        }
    },
    mounted() {
        this.selectTaskName = this.selectTask && this.selectTask.uavName ? this.selectTask.uavName : null;
    }
}
</script>

<style lang="scss" scoped>
.right-plane-Box {
    font-family: MicrosoftYaHei;
    width: 100%;
    height: 100%;
    padding: 16px 1px 0px 7px;
    box-sizing: border-box;
    .topinfo {
        height: 20%;
        background: #0B2554;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 14px 0px 11px;
        .topinfo-left {
            display: flex;
            align-items: center;
            span {
                margin-left: 8px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                .wrj {
                    font-size: 15px;
                    color: #84C0FF;
                }
            }
        }
        .imgUavInfo {
            width: 35px;
        }
    }
    .plane-info {
        height: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 0px;
        box-sizing: border-box;
        .bodyBox {
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 14px;
            font-weight: bold;
            .elColLeft {
                text-align: right;
                overflow: hidden;
                white-space: nowrap;
                color: #ffffff;
                margin-bottom: 0px;
            }
            .elColRight {
                text-align: start;
                color: #84c0ff;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 0px;
            }
        }
    }
    .plane-card {
        width: 100%;
        height: 53%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .plane-card-item {
            background: #0B2554;
            box-sizing: border-box;
            height: calc(50% - 8px);
            width: calc(50% - 8px);
            display: flex;
            padding: 0 10px;
            justify-content: space-between;
            align-items: center;
            color: #ffffff;
            &>span {
                display: flex;
                align-items: center;
                .imgUav {
                    width: 27px;
                    height: 27px;
                }
                .card-item-title{
                    margin-left: 8px;
                    line-height: 27px;
                    color: #FFFFFF;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #FFFFFF;
                }
                .card-item-value{
                    height: 25px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-size: 26px;
                    font-weight: bold;
                    color: #84C0FF;
                    line-height: 25px;
                }
                .card-item-unit{
                    margin-left: 8px;
                    height: 21px;
                    font-family: MicrosoftYaHei;
                    font-size: 16px;
                    color: #84C0FF;
                    line-height: 21px;
                }
            }
        }
    }
}

.flex {
    display: flex;
    align-items: center;
    &>.left>.el-row {
        display: flex;
        align-items: center;
    }
}
</style>