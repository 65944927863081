<template>
  <component
    :is="component"
    :projectInfo="projectInfo"
    ></component>
</template>
<script>

import loadingView from '@/components/loadingview/index.vue';
import screenCommon from './screenCommon/index.vue';
import screen from './screen/index.vue';
import emergencyScreen from './emergencyScreen/index.vue';
import { getProjectDetail } from "@/api/project/index";
import { getProjectDetail as getForestProjectDetail } from "@/api/forestGrass/index";
import { getProjectDetail as getPetroleumProjectDetail } from "@/api/oil-gas/index";
import {getEmergencytaskDetail } from "@/api/emergency/index";

export default {
  components: { screenCommon, loadingView, screen, emergencyScreen },
  data() {
    return {
      component: 'loadingView',
      mapping: {
        'petroleum': 'screen',
        'forest': 'screen',
        'emergency': 'emergencyScreen',
        'currency': 'screenCommon',
        'general': 'screenCommon'
      },
      projectInfo: {}
    }
  },
  created() {
    this.getProjectDetail()
  },
  methods: {
    // 获取项目信息
    getProjectDetail() {
      const { projectType, flyId, projectId } = this.$route.query;
      let getProjectDetailFun = '';
      switch(projectType) {
        case 'flight':
          getProjectDetailFun = getProjectDetail;
          break;
        case 'forest':
          getProjectDetailFun = getForestProjectDetail;
          break;
        case 'petroleum':
          getProjectDetailFun = getPetroleumProjectDetail;
          break;
        case 'emergency':
          getProjectDetailFun = getEmergencytaskDetail;
          break;
        default:
          getProjectDetailFun = getProjectDetail;
          break;
      }
      this.projectId = flyId;
      getProjectDetailFun({id: projectId}).then((res) => {
        if (res.data.code === 200) {
          let projectDetail = res.data.data;
          if (projectType !== 'flight' && projectType !== 'emergency' && (projectDetail.flyProjectID || flyId)) {
            getProjectDetail({id: projectDetail.flyProjectID || flyId}).then(flight => {
              if (flight.data.code === 200) {
                let flightData = flight.data.data;
                flightData.screenTitle = projectDetail.screenTitle;
                flightData.screenSubTitle = projectDetail.screenSubTitle;
                flightData.onDutyInfo = projectDetail.onDutyInfo;
                flightData.onDuty = projectDetail.onDuty;
                flightData.screenVO = projectDetail.screenVO;
                flightData.actualProjectId = projectDetail.id;
                this.projectInfo = flightData;
                let type = this.projectInfo.screenVO.route;
                if (type && this.mapping[type]) {
                  this.component = this.mapping[type];
                } else {
                  this.component = this.mapping['general'];
                }
              }
            });
          } else {
            this.projectInfo = projectDetail;
            let type = this.projectInfo.screenVO.route;
            if (type && this.mapping[type]) {
              this.component = this.mapping[type];
            } else {
              this.component = this.mapping['general'];
            }
          }
        }
      });
    },
  }
}
</script>
<style lang="scss">

</style>