var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "alarm-reallinfo-pop",
      attrs: { id: "alarm-reallinfo-pop" },
    },
    [
      _c("div", { staticClass: "alarm-pop-center" }, [
        _c("div", { staticClass: "alarm-pop-center-top" }, [
          _vm._v(_vm._s(_vm.$t("oilGasScreen.alarmInfo"))),
        ]),
        _c("div", { staticClass: "alarm-pop-center-msg" }, [
          _c(
            "div",
            { ref: "alarmListDom", staticClass: "alarm-pop-center-msgd" },
            [
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: { title: _vm.$t("oilGasScreen.alarmTypeInfo") },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("oilGasScreen.alarmTypeInfo")) +
                          "："
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "msg",
                      attrs: { title: _vm.alarmData.alarmTypeName },
                    },
                    [_vm._v(_vm._s(_vm.alarmData.alarmTypeName || "--"))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: {
                        title: _vm.$t("oilGasScreen.alarmLocationInfo"),
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("oilGasScreen.alarmLocationInfo")) +
                          "："
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "msg",
                      attrs: { title: _vm.alarmData.address },
                    },
                    [_vm._v(_vm._s(_vm.alarmData.address || "--"))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: { title: _vm.$t("oilGasScreen.alarmDeviceInfo") },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("oilGasScreen.alarmDeviceInfo")) +
                          "："
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "msg",
                      attrs: { title: _vm.uavName(_vm.alarmData.uavName) },
                    },
                    [_vm._v(_vm._s(_vm.uavName(_vm.alarmData.uavName)))]
                  ),
                ]),
              ]),
              _vm.alarmData.level
                ? _c("div", { staticClass: "msg-list" }, [
                    _c("div", { staticClass: "msg-item" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          attrs: { title: _vm.$t("warning.alarmLevel") },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("warning.alarmLevel")) +
                              "："
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "msg",
                          attrs: { title: _vm.alarmLevel(_vm.alarmData.level) },
                        },
                        [_vm._v(_vm._s(_vm.alarmLevel(_vm.alarmData.level)))]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: {
                        title: _vm.$t("oilGasScreen.flightProjectInfo"),
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("oilGasScreen.flightProjectInfo")) +
                          "："
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "msg",
                      attrs: { title: _vm.alarmData.projectName },
                    },
                    [_vm._v(_vm._s(_vm.alarmData.projectName || "--"))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: { title: _vm.$t("oilGasScreen.flightTaskInfo") },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("oilGasScreen.flightTaskInfo")) +
                          "："
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "msg",
                      attrs: { title: _vm.alarmData.taskName },
                    },
                    [_vm._v(_vm._s(_vm.alarmData.taskName || "--"))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: {
                        title: _vm.$t("oilGasScreen.inspectionProject"),
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("oilGasScreen.inspectionProject")) +
                          "："
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "msg",
                      attrs: { title: _vm.alarmData.inpectionProjectName },
                    },
                    [_vm._v(_vm._s(_vm.alarmData.inpectionProjectName || "--"))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: { title: _vm.$t("oilGasScreen.inspectionObject") },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("oilGasScreen.inspectionObject")) +
                          "："
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "msg",
                      attrs: { title: _vm.alarmData.inpectionObjectName },
                    },
                    [_vm._v(_vm._s(_vm.alarmData.inpectionObjectName || "--"))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: { title: _vm.$t("oilGasScreen.alarmTimeInfo") },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("oilGasScreen.alarmTimeInfo")) +
                          "："
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "msg",
                      attrs: { title: _vm.alarmData.alarmTime },
                    },
                    [_vm._v(_vm._s(_vm.alarmData.alarmTime || "--"))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: { title: _vm.$t("longitude") },
                    },
                    [_vm._v(_vm._s(_vm.$t("longitude")) + "：")]
                  ),
                  _c(
                    "div",
                    { staticClass: "msg", attrs: { title: _vm.alarmData.lon } },
                    [_vm._v(_vm._s(_vm.alarmData.lon || "--"))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label",
                      attrs: { title: _vm.$t("latitude") },
                    },
                    [_vm._v(_vm._s(_vm.$t("latitude")) + "：")]
                  ),
                  _c(
                    "div",
                    { staticClass: "msg", attrs: { title: _vm.alarmData.lat } },
                    [_vm._v(_vm._s(_vm.alarmData.lat || "--"))]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label label-msg",
                      attrs: { title: _vm.$t("oilGasScreen.alarmPicInfo") },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("oilGasScreen.alarmPicInfo")) +
                          "："
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "div",
                    { staticClass: "msg-img" },
                    _vm._l(
                      _vm.alarmPics(_vm.alarmData.picUrl),
                      function (item, index) {
                        return _c("el-image", {
                          key: index,
                          staticClass: "img",
                          attrs: {
                            src: item,
                            "preview-src-list": _vm.alarmPics(
                              _vm.alarmData.picUrl
                            ),
                          },
                        })
                      }
                    ),
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label label-msg",
                      attrs: { title: _vm.$t("warning.video") },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("warning.video")) +
                          "："
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "msg-list" }, [
                _c("div", { staticClass: "msg-item" }, [
                  _c(
                    "div",
                    { staticClass: "msg-video" },
                    _vm._l(
                      _vm.alarmVideos(_vm.alarmData.videoUrl),
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item + index,
                            staticClass: "video-list",
                            on: {
                              click: function ($event) {
                                return _vm.openViewVideoDialog(item)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "video-list-img",
                              attrs: {
                                src: require("@/assets/images/alarmvideo-bg.png"),
                                alt: "",
                              },
                            }),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _vm.viewVideoDialog
        ? _c(
            "el-dialog",
            {
              staticClass: "viewVideoDialog",
              attrs: {
                title: _vm.$t("warning.video"),
                visible: _vm.viewVideoDialog,
                width: "680px",
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                top: "20vh",
                "before-close": _vm.handleCloseVideo,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.viewVideoDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _vm.viewVideoUrl
                    ? _c("xg-player", {
                        ref: "videoLive",
                        staticClass: "videoPlayer-xg-player",
                        attrs: {
                          videoUrl: _vm.viewVideoUrl,
                          videoType: _vm.videoType(_vm.viewVideoUrl),
                          controls: true,
                          screen: false,
                          showDownLoad: false,
                          show: true,
                          isShowMenu: false,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }