var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rightWrap" }, [
    !_vm.isOther
      ? _c(
          "div",
          { staticClass: "duty" },
          [
            _c("Base", {
              attrs: { title: "值班值守" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function () {
                      return [_c("Duty", { attrs: { detail: _vm.detail } })]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                4294700785
              ),
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "today", class: _vm.isOther ? "hide-Duty" : "" },
      [
        _c("Base", {
          attrs: { title: "当日报警统计" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("Today", { staticStyle: { "margin-bottom": "10px" } }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "trend" },
      [
        _c("Base", {
          attrs: { title: "报警趋势" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [_c("Trend")]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }