<template>
  <div class="headerWrap">
    <div class="left">
      <div class="date">
        <div>
          <p>{{ date.date }}</p>
          <p>{{ date.day }}</p>
        </div>
        <div>
          <p>{{ date.time }}</p>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="title-container">
        <p class="subTitle" v-if="projectInfo.screenSubTitle">{{ projectInfo.screenSubTitle }}</p>
        <p class="title">{{ projectInfo.screenTitle }}</p>
      </div>
    </div>
    <div class="right">
      <div v-if="!isPri" class="weather">
        <area-cascader class="areaCascader" ref="areaCascader" @change="areaChange" :data="pcaa" v-model="selected"
          :level="0" type="all">
        </area-cascader>
        <div class="cloud flex" v-if="weather.today && Object.keys(weather.today).length">
          <p class="b20" style="line-height: 18px">
            {{ weather.today.temperature + "℃" }}
          </p>
          <p class="mini">
            {{
              weather.today.directionName + "风" + weather.today.speed + "m/s"
            }}
          </p>
        </div>
        <div class="today">
          <p class="b16">{{ weather.today.description || '' }}</p>
        </div>
      </div>
      <div class="user">
        <div @click="goBack" class="flex">
          <img src="~@/assets/images/shift.png" alt=""  />
          <p>管理后台</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import "vue-area-linkage/dist/index.css";
import { getWeather } from "@/api/screen/index.js";
import { pca, pcaa } from "area-data";
import { getLocation } from "@/api/weather/query";
export default {
  data() {
    return {
      pca: pca,
      pcaa: pcaa,
      cityName: '',
      cityCode: '',
      selected: [],
      timmer: null,
      weather: {
        today: {},
        tomorrow: {},
      },
      date: {
        time: null,
        date: null,
        month: null,
        day: null,
      },
      isPri: true
    };
  },
  props: {
    projectInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  mounted() {
    if (!process.env.VUE_APP_IS_PRI) {
      this.isPri = false
      this.getLocation().then(res => {
        if (res && res.data && res.data.code === 200) {
          this.getWeatherInfo();
        }
      });
    }
    this.queryDate();
  },
  beforeDestroy() {
    if (this.$options.timmer) {
      clearInterval(this.$options.timmer)
      this.$options.timmer = null;
    }
  },
  methods: {
    getLocation() {
      return new Promise((resolve, reject) => {
        getLocation().then(res => {
          const data = res.data.data;
          if (!data) return
          const { cityCode, city, proCode } = data;
          this.cityName = city;
          this.cityCode = cityCode;
          this.selected = [proCode, cityCode]
          resolve();
        })
      })
    },
    areaChange(e) {
      const city = e.at(-1)
      if (city) {
        for (let key in city) {
          let cityName = city[key]
          if (cityName === "市辖区") {
            cityName = Object.values(e[0])[0];
          }
          this.cityName = cityName;
          this.cityCode = key;
          document.querySelector('.area-selected-trigger').innerText = cityName;
        }
      }
      this.getWeatherInfo();
    },
    goBack() {
      this.$router.go(-1)
    },
    getWeatherInfo() {
      const params = {
        code: this.cityCode,
        city: this.cityName,
      };
      getWeather(params).then((r) => {
        const data = r.data.data;
        this.weather.today = data.realtime;
        this.weather.tomorrow = data.dailies[1];
      });
    },
    makeLevel(speed) {
      if (!speed) return "-";
      if (speed >= 1 && speed <= 5) {
        return "1";
      }
      if (speed > 5 && speed <= 11) {
        return "2";
      }
      if (speed > 11 && speed <= 19) {
        return "3";
      }
      if (speed > 19 && speed <= 28) {
        return "4";
      }
      if (speed > 28 && speed <= 38) {
        return "5";
      }
      if (speed > 38 && speed <= 49) {
        return "6";
      }
      if (speed > 49 && speed <= 61) {
        return "7";
      }
      if (speed > 61 && speed <= 74) {
        return "8";
      }
      if (speed > 74 && speed <= 88) {
        return "9";
      }
      if (speed > 88 && speed <= 102) {
        return "10";
      }
      if (speed > 117 && speed <= 134) {
        return "11";
      }
      if (speed > 134 && speed <= 149) {
        return "12";
      }
      if (speed > 149 && speed <= 166) {
        return "13";
      }
      if (speed > 166 && speed <= 183) {
        return "14";
      }
      if (speed > 183) {
        return "15";
      }
    },
    queryDate() {
      const date = new Date();
      this.date.date = dayjs(date).format("YYYY.MM.DD");
      this.date.time = dayjs(date).format("HH:mm:ss");
      //   this.date.month = dayjs(date).format("M月");
      this.date.day = " 星期" + "日一二三四五六".charAt(date.getDay());
      this.$options.timmer = setInterval(() => {
        const date = new Date();
        this.date.date = dayjs(date).format("YYYY.MM.DD");
        this.date.time = dayjs(date).format("HH:mm:ss");
        this.date.day = " 星期" + "日一二三四五六".charAt(date.getDay());
        // this.date.month = dayjs(date).format("M月");
      }, 1000);
    },
    logout() {
      localStorage.clear();
      this.$router.push({ path: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .area-select {
  background: transparent;
  border: none;
  width: unset;
  height: unset;

  .area-selected-trigger {
    font-size: 20px;
    font-weight: bold;
    color: #d9e6ff;
  }

  .cascader-menu-list-wrap {
    left: 50%;
    transform: translateX(-50%);
  }
}

.headerWrap {
  * {
    color: #ffffffcb;
  }


  width: 100%;
  height: 100%;
  display: flex;

  &>div {
    flex: 1;
    height: 100%;
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;

    .date {
      display: flex;
      align-items: center;

      &>div:nth-of-type(1) {
        display: flex;
        flex-direction: column;

        p {
          font-size: 14px;
          font-weight: bold;
          color: #d9e6ff;
        }

        margin-right: 20px;
      }

      &>div:nth-of-type(2) {
        display: flex;
        align-items: center;

        p {
          font-size: 32px;
          font-weight: bold;
          color: #d9e6ff;
        }
      }
    }
  }

  .center {
    text-align: center;

    .title-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        height: 40px;
      }

      .subTitle {
        font-size: 26px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #85beff;
        text-shadow: 0px 2px 4px rgba(0, 67, 141, 0.54);
      }

      .title {
        font-size: 40px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #cbf7ff;
        text-shadow: 0px 2px 4px #003a7a;
        line-height: 40px;
      }
    }
  }

  .right {
    display: flex;

    .flex {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 40px;
    }

    .mini {
      font-size: 12px;
      color: #d9e6ff;
    }

    .b20 {
      font-size: 20px;
      font-weight: bold;
      color: #d9e6ff;
    }

    .b16 {
      font-size: 12px;
      font-weight: bold;
      color: #d9e6ff;
    }

    .weather {
      display: flex;
      align-items: center;

      // margin-right: 12px;
      .city {
        cursor: pointer;
        margin-right: 12px;
      }

      .cloud {
        margin-right: 12px;
      }

      .today {
        margin-right: 16px;

        p {
          font-size: 16px;
        }
      }
    }

    padding-right: 12px;

    img {
      height: 18px;
      width: 18px;
      margin-bottom: 4px;
    }

    display: flex;
    justify-content: flex-end;

    .user {
      padding-right: 8px;
      position: relative;
      display: flex;
      align-items: center;

      p {
        user-select: none;
      }

      &>div {
        img {
          cursor: pointer;
        }
      }

      .info {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 28px;
          background: #445b7c;
          left: -8px;
          bottom: 0;
          top: 50%;
          transform: translate(0%, -50%);
        }
      }

      .switch {
        padding: 0 16px;
        position: relative;

        ::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 28px;
          background: #445b7c;
          left: 8px;
          bottom: 0;
          top: 50%;
          transform: translate(0%, -50%);
        }

        ::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 28px;
          background: #445b7c;
          right: 8px;
          bottom: 0;
          top: 50%;
          transform: translate(0%, -50%);
        }
      }
    }
  }
}
</style>
