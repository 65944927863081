<template>
    <div class="headerWrap">
        <div class="left">
            <div class="date">
                <div>
                    <p>{{ date.date }}</p>
                    <p>{{ date.day }}</p>
                </div>
                <div>
                    <p>{{ date.time }}</p>
                </div>
            </div>
        </div>
        <div class="center">
            <div class="title-container">
                <p class="subTitle" v-if="projectInfo.screenSubTitle">{{ projectInfo.screenSubTitle }}</p>
                <p class="title">{{ projectInfo.screenTitle }}</p>
            </div>
        </div>
        <div class="right">
            <div class="user user-info">
                <i class="el-icon-user"></i>
                <p>{{ userInfo.nick_name }}</p>
            </div>
            <div @click="goBack" class="user">
                <img src="~@/assets/images/shift.png" alt=""  />
                <p>{{ $t('screen.goback') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import * as dayjs from "dayjs";
import { mapGetters } from "vuex";
import "vue-area-linkage/dist/index.css";

export default {
    name: 'emergencyScreen-Header',
    props: {
        projectInfo: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            date: {
                time: null,
                date: null,
                month: null,
                day: null
            },
        }
    },
    computed: {
        ...mapGetters([
            "userInfo"
        ])
    },
    mounted() {
        this.queryDate();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        queryDate() {
            const days = [
                this.$t('Monday'),
                this.$t('Tuesday'),
                this.$t('Wednesday'),
                this.$t('Thursday'),
                this.$t('Friday'),
                this.$t('Saturday'),
                this.$t('Sunday')
            ];
            const date = new Date();
            this.date.date = dayjs(date).format("YYYY.MM.DD");
            this.date.time = dayjs(date).format("HH:mm:ss");
            this.date.day = days[date.getDay() - 1];
            this.timmer = setInterval(() => {
                const date = new Date();
                this.date.date = dayjs(date).format("YYYY.MM.DD");
                this.date.time = dayjs(date).format("HH:mm:ss");
                this.date.day = days[date.getDay() - 1];
            }, 1000);
        }
    },
    beforeDestroy() {
        if (this.$options.timmer) {
            clearInterval(this.$options.timmer);
            this.$options.timmer = null;
        }
    }
}
</script>

<style lang="scss" scoped>
.headerWrap {
    position: relative;
    color: #D9E6FF;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;
        .date {
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 14px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            color: #D9E6FF;
            &>div:last-child {
                margin-left: 20px;
                width: 137px;
                height: 42px;
                font-size: 32px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                color: #D9E6FF;
                line-height: 42px;
            }
        }
    }
    .center {
        height: 100%;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        .title-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .subTitle {
                margin-top: 15px;
                height: 26px;
                font-size: 26px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                line-height: 26px;
                color: #85BEFF;
            }
            .title {
                height: 52px;
                font-size: 40px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                line-height: 52px;
                color: #FFF;
                background: linear-gradient(180deg, #AFC5DD 0%, #E9F1FF 53%, #7FB6FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
    .right {
        height: 100%;
        display: flex;
        padding-right: 12px;
        justify-content: flex-end;
        .user {
            padding-right: 8px;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            p {
                margin-top: 6px;
                user-select: none;
                font-size: 13px;
                font-family: MicrosoftYaHei;
                color: #D9E6FF;
                line-height: 12px;
            }
            i {
                font-size: 18px;
            }
            img {
                width: 18px;
                height: 18px;
            }
        }
        .user-info {
            padding-right: 15px;
        }
    }
}
</style>