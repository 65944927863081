<template>
    <!-- 报警类型统计 -->
    <div class="alarmType">
        <div class="chart-cont">
            <chartBar 
                :id="'alarmType-chart'"
                :grid="{left: '2%', right: '2%', top: '23%', bottom: '8%'}"
                :legendWidth="'auto'"
                :barMaxWidth="'15'"
                :isBarMinHeight="true"
                :barMinHeight="10"
                :tooltip="tooltip"
                :barBorderRadius="[100, 100, 0, 0]"
                :xAxisLabel="{color: '#fff', rotate: 0, fontSize: 10}"
                :isZoom="true"
                :showZoomDataNum="6"
                :zoomOption="{width: '100%', height: 15, backgroundColor: '#1E222A', fillerColor: '#6AA4E6', 
                    bottom: 0, end: 50}"
                :chartData="chartData"
            />
        </div>
    </div>
</template>

<script>
import chartBar from '@/components/echarts/chartBar.vue';

export default {
    name: 'alarmType',
    components: {chartBar},
    props: ['alarmTypeData'],
    data() {
        return {
            chartData: [],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                valueFormatter: (value) => {
                    return value || 0;
                }
            }
        }
    },
    watch: {
        'alarmTypeData': {
            deep: true,
            handler: function() {
                this.handlerData();
            }
        }
    },
    methods: {
        handlerData() {
            let chartData = [];
            Object.keys(this.alarmTypeData).map(t => {
                let charItem = [];
                Object.keys(this.alarmTypeData[t]).map(i => {
                    charItem.push({name: i, value: this.alarmTypeData[t][i] || 0});
                });
                chartData.push({name: t, data: charItem});
            });
            this.chartData = chartData;
        }
    }
}
</script>

<style lang="scss" scoped>
.alarmType {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .chart-cont {
        width: 100%;
        height: 100%;
        position: relative;
    }
}
</style>