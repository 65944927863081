<template>
  <div class="screen_new_map_box" id="screen_new_map_box">
    <div id="cesiumContainer_new_map_center"></div>
    <!-- 右上角工具栏 -->
    <div class="right-top-tools">
      <div
        v-if="hasBtn && showTopTools"
        class="full-screen"
        @click="tiggerFullScreen"
        title="全屏">
        <img
          :class="$i18n.locale ==='zh'? 'full-image-zh': 'full-image'"
          :src="require(`@/assets${$i18n.locale ==='zh'? '': '/en'}/map/full.png`)" />
      </div>
      <div
        class="track-screen"
        @click="trackTo">
        <img 
          :class="$i18n.locale ==='zh'? 'full-image-zh': 'full-image'"
          :src="require(`@/assets${$i18n.locale ==='zh'? '': '/en'}/map/track${beingTracked? '_active': ''}.png`)" 
          />
      </div>
      <div
        @click="taskRouteHandler"
        class="route-task-line">
        <img
          :class="$i18n.locale ==='zh'? 'full-image-zh': 'full-image'"
          :style="$i18n.locale ==='zh'?`height: 48px`:``"
          :src="require(`@/assets${$i18n.locale ==='zh'? '': '/en'}/map/route${showRouteLine? '_active': ''}.png`)"
          />
      </div>
      <div 
        @click="updateRoute($options.map3d, selectTask.taskId)"
        class="route-line">
        <img 
          :class="$i18n.locale ==='zh'? 'full-image-zh': 'full-image'"
          :src="require(`@/assets${$i18n.locale ==='zh'? '': '/en'}/map/refresh.png`)" />
      </div>
    </div>
    <!-- 左下角工具栏 -->
    <div v-if="showBottomTools" id="baseController" :class="showRightPanel? 'open-oilInspection-panel': ''">
      <!-- <img
        id="navigationHelpButtonContainer"
        src="/img/map/compass.png"
        :style="'transform: rotate(' + degrees + 'deg)'"/> -->
      <div class="other">
        <div
          class="zoomBtn"
          style="border-radius: 4px;"
          @click="locationTo">
          <img src="/img/map/location.png" />
        </div>
        <!-- <div
          class="zoomBtn"
          style="border-top-right-radius: 4px; border-top-left-radius: 4px"
          @click="zoom(1)">
          <img src="/img/map/magnification.png" />
        </div>
        <div
          class="zoomBtn"
          style="border-bottom-right-radius: 4px;
            margin-top: 2px;
            border-bottom-left-radius: 4px;"
          @click="zoom(-1)">
          <img src="/img/map/reduction.png" />
        </div>
        <div @click="switchLayerType" :title="`${isVec ? '卫星图' : '矢量图'}`">
          <img
            class="layerSwitch"
            :src="`/img/map/${isVec ? '3D.png' : '2D.png'}`"/>
        </div> -->
      </div>
    </div>
    <div v-if="hoverTip.show"
      :style="`
        left: ${hoverTip.left}px;
        top: ${hoverTip.top}px;
        transform: translateY(calc(-100% - ${hoverTip.type===NodeMap.Node.POINT? 40: 18}px)) translateX(-50%);
      `"
      class="hover-tip">
      <div class="hover-tip-triangle"></div>
      <div class="hover-tip-item" v-for="item in hoverTip.list" :key="item.label">
        <span>{{ item.label }}: </span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <!-- 基站悬浮窗 -->
    <BaseStationPop ref="BaseStationPop" :detailData="baseStationDetail" />
    <!-- 飞机实时信息浮窗 -->
    <UavRealInfoPop ref="UavRealInfoPop" v-if="isEmergency" />
  </div>
</template>

<script>
import Map3d from "@/components/cesium/js/Map3d";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import { wgsArr2cartesianArr } from "@/util/mapUtils";
import transformTranslate from "@turf/transform-translate";
import { mapGetters } from "vuex";
import baseLine from "./line.json";
import { getInspectionObjects, getMarkObjects } from "@/api/project/label";
import { getMarkPoint, getMarkPolyline, getMarkPolygon } from "@/api/project/map";
import {getHangarList} from '@/api/hangar/index';
import NodeMap from '@/components/cesium/js/node/index';
import greenLandProvider from '@/components/cesium/mixins/greenLandProvider.vue';
import routeLine from '@/components/cesium/mixins/routeLine.vue';
import oilInspectionVue from '@/components/cesium/mixins/oilInspection.vue';
import noFlyZoneShowVue from "@/components/cesium/mixins/noFlyZoneShow.vue";
import airportShowVue from "@/components/cesium/mixins/airportShow.vue";
import basestationShowVue from "@/components/cesium/mixins/basestationShow.vue";
import UavRealInfoPop from "@/components/cesium/components/uav-realInfo-pop.vue";
// 图标
import trackIcon from '@/assets/map/track.png'
import track_activeIcon from '@/assets/map/track_active.png'
import routeIcon from '@/assets/map/route.png'
import route_activeIcon from '@/assets/map/route_active.png'
import PlaneIcon from "@/assets/img/map/XC_64x64_.png";
import PlaneActiveIcon from "@/assets/img/map/XC_seleted.png";
import HomeIcon from "@/assets/map/homePoint.png";
import HomeActiveIcon from "@/assets/map/homePoint-active.png";
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';
import userHangarHook from '@/components/cesium/js/hooks/hangarHook';

export default {
  name: "Map",
  mixins: [ greenLandProvider, routeLine, oilInspectionVue, noFlyZoneShowVue, airportShowVue, basestationShowVue ],
  components: { UavRealInfoPop },
  data() {
    return {
      NodeMap,
      viewModel: Cesium.SceneMode.SCENE3D,
      // 巡检对象图层
      inspectionLayer: null,
      // 标注定时器
      emergencyMakerTimer: null,
      markData: [], // 标注数据
      // 标注图层
      markLayer: null,
      // 是否跟踪无人机
      beingTracked: true,
      // 是否第一次设置跟随，当前值改变一次后就无需改变了，仅限判断是否是第一次设置跟随
      isSetFlight: false,
      // 一些图标变量
      trackIcon,
      track_activeIcon,
      PlaneIcon,
      routeIcon,
      route_activeIcon
    };
  },
  props: {
    projectInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    hasBtn: {
      type: Boolean,
      default: true
    },
    // 是否为应急
    isEmergency: {
      type: Boolean,
      default: false
    },
    // 是否展示右侧顶部操作栏
    showTopTools: {
      type: Boolean,
      default: true
    },
    // 是否展示右侧底部操作栏
    showBottomTools: {
      type: Boolean,
      default: true
    },
    // 切换飞机是否需要更改实体样式
    isChangeStyle: {
      type: Boolean,
      default: false
    },
    // 是否需要展示飞机跟随tips
    showMapTips: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'selectTask',
      'currentTaskMap',
      'currentTaskList',
      'itemForTaskMapChange'
    ]),
    // 当前正在执行任务列表
    taskIds() {
      let taskIds = this.currentTaskMap ? (Object.keys(this.currentTaskMap) || []) : [];
      return taskIds;
    }
  },
  watch: {
    itemForTaskMapChange: {
      handler(val) {
        if (val) {
          this.updatePlane(val);
        }
      },
      deep: true, //true 深度监听
    },
    selectTask: {
      handler: function(newVal, oldVal) {
        this.changeFlyStyle(oldVal, 'init');
        this.updateRoute(this.$options.map3d, newVal.taskId)
        if (newVal && newVal.taskId) {
          this.changetrackTo();
          this.changeFlyStyle(newVal, 'change');
        }
      }
    }
  },
  mounted() {
    this.$options.flyRouteHook = liveFlyRouteHook();
    this.$options.hangarHook = userHangarHook();
    this.init();
  },
  methods: {
    init() {
      const viewer = new Map3d("cesiumContainer_new_map_center", {
        sceneMode: this.viewModel,
        i18n: this.$i18n
      });
      this.$options.map3d = viewer;
      this.initLayer();
      this.getNoFlyDataTwo(); // 加载禁飞区
      this.getAirportData(); // 加载机场
      this.getBasestationData(); // 加载基站
      this.getHangarData(); // 获取加载机库列表
      this.requestInspectLabel(this.$route.query.flyId)
      if (this.isEmergency) {
        this.requestEmergencyMakerLabel(this.$route.query.projectId)
        if (this.emergencyMakerTimer) clearInterval(this.emergencyMakerTimer);
        this.emergencyMakerTimer = setInterval(() => {
          this.requestEmergencyMakerLabel(this.$route.query.projectId)
        }, 5000);
      }
      this.setEditModel()
      this.initGreenLand()
      setTimeout(() => {
        if (this.selectTask && this.selectTask.taskId) {
          this.initRouteLine(this.$options.map3d, this.selectTask.taskId)
        }
      }, 1000);
    },
    // 初始化图层
    initLayer() {
      this.lineLayer = new BaseLayer({ name: "lineLayer" });
      this.$options.flyRouteHook.setMap({map3d: this.$options.map3d, flylineLayerID: "flylineLayer", 
        showVisualPolygon: true, showHomePoint: true, flightFollowing: this.beingTracked});
      this.initNoflyShowLayer();
      this.initAirportShowLayer();
      this.initBasestationShowLayer();
      this.$options.hangarHook.setMap({map3d: this.$options.map3d, layerID: 'hangarLayer'});
      this.$options.map3d.layerManager.add(this.lineLayer);
      this.inspectionLayer = new BaseLayer({ name: "inspectionLayer" });
      this.$options.map3d.layerManager.add(this.inspectionLayer);
      this.markLayer = new BaseLayer({ name: "markLayer" });
      this.$options.map3d.layerManager.add(this.markLayer);
    },
    // 
    loadBaseLine() {
      const temp = JSON.parse(JSON.stringify(baseLine));
      const lineRight = transformTranslate(temp.geometry, 50 / 1000, 0);
      const lineLeft = transformTranslate(temp.geometry, 50 / 1000, 180);
      const car3 = wgsArr2cartesianArr(temp.geometry.coordinates);
      const lineCenter = new Cesium.Entity({
        id: "lineCenter",
        polyline: {
          positions: new Cesium.CallbackProperty(function () {
            return car3;
          }, true),
          material: new Cesium.Color.fromCssColorString("#FF0000"),
          width: 3,
        },
      });
      const car3right = wgsArr2cartesianArr(lineRight.coordinates);
      const lineRightEntity = new Cesium.Entity({
        id: "lineRight",
        polyline: {
          positions: new Cesium.CallbackProperty(function () {
            return car3right;
          }, true),
          material: new Cesium.Color.fromCssColorString("#0000FF"),
          width: 3,
        },
      });
      const car3Left = wgsArr2cartesianArr(lineLeft.coordinates);
      const lineLeftEntity = new Cesium.Entity({
        id: "lineLeft",
        polyline: {
          positions: new Cesium.CallbackProperty(function () {
            return car3Left;
          }, true),
          material: new Cesium.Color.fromCssColorString("#0000FF"),
          width: 3,
        },
      });
      this.lineLayer.add(lineCenter);
      this.lineLayer.add(lineRightEntity);
      this.lineLayer.add(lineLeftEntity);
      this.$options.map3d.zoomTo(lineCenter);
    },

    tiggerFullScreen() {
      this.$emit("fullScreen");
    },

    updateFlyRoute(value) {
      const id = value.key;
      let taskData = this.currentTaskMap[id];
      this.$options.flyRouteHook.findEntityById({id, showEntityLabel: true, entityLabelText: value.airName, 
        entityIcon: taskData.icon || this.PlaneIcon, iconSize: 60, homeIcon: HomeIcon, homeIconSize: 30, 
        lineColor: "#FFE600", lineWidth: 3, polygonColor: "#FFE600", polygonAlpha: 0.45, data: value.data});
      let UavRealInfoPop = this.$refs.UavRealInfoPop;
      if (this.selectTask.taskId == id && this.isEmergency) {
        // 设置飞行浮窗
        if (UavRealInfoPop && this.taskIds.length > 1) {
          const tempLon = Number(value.data.lon) * 1;
          const tempLat = Number(value.data.lat) * 1;
          this.$options.flyRouteHook.addUavRealInfoPop({
            id: 'uav-realInfo-pop',
            parentId: 'screen_new_map_box',
            positions: [tempLon, tempLat, 0],
            x: 0,
            y: 32
          });
        }
      }
      // 当前选择的任务id和更新的实体id一致则表示当前实体需要设置跟随以及切换实体样式
      if (this.selectTask.taskId == id && !this.isSetFlight) {
        setTimeout(() => {
          this.beingTracked = this.$options.flyRouteHook.setFlightFollowingById(this.selectTask.taskId);
          let currentTask = this.currentTaskMap[this.selectTask.taskId];
          this.isChangeStyle && this.$options.flyRouteHook.changeEntityStyle(
            this.selectTask.taskId,
            currentTask ? (currentTask.selectIcon || PlaneActiveIcon) : PlaneActiveIcon,
            '#FFFFFF',
            HomeActiveIcon
          );
          if (UavRealInfoPop && this.isEmergency && this.taskIds.length > 1) {
            UavRealInfoPop.show = true;
          }
        }, 2500);
        this.isSetFlight = true;
      }
    },

    updatePlane(data) {
      this.updateFlyRoute(data)
    },
    
    requestInspectLabel(id) {
      if (!id) return;
      getInspectionObjects({
        projectId: id,
        businessType: 1
      }).then(res=>{
        const code = res.data.code
        if (code === 200) {
          const data = res.data.data
          if (data) {
            this.inspectionPathList = data.map(item=>{
              return {
                label: item.dirName,
                value: item.id
              }
            })
            // 给编辑图层集合添加图层
            this.addInspectLabels(data)
          }
        }
      })
    },

    addInspectLabels (data) {
      this.createInspectionsIntoLayer(data, this.inspectionLayer)
    },

    requestEmergencyMakerLabel(id) {
      if (!id) return;
      getMarkObjects({projectId: id}).then(res => {
        const code = res.data.code;
        if (code === 200) {
          const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];
          const removeData = this.markData.filter(
            oldItem => data.findIndex(newItem => newItem.id === oldItem.id) === -1
          );
          this.markData = data;
          removeData.map(t => {
            this.markLayer.removeNodeById(t.id);
          });
          this.addEmergencyMakerLabels(data);
        }
      });
    },
    addEmergencyMakerLabels(data) {
      for (let j = 0; j < data.length; j++) {
        const mark = data[j];
        let markNode = this.markLayer.getNodeById(mark.id);
        if (markNode) {
          this.createMarkNode(mark, markNode);
        } else {
          const node = this.createMarkNode(mark);
          this.markLayer.addNode(node);
        }
      }
    },
    createMarkNode(mark, markNode) {
      const geojson = mark.content;
      const type = geojson.geometry.type;
      const coordinates = geojson.geometry.coordinates;
      let node;
      if (type === "Point") {
        if (markNode) {
          markNode.name = mark.name;
          markNode.color = geojson.properties.color ? geojson.properties.color : markNode.color;
          markNode.position = Cesium.Cartesian3.fromDegrees(...coordinates);
        } else {
          node = new NodeMap.MarkPoint({
            id: mark.id,
            name: mark.name,
            createBy: mark.createBy,
            show: !!mark.visibility,
            dirId: mark.dirId,
            color: geojson.properties.color,
            isFire: !!geojson.properties.icon,
            position: Cesium.Cartesian3.fromDegrees(...coordinates),
            canEdit: false
          });
        }
      }
      if (type === "LineString") {
        var positions = [];
        for (let k = 0; k < coordinates.length; k++) {
          const coordinate = coordinates[k];
          const position = Cesium.Cartesian3.fromDegrees(...coordinate);
          positions.push(position);
        }
        if (markNode) {
          markNode.entity.label.text._value = mark.name;
          markNode.color = geojson.properties.color ? geojson.properties.color : markNode.color;
          markNode.positions = positions;
        } else {
          node = new NodeMap.MarkLine({
            id: mark.id,
            positions,
            dirId: mark.dirId,
            createBy: mark.createBy,
            color: geojson.properties.color,
            show: !!mark.visibility,
            name: mark.name,
            canEdit: false
          });
        }
      }
      if (type === "Polygon") {
        var positions = [];
        for (let k = 0; k < coordinates[0].length - 1; k++) {
          const coordinate = coordinates[0][k];
          const position = Cesium.Cartesian3.fromDegrees(...coordinate);
          positions.push(position);
        }
        if (markNode) {
          markNode.entity.label.text._value = mark.name;
          markNode.color = geojson.properties.color ? geojson.properties.color : markNode.color;
          markNode.positions = positions;
        } else {
          node = new NodeMap.MarkPolygon({
            id: mark.id,
            positions,
            dirId: mark.dirId,
            createBy: mark.createBy,
            color: geojson.properties.color,
            show: !!mark.visibility,
            name: mark.name,
            canEdit: false
          });
        }
      }
      return node;
    },
    openScreenMakerHovertip(node) {
      if (!node || !node.id) return;
      let entity = this.markLayer.getById(node.id);
      if (!entity) return;
      let distance = '0m',
        dis,
        suffix,
        horiDistance = 0;
      switch (node.type) {
        case NodeMap.Node.POINT:
          getMarkPoint({id: node.id}).then(res => {
            if (res.data.code === 200) {
              const data = res.data.data;
              this.hoverTip.show = true;
              this.hoverTip.list = [{label: this.$t('name'), value: data.name}];
            }
          });
          break;
        case NodeMap.Node.LINE:
          getMarkPolyline({id: node.id}).then(res => {
            if (res.data.code === 200) {
              const data = res.data.data;
              // 水平距离/周长计算
              horiDistance = entity.horiDistance;
              dis = horiDistance.toFixed(2);
              suffix = 'm';
              if (dis > 1000) {
                dis = (dis / 1000).toFixed(2);
                suffix = 'km';
              }
              distance = dis + suffix;
              this.hoverTip.show = true;
              this.hoverTip.list = [
                {label: this.$t('name'), value: data.name},
                {label: this.$t('mark.horizontalDistance'), value: distance}
              ];
            }
          });
          break;
        case NodeMap.Node.POLYGON:
          getMarkPolygon({id: node.id}).then(res => {
            if (res.data.code === 200) {
              const data = res.data.data;
              // 水平距离/周长计算
              horiDistance = entity.horiDistance;
              dis = horiDistance.toFixed(2);
              suffix = 'm';
              if (dis > 1000) {
                dis = (dis / 1000).toFixed(2);
                suffix = 'km';
              }
              distance = dis + suffix;
              // 水平面积计算
              let area = '0m²';
              let square = data.area;
              if (square && square > 1000 * 1000) {
                area = (square / 1000 / 1000).toFixed(2) + 'km²';
              } else if (square && square <= 1000 * 1000) {
                area = square.toFixed(2)  + 'm²';
              }
              this.hoverTip.show = true;
              this.hoverTip.list = [
                {label: this.$t('name'), value: data.name},
                {label: this.$t('mark.horizontalPerimeter'), value: distance},
                {label: this.$t('mark.horizontalArea'), value: area}
              ];
            }
          });
          break;
      }
    },
    
    trackTo() {
      this.beingTracked = this.$options.flyRouteHook.changeFlightFollowing(this.selectTask.taskId);
    },

    changetrackTo() {
      this.beingTracked = this.$options.flyRouteHook.setFlightFollowingById(this.selectTask.taskId, true);
    },

    changeFlyStyle(data, type) {
      if (!data || !data.taskId) return;
      let currentTask = this.currentTaskMap[data.taskId];
      let PlaneIcon = this.PlaneIcon;
      if (currentTask) {
        PlaneIcon = type === 'init' ? (currentTask.icon || PlaneIcon) 
          : (currentTask.selectIcon || PlaneActiveIcon);
      }
      let color = type === 'init' ? '#FFE600' : '#FFFFFF';
      let homeImg = type === 'init' ? HomeIcon : HomeActiveIcon;
      this.isChangeStyle && this.$options.flyRouteHook.changeEntityStyle(data.taskId, PlaneIcon, color, homeImg);
      if (type === 'change' && this.isEmergency && !this.$parent.$parent.taskData) {
        let UavRealInfoPop = this.$refs.UavRealInfoPop;
        if (UavRealInfoPop) {
          UavRealInfoPop.show = true;
        }
      }
    },

    setEditModel(en = true) {
      console.log('当前地图注册的交互事件：', en );
      const self = this;
      this.activeDraw = ''
      if (en === true) {
        this.drawFinish = true;
        this.$options.map3d._container.style.cursor = "default";
      }
      // 绘制完成后需要自动进入编辑模式 同时对部分元素
      let afterFinish =  (model, origin, selectFeature) => {
        if (!model) {
          return;
        }
        if (model._drawType === 'FlyPoint' && this.isEmergency) {
          if (model && this.taskIds.length > 1) {
            if (!model.id) return;
            if (model.id === this.selectTask.taskId) {
              let UavRealInfoPop = this.$refs.UavRealInfoPop;
              if (UavRealInfoPop && !this.$parent.$parent.taskData) {
                UavRealInfoPop.show = true;
              }
            } else {
              let taskItem = this.currentTaskList.find(t => t.taskId === model.id);
              let noSetFirstTask = this.taskIds.length > 3 ? true : false;
              if (taskItem && !this.$parent.$parent.taskData) {
                taskItem && this.$emit('changeUav', taskItem, noSetFirstTask);
              }
            }
          }
        }
        let drawType = selectFeature && selectFeature.drawType
        this.selectedOilNode(selectFeature)
        this.$options.map3d.scene.screenSpaceCameraController.enableTilt = false;
        if (selectFeature && selectFeature.id.indexOf("uav-") > -1) {
          self.clickUav(selectFeature.id);
          return;
        }
        const primitive = model.parent ? model.parent : model;
        const orgId = primitive.orgId;
        drawType = primitive.drawType;
        // 若存在原始id 则代表需要调用 更新接口 而不是新增接口
        if (orgId && drawType !== "flyline") {
          const type = primitive.polygon
            ? "Polygon"
            : primitive.polyline
            ? "LineString"
            : "Point";
          const geojson = labelEntity2geojson(this.$options.map3d, primitive, type);
          const feature = {
            dirId: primitive.editLayer.id,
            geojson: JSON.stringify(geojson),
            tagName: geojson.properties.text,
            tagType: type,
            id: primitive.id,
          };
          // 后台接口弃用----lmw
          // const request = updateMapLabel(feature);
          // request.then((res) => {});
        }
      }
      // 注册完交互事件的对用回调
      this.$options.map3d.triggerEditModel(en, {
        map: this.$options.map3d,
        vueHandler: self,
        afterFinish
      });
    },

    taskRouteHandler() {
      this.showRouteLine = !this.showRouteLine
      this.showHiddenRoute()
    },

    zoom(amount) {
      let cartographic;
      if (this.viewModel === Cesium.SceneMode.SCENE3D) {
        const cameraPos = this.$options.map3d.camera.position;
        const ellipsoid = this.$options.map3d.scene.globe.ellipsoid;
        cartographic = ellipsoid.cartesianToCartographic(cameraPos);
      } else {
        cartographic = this.$options.map3d.camera.positionCartographic;
      }
      const height = cartographic.height;
      const centerLon = Cesium.Math.toDegrees(cartographic.longitude);
      const centerLat = Cesium.Math.toDegrees(cartographic.latitude);

      // 镜头动画
      this.$options.map3d.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          centerLon,
          centerLat,
          amount > 0 ? height / 1.8 : height * 1.8
        ),
        duration: 1.0,
      });
    },

    locationTo() {
      try {
        if (this.beingTracked) return;
        this.$options.flyRouteHook.locationToEntity(this.selectTask.taskId);
      } catch (error) {}
    },

    switchLayerType() {
      this.isVec = !this.isVec;
      const type = this.isVec ? "vector" : "satellite";
      this.$options.map3d.switchLayer(type);
    },
    // 获取机库列表并绘制
    getHangarData() {
      const { projectType } = this.$route.query;
      let uavList = [];
      if (projectType === 'emergency') {
        uavList = this.projectInfo ? (this.projectInfo.equipmentIds || []) : [];
      } else {
        uavList = this.projectInfo ? (this.projectInfo.uavList || []) : [];
      }
      this.$options.hangarHook.clearRenderLayer(); // 绘制之前清空一次
      if (uavList.length) {
        getHangarList(uavList).then(res => {
          if (res.data.code == 200) {
            this.$options.hangarHook.handlingHangarData({data: res.data.data || [], type: 'show'});
          }
        });
      }
    }
  },
  beforeDestroy() {
    if (this.emergencyMakerTimer) {
      clearInterval(this.emergencyMakerTimer);
      this.emergencyMakerTimer = null;
    }
    this.$options.flyRouteHook && this.$options.flyRouteHook.clearFlyRoute();
    this.$options.flyRouteHook = null;
    this.$options.hangarHook && this.$options.hangarHook.clearHangar();
    this.$options.hangarHook = null;
    this.$options.map3d && this.$options.map3d.destroy();
  }
};
</script>

<style lang="scss" scoped>
.screen_new_map_box{
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  #cesiumContainer_new_map_center {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
  }
  .right-top-tools {
    position: absolute;
    top: 10px;
    right: 10px;
    &>div {
      cursor: pointer;
    }
    .full-image-zh {
      width: 36px;
      height: 40px;
    }
    .full-image {
      width: 48px;
      height: 46px;
    }
  }
  #baseController {
    position: absolute;
    bottom: 10%;
    right: 1%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    #navigationHelpButtonContainer {
      width: 21px;
      height: 42px;
      margin-bottom: 10px;
    }
    .other {
      display: flex;
      flex-direction: column;
      align-items: center;
      .zoomBtn {
        display: flex;
        margin-top: 4px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 36px;
        height: 36px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .layerSwitch {
        margin-top: 20px;
        width: 32px;
        height: 32px;
        border-radius: 18px;
        border: 2px solid #fff;
        cursor: pointer;
      }
    }
    
  }
  .hover-tip {
    position: absolute;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFFFFF;
    padding: 8px;
    border-radius: 4px;
    &-triangle{
      position: absolute;
      box-sizing: border-box;
      border: 10px solid;
      border-color: transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8) transparent;
      left: 50%;
      top: 100%;
      transform: translateX(-50%)  translateY(-51%) rotateZ(45deg);
    }
    &-item{
      word-wrap: break-word
    }
  }
}
</style>
