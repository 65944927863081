<template>
    <!-- 报警地图弹窗 -->
    <div id="alarm-reallinfo-pop" class="alarm-reallinfo-pop" v-show="show">
        <div class="alarm-pop-center">
            <div class="alarm-pop-center-top">{{$t('oilGasScreen.alarmInfo')}}</div>
            <div class="alarm-pop-center-msg">
                <div class="alarm-pop-center-msgd" ref="alarmListDom">
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('oilGasScreen.alarmTypeInfo')">
                                {{$t('oilGasScreen.alarmTypeInfo')}}：</label>
                            <div class="msg" :title="alarmData.alarmTypeName">{{alarmData.alarmTypeName || '--'}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('oilGasScreen.alarmLocationInfo')">
                                {{$t('oilGasScreen.alarmLocationInfo')}}：</label>
                            <div class="msg" :title="alarmData.address">{{alarmData.address || '--'}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('oilGasScreen.alarmDeviceInfo')">
                                {{$t('oilGasScreen.alarmDeviceInfo')}}：</label>
                            <div class="msg" :title="uavName(alarmData.uavName)">{{uavName(alarmData.uavName)}}</div>
                        </div>
                    </div>
                    <div class="msg-list" v-if="alarmData.level">
                        <div class="msg-item">
                            <label class="label" :title="$t('warning.alarmLevel')">
                                {{$t('warning.alarmLevel')}}：</label>
                            <div class="msg" :title="alarmLevel(alarmData.level)">{{alarmLevel(alarmData.level)}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('oilGasScreen.flightProjectInfo')">
                                {{$t('oilGasScreen.flightProjectInfo')}}：</label>
                            <div class="msg" :title="alarmData.projectName">{{alarmData.projectName || '--'}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('oilGasScreen.flightTaskInfo')">
                                {{$t('oilGasScreen.flightTaskInfo')}}：</label>
                            <div class="msg" :title="alarmData.taskName">{{alarmData.taskName || '--'}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('oilGasScreen.inspectionProject')">
                                {{$t('oilGasScreen.inspectionProject')}}：</label>
                            <div class="msg" :title="alarmData.inpectionProjectName">{{alarmData.inpectionProjectName || '--'}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('oilGasScreen.inspectionObject')">
                                {{$t('oilGasScreen.inspectionObject')}}：</label>
                            <div class="msg" :title="alarmData.inpectionObjectName">{{alarmData.inpectionObjectName || '--'}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('oilGasScreen.alarmTimeInfo')">
                                {{$t('oilGasScreen.alarmTimeInfo')}}：</label>
                            <div class="msg" :title="alarmData.alarmTime">{{alarmData.alarmTime || '--'}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('longitude')">{{$t('longitude')}}：</label>
                            <div class="msg" :title="alarmData.lon">{{alarmData.lon || '--'}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('latitude')">{{$t('latitude')}}：</label>
                            <div class="msg" :title="alarmData.lat">{{alarmData.lat || '--'}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label label-msg" :title="$t('oilGasScreen.alarmPicInfo')">
                                {{$t('oilGasScreen.alarmPicInfo')}}：</label>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <div class="msg-img">
                                <el-image class="img" v-for="(item, index) in alarmPics(alarmData.picUrl)" :key="index" 
                                    :src="item" :preview-src-list="alarmPics(alarmData.picUrl)" />
                            </div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label label-msg" :title="$t('warning.video')">
                                {{$t('warning.video')}}：</label>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <div class="msg-video">
                                <div class="video-list" v-for="(item, index) in alarmVideos(alarmData.videoUrl)" 
                                    :key="item + index" @click="openViewVideoDialog(item)">
                                    <img class="video-list-img" src="@/assets/images/alarmvideo-bg.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 查看报警视频弹窗 -->
        <el-dialog class="viewVideoDialog" :title="$t('warning.video')" :visible.sync="viewVideoDialog" width="680px" 
            v-if="viewVideoDialog" :modal-append-to-body="false" :close-on-click-modal="false" top="20vh" 
            :before-close="handleCloseVideo">
            <div class="content">
                <xg-player class="videoPlayer-xg-player" v-if="viewVideoUrl" ref="videoLive" :videoUrl="viewVideoUrl" 
                    :videoType="videoType(viewVideoUrl)" :controls="true" :screen="false" :showDownLoad="false" 
                    :show="true" :isShowMenu="false" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import XgPlayer from '@/components/video-live-xgplayer';

export default {
    name: 'alarm-reallinfo-pop',
    components: { XgPlayer },
    props: {
        alarmData: {
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            show: false,
            viewVideoDialog: false,
            viewVideoUrl: ''
        }
    },
    computed: {
        uavName() {
            return function(data) {
                if (!data || !data.length) return '--';
                let names = '';
                let length = data.length;
                data.map((t, index) => {
                    names = index + 1 === length ? `${names}${t}` : `${names}${t},`;
                });
                return names ? names : '--';
            }
        },
        alarmLevel() {
            return function(data) {
                if (data == 1) {
                    return this.$t('warning.levelOne');
                } else if (data == 2) {
                    return this.$t('warning.levelTwo');
                } else if (data == 3) {
                    return this.$t('warning.levelThree');
                } else if (data == 4) {
                    return this.$t('warning.levelFour');
                }
                return '--';
            }
        },
        alarmPics() {
            return function(data) {
                if (!data) return [];
                if (Array.isArray(data)) {
                    return data;
                }
                let pics = data.split(',');
                return pics;
            }
        },
        alarmVideos() {
            return function(data) {
                if (!data) return [];
                if (Array.isArray(data)) {
                    return data;
                }
                let videos = data.split(',');
                return videos;
            }
        },
        videoType() {
            return function(data) {
                return data.substr(-3);
            }
        }
    },
    watch: {
        show(val) {
            if (val) {
                setTimeout(() => {
                    this.$refs.alarmListDom.scrollTop = 0;
                }, 500);
            }
        }
    },
    methods: {
        openViewVideoDialog(url) {
            this.viewVideoUrl = url;
            this.viewVideoDialog = true;
        },
        handleCloseVideo() {
            this.viewVideoDialog = false;
            this.viewVideoUrl = '';
        }
    }
}
</script>

<style lang="scss" scoped>
.alarm-reallinfo-pop {
    width: 300px;
    position: absolute;
    cursor: pointer;
    .alarm-pop-center {
        width: 300px;
        height: 406px;
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("~@/assets/images/mapTipsbigbg.png");
        .alarm-pop-center-top {
            height: 23px;
            line-height: 26px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            text-align: center;
        }
        .alarm-pop-center-msg {
            height: 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 18px 26px 22px 20px;
            .alarm-pop-center-msgd {
                height: 0;
                flex: 1;
                font-family: MicrosoftYaHeiUI;
                font-size: 14px;
                color: #FFFFFF;
                overflow: auto;
                .msg-list {
                    flex: 1;
                    margin-top: 8px;
                    &:first-child {
                        margin-top: 0;
                    }
                    .msg-item {
                        display: flex;
                        flex: 1;
                        .label {
                            width: 85px;
                            color: rgba(255, 255, 255, 0.7);
                            cursor: pointer;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            text-overflow: ellipsis;
                            word-break: break-all;
                            &.label-msg {
                                flex: 1;
                            }
                        }
                        .msg {
                            flex: 1;
                            margin-left: 5px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            text-overflow: ellipsis;
                            word-break: break-all;
                        }
                        .msg-img {
                            display: flex;
                            overflow: auto;
                            .img {
                                min-width: 75px;
                                width: 75px;
                                height: 48px;
                                margin-left: 5px;
                                &:first-child {
                                    margin-left: 0;
                                }
                            }
                        }
                        .msg-video {
                            display: flex;
                            overflow-x: auto;
                            overflow-y: hidden;
                            .video-list {
                                width: 75px;
                                min-width: 75px;
                                height: 48px;
                                margin-left: 5px;
                                cursor: pointer;
                                &:first-child {
                                    margin-left: 0;
                                }
                                .video-list-img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .viewVideoDialog {
        ::v-deep .el-dialog {
            background-color: rgba(0, 0, 0, 1);
            .el-dialog__header {
                .el-dialog__title {
                    font-size: 16px;
                    color: #FFFFFF;
                }
                .el-dialog__headerbtn .el-dialog__close {
                    color: #ffffff;
                }
            }
            .el-dialog__body {
                padding: 0;
                .content {
                    width: 100%;
                    height: 325px;
                    .videoPlayer-xg-player {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>