var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "uavPage", staticClass: "uav-page" }, [
    _c(
      "div",
      { staticClass: "swtch-list" },
      [
        _c(
          "el-radio-group",
          {
            directives: [{ name: "removeAria", rawName: "v-removeAria" }],
            attrs: { size: "mini" },
            on: { input: _vm.handlerData },
            model: {
              value: _vm.categoryType,
              callback: function ($$v) {
                _vm.categoryType = $$v
              },
              expression: "categoryType",
            },
          },
          [
            _c("el-radio", { attrs: { label: "flightSorties" } }, [
              _vm._v(_vm._s(_vm.$t("oilGasScreen.sortie"))),
            ]),
            _c("el-radio", { attrs: { label: "flightDuration" } }, [
              _vm._v(_vm._s(_vm.$t("oilGasScreen.duration"))),
            ]),
            _c("el-radio", { attrs: { label: "flightMileage" } }, [
              _vm._v(_vm._s(_vm.$t("oilGasScreen.mileage"))),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "chart-cont" },
      [
        _c("chartBar", {
          attrs: {
            id: "uav-chart",
            isTransverse: true,
            chartColors: ["#588CF2"],
            grid: { left: "3%", right: "10%", top: "15%", bottom: "3%" },
            legendWidth: "auto",
            xAxisLabel: { color: "#fff", rotate: 0, fontSize: 10 },
            barMaxWidth: 10,
            barBorderRadius: [0, 4, 4, 0],
            tooltip: _vm.tooltip,
            barGradientList: _vm.gradientList,
            isZoom: true,
            zoomOption: {
              width: 10,
              height: "80%",
              backgroundColor: "#1E222A",
              fillerColor: "#6AA4E6",
              right: "3%",
              bottom: 0,
              end: 50,
            },
            chartData: _vm.chartData,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }