<template>
    <div class="center-content" :class="{'screen-center-content': taskData}">
        <div class="map-container">
            <div class="suspended" v-if="taskData && liveVideoVOList(taskData).length">
                <div class="suspended-cont">
                    <div class="suspended-item" @click="$emit('clearnSelectTask', '')">{{$t('navbar.screenfullF')}}</div>
                </div>
            </div>
            <BaseBox class="label-title" :title="!taskData ? $t('screen.realMap') : $t('screen.realVideo')">
                <template v-slot:content>
                    <div class="screen-map" :class="{'narrow-map': taskData}" :style="locationObj">
                        <div class="narrow-map-top" v-if="taskData">{{$t('screen.realMap')}}</div>
                        <div class="screen-map-center" :class="{'narrow-map-center': taskData}">
                            <component ref="map" :is="mapComponent" :projectInfo="projectInfo" :showTopTools="false" 
                                :showBottomTools="false" :isEmergency="true" :isChangeStyle="true" :showMapTips="true" 
                                :mapData="mapData" @changeUav="changeUav" />
                        </div>
                    </div>
                    <div class="screen-video" v-if="taskData">
                        <div class="screen-video-center">
                            <component v-if="liveVideoVOList(taskData).length" :is="useLive(taskData)" 
                                ref="videoLive" class="video-plane" :screen="false" 
                                :liveVideoVOList="liveVideoVOList(taskData)" />
                            <div v-else class="video-loading" v-loading="true" 
                                :element-loading-text="$t('WaitingForVideoTransmission')" 
                                element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                            </div>
                        </div>
                    </div>
                </template>
            </BaseBox>
        </div>
    </div>
</template>

<script>
import BaseBox from "@/components/screen/BaseBox.vue";
import Map from "@/components/screen/map/map-center.vue";
import NewMap from "@/components/screen/map/new-map-center.vue";
import VdieoLiveEasyplayer from "@/components/video-live-easyPlayer/index.vue";
import VdieoLiveHWLLS from "@/components/video-live-HWLLS/index.vue";
import { mapGetters } from "vuex";

export default {
    name: "content-left",
    components: {Map, NewMap, BaseBox, VdieoLiveEasyplayer, VdieoLiveHWLLS},
    props: {
        taskData: {
            default: ''
        },
        projectInfo: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            locationObj: {
                top: '0px',
                bottom: '0px',
                left: '0px',
                right: '0px'
            }
        };
    },
    computed: {
        ...mapGetters([
            'newSocketData',
            'socketData',
            'wsData',
            'task',
            'currentTaskList'
        ]),
        mapData() {
            // return this.task && this.task.useIot === 0 ? this.socketData : this.newSocketData;
            return this.newSocketData;
        },
        mapComponent() {
            // return this.task && this.task.useIot === 0 ? 'Map' : 'NewMap';
            return 'NewMap';
        },
        liveVideoVOList() {
            return function(taskData) {
                const liveVideoVOList = taskData.liveVideoVOList;
                if (liveVideoVOList && Array.isArray(liveVideoVOList)) {
                    return liveVideoVOList;
                }
                return [];
            }
        },
        useLive() {
            return function(taskData) {
                return taskData.liveType === 1 ? 'VdieoLiveHWLLS': 'VdieoLiveEasyplayer';
            }
        },
        taskList() {
            let taskList = this.currentTaskList || [];
            return taskList;
        }
    },
    watch: {
        'taskData': {
            deep: true,
            handler: function(val) {
                let UavRealInfoPop = this.$refs.map.$refs.UavRealInfoPop;
                if (val) {
                    this.locationObj = {
                        width: '400px',
                        height: '350px',
                        // width: '22%',
                        // height: '50%',
                        top: '30px',
                        right: '30px'
                    }
                    if (UavRealInfoPop) {
                        UavRealInfoPop.show = false;
                    }
                } else {
                    this.locationObj = {
                        top: '0px',
                        bottom: '0px',
                        left: '0px',
                        right: '0px'
                    }
                    if (UavRealInfoPop && this.taskList.length > 1) {
                        UavRealInfoPop.show = true;
                    }
                }
                if (this.$refs.map.$options.map3d && this.$refs.map.$options.map3d.resize) {
                    this.$refs.map.$options.map3d.resize();
                }
            }
        }
    },
    methods: {
        changeUav(data, noSetFirstTask) {
            this.$parent.$refs.rightDom.handleChange(data, noSetFirstTask);
        }
    }
}

</script>

<style lang="scss" scoped>
.center-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-right: 20px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &.screen-center-content {
        padding-right: 0;
    }
    .map-container {
        width: 100%;
        height: 100%;
        color: #fff;
        display: flex;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("~@/assets/images/map-container-bg.png");
        ::v-deep .base-box {
            background-image: none;
            .content {
                padding: 0;
                border-radius: 4px;
            }
        }
        .suspended {
            display: none;
            position: absolute;
            z-index: 9999;
            bottom: 55px;
            left: 50%;
            transform: translateX(-50%);
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #FFFFFF;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 16px;
            cursor: pointer;
            .suspended-cont {
                display: flex;
                .suspended-item {
                    padding: 5px 18px;
                }
            }
        }
        &:hover .suspended {
            display: block;
        }
        .screen-map {
            position: absolute;
            z-index: 1000;
            padding: 4px 2.5px 3px 4px;
            .screen-map-center {
                height: 100%;
                ::v-deep #cesiumContainer_new_map_center {
                    border-radius: 7px;
                }
            }
        }
        .narrow-map {
            display: flex;
            flex-direction: column;
            padding: 0;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("~@/assets/images/narrow-map-bg.png");
            .narrow-map-top {
                height: 20px;
                line-height: 20px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
            }
            .narrow-map-center {
                height: 0;
                flex: 1;
                padding: 8px 7px;
                ::v-deep #cesiumContainer_new_map_center {
                    border-radius: 16px;
                }
            }
        }
        .screen-video {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .screen-video-center {
                flex: 1;
                padding: 4px 2.5px 3px 4px;
                ::v-deep #player_container {
                    border-radius: 10px;
                }
                ::v-deep .easy-player {
                    border-radius: 10px;
                }
            }
        }
    }
}
.label-title {
    flex: 1;
}
</style>