<template>
    <!-- 巡检对象异常排名 -->
    <div class="objectException">
        <div class="swtch-project">
            <el-select v-model="checkProject" size="mini" @change="changeProject" 
                :placeholder="$t('oilGasScreen.pleaseSelectInspectionItem')">
                <el-option v-for="item in projectList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
        </div>
        <div class="chart-cont">
            <chartBar 
                :id="'objectExceptione-chart'"
                :isTransverse="true"
                :chartColors="['#33DBD6']"
                :grid="{left: '3%', right: '10%', top: '5%', bottom: '3%'}"
                :legendWidth="'auto'"
                :xAxisLabel="{color: '#fff', rotate: 0, fontSize: 10}"
                :barMaxWidth="10"
                :barBorderRadius="[0, 4, 4, 0]"
                :barGradientList="gradientList"
                :isZoom="true"
                :zoomOption="{width: 10, height: '90%', backgroundColor: '#1E222A', fillerColor: '#6AA4E6', 
                    right: '3%', bottom: 0, end: 50}"
                :chartData="chartData"
            />
        </div>
    </div>
</template>

<script>
import chartBar from '@/components/echarts/chartBar.vue';

export default {
    name: 'objectException-page',
    components: {chartBar},
    props: ['objectExceptionData'],
    data() {
        return {
            checkProject: '',
            projectList: [],
            gradientList: [
                new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {offset: 0, color: 'rgba(51,219,214, 1)'},
                    {offset: 0.5, color: 'rgba(51,219,214, 0.5)'},
                    {offset: 1, color: 'rgba(51,219,214, 0.1)'}
                ])
            ],
            chartData: [
                {name: '', data: []}
            ]
        }
    },
    watch: {
        'objectExceptionData': {
            deep: true,
            handler: function() {
                this.handlerData();
            }
        }
    },
    methods: {
        handlerData() {
            let projectList = [];
            this.objectExceptionData && Object.keys(this.objectExceptionData).map(t => {
                projectList.push({value: t, label: t});
            });
            this.projectList = projectList;
            let itemData = this.objectExceptionData[this.checkProject];
            if (!this.checkProject) {
                this.checkProject = projectList.length ? projectList[0].value : '';
                itemData = this.objectExceptionData[this.checkProject];
            } else {
                if (!itemData) {
                    this.checkProject = projectList.length ? projectList[0].value : '';
                    itemData = this.objectExceptionData[this.checkProject];
                }
            }
            let chartData = [];
            itemData && Object.keys(itemData).map(t => {
                chartData.push({name: t, value: itemData[t]});
            });
            this.chartData[0].data = chartData;
        },
        changeProject() {
            let itemData = this.objectExceptionData[this.checkProject];
            let chartData = [];
            itemData && Object.keys(itemData).map(t => {
                chartData.push({name: t, value: itemData[t] || 0});
            });
            this.chartData[0].data = chartData;
        }
    }
}
</script>

<style lang="scss" scoped>
.objectException {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .swtch-project {
        position: absolute;
        top: 2px;
        right: 80px;
        ::v-deep .el-select {
            width: 150px;
            .el-input__inner {
                background-color: transparent;
                border: 1px solid #1A417B;
                color: #fff;
                &::placeholder {
                    color: #fff;
                }
            }
            .el-input__icon {
                color: #fff;
            }
        }
    }
    .chart-cont {
        width: 100%;
        height: 100%;
        position: relative;
    }
}
</style>