var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "left-cont" }, [
    _c(
      "div",
      { staticClass: "box-main" },
      [
        _c("BaseBox", {
          attrs: {
            title: !_vm.taskData
              ? _vm.$t("screen.realMap")
              : _vm.$t("screen.realVideo"),
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.taskData,
            expression: "taskData",
          },
        ],
        staticClass: "suspended",
      },
      [
        _c("div", { staticClass: "suspended-cont" }, [
          _c(
            "div",
            {
              staticClass: "suspended-item",
              on: {
                click: function ($event) {
                  return _vm.$emit("clearnSelectTask", "")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("navbar.screenfullF")))]
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "map-cont",
        class: { "narrow-map": _vm.taskData },
        style: _vm.locationObj,
      },
      [
        _vm.taskData
          ? _c("div", { staticClass: "narrow-map-top" }, [
              _vm._v(_vm._s(_vm.$t("screen.realMap"))),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            class: {
              "screen-map-center": !_vm.taskData,
              "narrow-map-center": _vm.taskData,
            },
          },
          [
            _c("oliMap", {
              ref: "map",
              attrs: { isShowTopTools: true, mapType: "realTimeMap" },
              on: { changeUav: _vm.changeUav },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.taskData,
            expression: "taskData",
          },
        ],
        staticClass: "video-cont",
      },
      [
        _vm.liveVideoVOList(_vm.taskData).length
          ? _c(_vm.useLive(_vm.taskData), {
              ref: "videoLive",
              tag: "component",
              staticClass: "video-plane",
              attrs: {
                screen: false,
                liveVideoVOList: _vm.liveVideoVOList(_vm.taskData),
              },
            })
          : _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: true,
                  expression: "true",
                },
              ],
              staticClass: "video-loading",
              attrs: {
                "element-loading-text": _vm.$t("WaitingForVideoTransmission"),
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }