<template>
    <!-- 厂处报警统计 -->
    <div class="factoryAlarmPage" ref="factoryAlarmPage">
        <div class="chart-cont">
            <div class="chart-bg" :style="{'width': `${bgPosNum}px`, 'height': `${bgPosNum}px`}"></div>
            <chartPie 
                v-if="showChart"
                :id="'record-chart'"
                :isRoseChart="true"
                :chartRadius="['30%', '52%']"
                :chartCenter="['50%', '50%']"
                :graphicOption="{x: fontPosNum.x, y: fontPosNum.y, text: chartTotal}"
                :labelFormatter="'{c} \n {b}'"
                :labelLine="{length: 20, length2: 50}"
                :labelPadding="[0, -45]"
                :labelLayout="{verticalAlign: 'middle', dy: 2}"
                :chartData="chartData"
            />
        </div>
    </div>
</template>

<script>
import chartPie from '@/components/echarts/chartPie.vue';

export default {
    name: 'factoryAlarmPage',
    components: {chartPie},
    props: ['factoryAlarmData'],
    data() {
        return {
            showChart: false,
            fontPosNum: {x: 0, y: 0},
            bgPosNum: 120,
            chartTotal: '0',
            chartData: []
        }
    },
    watch: {
        'factoryAlarmData': {
            deep: true,
            handler: function() {
                this.handlerData();
            }
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.showChart = false;
            this.$nextTick(() => {
                const domWidth = this.$refs.factoryAlarmPage.offsetWidth;
                const domHeight = this.$refs.factoryAlarmPage.offsetHeight;
                this.fontPosNum = {
                    x: (domWidth * 0.5),
                    y: (domHeight * 0.5) - 11
                };
                if (domWidth >= domHeight) {
                    this.bgPosNum = domHeight * 0.6;
                } else {
                    this.bgPosNum = domWidth * 0.6;
                }
                this.showChart = true;
            });
        },
        handlerData() {
            let itemData = this.factoryAlarmData[0];
            let chartData = [];
            itemData.data && Object.keys(itemData.data).map(t => {
                chartData.push({name: t, value: itemData.data[t] || 0});
            });
            this.chartTotal = itemData.total ? `${itemData.total}` : '0';
            this.chartData = chartData;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style lang="scss" scoped>
.factoryAlarmPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .chart-cont {
        width: 100%;
        height: 100%;
        position: relative;
        .chart-bg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("~@/assets/images/RosePie-bg.png");
        }
    }
}
</style>