var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "oil-navigation-bar" }, [
    _c("div", { staticClass: "navigation-inner" }, [
      _c("div", { staticClass: "time" }, [
        _c("span", { staticClass: "date" }, [
          _c("span", { staticClass: "day" }, [_vm._v(_vm._s(_vm.day))]),
          _c("span", { staticClass: "week" }, [_vm._v(_vm._s(_vm.week))]),
        ]),
        _c("span", { staticClass: "clock" }, [
          _vm._v("\n        " + _vm._s(_vm.time) + "\n      "),
        ]),
      ]),
      _c("div", { staticClass: "bar" }, [
        _c(
          "div",
          {
            staticClass: "overall-situation side-tabs",
            class:
              _vm.currentPath === "overall-situation"
                ? "overall-situation-selected"
                : "",
          },
          [
            _c(
              "span",
              {
                staticClass: "side-tabs-txt",
                on: {
                  click: function ($event) {
                    return _vm.change("overall-situation")
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("oilGasScreen.globalOverview")) +
                    "\n        "
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "real-time side-tabs-center",
            class: _vm.currentPath === "real-time" ? "real-time-selected" : "",
          },
          [
            _c(
              "span",
              {
                staticClass: "side-tabs-txt",
                on: {
                  click: function ($event) {
                    return _vm.change("real-time")
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("oilGasScreen.realTimeTask")) +
                    "\n        "
                ),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "center-title" }, [
          _c("div", { staticClass: "title-text-shadow title-text" }, [
            _vm._v(
              _vm._s(_vm.screenInfo.name || _vm.$t("oilGasScreen.screenTitle"))
            ),
          ]),
          _c("div", { staticClass: "title-text" }, [
            _vm._v(
              _vm._s(_vm.screenInfo.name || _vm.$t("oilGasScreen.screenTitle"))
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "inspection-record side-tabs-center",
            class:
              _vm.currentPath === "inspection-record"
                ? "inspection-record-selected"
                : "",
          },
          [
            _c(
              "span",
              {
                staticClass: "side-tabs-txt",
                on: {
                  click: function ($event) {
                    return _vm.change("inspection-record")
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("oilGasScreen.inspectionRecord")) +
                    "\n        "
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "alarm-record side-tabs",
            class:
              _vm.currentPath === "alarm-record" ? "alarm-record-selected" : "",
          },
          [
            _c(
              "span",
              {
                staticClass: "side-tabs-txt",
                on: {
                  click: function ($event) {
                    return _vm.change("alarm-record")
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("oilGasScreen.alarmRecord")) +
                    "\n        "
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "exit", on: { click: _vm.back } }, [
        _vm._v("\n      " + _vm._s(_vm.$t("oilGasScreen.exit")) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }