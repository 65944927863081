<template>
  <div class="trend-chart">
    <div class="tool-box">
      <!-- <el-select
        class="type-chooser"
        v-model="type"
        placeholder="设备类型"
        @change="changeType"
      >
        <el-option
          v-for="item in typeOption"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <p class="time-chooser">
        <span
          :class="
            time === 'day' ? 'time-item time-chooser--choosen' : 'time-item'
          "
          @click="changeTime('day')"
          >日</span
        >
        <span
          :class="
            time === 'week' ? 'time-item time-chooser--choosen' : 'time-item'
          "
          @click="changeTime('week')"
          >周</span
        >
        <span
          :class="
            time === 'month' ? 'time-item time-chooser--choosen' : 'time-item'
          "
          @click="changeTime('month')"
          >月</span
        >
      </p>
    </div>
    <div id="line-chart"></div>
  </div>
</template>

<script>
import { getlineChartData } from "@/api/screen/index.js";
export default {
  data() {
    return {
      type: "complex",
      time: "day",
      typeOption: [
        {
          label: "复合翼无人机",
          value: "complex",
        },
        {
          label: "多旋翼无人机",
          value: "multiRotor",
        },
        {
          label: "直升机",
          value: "helicopter",
        },
      ],
      option: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          orient: "vertical",
          // left: "right",
          // bottom: "bottom",
          // backgroundColor: "rgba(0,0,0,0.2)",
          padding: [33, 0, 20, 0],
          itemWidth: 9,
          itemHeight: 10,
          data: [
            {
              name: "复合翼无人机",
              icon: "circle",
              textStyle: {
                color: "#ffffff",
                fontSize: 10,
              },
            },
            {
              name: "多旋翼无人机",
              icon: "circle",
              textStyle: {
                color: "#ffffff",
                fontSize: 10,
              },
            },
            {
              name: "直升机",
              icon: "circle",
              textStyle: {
                color: "#ffffff",
                fontSize: 10,
              },
            },
          ],
        },
        grid: {
          top: "30%",
          left: "5%",
          right: "7%",
          bottom: "1%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          color: "#ffffff",
          type: "category",
          boundaryGap: false,
          data: [],
          axisLabel: {
            interval: 0,
            show: true,
            textStyle: {
              color: "#ffffff",
              fontSize: 10,
            },
          },
          splitLine: {
            show: false,
            color: "#223E60",
          },
          axisLine: {
            show: false,
            color: "#223E60",
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          color: "#ffffff",
          type: "value",
          axisLabel: {
            interval: 0,
            show: true,
            textStyle: {
              color: "#ffffff",
              fontSize: 10,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#223E60"],
              width: 1,
              type: "solid",
            },
          },
        },
        series: [],
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
      },
      initStyle: {
        width: 0,
        height: 0,
      },
    };
  },
  mounted() {
    this.getLineData();
  },
  methods: {
    getLineData() {
      const flyId = this.$route.query.flyId;
      if (!flyId) return;
      const params = {
        projectId: flyId,
        date: this.time,
        // type: this.type,
      };
      getlineChartData(params).then((query) => {
        const res = query.data;
        if (res.code === 200) {
          const data = res.data;
          let lineData = [];
          let xAxisData = [];
          let series = [];
          data.length &&
            data.map((item) => {
              lineData.push(item.count);
              xAxisData.push(item.date);
            });
          switch (this.type) {
            case "complex":
              series = [
                {
                  name: "",
                  type: "line",
                  stack: "Total",
                  data: lineData,
                  smooth: true,
                  symbol: "circle", //折线点设置为实心点
                  symbolSize: 6, //折线点的大小
                  itemStyle: {
                    color: "#50FCFF",
                    shadowColor: "#50FCFF",
                    shadowBlur: 20,
                  },
                  lineStyle: {
                    shadowColor: "#50FCFF",
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    opacity: 1,
                    shadowBlur: 8,
                    type: "solid",
                    width: 2,
                  },
                  areaStyle: {
                    normal: {
                      color: {
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                          {
                            offset: 0,
                            color: "#A6FEFF", // 0% 处的颜色
                          },
                          {
                            offset: 1,
                            color: "rgba(77, 248, 244, 0.07)", // 100% 处的颜色
                          },
                        ],
                        globalCoord: false, // 缺省为 false
                      },
                    },
                  },
                },
              ];
              break;
            case "multiRotor":
              series = [
                {
                  name: "多旋翼无人机",
                  type: "line",
                  stack: "Total",
                  data: lineData,
                  smooth: true,
                  symbol: "circle", //折线点设置为实心点
                  symbolSize: 6, //折线点的大小
                  itemStyle: {
                    color: "#334BF7",
                    shadowColor: "#334BF7",
                    shadowBlur: 20,
                  },
                  lineStyle: {
                    shadowColor: "#334BF7",
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    opacity: 1,
                    shadowBlur: 8,
                    type: "solid",
                    width: 2,
                  },
                  areaStyle: {
                    normal: {
                      color: {
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                          {
                            offset: 0,
                            color: "#334BF7", // 0% 处的颜色
                          },
                          {
                            offset: 1,
                            color: "rgba(51, 75, 247, 0.07)", // 100% 处的颜色
                          },
                        ],
                        globalCoord: false, // 缺省为 false
                      },
                    },
                  },
                },
              ];
              break;
            case "helicopter":
              series = [
                {
                  name: "直升机",
                  type: "line",
                  stack: "Total",
                  data: lineData,
                  smooth: true,
                  symbol: "circle", //折线点设置为实心点
                  symbolSize: 6, //折线点的大小
                  itemStyle: {
                    color: "#9B3BFF",
                    shadowColor: "#9B3BFF",
                    shadowBlur: 20,
                  },
                  lineStyle: {
                    shadowColor: "#9B3BFF",
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    opacity: 1,
                    shadowBlur: 8,
                    type: "solid",
                    width: 2,
                  },
                  areaStyle: {
                    normal: {
                      color: {
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                          {
                            offset: 0,
                            color: "#9B3BFF", // 0% 处的颜色
                          },
                          {
                            offset: 1,
                            color: "rgba(155, 59, 255, 0.07)", // 100% 处的颜色
                          },
                        ],
                        globalCoord: false, // 缺省为 false
                      },
                    },
                  },
                },
              ];
              break;
            default:
              return;
          }
          this.option.series = series;
          this.option.xAxis.data = xAxisData;
          
          this.initChart();
        }
      });
    },
    changeType(type) {
      this.type = type;
      this.getLineData();
    },
    changeTime(time) {
      this.time = time;
      this.getLineData();
    },
    initChart() {
      var chartDom = document.getElementById("line-chart");
      var myChart = this.$echarts.init(chartDom);
      myChart.setOption(this.option);
    },
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  margin-top: 20px;
  height: calc(38% - 10px);
}
#line-chart {
  width: 100%;
  height: 100%;
}
.tool-box {
  position: absolute;
  top: 23px;
  z-index: 999;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 34px 0 20px;
  .type-chooser {
    width: 125px;
    height: 29px;
    border-radius: 4px;
    border: 1px solid #3377ff;
  }
  .time-chooser {
    font-size: 10px;
    font-family: "MicrosoftYaHei, Microsoft YaHei";
    .time-item {
      color: #ffffff;
      cursor: pointer;
      padding-left: 9px;
    }
    &--choosen {
      color: #84a6ff !important;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>

<style lang="scss">
.el-select-dropdown.el-popper {
  border: 1px solid #568fe6;
  .popper__arrow {
    border-bottom-color: #568fe6;
  }
}
.type-chooser {
  .el-input--suffix .el-input__inner {
    background: rgba(0, 0, 0, 0.41);
    font-size: 12px;
    height: 29px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
  }
  .el-input__prefix,
  .el-input__suffix {
    top: 6px;
    color: #ffffff;
  }
}
</style>
