<template>
    <div class="screen-right-box" v-loading="loading" :element-loading-text="$t('loading')" 
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 1)">
        <!-- 单任务 -->
        <div class="single-task" v-if="taskList.length <= 1">
            <div class="video-container">
                <BaseBox :title="$t('screen.currentFootage')">
                    <template v-slot:content>
                        <div class="video-live-box">
                            <div class="suspended" v-if="liveVideoVOList(firstTask).length">
                                <div class="suspended-cont">
                                    <div class="suspended-item" @click="openVideoScreen(firstTask)">
                                        {{$t('navbar.screenfull')}}</div>
                                </div>
                            </div>
                            <div class="video-live-box-cont active">
                                <div class="video-live-box-top l-active">
                                    <img src="@/assets/images/task-select-fkbg-ac.png" alt="">
                                    <span>{{firstTask.taskName || ''}}</span>
                                </div>
                                <component v-if="liveVideoVOList(firstTask).length" :is="useLive(firstTask)" 
                                    ref="videoLive" class="video-plane" :screen="false" 
                                    :liveVideoVOList="liveVideoVOList(firstTask)" />
                                <div v-else class="video-loading" v-loading="true" 
                                    :element-loading-text="$t('WaitingForVideoTransmission')" 
                                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                </div>
                            </div>
                        </div>
                    </template>
                </BaseBox>
            </div>
            <div class="plane">
                <BaseBox :title="$t('screen.taskInfo')">
                    <template v-slot:content>
                        <Plant ref="plane" />
                    </template>
                </BaseBox>
            </div>
        </div>
        <!-- 双任务 -->
        <div class="dual-task" v-if="taskList.length == 2">
            <div class="video-container">
                <BaseBox :title="$t('screen.currentFootage')">
                    <template v-slot:content>
                        <div class="video-live-box">
                            <div class="suspended" v-if="liveVideoVOList(firstTask).length">
                                <div class="suspended-cont">
                                    <div class="suspended-item" @click="openVideoScreen(firstTask)">
                                        {{$t('navbar.screenfull')}}</div>
                                </div>
                            </div>
                            <div class="video-live-box-cont active">
                                <div class="video-live-box-top l-active">
                                    <img src="@/assets/images/task-select-fkbg-ac.png" alt="">
                                    <span>{{firstTask.taskName || ''}}</span>
                                </div>
                                <component v-if="liveVideoVOList(firstTask).length" :is="useLive(firstTask)" 
                                    ref="videoLive" class="video-plane" :screen="false" 
                                    :liveVideoVOList="liveVideoVOList(firstTask)" />
                                <div v-else class="video-loading" v-loading="true" 
                                    :element-loading-text="$t('WaitingForVideoTransmission')" 
                                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                </div>
                            </div>
                        </div>
                        <div class="video-live-box" v-for="(item, index) in argtTaskList" :key="item.taskId">
                            <div class="suspended" v-if="liveVideoVOList(item).length">
                                <div class="suspended-cont">
                                    <div class="suspended-item" @click="openVideoScreen(item)">
                                        {{$t('navbar.screenfull')}}</div>
                                </div>
                            </div>
                            <div class="video-live-box-cont" @click="handleChange(item)">
                                <div class="video-live-box-top">
                                    <img src="@/assets/images/task-select-fkbg.png" alt="">
                                    <span>{{item.taskName || ''}}</span>
                                </div>
                                <component v-if="liveVideoVOList(item).length" :is="useLive(item)" 
                                    :ref="'videoLive' + index" class="video-plane" :screen="false" 
                                    :liveVideoVOList="liveVideoVOList(item)" />
                                <div v-else class="video-loading" v-loading="true" 
                                    :element-loading-text="$t('WaitingForVideoTransmission')" 
                                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                </div>
                            </div>
                        </div>
                    </template>
                </BaseBox>
            </div>
        </div>
        <!-- 三任务 -->
        <div class="three-tasks" v-if="taskList.length == 3">
            <div class="video-container">
                <BaseBox :title="$t('screen.currentFootage')">
                    <template v-slot:content>
                        <div class="video-live-box">
                            <div class="suspended" v-if="liveVideoVOList(firstTask).length">
                                <div class="suspended-cont">
                                    <div class="suspended-item" @click="openVideoScreen(firstTask)">
                                        {{$t('navbar.screenfull')}}</div>
                                </div>
                            </div>
                            <div class="video-live-box-cont active">
                                <div class="video-live-box-top l-active">
                                    <img src="@/assets/images/task-select-fkbg-ac.png" alt="">
                                    <span>{{firstTask.taskName || ''}}</span>
                                </div>
                                <component v-if="liveVideoVOList(firstTask).length" :is="useLive(firstTask)" 
                                    ref="videoLive" class="video-plane" :screen="false" 
                                    :liveVideoVOList="liveVideoVOList(firstTask)" />
                                <div v-else class="video-loading" v-loading="true" 
                                    :element-loading-text="$t('WaitingForVideoTransmission')" 
                                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                </div>
                            </div>
                        </div>
                        <div class="other-video">
                            <div class="video-live-box" v-for="(item, index) in argtTaskList" :key="item.taskId">
                                <div class="suspended" v-if="liveVideoVOList(item).length">
                                    <div class="suspended-cont">
                                        <div class="suspended-item" @click="openVideoScreen(item)">
                                            {{$t('navbar.screenfull')}}</div>
                                    </div>
                                </div>
                                <div class="video-live-box-cont" @click="handleChange(item)">
                                    <div class="video-live-box-top video-live-box-top-d">
                                        <img src="@/assets/images/task-select-fkbg.png" alt="">
                                        <span>{{item.taskName || ''}}</span>
                                    </div>
                                    <component v-if="liveVideoVOList(item).length" :is="useLive(item)" 
                                        :ref="'videoLive' + index" class="video-plane" :screen="false" 
                                        :liveVideoVOList="liveVideoVOList(item)" />
                                    <div v-else class="video-loading" v-loading="true" 
                                        :element-loading-text="$t('WaitingForVideoTransmission')" 
                                        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </BaseBox>
            </div>
        </div>
        <!-- 多任务 -->
        <div class="multi-tasking" v-if="taskList.length > 3">
            <div class="video-container">
                <BaseBox :title="$t('screen.UAVFootage')">
                    <template v-slot:content>
                        <div class="video-live-box" v-for="(item, index) in argtTaskList" :key="item.taskId">
                            <div class="suspended" v-if="liveVideoVOList(item).length">
                                <div class="suspended-cont">
                                    <div class="suspended-item" @click="openVideoScreen(item)">
                                        {{$t('navbar.screenfull')}}</div>
                                </div>
                            </div>
                            <div class="video-live-box-cont" :class="{'active': selectTaskId === item.taskId}" 
                                @click="handleChange(item, true)">
                                <div class="video-live-box-top video-live-box-top-d" 
                                    :class="{'d-active': selectTaskId === item.taskId}">
                                    <img v-show="selectTaskId === item.taskId" 
                                        src="@/assets/images/task-select-fkbg-ac.png" alt="">
                                    <img v-show="selectTaskId !== item.taskId" 
                                        src="@/assets/images/task-select-fkbg.png" alt="">
                                    <span>{{item.taskName || ''}}</span>
                                </div>
                                <component v-if="liveVideoVOList(item).length" :is="useLive(item)" 
                                    :ref="'videoLive' + index" class="video-plane" :screen="false" 
                                    :liveVideoVOList="liveVideoVOList(item)" />
                                <div v-else class="video-loading" v-loading="true" 
                                    :element-loading-text="$t('WaitingForVideoTransmission')" 
                                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                                </div>
                            </div>
                        </div>
                    </template>
                </BaseBox>
            </div>
        </div>
    </div>
</template>

<script>
import BaseBox from "@/components/screen/BaseBox.vue";
import Plant from "./plane.vue";
import VdieoLiveEasyplayer from "@/components/video-live-easyPlayer/index.vue";
import VdieoLiveHWLLS from "@/components/video-live-HWLLS/index.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'content-right',
    components: {VdieoLiveHWLLS, VdieoLiveEasyplayer, Plant, BaseBox},
    data() {
        return {
            loading: true,
            firstTask: {},
            argtTaskList: [],
            selectTaskId: ''
        }
    },
    computed: {
        ...mapGetters([
            'currentTaskList'
        ]),
        liveVideoVOList() {
            return function(taskData) {
                const liveVideoVOList = taskData.liveVideoVOList;
                if (liveVideoVOList && Array.isArray(liveVideoVOList)) {
                    return liveVideoVOList;
                }
                return [];
            }
        },
        useLive() {
            return function(taskData) {
                return taskData.liveType === 1 ? 'VdieoLiveHWLLS': 'VdieoLiveEasyplayer';
            }
        },
        // 当前正在执行任务列表
        taskList() {
            let taskList = this.currentTaskList || [];
            setTimeout(() => {
                this.loading = false;
            }, 500);
            return taskList;
        }
    },
    watch: {
        'currentTaskList': {
            deep: true,
            handler: function(val) {
                if (val && val.length) {
                    this.firstTask = JSON.parse(JSON.stringify(val[0]));
                    let length = val.length;
                    this.argtTaskList = [];
                    let argtTaskList = [];
                    this.argtTaskList = val.map((t, index) => {
                        if (length > 3) {
                            argtTaskList.push(t);
                        } else {
                            if (index) {
                                argtTaskList.push(t);
                            }
                        }
                    });
                    this.argtTaskList = [...argtTaskList];
                    this.selectTaskId = val[0].taskId;
                }
            }
        }
    },
    methods: {
        ...mapActions([
            'setSelectTask'
        ]),
        // 切换视频选中
        handleChange(taskData, noSetFirstTask) {
            if (taskData) {
                let currentTaskList = [...this.currentTaskList];
                let task = currentTaskList.find(t => t.taskId === taskData.taskId);
                if (task) {
                    if (!noSetFirstTask) {
                        let argtTaskItem = this.argtTaskList.find(t => t.taskId === task.taskId);
                        let index = this.argtTaskList.indexOf(argtTaskItem);
                        if (index != -1) {
                            this.$set(this.argtTaskList, index, JSON.parse(JSON.stringify(this.firstTask)));
                        }
                        this.firstTask = JSON.parse(JSON.stringify(task));
                    }
                    this.selectTaskId = task.taskId;
                    this.setSelectTask(task);
                }
            }
        },
        // 打开视频全屏
        openVideoScreen(taskData) {
            if (taskData) {
                if (this.selectTaskId !== taskData.taskId) {
                    // 当前点击全屏的taskId与选中的taskId不一致时
                    let noSetFirstTask = this.taskList.length > 3 ? true : false;
                    this.handleChange(taskData, noSetFirstTask);
                }
                this.$emit('changeSelectTask', JSON.parse(JSON.stringify(taskData)));
            }
        }
    }
}
</script>

<style scoped lang="scss">
.screen-right-box {
    width: 100%;
    height: 100%;
    .single-task {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        color: #FFFFFF;
        flex-direction: column;
        justify-content: space-between;
        .plane {
            height: calc(52% - 25px);
            box-sizing: border-box;
            .base-box {
                background-image: url("~@/assets/images/rwxx-bg.png");
            }
        }
        .video-container {
            width: 100%;
            height: 48%;
            box-sizing: border-box;
            ::v-deep .base-box {
                .content {
                    padding: 0 0 10px 0;
                }
            }
            .video-live-box {
                height: 100%;
                display: flex;
                flex-direction: column;
                .video-plane{
                    height: 100%;
                }
                .video-loading {
                    height: 100%;
                    width: 100%;
                    background-color: #011a38;
                }
            }
        }
    }
    .dual-task {
        width: 100%;
        height: 100%;
        .video-container {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            .video-live-box {
                height: 46%;
                .video-plane{
                    height: 100%;
                }
                .video-loading {
                    height: 100%;
                    width: 100%;
                    background-color: #011a38;
                }
            }
        }
    }
    .three-tasks {
        width: 100%;
        height: 100%;
        .video-container {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            .video-live-box {
                height: 60%;
                .video-plane{
                    height: 100%;
                }
                .video-loading {
                    height: 100%;
                    width: 100%;
                    background-color: #011a38;
                }
            }
        }
        .other-video {
            height: 31%;
            display: flex;
            .video-live-box {
                flex: 1;
                height: 100%;
                box-sizing: border-box;
                margin-left: 15px;
                &:first-child {
                    margin-left: 0;
                }
                .video-plane{
                    height: 100%;
                }
                .video-loading {
                    height: 100%;
                    width: 100%;
                    background-color: #011a38;
                }
            }
        }
    }
    .multi-tasking {
        width: 100%;
        height: 100%;
        .video-container {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            ::v-deep .base-box {
                .content {
                    padding: 0 0 38px 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    overflow: auto;
                }
            }
            .video-live-box {
                width: calc(50% - 9px);
                height: 30%;
                box-sizing: border-box;
                margin-left: 15px;
                margin-top: 25px;
                &:nth-child(2n - 1) {
                    margin-left: 2px;
                }
                &:nth-child(1) {
                    margin-top: 10px;
                }
                &:nth-child(2) {
                    margin-top: 10px;
                }
                .video-plane{
                    height: 100%;
                }
                .video-loading {
                    height: 100%;
                    width: 100%;
                    background-color: #011a38;
                }
            }
        }
    }
    .video-container {
        position: relative;
        ::v-deep .base-box {
            .content {
                padding: 0 0 25px 0;
                border-radius: 0;
            }
        }
        .video-live-box {
            position: relative;
            .video-live-box-cont {
                height: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                border: 2px solid #0E3A72;
                border-radius: 5px;
                .video-live-box-top {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-image: url("~@/assets/images/task-select-bg-l.png");
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: bold;
                    font-size: 15px;
                    color: #FFFFFF;
                    cursor: pointer;
                    img {
                        width: 24px;
                        height: 24px;
                        margin: 0 5px;
                    }
                    &.video-live-box-top-d {
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        background-image: url("~@/assets/images/task-select-bg-d.png");
                    }
                    &.l-active {
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        background-image: url("~@/assets/images/task-select-bg-l-ac.png");
                    }
                    &.d-active {
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        background-image: url("~@/assets/images/task-select-bg-d-ac.png");
                    }
                }
                &.active {
                    border: 2px solid #2871EB;
                }
            }
            .suspended {
                display: none;
                position: absolute;
                z-index: 9999;
                bottom: 13%;
                left: 50%;
                transform: translateX(-50%);
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #FFFFFF;
                background: rgba(0, 0, 0, 0.6);
                border-radius: 16px;
                cursor: pointer;
                .suspended-cont {
                    display: flex;
                    .suspended-item {
                        padding: 5px 18px;
                    }
                }
            }
            &:hover .suspended {
                display: block;
            }
        }
    }
}
</style>