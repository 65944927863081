<template>
  <div>
    <basic-container>
      <avue-form :option="option" v-model="form" @tab-click="handleTabClick" @submit="handleSubmit"></avue-form>
    </basic-container>
  </div>
</template>

<script>
// import option from "@/option/user/info";
import { getUserInfo, updateInfo, updatePassword } from "@/api/system/user";
import md5 from 'js-md5';
import func from "@/util/func";

export default {

  // 其他组件选项...
  computed: {
    option() {
      return {
        tabs: true,
        tabsActive: 1,
        group: [
          {
            label: this.$t('navbar.userinfo'),
            prop: 'info',
            column: [{
              label:  this.$t('userinfo.head'),
              type: 'upload',
              listType: 'picture-img',
              propsHttp: {
                res: 'data',
                url: 'link',
              },
              canvasOption: {
                text: ' ',
                ratio: 0.1
              },
              data:{
                system:"system",
                business: "user",
                module: "userinfo"
              },
              action: '/api/blade-resource/oss/endpoint/put-file',
              tip:  this.$t('userinfo.headTip'),
              span: 12,
              row: true,
              prop: 'avatar'
            }, {
              label:  this.$t('userinfo.name'),
              span: 12,
              row: true,
              prop: 'name'
            }, {
              label:  this.$t('userinfo.username'),
              span: 12,
              row: true,
              prop: 'realName'
            }, {
              label:  this.$t('userinfo.mobile'),
              span: 12,
              row: true,
              prop: 'phone'
            }, {
              label:  this.$t('userinfo.email'),
              prop: 'email',
              span: 12,
              row: true,
            }]
          },
          {
            label: this.$t('userinfo.editPwd'),
            prop: 'password',
            column: [{
              label: this.$t('userinfo.oldPwd'),
              span: 12,
              row: true,
              type: 'password',
              prop: 'oldPassword'
            }, {
              label: this.$t('userinfo.newPwd'),
              span: 12,
              row: true,
              type: 'password',
              prop: 'newPassword'
            }, {
              label: this.$t('userinfo.confirmPwd'),
              span: 12,
              row: true,
              type: 'password',
              prop: 'newPassword1'
            }]
          }
        ],
      }
    }
  },

  data() {
    return {
      index: 0,
      form: {}
    };
  },
  created() {
    this.handleWitch();
  },



  methods: {
    handleSubmit(form, done) {
      if (this.index === 0) {
        updateInfo(form).then(res => {
          if (res.data.success) {
            this.$message({
              type: "success",
              message: "修改信息成功!"
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            });
          }
          done();
        }, error => {
          window.console.log(error);
          done();
        })
      } else {
        updatePassword(md5(form.oldPassword), md5(form.newPassword), md5(form.newPassword1)).then(res => {
          if (res.data.success) {
            this.$message({
              type: "success",
              message: "修改密码成功!"
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            });
          }
          done();
        }, error => {
          window.console.log(error);
          done();
        })
      }
    },
    handleWitch() {
      if (this.index === 0) {
        getUserInfo().then(res => {
          const user = res.data.data;
          this.form = {
            id: user.id,
            avatar: user.avatar,
            name: user.name,
            realName: user.realName,
            phone: user.phone,
            email: user.email,
          }
        });
      }
    },
    handleTabClick(tabs) {
      this.index = func.toInt(tabs.index);
      this.handleWitch();
    }
  }
};
</script>

<style></style>
