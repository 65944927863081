<template>
  <div class="Box">
    <div class="gasDataBox">
      <div class="dataTitle">
        <div class="gasTitle">任务名称</div>
        <div class="gasTitle">无人机名称</div>
        <div class="gasTitle">飞行里程(km)</div>
        <div class="gasTitle">飞行日期</div>
        <div class="gasTitle">飞行时长</div>
        <div class="gasTitle">有无异常</div>
        <div class="gasTitle">巡检报告</div>
      </div>
      <div
        class="scroll-container"
        @mouseenter="stopMove"
        @mouseleave="startMove"
      >
        <div class="scroll-content" v-show="store && store.length">
          <div class="scroll-content-list" ref="originHtml">
            <div
              class="scroll-item"
              v-for="(item, index) in store"
              :key="index"
            >
              <span class="title">
                <span :title="item.taskName" slot="reference">{{
                  item.taskName
                }}</span>
              </span>
              <span class="title">
                <span :title="item.uavId" slot="reference">{{
                  item.uavId
                }}</span>
              </span>
              <span class="title">
                <span :title="item.flyDistance" slot="reference">{{
                  (item.flyDistance / 1000).toFixed(2)
                }}</span>
              </span>
              <span class="title">
                <span :title="item.flyingDate" slot="reference">{{
                  item.flyingDate
                }}</span>
              </span>
              <span class="title">
                <span :title="item.flyingTime" slot="reference">
                  {{ getFlyingTime(item.flyingTime) }}
                </span>
              </span>
              <span class="title">
                <span
                  :title="item.alarmState ? '有' : '无'"
                  :style="
                    item.alarmState ? { color: 'red' } : { color: '#fff' }
                  "
                  >{{ item.alarmState ? "有" : "无" }}</span
                >
              </span>
              <span class="title"
                ><img
                  class="download"
                  src="~@/assets/images/download.png"
                  alt=""
                  @click="download(item)"
              /></span>
            </div>
          </div>
          <div v-html="copyHtml" ref="copyHtml"></div>
        </div>
        <avue-empty
          v-show="!store || !store.length"
          style="margin-top: 10px"
          class="fff-text"
          description="暂无数据"
        ></avue-empty>
      </div>
    </div>
  </div>
</template>
<script>
import { download } from "@/api/alarm/identify-data";
import { withThrottle } from "@/util/util";
export default {
  data() {
    return {
      timer: null,
      yPos: 0,
      rAFid: null,
      copyHtml: "",
      isFirst: false,
      cb: null,
    };
  },
  watch: {
    store(newVal) {
      if (newVal && newVal.length && !this.isFirst) {
        this.isFirst = true;
        this.initMove();
      }
    },
  },
  mounted() {
    window.addEventListener(
      "resize",
      this.judgeResize
    );
  },
  computed: {
    store() {
      return this.$store.state.wsData.projectTasks;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.judgeResize);
  },
  methods: {
    isOverFlow() {
      return (
        document.querySelector(".scroll-content").clientHeight -
          document.querySelector(".scroll-container").clientHeight >
        0
      );
    },
    judgeResize() {
      this.copyHtml = "";
      this.$nextTick(() => {
        this.reset();
        if (!this.isOverFlow()) {
          this.stopMove();
        }
      });
    },
    reset() {
      this.stopMove();
      this.yPos = 0;
      this.initMove();
    },
    stopMove() {
      this.rAFid && window.cancelAnimationFrame(this.rAFid);
    },
    startMove() {
      this.makeScroll();
    },
    initMove() {
      this.$nextTick(() => {
        if (!this.isOverFlow()) return;
        this.copyHtml = this.$refs.originHtml.innerHTML;
        this.makeScroll();
      });
    },
    makeScroll() {
      try {
        const offset =
          document.querySelector(".scroll-content-list").clientHeight -
          document.querySelector(".scroll-container").clientHeight;
        const height =
          document.querySelector(".scroll-content").clientHeight -
          document.querySelector(".scroll-container").clientHeight;
        this.yPos += 0.5;
        document.querySelector(".scroll-container").scrollTop = this.yPos;
        if (this.yPos >= height) {
          this.yPos = offset;
          this.copyHtml = this.$refs.originHtml.innerHTML;
        }
        this.rAFid = window.requestAnimationFrame(this.makeScroll);
      } catch (error) {
        setTimeout(this.makeScroll, 1000)
      }
    },
    download(row) {
      download(row.id).then((r) => {
        let index = r.headers["content-disposition"].indexOf("=") + 1;
        let fileName = decodeURIComponent(
          r.headers["content-disposition"].substr(index)
        );
        const blob = r.data;
        let link = document.createElement("a");
        link.href = URL.createObjectURL(
          new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          })
        );
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    getFlyingTime(time) {
      if ( typeof time === 'string') {
        time = Number(time)
      }
      let s = time % 60
      s = s > 10? s: '0' + s
      let min = time > 60? parseInt((time % 3600) / 60) : 0
      min = min > 10? min: '0' + min
      let hour = time > 3600 ? parseInt(time/3600) : 0
      hour = hour > 10? hour: '0' + hour
      return `${hour}: ${min}: ${s}`
    }
  },
};
</script>
<style lang="scss" scoped>
.Box {
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  font-size: 12px;
}

.gasDataBox {
  height: 95%;
  overflow: hidden;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.dataTitle {
  display: flex;
  background-color: #011a38;
  height: 30px;
  line-height: 30px;
}

.gasTitle {
  color: #fff;
  width: 14%;
  text-align: center;
}

.scroll-container {
  position: relative;
  overflow-y: auto;
  width: 100%;
  flex: 1;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  span {
    color: white;
    background-color: transparent;
  }

  .scroll-content {
    width: 100%;
    margin: 0;
    // transition: linear all 0.3;

    .scroll-item {
      display: flex;
      width: 100%;
      align-items: center;
      border-bottom: 1px dashed #21548a;
      height: 36px;
      display: flex;
      align-items: center;

      span {
        text-align: center;
        margin: 0;
        width: 14%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.download {
  cursor: pointer;
  margin-top: 6px;
}
</style>
<style>
.popperOptions {
  background-color: #011a38;
  color: #ffffff;
  font-size: 12px;
  padding: 5px;
  border: #011a38;
}
</style>
