<template>
  <div class="screen-common-bgc">
    <div class="screen-common-home">
      <div class="header">
        <header-title
          :projectInfo="projectInfo" 
        />
      </div>
      <div class="content">
        <Content 
          :projectInfo="projectInfo" 
          ref="content" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTitle from "./Header.vue";
import Content from "./Content.vue";
import { mapGetters, mapActions } from "vuex";
import { setToken, getToken } from '@/util/auth';
import { getFlyingTask } from "@/api/phugiacloud/projectManagement";
import { findEquipmentModel, getRunningTaskList } from "@/api/map/index";
import planeIcon from "@/assets/img/map/XC_64x64_.png";
import planeIcon_seleted from "@/assets/img/map/XC_seleted.png";
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';

export default {
  name: "screen",
  components: {
    HeaderTitle,
    Content
  },
  props: {
    projectInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // 当前选中的正在执行的任务
      selectedTask: null
      // 
    };
  },
  created() {
    this.$options.flyRouteHook = liveFlyRouteHook();
    const { projectType } = this.$route.query;
    if (projectType === 'emergency') {
      this.getEmergencyTaskList(); // 应急任务单独获取任务
    } else {
      this.getCurrentTaskList(); // 获取飞行任务
    }
    // 获取token参数
    const { token } = this.$route.query;
    if (token && getToken() !== token) {
      // 单点登录带入token
      setToken(token);
    }
  },
  computed: {
    ...mapGetters([
      'currentTaskMap'
    ])
  },
  methods: {
    ...mapActions([
      // 设置当前执行的任务
      'addCurrentTaskList',
      // 清空当前执行的任务map
      'clearCurrentTaskList',
      // 当前执行的任务mapde
      'setCurrentTaskDataByKey',
      // 设置当前正在执行的任务
      'setSelectTask',
      // 设置正在执行的任务列表
      'setCurrentTaskList'
    ]),

    // 获取当前正在执行的任务
    getCurrentTaskList () {
      const flyId = this.$route.query.flyId;
      if (!flyId) return;
      getFlyingTask({projectId: flyId}).then(res=>{
        if (res.data.code === 200) {
          let data = res.data.data
          for (var i = 0; i < data.length; i++) {
            this.createTaskmap(data[i])
          }
          this.setCurrentTaskList(data)
          this.setSelectTask(data.length > 0? data[0]: {})
        }
      });
    },

    // 应急任务获取任务
    getEmergencyTaskList() {
      getRunningTaskList().then(res => {
        if (res.data.code === 200) {
          const data = res.data.data || [];
          let taskList = [];
          data.map(t => {
            if (this.projectInfo && this.projectInfo.snList && Array.isArray(this.projectInfo.snList)) {
              if (this.projectInfo.snList.indexOf(t.sn) > -1) {
                taskList.push(t);
              }
            }
          });
          this.getEmergencyExecuteTaskList(taskList);
        }
      });
    },
    // 获取应急所有的正在执行的任务列表
    async getEmergencyExecuteTaskList(data = []) {
      let currentTaskList = [];
      let taskList = '';
      for (let i = 0; i < data.length; i++) {
        if (!data[i]['obtained']) {
          // 如果当前信息已经处理过了则跳过
          let res = await getFlyingTask({projectId: data[i].projectId});
          if (res.data.code === 200) {
            taskList = res.data.data;
            taskList.map(t => {
              let item = data.find(i => i.sn === t.airPlaneSn);
              if (item) {
                item['obtained'] = true;
                currentTaskList.push(t);
              }
            });
          }
        }
      }
      for (var i = 0; i < currentTaskList.length; i++) {
        this.createTaskmap(currentTaskList[i]);
      }
      this.setCurrentTaskList(currentTaskList);
      this.setSelectTask(currentTaskList.length > 0 ? currentTaskList[0] : {});
    },

    // 维护全局变量的当前执行任务表
    createTaskmap(data){
      const videoUrl = data.videoUrl
      let icon = planeIcon
      let selectIcon = planeIcon_seleted
      try {
        findEquipmentModel({
          sn: data.airPlaneSn
        }).then(res=>{
          if (res.data.code === 200 && res.data.data) {
            icon = res.data.data.modelIcon || icon;
            selectIcon = res.data.data.modelSelectIcon || selectIcon;
          }
        }).finally(()=>{
          let socket = this.webSocketInt(data)
          this.addCurrentTaskList({
            key: data.taskId,
            value: {
              socket,
              videoUrl,
              data: {},
              icon,
              selectIcon
            }
          })
        })
      } catch (error) {
        console.error('创建正在执行任务失败',error);
      }
    },

    // 创建websocket
    webSocketInt(data) {
      let _this = this;
      let ws = null;
      let wsUrl = "";
      try {
        let useIot = data.useIot,
          airPlaneSn = data.airPlaneSn, 
          taskId = data.taskId,
          orderID = data.orderID,
          airName = data.airName;
        // 不再使用iot判断了
        // if (useIot === 1) {
        //   let guid = Cesium.createGuid().slice(0, 6).replace('-', 'A')
        //   wsUrl = `${window.global.VUE_APP_WS_ROUTE_NEW }/${airPlaneSn}-${guid}`;
        // } else {
        //   let uavID = orderID.split('-')[0];
        //   wsUrl = `${window.global.VUE_APP_WS_ROUTE}/?type=REALTIME&uavId=${uavID.toLocaleUpperCase()}`;
        // }
        let guid = Cesium.createGuid().slice(0, 6).replace('-', 'A')
        wsUrl = `${window.global.VUE_APP_WS_ROUTE_NEW }/${airPlaneSn}-${guid}`;
        const wsCallBack = function({data}) {
          // 新方案的数据存储
          _this.setCurrentTaskDataByKey({
            key: taskId,
            airName,
            value: data
          })
        }
        if (wsUrl) {
          ws = this.$options.flyRouteHook.initWebSocket({url: wsUrl, callback: wsCallBack});
        }
        return ws
      } catch (error) {
        throw error
      }
    }
  },
  beforeDestroy() {
    this.clearCurrentTaskList()
    this.$options.flyRouteHook.closeWsLink();
    this.$options.flyRouteHook = null;
  }
};
</script>

<style lang="scss">
.screen-common-bgc {
  width: 100%;
  height: 100%;
  background-color: #051823;
  .screen-common-home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #233561C9 0%, #021C3CC9 100%);
    .header {
      height: 110px;
      background-image: url("~@/assets/images/main-bg-header.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    &>.content {
      flex: 1;
      display: flex;
      padding: 9px 11px 12px 14px;
      background-image: url("~@/assets/images/main-bg-new.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: calc(100% - 110px);
      overflow: hidden;
    }
  }
}

</style>
