var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "oilWellPage", staticClass: "oilWell-page" },
    [
      _c("div", { staticClass: "today" }, [
        _vm._v(_vm._s(_vm.$t("oilGasScreen.today"))),
      ]),
      _c("div", { staticClass: "tomonth" }, [
        _vm._v(_vm._s(_vm.$t("oilGasScreen.theMonth"))),
      ]),
      _vm.showChart
        ? _c("chartPie", {
            attrs: {
              id: "oilWell-chart",
              isDoubleChart: true,
              isSemicircle: true,
              legendPosition: "right",
              chartRadius: [40, 70],
              chartCenter: [110, "75%"],
              chartRadiusTwo: [40, 70],
              chartCenterTwo: [330, "75%"],
              graphicOption: {
                x: 110,
                y: _vm.fontPosNum,
                text: _vm.chartTotal,
              },
              graphicOptionTwo: {
                x: 330,
                y: _vm.fontPosNum,
                text: _vm.chartTwoTotal,
              },
              chartData: _vm.chartData,
              chartDataTwo: _vm.chartDataTwo,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }