var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "social-container" }, [
    _c(
      "div",
      {
        on: {
          click: function ($event) {
            return _vm.handleClick("github")
          },
        },
      },
      [
        _c(
          "span",
          { staticClass: "container", style: { backgroundColor: "#61676D" } },
          [
            _c("i", {
              staticClass: "iconfont icongithub",
              attrs: { "icon-class": "github" },
            }),
          ]
        ),
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("login.github"))),
        ]),
      ]
    ),
    _c(
      "div",
      {
        on: {
          click: function ($event) {
            return _vm.handleClick("gitee")
          },
        },
      },
      [
        _c(
          "span",
          { staticClass: "container", style: { backgroundColor: "#c35152" } },
          [
            _c("i", {
              staticClass: "iconfont icongitee2",
              attrs: { "icon-class": "gitee" },
            }),
          ]
        ),
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("login.gitee"))),
        ]),
      ]
    ),
    _c(
      "div",
      {
        on: {
          click: function ($event) {
            return _vm.handleClick("wechat_open")
          },
        },
      },
      [
        _c(
          "span",
          { staticClass: "container", style: { backgroundColor: "#8dc349" } },
          [
            _c("i", {
              staticClass: "iconfont icon-weixin",
              attrs: { "icon-class": "wechat" },
            }),
          ]
        ),
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("login.wechat"))),
        ]),
      ]
    ),
    _c(
      "div",
      {
        on: {
          click: function ($event) {
            return _vm.handleClick("qq")
          },
        },
      },
      [
        _c(
          "span",
          { staticClass: "container", style: { backgroundColor: "#6ba2d6" } },
          [
            _c("i", {
              staticClass: "iconfont icon-qq",
              attrs: { "icon-class": "qq" },
            }),
          ]
        ),
        _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t("login.qq")))]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }