<template>
    <div id="uav-realInfo-pop" class="uav-realInfo-pop" v-show="show">
        <div class="uav-pop-top">
            <img src="@/assets/images/closedicon.png" alt="" @click="show = false">
        </div>
        <div class="uav-pop-center">
            <div class="uav-pop-center-top">{{$t('screen.taskInfo')}}</div>
            <div class="uav-pop-center-msg">
                <div class="uav-pop-center-msgd">
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('screen.UAVNumber')">{{$t('screen.UAVNumber')}}：</label>
                            <div class="msg" :title="sn">{{sn}}</div>
                        </div>
                        <div class="msg-item">
                            <label class="label" :title="$t('height')">{{$t('height')}}：</label>
                            <div class="msg">{{UAVData.fieldHeight ? UAVData.fieldHeight.toFixed(2) : '0'}}m</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('mission.name')">{{$t('mission.name')}}：</label>
                            <div class="msg" :title="taskName">{{taskName}}</div>
                        </div>
                        <div class="msg-item">
                            <label class="label" :title="$t('RealTimeMileage')">{{$t('RealTimeMileage')}}：</label>
                            <div class="msg">{{UAVData.distOnAir ? (UAVData.distOnAir / 1000).toFixed(2) : '0'}}km</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('CurrentBatteryLevel')">{{$t('CurrentBatteryLevel')}}：</label>
                            <div class="msg">{{typeof UAVData.pwrLeft === "number" ? UAVData.pwrLeft : "0"}}%</div>
                        </div>
                        <div class="msg-item">
                            <label class="label" :title="$t('speed')">{{$t('speed')}}：</label>
                            <div class="msg">{{UAVData.gs ? UAVData.gs : '0'}}m/s</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('longitude')">{{$t('longitude')}}：</label>
                            <div class="msg">{{UAVData.lon ? UAVData.lon : "-"}}</div>
                        </div>
                    </div>
                    <div class="msg-list">
                        <div class="msg-item">
                            <label class="label" :title="$t('latitude')">{{$t('latitude')}}：</label>
                            <div class="msg">{{UAVData.lat ? UAVData.lat : "-"}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'uav-realInfo-pop',
    data() {
        return {
            show: false
        }
    },
    computed: {
        ...mapGetters([
            'selectTask',
            'currentTaskMap'
        ]),
        // 无人机数据
        UAVData() {
            let UAVData = {};
            if (this.selectTask && (this.selectTask.taskId || this.selectTask.sn)) {
                if (this.currentTaskMap[this.selectTask.taskId || this.selectTask.sn]) {
                    UAVData = this.currentTaskMap[this.selectTask.taskId || this.selectTask.sn].data || {};
                }
            }
            return UAVData;
        },
        // 无人机序列号
        sn() {
            let sn = '';
            if (this.selectTask) {
                if (this.selectTask.airPlaneSn) {
                    sn = this.selectTask.airPlaneSn;
                } else if (this.selectTask.sn) {
                    sn = this.selectTask.sn;
                }
            }
            return sn;
        },
        // 任务名称
        taskName() {
            return this.selectTask && this.selectTask.taskName ? this.selectTask.taskName : '';
        }
    },
    methods: {
        // 
    }
}
</script>

<style lang="scss" scoped>
.uav-realInfo-pop {
    width: 430px;
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, calc(-50% - 140px));
    cursor: pointer;
    .uav-pop-top {
        text-align: right;
        img {
            width: 18px;
            height: 18px;
        }
    }
    .uav-pop-center {
        width: 430px;
        height: 195px;
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("~@/assets/images/mapTipsbg.png");
        .uav-pop-center-top {
            height: 23px;
            line-height: 23px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            text-align: center;
        }
        .uav-pop-center-msg {
            height: 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 15px 22px 22px 22px;
            .uav-pop-center-msgd {
                height: 0;
                flex: 1;
                font-family: MicrosoftYaHeiUI;
                font-size: 14px;
                color: #FFFFFF;
                .msg-list {
                    display: flex;
                    flex: 1;
                    margin-top: 8px;
                    &:first-child {
                        margin-top: 0;
                    }
                    .msg-item {
                        display: flex;
                        flex: 1;
                        margin-left: 20px;
                        &:first-child {
                            margin-left: 0;
                        }
                        .label {
                            width: 85px;
                            color: rgba(255, 255, 255, 0.7);
                            cursor: pointer;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            text-overflow: ellipsis;
                            word-break: break-all;
                        }
                        .msg {
                            flex: 1;
                            margin-left: 5px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            text-overflow: ellipsis;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
}
</style>