<template>
    <!-- 实时报警 -->
    <div class="realTimeAlarm-page">
        <div class="alarm-main">
            <div class="alarm-cont" v-show="isSHowAlarm">
                <i class="el-icon-close" @click="isSHowAlarm = false"></i>
                <BaseBox :title="$t('oilGasScreen.realTimeAlarm')">
                    <template v-slot:content>
                        <div class="realTimeAlarm">
                            <div class="realTimeAlarm-cont">
                                <div class="realTimeAlarm-main" v-if="alarmList.length">
                                    <div class="realTimeAlarm-item" v-for="item in alarmList" :key="item.id">
                                        <div class="alarm-img">
                                            <el-image class="alarm-img-el" :fit="'fill'" :src="picUrl(item.picUrl)" 
                                                :preview-src-list="previewSrcList(item.picUrl)" />
                                        </div>
                                        <div class="alarm-msg">
                                            <div class="alarm-title" :title="item.alarmTypeName">
                                                {{$t('oilGasScreen.alarmTypes')}}：{{item.alarmTypeName || '--'}}
                                            </div>
                                            <div class="time" :title="item.alarmTime">
                                                {{$t('oilGasScreen.time')}}：{{item.alarmTime || '--'}}
                                            </div>
                                            <div class="other-msg" :title="item.uavName ? item.uavName[0] : '--'">
                                                {{$t('oilGasScreen.uavName')}}：{{item.uavName ? item.uavName[0] : '--'}}
                                            </div>
                                            <div class="other-msg" :title="item.lon + ',' + item.lat">
                                                {{$t('oilGasScreen.latLon')}}：{{item.lon || '--'}}，{{item.lat || '--'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="realTimeAlarm-main" v-else>
                                    <div class="no-data">
                                        <i class="el-icon-folder-delete" />
                                        <p>{{$t('oilGasScreen.noData')}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </BaseBox>
            </div>
            <div class="search-cont" v-show="isShowSearch">
                <i class="el-icon-close" @click="isShowSearch = false"></i>
                <BaseBox :title="$t('oilGasScreen.alarmSearch')">
                    <template v-slot:content>
                        <div class="search-main">
                            <div class="search-main-cont">
                                <div class="label">{{$t('oilGasScreen.alarmTimeInfo')}}</div>
                                <div class="msg">
                                    <div class="search-btn">
                                        <el-button-group class="search-btn-group">
                                            <el-button :type="item.id == selectSearchBtn ? 'primary' : ''" 
                                                v-for="(item, index) in searchBtnList" size="mini" :key="index + 'btn'" 
                                                @click="changeSearchBtn(item.id)">{{item.label}}</el-button>
                                        </el-button-group>
                                    </div>
                                    <div class="custom-time" v-show="selectSearchBtn == 5">
                                        <el-date-picker v-model="customTime" size="mini" type="datetimerange" 
                                            :range-separator="$t('to')" popper-class="time-item-poper" 
                                            :default-time="['00:00:00', '23:59:59']" :start-placeholder="$t('startTime')" 
                                            :end-placeholder="$t('endTime')" value-format="yyyy-MM-dd HH:mm:ss" 
                                            :clearable="false" @change="changeSelectData" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </BaseBox>
            </div>
        </div>
        <div class="alarm-icon">
            <div class="alarm-img" :title="$t('oilGasScreen.realTimeAlarm')"  @click="isSHowAlarm = !isSHowAlarm">
                <img v-show="!isSHowAlarm" src="@/assets/img/srceen/warningIcon.png" alt="">
                <img v-show="isSHowAlarm" src="@/assets/img/srceen/warningIcon-active.png" alt="">
            </div>
            <div class="alarm-img alarm-img-search" :title="$t('oilGasScreen.alarmSearch')"  
                @click="isShowSearch = !isShowSearch">
                <img v-show="!isShowSearch" src="@/assets/img/srceen/searchIcon.png" alt="">
                <img v-show="isShowSearch" src="@/assets/img/srceen/searchIcon-active.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import BaseBox from '../../BaseBox/index.vue';

export default {
    name: 'realTimeAlarm-page',
    components: {BaseBox},
    props: ['alarmData'],
    data() {
        return {
            isSHowAlarm: false,
            alarmList: [],
            isShowSearch: false,
            searchBtnList: [
                {id: 1, label: this.$t('oilGasScreen.today')},
                {id: 2, label: this.$t('oilGasScreen.threeDay')},
                {id: 3, label: this.$t('oilGasScreen.oneWeek')},
                {id: 4, label: this.$t('oilGasScreen.oneMonth')},
                {id: 5, label: this.$t('oilGasScreen.custom')}
            ],
            selectSearchBtn: 1,
            customTime: ''
        }
    },
    computed: {
        picUrl() {
            return function(data) {
                if (!data) return '';
                let spiltData = data.split(',');
                return spiltData[0] || '';
            }
        },
        previewSrcList() {
            return function(data) {
                if (!data) return [];
                return data.split(',');
            }
        }
    },
    watch: {
        'alarmData': {
            deep: true,
            handler: function() {
                this.alarmList = [...this.alarmData];
            }
        }
    },
    methods: {
        changeSearchBtn(data) {
            this.selectSearchBtn = data;
            let time = [];
            // 当前日期
            let currentDate  = new Date();
            let year = currentDate.getFullYear(); // 获取年份
            let month = currentDate.getMonth() + 1; // 获取月份，+1因为getMonth()返回的月份从0开始
            let day = currentDate.getDate(); // 获取日
            let endDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            let currentTimer = new Date().getTime(endDate);
            if (this.selectSearchBtn == 1) {
                // 今天
                time = [`${endDate} 00:00:00`, `${endDate} 23:59:59`];
            } else if (this.selectSearchBtn == 2) {
                // 近3天
                let beforeTimer = new Date(currentTimer - 1000 * 60 * 60 * 24 * 3);
                let lastY = beforeTimer.getFullYear();
                let lastM = beforeTimer.getMonth() + 1;
                let lastD = beforeTimer.getDate();
                let startData = `${lastY}-${lastM.toString().padStart(2, '0')}-${lastD.toString().padStart(2, '0')}`;
                time = [`${startData} 00:00:00`, `${endDate} 23:59:59`];
            } else if (this.selectSearchBtn == 3) {
                // 近1周
                let beforeTimer = new Date(currentTimer - 1000 * 60 * 60 * 24 * 7);
                let lastY = beforeTimer.getFullYear();
                let lastM = beforeTimer.getMonth() + 1;
                let lastD = beforeTimer.getDate();
                let startData = `${lastY}-${lastM.toString().padStart(2, '0')}-${lastD.toString().padStart(2, '0')}`;
                time = [`${startData} 00:00:00`, `${endDate} 23:59:59`];
            } else if (this.selectSearchBtn == 4) {
                // 近1月
                let beforeTimer = new Date(currentTimer - 1000 * 60 * 60 * 24 * 30);
                let lastY = beforeTimer.getFullYear();
                let lastM = beforeTimer.getMonth() + 1;
                let lastD = beforeTimer.getDate();
                let startData = `${lastY}-${lastM.toString().padStart(2, '0')}-${lastD.toString().padStart(2, '0')}`;
                time = [`${startData} 00:00:00`, `${endDate} 23:59:59`];
            } else if (this.selectSearchBtn == 5) {
                // 自定义日期
                time = this.customTime;
            }
            if (!time || !time.length) return;
            this.$emit('changeSearchAlarm', {time});
        },
        changeSelectData() {
            if (this.customTime && this.customTime.length) {
                this.$emit('changeSearchAlarm', {time: this.customTime});
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.realTimeAlarm-page {
    display: flex;
    .alarm-main {
        width: 30vw;
        .alarm-cont {
            width: 30vw;
            max-height: 248px;
            background: rgba(0, 14, 46, 0.5);
            margin-bottom: 10px;
            position: relative;
            .realTimeAlarm {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                .realTimeAlarm-cont {
                    height: 0;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding: 10px;
                    .realTimeAlarm-main {
                        height: 0;
                        flex: 1;
                        overflow: auto;
                        .realTimeAlarm-item {
                            border-radius: 2px;
                            padding: 10px;
                            display: flex;
                            &:first-child {
                                background: linear-gradient(to right, rgba(128, 102, 121, 0.7) 0%, 
                                    rgba(128, 102, 121, 0.2) 50%, rgba(128, 102, 121, 0) 100%);
                            }
                            .alarm-img {
                                width: 133px;
                                height: 75px;
                                .alarm-img-el {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .alarm-msg {
                                width: 0;
                                display: flex;
                                flex: 1;
                                flex-direction: column;
                                margin-left: 10px;
                                font-family: 'Arial Normal', 'Arial';
                                font-weight: 400;
                                font-style: normal;
                                cursor: pointer;
                                .alarm-title {
                                    font-size: 14px;
                                    color: #FE0000;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 1;
                                    text-overflow: ellipsis;
                                    word-break: break-all;
                                }
                                .time {
                                    font-size: 12px;
                                    color: #fff;
                                    margin-top: 3px;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 1;
                                    text-overflow: ellipsis;
                                    word-break: break-all;
                                }
                                .other-msg {
                                    font-size: 12px;
                                    color: #999999;
                                    margin-top: 3px;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 1;
                                    text-overflow: ellipsis;
                                    word-break: break-all;
                                }
                            }
                        }
                        .no-data {
                            text-align: center;
                            color: #fff;
                            font-size: 14px;
                            .el-icon-folder-delete {
                                font-size: 25px;
                            }
                        }
                    }
                }
            }
        }
        .search-cont {
            width: 30vw;
            background: rgba(0, 14, 46, 0.5);
            position: relative;
            .search-main {
                padding: 15px 10px;
                .search-main-cont {
                    display: flex;
                    .label {
                        font-family: 'Arial Normal', 'Arial';
                        font-size: 13px;
                        color: #fff;
                    }
                    .msg {
                        flex: 1;
                        margin-left: 10px;
                        .search-btn {
                            .search-btn-group {
                                .el-button {
                                    color: #fff;
                                    background: transparent;
                                }
                                .el-button--primary {
                                    color: #409EFF;
                                }
                            }
                        }
                        .custom-time {
                            margin-top: 10px;
                            ::v-deep .el-input__inner {
                                background-color: transparent;
                                color: #fff;
                                .el-input__icon, .el-range-separator {
                                    color: #fff;
                                }
                                .el-range-input {
                                    color: #fff;
                                    background-color: transparent;
                                    &::placeholder {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .el-icon-close {
            position: absolute;
            top: 2px;
            right: 8px;
            z-index: 2;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-size: 18px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
    .alarm-icon {
        .alarm-img {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
            &.alarm-img-search {
                margin-top: 10px;
            }
        }
    }
}
</style>

<style lang="scss">
.time-item-poper {
  .el-picker-panel__footer .el-button--text {
    display: none;
  }
}
</style>