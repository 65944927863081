var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Box" }, [
    _c("div", { staticClass: "gasDataBox" }, [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "scroll-container",
          on: { mouseenter: _vm.stopMove, mouseleave: _vm.startMove },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.store && _vm.store.length,
                  expression: "store && store.length",
                },
              ],
              staticClass: "scroll-content",
            },
            [
              _c(
                "div",
                { ref: "originHtml", staticClass: "scroll-content-list" },
                _vm._l(_vm.store, function (item, index) {
                  return _c("div", { key: index, staticClass: "scroll-item" }, [
                    _c("span", { staticClass: "title" }, [
                      _c(
                        "span",
                        {
                          attrs: { slot: "reference", title: item.taskName },
                          slot: "reference",
                        },
                        [_vm._v(_vm._s(item.taskName))]
                      ),
                    ]),
                    _c("span", { staticClass: "title" }, [
                      _c(
                        "span",
                        {
                          attrs: { slot: "reference", title: item.uavId },
                          slot: "reference",
                        },
                        [_vm._v(_vm._s(item.uavId))]
                      ),
                    ]),
                    _c("span", { staticClass: "title" }, [
                      _c(
                        "span",
                        {
                          attrs: { slot: "reference", title: item.flyDistance },
                          slot: "reference",
                        },
                        [_vm._v(_vm._s((item.flyDistance / 1000).toFixed(2)))]
                      ),
                    ]),
                    _c("span", { staticClass: "title" }, [
                      _c(
                        "span",
                        {
                          attrs: { slot: "reference", title: item.flyingDate },
                          slot: "reference",
                        },
                        [_vm._v(_vm._s(item.flyingDate))]
                      ),
                    ]),
                    _c("span", { staticClass: "title" }, [
                      _c(
                        "span",
                        {
                          attrs: { slot: "reference", title: item.flyingTime },
                          slot: "reference",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.getFlyingTime(item.flyingTime)) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _c("span", { staticClass: "title" }, [
                      _c(
                        "span",
                        {
                          style: item.alarmState
                            ? { color: "red" }
                            : { color: "#fff" },
                          attrs: { title: item.alarmState ? "有" : "无" },
                        },
                        [_vm._v(_vm._s(item.alarmState ? "有" : "无"))]
                      ),
                    ]),
                    _c("span", { staticClass: "title" }, [
                      _c("img", {
                        staticClass: "download",
                        attrs: {
                          src: require("@/assets/images/download.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.download(item)
                          },
                        },
                      }),
                    ]),
                  ])
                }),
                0
              ),
              _c("div", {
                ref: "copyHtml",
                domProps: { innerHTML: _vm._s(_vm.copyHtml) },
              }),
            ]
          ),
          _c("avue-empty", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.store || !_vm.store.length,
                expression: "!store || !store.length",
              },
            ],
            staticClass: "fff-text",
            staticStyle: { "margin-top": "10px" },
            attrs: { description: "暂无数据" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dataTitle" }, [
      _c("div", { staticClass: "gasTitle" }, [_vm._v("任务名称")]),
      _c("div", { staticClass: "gasTitle" }, [_vm._v("无人机名称")]),
      _c("div", { staticClass: "gasTitle" }, [_vm._v("飞行里程(km)")]),
      _c("div", { staticClass: "gasTitle" }, [_vm._v("飞行日期")]),
      _c("div", { staticClass: "gasTitle" }, [_vm._v("飞行时长")]),
      _c("div", { staticClass: "gasTitle" }, [_vm._v("有无异常")]),
      _c("div", { staticClass: "gasTitle" }, [_vm._v("巡检报告")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }