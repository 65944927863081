<template>
    <!-- 管线巡检历程统计 -->
    <div class="pipeline-page" ref="pipelinePage">
        <div class="today">{{$t('oilGasScreen.today')}}</div>
        <div class="tomonth">{{$t('oilGasScreen.theMonth')}}</div>
        <chartPie 
            v-if="showChart"
            :id="'pipeline-chart'"
            :isDoubleChart="true"
            :legendPosition="'right'"
            :chartRadius="[40, 60]"
            :chartCenter="[90, '55%']"
            :chartRadiusTwo="[40, 60]"
            :chartCenterTwo="[295, '55%']"
            :graphicOption="{x: 90, y: fontPosNum, text: chartTotal}"
            :graphicOptionTwo="{x: 295, y: fontPosNum, text: chartTwoTotal}"
            :tooltip="tooltip"
            :chartData="chartData"
            :chartDataTwo="chartDataTwo"
        />
    </div>
</template>

<script>
import chartPie from '@/components/echarts/chartPie.vue';

export default {
    name: 'pipeline-page',
    components: {chartPie},
    props: ['pipeData'],
    data() {
        return {
            showChart: false,
            fontPosNum: 0,
            chartTotal: '0',
            chartData: [],
            chartTwoTotal: '0',
            tooltip: {
                trigger: 'item',
                valueFormatter: (value) => {
                    return `${value}km`;
                }
            },
            chartDataTwo: []
        }
    },
    watch: {
        'pipeData': {
            deep: true,
            handler: function() {
                this.handlerData();
            }
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.showChart = false;
            this.$nextTick(() => {
                const domHeight = this.$refs.pipelinePage.offsetHeight;
                this.fontPosNum = domHeight * 0.55 - 11;
                this.showChart = true;
            });
        },
        handlerData() {
            let chartData = [];
            this.chartTotal = this.pipeData[0].total ? `${this.pipeData[0].total}` : '0';
            Object.keys(this.pipeData[0].data).map(t => {
                chartData.push({name: t, value: this.pipeData[0].data[t] || 0});
            });
            this.chartData = chartData;
            let chartTwoData = [];
            this.chartTwoTotal = this.pipeData[1].total ? `${this.pipeData[1].total}` : '0';
            Object.keys(this.pipeData[1].data).map(t => {
                chartTwoData.push({name: t, value: this.pipeData[1].data[t] || 0});
            });
            this.chartDataTwo = chartTwoData;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style lang="scss" scoped>
.pipeline-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    .today {
        position: absolute;
        bottom: 5px;
        left: 80px;
        font-family: MicrosoftYaHeiUI;
        font-size: 13px;
        color: #FFFFFF;
    }
    .tomonth {
        position: absolute;
        bottom: 5px;
        right: 83px;
        font-family: MicrosoftYaHeiUI;
        font-size: 13px;
        color: #FFFFFF;
    }
}
</style>