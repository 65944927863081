<template>
  <div class="trend-warning">
    <div class="sub-title">AI识别报警</div>
    <div class="container">
      <div
        v-for="(item, index) in alarmDataList"
        :key="index"
        class="waning-item"
      >
        <div v-if="index === 0" class="waning-item--warning animated"></div>
        <div class="image-box">
          <div class="order-num">{{ index + 1 }}</div>
          <!-- <div
            @click="viewDetail(item.displayPicUrl, item.alarmTypeCHN)"
            class="detail"
          >
            <img src="@/assets/images/enlarge.png" alt="" />
          </div> -->
          <!-- <img class="img" :src="item.displayPicUrl" alt="图片加载失败" /> -->
          <el-image
            class="img"
            :src="(item.displayPicUrl && Array.isArray(item.displayPicUrl))? item.displayPicUrl[0] : ''"
            :preview-src-list="item.displayPicUrl"
          >
          </el-image>
        </div>
        <div class="info-box">
          <p class="title">报警类型：{{ item.alarmTypeCHN }}</p>
          <p class="time">时间: {{ item.alarmTimeStr }}</p>
          <p class="lnt">经度： {{ item.lon }}</p>
          <p class="lat">纬度： {{ item.lat }}</p>
        </div>
      </div>
    </div>

    <Modal v-model="visible" @close="close" :title="title">
      <template v-slot:content>
        <div class="modal-content">
          <img :src="url" alt="" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/screen/modal.vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      visible: false,
      url: "",
      title: "",
    };
  },
  computed: {
    alarmDataList() {
      // return [
      //   ...this.$store.state.wsData.alarmDataList,
      //   ...this.$store.state.wsData.alarmDataList,
      // ];
      return this.$store.state.wsData.alarmDataList;
    },
  },
  methods: {
    viewDetail(url, title) {
      this.title = title ? `AI识别报警 — ${title}` : "";
      this.url = url;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.trend-warning {
  width: 100%;
  height: calc(62% - 10px);
  margin-top: 10px;
  position: relative;
  padding: 8px 0px;
  .container {
    position: absolute;
    top: 8px;
    left: 0;
    bottom: 0;
    right: 0;
    // background: red;
    overflow: auto;
    height: calc(100% - 21px);
  }
  .sub-title {
    z-index: 99;
    position: absolute;
    width: 122px;
    height: 21px;
    left: 0;
    top: -2px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #f0f5ff;
    text-shadow: 0px 1px 4px #001c4b;
    text-align: center;
    line-height: 21px;
    background-image: url("~@/assets/images/subTitle.png");
    background-position: center;
    background-size: cover;
  }
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes twinkling {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  @keyframes twinkling {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  @-webkit-keyframes movingLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes twinkling {
    100% {
      transform: translateX(-100%);
    }
  }
  .waning-item {
    overflow: hidden;
    &--warning {
      animation: movingLeft 1.5s infinite;
      background: linear-gradient(90deg, #992d4e 0%, rgba(94, 51, 86, 0) 100%);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    border-top: 1px dashed #1c3d91;
    width: 100%;
    height: 130px;
    padding: 12px 14px 13px 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
    &:first-child {
      padding-top: 24px;
      background: #542850;
    }
  }
  .image-box {
    width: 50%;
    position: relative;
    left: 8%;
    .img {
      width: 100%;
      height: 100%;
    }
    .order-num {
      position: absolute;
      width: 26px;
      height: 21px;
      background: #000000;
      color: #ffffff;
      text-align: center;
      line-height: 21px;
      left: 4px;
      top: 4px;
    }
    .detail {
      position: absolute;
      width: 29px;
      height: 18px;
      border-radius: 1px;
      background: #ffffff;
      text-align: center;
      line-height: 21px;
      right: 6px;
      top: 6px;
      cursor: pointer;
      img {
        width: 28px;
      }
    }
  }
  .info-box {
    width: 40%;
    padding-left: 15px;
    text-align: left;
    position: relative;
    z-index: 9;
    .title {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ff344c;
      margin-top: -3px;
    }
    .time {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      margin-top: 6px;
    }
    .lnt {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #9ea5ae;
      margin-top: 13px;
    }
    .lat {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #9ea5ae;
      margin-top: 4px;
    }
  }
}
.modal-content {
  height: 100%;
  width: 100%;
  img {
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
