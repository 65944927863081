var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "right-plane-Box" }, [
    _c("div", { staticClass: "topinfo" }, [
      _c("span", { staticClass: "topinfo-left" }, [
        _c("img", {
          staticClass: "imgUavInfo",
          attrs: { src: _vm.drone, alt: "" },
        }),
        _c("span", [
          _vm._v(_vm._s(_vm.$t("screen.UAVModel")) + "："),
          _c("span", { staticClass: "wrj" }, [
            _vm._v(_vm._s(_vm.selectTaskName || "--")),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "plane-info" },
      [
        _c(
          "el-row",
          { staticClass: "bodyBox", attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { span: 8 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "elColLeft",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 12 : 15 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("currentMode")) +
                            "：\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "elColRight",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 12 : 9 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.UAVData.flgtmd ? _vm.UAVData.flgtmd : "-"
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 8 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "elColLeft",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 12 : 19 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("CurrentBatteryLevel")) +
                            "：\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "elColRight",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 10 : 5 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              typeof _vm.UAVData.pwrLeft === "number"
                                ? "" + (_vm.UAVData.pwrLeft + "%")
                                : "-"
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 8 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "elColLeft",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 12 : 15 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("unlockingSignal")) +
                            "：\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "elColRight",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 12 : 9 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.UAVData.lcksta == 1
                                ? _vm.$t("unlocked")
                                : _vm.$t("locked")
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          { staticClass: "bodyBox", attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { span: _vm.$i18n.locale === "zh" ? 8 : 9 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "elColLeft",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 8 : 11 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("longitude")) +
                            "：\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "elColRight",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 16 : 13 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.UAVData.lon ? _vm.UAVData.lon : "-") +
                            "\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: _vm.$i18n.locale === "zh" ? 8 : 12 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "elColLeft",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 8 : 10 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("latitude")) +
                            "：\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "elColRight",
                        attrs: { span: _vm.$i18n.locale === "zh" ? 16 : 14 },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.UAVData.lat ? _vm.UAVData.lat : "-") +
                            "\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "plane-card" }, [
      _c("div", { staticClass: "plane-card-item" }, [
        _c("span", [
          _c("img", {
            staticClass: "imgUav",
            attrs: { src: _vm.speed, alt: "" },
          }),
          _c("span", { staticClass: "card-item-title" }, [
            _vm._v(_vm._s(_vm.$t("speed"))),
          ]),
        ]),
        _c("span", [
          _c("span", { staticClass: "card-item-value" }, [
            _vm._v(_vm._s(_vm.UAVData.gs)),
          ]),
          _c("span", { staticClass: "card-item-unit" }, [_vm._v("m/s")]),
        ]),
      ]),
      _c("div", { staticClass: "plane-card-item" }, [
        _c("span", [
          _c("img", {
            staticClass: "imgUav",
            attrs: { src: _vm.altitude, alt: "" },
          }),
          _c("span", { staticClass: "card-item-title" }, [
            _vm._v(_vm._s(_vm.$t("height"))),
          ]),
        ]),
        _c("span", [
          _c("span", { staticClass: "card-item-value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(
                  _vm.UAVData.fieldHeight
                    ? _vm.UAVData.fieldHeight.toFixed(2)
                    : 0
                ) +
                "\n                "
            ),
          ]),
          _c("span", { staticClass: "card-item-unit" }, [_vm._v("m")]),
        ]),
      ]),
      _c("div", { staticClass: "plane-card-item" }, [
        _c("span", [
          _c("img", {
            staticClass: "imgUav",
            attrs: { src: _vm.mileage, alt: "" },
          }),
          _c("span", { staticClass: "card-item-title" }, [
            _vm._v(_vm._s(_vm.$t("RealTimeMileage"))),
          ]),
        ]),
        _c("span", [
          _c("span", { staticClass: "card-item-value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(
                  this.UAVData.distOnAir
                    ? (this.UAVData.distOnAir / 1000).toFixed(2)
                    : "0"
                ) +
                "\n                "
            ),
          ]),
          _c("span", { staticClass: "card-item-unit" }, [_vm._v("km")]),
        ]),
      ]),
      _c("div", { staticClass: "plane-card-item" }, [
        _c("span", [
          _c("img", {
            staticClass: "imgUav",
            attrs: { src: _vm.flight_time, alt: "" },
          }),
          _c("span", { staticClass: "card-item-title" }, [
            _vm._v(_vm._s(_vm.$t("FlightTime"))),
          ]),
        ]),
        _c("span", [
          _c("span", { staticClass: "card-item-value" }, [
            _vm._v(_vm._s(this.minuteFormate)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }