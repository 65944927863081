var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "realTimeAlarm" }, [
    _c("div", { staticClass: "realTimeAlarm-cont" }, [
      _vm.alarmList.length
        ? _c(
            "div",
            { staticClass: "realTimeAlarm-main" },
            _vm._l(_vm.alarmList, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "realTimeAlarm-item" },
                [
                  _c(
                    "div",
                    { staticClass: "alarm-img" },
                    [
                      _c("el-image", {
                        staticClass: "alarm-img-el",
                        attrs: {
                          fit: "fill",
                          src: _vm.picUrl(item.picUrl),
                          "preview-src-list": _vm.previewSrcList(item.picUrl),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "alarm-msg" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        attrs: { title: item.alarmTypeName },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("oilGasScreen.alarmTypes")) +
                            "：" +
                            _vm._s(item.alarmTypeName || "--") +
                            "\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "time", attrs: { title: item.alarmTime } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("oilGasScreen.time")) +
                            "：" +
                            _vm._s(item.alarmTime || "--") +
                            "\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "other-msg",
                        attrs: { title: item.uavName ? item.uavName[0] : "--" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("oilGasScreen.uavName")) +
                            "：" +
                            _vm._s(item.uavName ? item.uavName[0] : "--") +
                            "\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "other-msg",
                        attrs: { title: item.lon + "," + item.lat },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("oilGasScreen.latLon")) +
                            "：" +
                            _vm._s(item.lon || "--") +
                            "，" +
                            _vm._s(item.lat || "--") +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "realTimeAlarm-main" }, [
            _c("div", { staticClass: "no-data" }, [
              _c("i", { staticClass: "el-icon-folder-delete" }),
              _c("p", [_vm._v(_vm._s(_vm.$t("oilGasScreen.noData")))]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }