<template>
  <div class="right-plane-Box">
    <div class="topinfo">
        <span class="topinfo-left">
          <img class="imgUavInfo" :src="drone" alt="" />
          <el-select
            class="uav-select-screen"
            v-model="selectTaskId"
            @change="handleChange"
            style="width: 145px"
            size="small"
          >
            <el-option
              v-for="item in taskList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </span>
        <span class="topinfo-right">
          {{ sn }}
        </span>
    </div>
    <div class="plane-info">
      <el-row :gutter="24" class="bodyBox">
        <el-col :span="8">
          <el-row>
            <el-col :span="$i18n.locale ==='zh'? 10 : 15" class="elColLeft"> {{$t('currentMode')}}：</el-col>
            <el-col :span="$i18n.locale ==='zh'? 14 : 9" class="elColRight">{{
              UAVData.flgtmd? UAVData.flgtmd: "-"
            }}</el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <el-col :span="$i18n.locale ==='zh'? 10 : 19" class="elColLeft">{{$t('CurrentBatteryLevel')}}：</el-col>
            <el-col :span="$i18n.locale ==='zh'? 10 : 5" class="elColRight">{{
              typeof UAVData.pwrLeft === "number"
              ? `${UAVData.pwrLeft + "%"}`
              : "-"
            }}</el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <el-col :span="$i18n.locale ==='zh'? 10 : 15" class="elColLeft">{{$t('unlockingSignal')}}：</el-col>
            <el-col :span="$i18n.locale ==='zh'? 14 : 9" class="elColRight">
              {{ UAVData.lcksta == 1? $t('unlocked'): $t('locked') }}
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="bodyBox">
        <el-col :span="$i18n.locale ==='zh'? 8 : 9">
          <el-row>
            <el-col :span="$i18n.locale ==='zh'? 10 : 13" class="elColLeft">{{$t('longitude')}}：</el-col>
            <el-col :span="$i18n.locale ==='zh'? 14 : 10" class="elColRight">{{ UAVData.lon ? UAVData.lon : "-" }}</el-col>
          </el-row>
        </el-col>
        <el-col :span="$i18n.locale ==='zh'? 8 : 12">
          <el-row>
            <el-col :span="$i18n.locale ==='zh'? 10 : 10" class="elColLeft">{{$t('latitude')}}：</el-col>
            <el-col :span="$i18n.locale ==='zh'? 14 : 10" class="elColRight">{{
              UAVData.lat ? UAVData.lat : "-"
            }}</el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <div class="plane-card">
      <div class="plane-card-item">
        <span>
          <img class="imgUav" :src="speed" alt="" />
          <span class="card-item-title">{{$t('speed')}}</span>
        </span>
        <span>
          <span class="card-item-value">{{ UAVData.gs }}</span>
          <span class="card-item-unit">m/s</span>
        </span>
      </div>
      <div class="plane-card-item">
        <span>
          <img class="imgUav" :src="altitude" alt="" />
          <span class="card-item-title">{{$t('height')}}</span>
        </span>
        <span>
          <span class="card-item-value">{{ UAVData.fieldHeight? UAVData.fieldHeight.toFixed(2): 0 }}</span>
          <span class="card-item-unit">m</span>
        </span>
      </div>
      <div class="plane-card-item">
        <span>
          <img class="imgUav" :src="mileage" alt=""/>
          <span class="card-item-title">{{$t('RealTimeMileage')}}</span>
        </span>
        <span>
          <span class="card-item-value">{{ this.UAVData.distOnAir? (this.UAVData.distOnAir / 1000).toFixed(2): '0' }}</span>
          <span class="card-item-unit">km</span>
        </span>
      </div>
      <div class="plane-card-item">
        <span>
          <img class="imgUav" :src="flight_time" alt="" />
          <span class="card-item-title">{{$t('FlightTime')}}</span>
        </span>
        <span>
          <span class="card-item-value">{{ this.minuteFormate }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import drone from "@/assets/images/drone.png";
import speed from "@/assets/images/speed.png";
import altitude from "@/assets/images/altitude.png";
import mileage from "@/assets/images/mileage.png";
import flight_time from "@/assets/images/flight_time.png";
import * as dayjs from "dayjs";
var objectSupport = require("dayjs/plugin/objectSupport");
dayjs.extend(objectSupport);
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  mounted() {},
  data() {
    return {
      selectTaskId: '',
      speed,
      drone,
      altitude,
      mileage,
      flight_time
    };
  },
  mounted() {
    this.selectTaskId = this.selectTask && this.selectTask.taskId? this.selectTask.taskId: null
  },
  computed: {
    ...mapGetters([
      'selectTask',
      'currentTaskList',
      'currentTaskMap'
    ]),
    // 当前正在执行任务列表
    taskList() {
      return this.currentTaskList.map(item=>{
        return {
          value: item.taskId,
          label: item.airName
        }
      })
    },
    // 无人机序列号
    sn() {
      return this.selectTask && this.selectTask.airPlaneSn? this.selectTask.airPlaneSn: ''
    },
    // 无人机数据
    UAVData() {
      let UAVData = {}
      if (this.selectTask && this.selectTask.taskId) {
        if (this.currentTaskMap[this.selectTask.taskId]) {
          UAVData = this.currentTaskMap[this.selectTask.taskId].data || {}
        }
      }
      return UAVData
    },
    // 飞手名称
    flyOperatorName() {
      return '';
    },
    // 飞行时间
    minuteFormate() {
      if (!this.UAVData && !this.UAVData.tmOnAir) {
        return "00:00"
      }
      var hours = Math.floor(this.UAVData.tmOnAir / 3600);
      var minutes = Math.floor((this.UAVData.tmOnAir - (hours * 3600)) / 60);
      var remainingSeconds = this.UAVData.tmOnAir - (hours * 3600) - (minutes * 60);
      if (isNaN(hours) || isNaN(minutes) || isNaN(remainingSeconds)) {
        return "00:00"
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (remainingSeconds < 10) {
        remainingSeconds = "0" + remainingSeconds;
      }
      return hours + ":" + minutes + ":" + remainingSeconds;
    }
  },

  watch: {
    selectTask: {
      handler(){
        this.selectTaskId = this.selectTask && this.selectTask.taskId? this.selectTask.taskId: ''
      },
      deep: true
    },
  },

  methods: {
    ...mapActions([
      'setSelectTask'
    ]),
    handleChange() {
      for (var i = 0; i < this.currentTaskList.length; i++){
        let task = this.currentTaskList[i]
        if (task.taskId === this.selectTaskId) {
          return this.setSelectTask(task)
        }
      }
    }
  }
};
</script>
<style lang="scss">
.right-plane-Box {
  font-family: MicrosoftYaHei;
  width: 100%;
  height: 100%;
  padding: 16px 1px 0px 7px;
  box-sizing: border-box;
  .topinfo {
    height: 22%;
    background: #00000049;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 14px 0px 11px;
    .topinfo-left {
      display: flex;
      align-items: center;
      .uav-select-screen{
        margin-left: 6px;
        .el-input__inner {
          color: #FFFFFF;
          border: 1px solid #1952A6;
          background-color: #000000;
        }
      }
    }
    .topinfo-right {
      height: 26px;
      font-size: 18px;
      font-weight: bold;
      color: #84C0FF;
      line-height: 26px;
    }
    .imgUavInfo {
      width: 39px;
    }

  }
  .plane-info {
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0px;
    box-sizing: border-box;
    .bodyBox {
      font-size: 12px;
      font-weight: bold;
      .elColLeft {
        text-align: right;
        overflow: hidden;
        white-space: nowrap;
        color: #ffffff;
        margin-bottom: 0px;
      }
      .elColRight {
        text-align: start;
        color: #84c0ff;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0px;
      }
    }
  }
  .plane-card {
    width: 100%;
    height: 53%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .plane-card-item {
      background: #00000049;
      box-sizing: border-box;
      height: calc(50% - 8px);
      width: calc(50% - 8px);
      display: flex;
      padding: 0 10px;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;
      &>span {
        display: flex;
        align-items: center;
        .imgUav {
          width: 27px;
          height: 27px;
        }
        .card-item-title{
          margin-left: 8px;
          font-size: 12px;
          line-height: 27px;
          color: #FFFFFF;
        }
        .card-item-value{
          height: 25px;
          font-size: 26px;
          font-weight: bold;
          color: #84C0FF;
          line-height: 25px;
        }
        .card-item-unit{
          margin-left: 8px;
          height: 21px;
          font-size: 16px;
          color: #84C0FF;
          line-height: 21px;
        }
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;

  &>.left>.el-row {
    display: flex;
    align-items: center;
  }
}
</style>
