<template>
  <div class="no-login-container" ref="login">
    <loadding-view class="loading"></loadding-view>
    <user-login v-show="false"></user-login>
  </div>
</template>
<script>
import userLogin from "./no-login";
import loaddingView from "@/components/loadingview/index.vue"

export default {
  name: "login",
  components: {
    userLogin,
    loaddingView
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="scss" scoped>
 .no-login-container{
  height: 100%;
  .loading{
    height: 100%;
  }
 }
</style>
