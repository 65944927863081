var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "factoryAlarmPage", staticClass: "factoryAlarmPage" },
    [
      _c(
        "div",
        { staticClass: "chart-cont" },
        [
          _c("div", {
            staticClass: "chart-bg",
            style: { width: _vm.bgPosNum + "px", height: _vm.bgPosNum + "px" },
          }),
          _vm.showChart
            ? _c("chartPie", {
                attrs: {
                  id: "record-chart",
                  isRoseChart: true,
                  chartRadius: ["30%", "52%"],
                  chartCenter: ["50%", "50%"],
                  graphicOption: {
                    x: _vm.fontPosNum.x,
                    y: _vm.fontPosNum.y,
                    text: _vm.chartTotal,
                  },
                  labelFormatter: "{c} \n {b}",
                  labelLine: { length: 20, length2: 50 },
                  labelPadding: [0, -45],
                  labelLayout: { verticalAlign: "middle", dy: 2 },
                  chartData: _vm.chartData,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }