<template>
    <!-- 实时报警 -->
    <div class="realTimeAlarm">
        <div class="realTimeAlarm-cont">
            <div class="realTimeAlarm-main" v-if="alarmList.length">
                <div class="realTimeAlarm-item" v-for="item in alarmList" :key="item.id">
                    <div class="alarm-img">
                        <el-image class="alarm-img-el" :fit="'fill'" :src="picUrl(item.picUrl)" 
                            :preview-src-list="previewSrcList(item.picUrl)" />
                    </div>
                    <div class="alarm-msg">
                        <div class="title" :title="item.alarmTypeName">
                            {{$t('oilGasScreen.alarmTypes')}}：{{item.alarmTypeName || '--'}}
                        </div>
                        <div class="time" :title="item.alarmTime">
                            {{$t('oilGasScreen.time')}}：{{item.alarmTime || '--'}}
                        </div>
                        <div class="other-msg" :title="item.uavName ? item.uavName[0] : '--'">
                            {{$t('oilGasScreen.uavName')}}：{{item.uavName ? item.uavName[0] : '--'}}
                        </div>
                        <div class="other-msg" :title="item.lon + ',' + item.lat">
                            {{$t('oilGasScreen.latLon')}}：{{item.lon || '--'}}，{{item.lat || '--'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="realTimeAlarm-main" v-else>
                <div class="no-data">
                    <i class="el-icon-folder-delete" />
                    <p>{{$t('oilGasScreen.noData')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'realTimeAlarm',
    props: ['realTimeAlarmData'],
    data() {
        return {
            alarmList: []
        }
    },
    computed: {
        picUrl() {
            return function(data) {
                if (!data) return '';
                let spiltData = data.split(',');
                return spiltData[0] || '';
            }
        },
        previewSrcList() {
            return function(data) {
                if (!data) return [];
                return data.split(',');
            }
        }
    },
    watch: {
        'realTimeAlarmData': {
            deep: true,
            handler: function() {
                this.alarmList = [...this.realTimeAlarmData];
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.realTimeAlarm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .realTimeAlarm-cont {
        height: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 10px;
        .realTimeAlarm-main {
            height: 0;
            flex: 1;
            overflow: auto;
            .realTimeAlarm-item {
                border-radius: 2px;
                padding: 10px;
                display: flex;
                &:first-child {
                    background: linear-gradient(to right, rgba(128, 102, 121, 0.7) 0%, 
                        rgba(128, 102, 121, 0.2) 50%, rgba(128, 102, 121, 0) 100%);
                }
                .alarm-img {
                    width: 133px;
                    height: 75px;
                    .alarm-img-el {
                        width: 100%;
                        height: 100%;
                    }
                }
                .alarm-msg {
                    width: 0;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    margin-left: 10px;
                    font-family: 'Arial Normal', 'Arial';
                    font-weight: 400;
                    font-style: normal;
                    cursor: pointer;
                    .title {
                        font-size: 14px;
                        color: #FE0000;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        text-overflow: ellipsis;
                        word-break: break-all;
                    }
                    .time {
                        font-size: 12px;
                        color: #fff;
                        margin-top: 3px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        text-overflow: ellipsis;
                        word-break: break-all;
                    }
                    .other-msg {
                        font-size: 12px;
                        color: #999999;
                        margin-top: 3px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        text-overflow: ellipsis;
                        word-break: break-all;
                    }
                }
            }
            .no-data {
                text-align: center;
                color: #fff;
                font-size: 14px;
                .el-icon-folder-delete {
                    font-size: 25px;
                }
            }
        }
    }
}
</style>