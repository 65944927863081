<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-09-24 10:48:36
 * @watermark: 成都沃飞长空
 * @LastEditors: wq
-->
<template>
  <div>
    <div class="contentWrap">
      <div class="left">
         <Left @handleContentClick="handleContentClick" ref="left" />
      </div>
      <div class="center">
         <Center :projectInfo="detail" />
      </div>
       <div class="right">
        <Right :detail="detail"/>
      </div> 
    </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import Center from "./center/index.vue";
import Left from "./left/index.vue";
import Right from "./right/index.vue";
export default {
  components: {
    Center,
    Left,
    Right,
  },
  props: {
    detail: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  mounted() {
    this.queryDate();
  },
  beforeDestroy() {
    if (this.timmer) {
      clearInterval(this.timmer)
    }
    this.timmer = null;
  },
  data() {
    return {
      Player: null,
      urls: [],
      url: "",
      weeks: [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      timmer: null,
      date: {
        time: null,
        date: null,
        month: null,
      },
    };
  },
  methods: {
    makeDate() {
      const date = new Date();
      this.date.date = dayjs(date).format("YYYY.MM.DD");
      this.date.time = dayjs(date).format("HH : mm : ss");
      this.date.month = dayjs(date).format("M月");
      this.date.week = this.weeks[date.getDay()];
    },
    queryDate() {
      this.makeDate();
      this.timmer = setInterval(() => {
        this.makeDate();
      }, 1000);
    },
    handleContentClick(param) {
      // 触发自定义事件
      this.$emit('handleGrandchildClick', param);
    },
  }
};
</script>

<style scoped lang="scss">
.content {
  height: 100%;
  width: 100%;
  display: flex;
  &>div {
    // overflow: hidden;
    flex: 1;
    height: 100%;
  }
}

.contentWrap {
  height: 100%;
  width: 100%;
  &>div {
    height: 100%;
  }
  .left {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
  }
  .center {
    display: inline-block;
    width: calc(40% - 20px);
    box-sizing: border-box;
    margin: 0 10px;
    vertical-align: top;
  }
  .right {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
  }
}</style>
