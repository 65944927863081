var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "trend-warning" },
    [
      _c("div", { staticClass: "sub-title" }, [_vm._v("AI识别报警")]),
      _c(
        "div",
        { staticClass: "container" },
        _vm._l(_vm.alarmDataList, function (item, index) {
          return _c("div", { key: index, staticClass: "waning-item" }, [
            index === 0
              ? _c("div", { staticClass: "waning-item--warning animated" })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "image-box" },
              [
                _c("div", { staticClass: "order-num" }, [
                  _vm._v(_vm._s(index + 1)),
                ]),
                _c("el-image", {
                  staticClass: "img",
                  attrs: {
                    src:
                      item.displayPicUrl && Array.isArray(item.displayPicUrl)
                        ? item.displayPicUrl[0]
                        : "",
                    "preview-src-list": item.displayPicUrl,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "info-box" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v("报警类型：" + _vm._s(item.alarmTypeCHN)),
              ]),
              _c("p", { staticClass: "time" }, [
                _vm._v("时间: " + _vm._s(item.alarmTimeStr)),
              ]),
              _c("p", { staticClass: "lnt" }, [
                _vm._v("经度： " + _vm._s(item.lon)),
              ]),
              _c("p", { staticClass: "lat" }, [
                _vm._v("纬度： " + _vm._s(item.lat)),
              ]),
            ]),
          ])
        }),
        0
      ),
      _c("Modal", {
        attrs: { title: _vm.title },
        on: { close: _vm.close },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-content" }, [
                  _c("img", { attrs: { src: _vm.url, alt: "" } }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.visible,
          callback: function ($$v) {
            _vm.visible = $$v
          },
          expression: "visible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }