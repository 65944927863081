var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Box" }, [
    _c("div", { staticClass: "topinfo" }, [
      _vm.taskList.length
        ? _c(
            "div",
            { staticClass: "top1" },
            [
              _c("avue-select", {
                attrs: { dic: _vm.taskList, clearable: false },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.taskSelect,
                  callback: function ($$v) {
                    _vm.taskSelect = $$v
                  },
                  expression: "taskSelect",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "top2" }, [
        _c("div", { staticClass: "left" }, [
          _c("img", {
            staticClass: "imgUavInfo",
            attrs: { src: _vm.iconMap["drone"], alt: "" },
          }),
          _vm._v("\n        无人机序列号\n      "),
        ]),
        _c("div", { staticClass: "right" }, [
          _vm._v(_vm._s(_vm.equipmentInfo.serialNumber || "-")),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "plane-info" },
      [
        _c(
          "el-row",
          { staticClass: "bodyBox", attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { span: 9 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { staticClass: "elColLeft", attrs: { span: 13 } },
                      [_vm._v(" 当前模式：")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "elColRight", attrs: { span: 11 } },
                      [
                        _vm._v(
                          _vm._s(_vm.store.flyMode ? _vm.store.flyMode : "-")
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 6 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { staticClass: "elColLeft", attrs: { span: 15 } },
                      [_vm._v("当前电量：")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "elColRight", attrs: { span: 9 } },
                      [
                        _vm._v(
                          _vm._s(
                            typeof _vm.store.batteryRemaining === "number"
                              ? "" + (_vm.store.batteryRemaining + "%")
                              : "-"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 9 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { staticClass: "elColLeft", attrs: { span: 8 } },
                      [_vm._v("纬度：")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "elColRight", attrs: { span: 16 } },
                      [
                        _vm._v(
                          _vm._s(_vm.store.latitude ? _vm.store.latitude : "-")
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          { staticClass: "bodyBox", attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { span: 9 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { staticClass: "elColLeft", attrs: { span: 13 } },
                      [_vm._v("飞手姓名：")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "elColRight", attrs: { span: 11 } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.flyOperatorName ? _vm.flyOperatorName : "-"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 6 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { staticClass: "elColLeft", attrs: { span: 15 } },
                      [_vm._v("解锁信号：")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "elColRight", attrs: { span: 9 } },
                      [
                        _vm._v(
                          _vm._s(
                            typeof _vm.store.lockedState === "boolean"
                              ? _vm.store.lockedState
                                ? "已解锁"
                                : "已加锁"
                              : "-"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 9 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { staticClass: "elColLeft", attrs: { span: 8 } },
                      [_vm._v("经度：")]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "elColRight", attrs: { span: 16 } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.store.longitude ? _vm.store.longitude : "-"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom plane-card" },
      [
        _c(
          "el-row",
          { attrs: { type: "flex", justify: "space-between" } },
          [
            _c(
              "el-col",
              { staticClass: "col", attrs: { span: 12 } },
              [
                _c(
                  "el-row",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "left", attrs: { span: 12 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("img", {
                                staticClass: "imgUav",
                                attrs: { src: _vm.iconMap["speed"], alt: "" },
                              }),
                            ]),
                            _c("el-col", { attrs: { span: 16 } }, [
                              _vm._v("速度"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "right", attrs: { span: 12 } },
                      [
                        _vm._v(_vm._s(_vm.store.gs)),
                        _c("span", [_vm._v("m/s")]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { staticClass: "col", attrs: { span: 12 } },
              [
                _c(
                  "el-row",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "left", attrs: { span: 12 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("img", {
                                staticClass: "imgUav",
                                attrs: {
                                  src: _vm.iconMap["altitude"],
                                  alt: "",
                                },
                              }),
                            ]),
                            _c("el-col", { attrs: { span: 16 } }, [
                              _vm._v("场高"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "right", attrs: { span: 12 } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.store.height ? _vm.store.height.toFixed(2) : 0
                          )
                        ),
                        _c("span", [_vm._v("m")]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          {
            staticStyle: { "margin-top": "20px" },
            attrs: { type: "flex", justify: "space-between" },
          },
          [
            _c(
              "el-col",
              { staticClass: "col", attrs: { span: 12 } },
              [
                _c(
                  "el-row",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "left", attrs: { span: 12 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("img", {
                                staticClass: "imgUav",
                                attrs: { src: _vm.iconMap["mileage"], alt: "" },
                              }),
                            ]),
                            _c("el-col", { attrs: { span: 16 } }, [
                              _vm._v("实时里程"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "right", attrs: { span: 12 } },
                      [
                        _vm._v(
                          _vm._s(this.flyDistanceFormat) + "\n            "
                        ),
                        _c("span", [_vm._v("km")]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { staticClass: "col", attrs: { span: 12 } },
              [
                _c(
                  "el-row",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "left", attrs: { span: 12 } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("img", {
                                staticClass: "imgUav",
                                attrs: {
                                  src: _vm.iconMap["flight_time"],
                                  alt: "",
                                },
                              }),
                            ]),
                            _c("el-col", { attrs: { span: 16 } }, [
                              _vm._v("飞行时间"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "right", attrs: { span: 12 } },
                      [_vm._v(_vm._s(this.minuteFormate))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }