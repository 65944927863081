<template>
    <!-- 公共元素盒子 -->
    <div class="base-box">
        <div class="title">
            <img class="left-img" src="@/assets/images/plane-new-titleleft.png" />
            <span>{{ title }}</span>
            <img class="right-img" src="@/assets/images/plane-new-titleright.png" />
        </div>
        <div class="content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseBox',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.base-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid rgba(15, 36, 76, 0.3);
    border-radius: 4px;
    position: relative;
    .title {
        display: flex;
        align-items: center;
        height: 33px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("~@/assets/images/plane-new-title.png");
        position: relative;
        cursor: pointer;
        span {
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 15px;
            color: #FFFFFF;
            margin-left: 38px;
        }
        .left-img {
            width: 30px;
            height: 28px;
            position: absolute;
            left: 10px;
            bottom: 0;
        }
        .right-img {
            width: 43px;
            height: 7px;
            position: absolute;
            right: 5px;
            bottom: 5px;
        }
    }
    .content {
        width: 100%;
        height: 0;
        display: flex;
        flex: 1;
        flex-direction: column;
        background: rgba(0, 14, 46, 0.4);
        box-sizing: border-box;
        overflow: auto;
    }
}
</style>