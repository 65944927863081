var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "AlarmRecord" }, [
    _c(
      "div",
      { staticClass: "Alarm-map" },
      [
        _c("oliMap", {
          attrs: {
            isShowTopTools: false,
            isTracked: false,
            mapType: "alarmMap",
            alarmData: _vm.alarmData,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "top-panle" }, [
      _c(
        "div",
        { staticClass: "select-item" },
        [
          _c(
            "el-select",
            {
              attrs: {
                size: "mini",
                placeholder: _vm.$t("oilGasScreen.pleaseSelectInspectionItem"),
              },
              on: { change: _vm.changeSelectData },
              model: {
                value: _vm.chenckForm.project,
                callback: function ($$v) {
                  _vm.$set(_vm.chenckForm, "project", $$v)
                },
                expression: "chenckForm.project",
              },
            },
            _vm._l(_vm.projectList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "select-item" },
        [
          _c(
            "el-select",
            {
              attrs: {
                size: "mini",
                placeholder: _vm.$t("oilGasScreen.pleaseSelectAlarmType"),
              },
              on: { change: _vm.changeSelectData },
              model: {
                value: _vm.chenckForm.alarmType,
                callback: function ($$v) {
                  _vm.$set(_vm.chenckForm, "alarmType", $$v)
                },
                expression: "chenckForm.alarmType",
              },
            },
            _vm._l(_vm.alarmTypeList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "time-item" },
        [
          _c("el-date-picker", {
            attrs: {
              size: "mini",
              type: "datetimerange",
              "range-separator": _vm.$t("to"),
              "popper-class": "time-item-poper",
              "start-placeholder": _vm.$t("startTime"),
              "end-placeholder": _vm.$t("endTime"),
              "default-time": ["00:00:00", "23:59:59"],
              "value-format": "yyyy-MM-dd HH:mm:ss",
              clearable: false,
            },
            on: { change: _vm.changeSelectData },
            model: {
              value: _vm.chenckForm.time,
              callback: function ($$v) {
                _vm.$set(_vm.chenckForm, "time", $$v)
              },
              expression: "chenckForm.time",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "left-panle panle-main" }, [
      _c("div", { staticClass: "panle-content" }, [
        _c(
          "div",
          { staticClass: "panle-item" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.factoryAlarm") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("factoryAlarm", {
                        attrs: { factoryAlarmData: _vm.factoryAlarmData },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "panle-item" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.objectAlarm") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("inspectionAlarm", {
                        attrs: { inspectionAlarmData: _vm.inspectionAlarmData },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "panle-item" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.alarmType") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("alarmType", {
                        attrs: { alarmTypeData: _vm.alarmTypeData },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "right-panle panle-main" }, [
      _c("div", { staticClass: "panle-content" }, [
        _c(
          "div",
          { staticClass: "panle-item" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.alarmTrend") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("alarmTrend", {
                        attrs: { alarmTrendData: _vm.alarmTrendData },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "panle-item" },
          [
            _c("BaseBox", {
              attrs: { title: _vm.$t("oilGasScreen.realTimeAlarm") },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("realTimeAlarm", {
                        attrs: { realTimeAlarmData: _vm.realTimeAlarmData },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }