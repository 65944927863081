<template>
<div class="trend-box">
  <LineChart />
  <WarningBox />
</div>
  
</template>

<script>
import LineChart from './components/LineChart.vue'
import WarningBox from './components/WarningBox.vue'
export default {
  components: {
    LineChart,
    WarningBox
  }
}
</script>

<style lang="scss" scoped>
.trend-box {
  height: 100%;
  overflow: hidden;
}
</style>