<template>
  <div class="base-box" :class="bigBGIMG? 'base-box-big': ''">
    <div class="title" @click="$emit('clickTitle')">
      <span>{{ title }}</span>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    bigBGIMG: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
};
</script>

<style scoped lang="scss">
.base-box {
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 1px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("~@/assets/images/plane-new.png");
  .title {
    position: absolute;
    z-index: 2001;
    left: 0;
    top: -16px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("~@/assets/images/tag-bg-new.png");
    height: 32px;
    line-height: 32px;
    padding: 0 24px;
    cursor: pointer;
    span {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }
  .content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 8px;
    border-radius: 0px 40px 40px 40px;
  }
}
.base-box-big {
  background-image: url("~@/assets/images/plane-new-big.png") !important;
}
</style>
