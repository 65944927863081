var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "podVideo-page" }, [
    _c(
      "div",
      { staticClass: "swtch-uav" },
      [
        _c(
          "el-select",
          {
            attrs: {
              size: "mini",
              placeholder: _vm.$t("oilGasScreen.pleaseSelectDrone"),
            },
            on: { change: _vm.changeUav },
            model: {
              value: _vm.checkUav,
              callback: function ($$v) {
                _vm.checkUav = $$v
              },
              expression: "checkUav",
            },
          },
          _vm._l(_vm.uavList, function (item) {
            return _c("el-option", {
              key: item.taskName,
              attrs: { label: item.uavName, value: item.uavName },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "video-cont" },
      [
        _vm.liveVideoVOList.length
          ? _c(_vm.useLive, {
              ref: "videoLive",
              tag: "component",
              staticClass: "video-plane",
              attrs: { screen: false, liveVideoVOList: _vm.liveVideoVOList },
            })
          : _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: true,
                  expression: "true",
                },
              ],
              staticClass: "video-loading",
              attrs: {
                "element-loading-text": _vm.$t("WaitingForVideoTransmission"),
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }