var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "center-content",
      class: { "screen-center-content": _vm.taskData },
    },
    [
      _c(
        "div",
        { staticClass: "map-container" },
        [
          _vm.taskData && _vm.liveVideoVOList(_vm.taskData).length
            ? _c("div", { staticClass: "suspended" }, [
                _c("div", { staticClass: "suspended-cont" }, [
                  _c(
                    "div",
                    {
                      staticClass: "suspended-item",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("clearnSelectTask", "")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("navbar.screenfullF")))]
                  ),
                ]),
              ])
            : _vm._e(),
          _c("BaseBox", {
            staticClass: "label-title",
            attrs: {
              title: !_vm.taskData
                ? _vm.$t("screen.realMap")
                : _vm.$t("screen.realVideo"),
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "screen-map",
                        class: { "narrow-map": _vm.taskData },
                        style: _vm.locationObj,
                      },
                      [
                        _vm.taskData
                          ? _c("div", { staticClass: "narrow-map-top" }, [
                              _vm._v(_vm._s(_vm.$t("screen.realMap"))),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "screen-map-center",
                            class: { "narrow-map-center": _vm.taskData },
                          },
                          [
                            _c(_vm.mapComponent, {
                              ref: "map",
                              tag: "component",
                              attrs: {
                                projectInfo: _vm.projectInfo,
                                showTopTools: false,
                                showBottomTools: false,
                                isEmergency: true,
                                isChangeStyle: true,
                                showMapTips: true,
                                mapData: _vm.mapData,
                              },
                              on: { changeUav: _vm.changeUav },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.taskData
                      ? _c("div", { staticClass: "screen-video" }, [
                          _c(
                            "div",
                            { staticClass: "screen-video-center" },
                            [
                              _vm.liveVideoVOList(_vm.taskData).length
                                ? _c(_vm.useLive(_vm.taskData), {
                                    ref: "videoLive",
                                    tag: "component",
                                    staticClass: "video-plane",
                                    attrs: {
                                      screen: false,
                                      liveVideoVOList: _vm.liveVideoVOList(
                                        _vm.taskData
                                      ),
                                    },
                                  })
                                : _c("div", {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: true,
                                        expression: "true",
                                      },
                                    ],
                                    staticClass: "video-loading",
                                    attrs: {
                                      "element-loading-text": _vm.$t(
                                        "WaitingForVideoTransmission"
                                      ),
                                      "element-loading-spinner":
                                        "el-icon-loading",
                                      "element-loading-background":
                                        "rgba(0, 0, 0, 0.8)",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }