<template>
  <div class="base">
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style scoped lang="scss">
.base {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // box-shadow: inset 0px 0px 10px 3px #124db228;
  // background-image: url("~@/assets/images/content-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  // padding-top: 20px;
  i {
    display: block;
  }
  .title {
    position: absolute;
    z-index: 9;
    left: 0;
    top: -16px;
    background-image: url("~@/assets/images/tag-bg.png");
    height: 32px;
    width: 150px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      // letter-spacing: 1px;
    }
  }
  .content {
    width: 100%;
    height: 100%;
    flex: 1;
  }
}
</style>
