<template>
    <!-- 报警趋势图 -->
    <div class="alarmTrend">
        <div class="swtch-list">
            <el-radio-group v-model="timeType" size="mini" @input="handlerData">
                <el-radio :label="'dailyAlarmTrend'">{{$t('oilGasScreen.daily')}}</el-radio>
                <el-radio :label="'weeklyAlarmTrend'">{{$t('oilGasScreen.weekly')}}</el-radio>
                <el-radio :label="'monthlyAlarmTrend'">{{$t('oilGasScreen.monthly')}}</el-radio>
            </el-radio-group>
        </div>
        <div class="chart-cont">
            <chartLine 
                :id="'alarmTrend-chart'"
                :xAxisLabel="{color: '#fff', rotate: 0, fontSize: 10}"
                :areaStyleList="[areaStyle]"
                :chartData="chartData"
            />
        </div>
    </div>
</template>

<script>
import chartLine from '@/components/echarts/chartLine.vue';

export default {
    components: {chartLine},
    name: 'alarmTrend',
    props: ['alarmTrendData'],
    data() {
        return {
            timeType: 'dailyAlarmTrend',
            chartData: []
        }
    },
    watch: {
        'alarmTrendData': {
            deep: true,
            handler: function() {
                this.handlerData();
            }
        }
    },
    methods: {
        handlerData() {
            let data = this.alarmTrendData.find(t => t.name === this.timeType).data;
            let chartData = [];
            Object.keys(data).map(t => {
                let charItem = [];
                Object.keys(data[t]).map(i => {
                    charItem.push({name: i, value: data[t][i] || 0});
                });
                chartData.push({name: t, data: charItem});
            });
            this.chartData = chartData;
        }
    }
}
</script>

<style lang="scss" scoped>
.alarmTrend {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .swtch-list {
        position: absolute;
        top: 3px;
        right: 10px;
        z-index: 5;
        ::v-deep .el-radio-group {
            .el-radio {
                margin-right: 8px;
                .el-radio__inner {
                    width: 12px;
                    height: 12px;
                }
                .el-radio__label {
                    font-size: 12px;
                    color: #fff;
                    padding-left: 5px;
                }
                .el-radio__input.is-checked+.el-radio__label {
                    color: #409EFF;
                }
            }
        }
    }
    .chart-cont {
        width: 100%;
        height: 100%;
        position: relative;
    }
}
</style>