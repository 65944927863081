<template>
  <div class="forget">
    <div class="forget-title">
      {{ $t("forget.title") }}
    </div>
    <el-form
      class="forget-form"
      :rules="forgetRules"
      ref="forgetForm"
      :model="forgetForm"
      label-width="10px"
    >
      <el-form-item label=" " prop="account">
        <el-input
          size="small"
          class="login-input"
          v-model="forgetForm.account"
          auto-complete="off"
          :placeholder="$t('forget.username')"
        >
        </el-input>
      </el-form-item>
      <el-form-item label=" " prop="newPassword">
        <el-input
          size="small"
          class="login-input"
          :type="passwordType"
          v-model="forgetForm.newPassword"
          auto-complete="off"
          :placeholder="$t('forget.password')"
        >
          <i
            class="el-icon-view el-input__icon"
            slot="suffix"
            @click="showPassword"
          />
        </el-input>
        <div class="forget-tips">{{ $t("forget.passwrodTips") }}</div>
      </el-form-item>
      <el-form-item label=" " prop="newPassword1">
        <el-input
          size="small"
          class="login-input"
          :type="passwordType"
          v-model="forgetForm.newPassword1"
          auto-complete="off"
          :placeholder="$t('forget.ensurePwd')"
        >
        </el-input>
      </el-form-item>
      <el-form-item label=" " prop="phone">
        <el-input
          size="small"
          class="login-input"
          v-model="forgetForm.phone"
          auto-complete="off"
          :placeholder="$t('forget.phone')"
        >
        </el-input>
      </el-form-item>
      <el-form-item label=" " v-if="this.website.captchaMode" prop="code">
        <el-row :span="24">
          <el-col :span="16">
            <el-input
              size="small"
              class="login-input"
              v-model="forgetForm.code"
              auto-complete="off"
              :placeholder="$t('forget.msgText')"
            >
            </el-input>
          </el-col>
          <el-col :span="8">
            <!-- <el-button class="send-code" plain>发送验证码</el-button> -->
             <el-button
              class="send-code"
              plain
              @click="sendSms"
              :disabled="smsBtnDisabled"
              >{{ smsBtnTitle }}</el-button
            >
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <div class="btn-group">
          <el-button
            type="primary"
            class="btn btn-cancel"
            size="small"
            @click="cancel"
            >{{ $t("forget.cancel") }}
          </el-button>
          <el-button
            type="primary"
            class="btn btn-submit"
            size="small"
            @click="submit"
            >{{ $t("forget.submit") }}
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { findPassword, sendSmsCode } from "@/api/login";
import md5 from "js-md5";

export default {
  name: "forget",
  data() {
    // 电话号码校验
    var phoneReg = (rule, value, callback) => {
      var reg =
        /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
      if (!value) {
        callback(new Error("请填写电话号码！"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请检查电话号码格式！"));
        }
        callback();
      }
    };
    // 密码校验
    var passwordReg = (rule, value, callback) => {
      var reg = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/;
      if (value === "") {
        callback(new Error("请输入密码！"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入6-16位由大小写英文字母和数字组成的密码！"));
        } else {
          if (this.forgetForm.newPassword1 !== "") {
            this.$refs.forgetForm.validateField("newPassword1");
          }
          callback();
        }
      }
    };
    // 再次输入密码校验
    var checkPasswordReg = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.forgetForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      smsBtnTitle: "发送验证码",
      smsBtnDisabled: false,
      forgetForm: {
        //用户名
        account: "",
        //密码
        newPassword: "",
        // 确认密码
        newPassword1: "",
        // 电话号码
        phone: "",
        //验证码的值
        code: "",
      },
      forgetRules: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        newPassword: [
          { required: true, validator: passwordReg, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        newPassword1: [
          { required: true, validator: checkPasswordReg, trigger: "blur" },
        ],
        phone: [{ required: true, validator: phoneReg, trigger: "blur" }],
      },
      passwordType: "password",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["tagWel"]),
  },
  props: [],
  methods: {
    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.smsBtnDisabled = false;
          this.smsBtnTitle = "发送验证码";
        } else {
          this.smsBtnTitle = time + "秒后重试";
          this.smsBtnDisabled = true;
          time--;
        }
      }, 1000);
    },
    sendSms() {
      this.$refs.forgetForm.validateField("phone", (phone_check_err) => {
        if (!!phone_check_err) {
          return;
        }
        const params = {
          phone: this.forgetForm.phone,
        };
        sendSmsCode(params)
          .then((res) => {
            if (res.data.code === 200) {
              this.validateBtn();
              this.$message.success("短信发送成功！");
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },
    cancel() {
      this.$emit("goFlow", "login");
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    submit() {
      this.$refs.forgetForm.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "密码重置中...请稍后...",
            spinner: "el-icon-loading",
          });
          let mdForm = JSON.parse(JSON.stringify(this.forgetForm));
          mdForm.newPassword = md5(mdForm.newPassword);
          mdForm.newPassword1 = md5(mdForm.newPassword1);
          findPassword(mdForm)
            .then((res) => {
              console.log(res);
              if (res.data.code === 200) {
                this.$message.success("密码重置成功！");
                loading.close();
                setTimeout(() => {
                  this.$emit("goFlow", "login");
                }, 1000);
              }
            })
            .catch((err) => {
              loading.close();
              this.$message.error(err);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.forget {
  &-title {
    color: #0088ff;
    font-size: 24px;
    margin-top: 46px;
    padding-left: 32px;
  }
  &-form {
    margin-top: 22px;
    .el-form-item {
      margin-bottom: 12px;
      margin-left: 32px;
      position: relative;
      &__content {
        width: 480px;
      }
      &__label {
        position: absolute;
        left: 16px;
        top: 3px;
      }
    }

    .el-input {
      input {
        // padding-bottom: 10px;
        text-indent: 5px;
        background: transparent;
        border: none;
        border-radius: 0;
        color: #333;
        border-bottom: 1px solid rgb(235, 237, 242);
      }

      .el-input__prefix {
        i {
          padding: 0 5px;
          font-size: 16px !important;
        }
      }
    }
  }
  &-tips {
    color: #b8b8b8;
    font-size: 12px;
    margin-top: -10px;
    height: 30px;
  }
  .send-code {
    width: 132px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b8b8b8 !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    color: #9b9b9b !important;
    margin-left: 21px;
  }
  .btn-group {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    padding: 0 34px 0 5px;
    .btn {
      width: 180px;
      height: 64px;
      font-size: 24px;
      border-radius: 8px;
      &-cancel {
        border: 1px solid #0088ff !important;
        background: #ffffff !important;
        color: #0088ff !important;
      }
      &-submit {
        background: #0088ff !important;
        color: #ffffff !important;
      }
    }
  }
}
</style>
