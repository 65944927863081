<template>
  <div class="screen-box-oil">
    <loading-view
      v-if="loading">
    </loading-view>
    <div class="screen-main-oil">
      <navigation-bar></navigation-bar>
      <div class="screen-oil-content">
        <!-- <keep-alive> -->
          <router-view @loadEnd="loadEnd">
          </router-view>
        <!-- </keep-alive> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validatenull } from "@/util/validate";
import { calcDate } from "@/util/date.js";
import { getStore } from "@/util/store.js";
import loadingView from '@/components/loadingview/index.vue';
import navigationBar from './components/navigationBar/index.vue';

export default {
  components: { loadingView, navigationBar },
  data() {
    return {
      loading: true,
      // 刷新token锁
      refreshLock: false,
      // 刷新token的时间
      refreshTime: ""
    }
  },
  computed: {
    ...mapGetters([
      "website"
    ])
  },
  created() {
    const screenOil = this.$route.query.screenOil;
    if (screenOil) {
      sessionStorage.setItem('screenOil', true);
    }
    // 实时检测刷新token
    this.refreshToken();
  },
  methods: {
    loadEnd(){
      this.loading = false
    },
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: "user-center-access-token",
          debug: true,
        }) || {};
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= this.website.tokenTime && !this.refreshLock) {
          this.refreshLock = true;
          this.$store.dispatch("refreshToken").then(() => {
            this.refreshLock = false;
          }).catch(() => {
            this.refreshLock = false;
          });
        }
      }, 10000);
    }
  },
  beforeDestroy() {
    this.refreshTime && clearInterval(this.refreshTime);
    this.refreshTime = '';
  }
}
</script>

<style lang="scss">
.screen-box-oil {
  height: 100%;
  overflow: hidden;
  .screen-main-oil{
    width: 100%;
    height: 100%;
    background: radial-gradient( 0% 67% at 49% 52%, rgba(0,0,0,0.03) 0%, #000E2E 100%);
    .screen-oil-content{
      background-image: url("~@/assets/images/oil-content.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      padding: 0px 28px 48px 28px;
      height: calc(100% - 63px);
    }
  }
}
</style>