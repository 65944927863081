var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "contentWrap" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("Left", {
            ref: "left",
            on: { handleContentClick: _vm.handleContentClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center" },
        [_c("Center", { attrs: { projectInfo: _vm.detail } })],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [_c("Right", { attrs: { detail: _vm.detail } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }